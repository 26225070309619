export interface ClovaDomainUrlAndSecret {
  id?: string;
  name?: string;
  secret?: string;
  url?: string;
}

export interface PrivateConfiguration {
  clova_general_api_key?: string;
  clova_general_api_url?: string;
  openai_api_key?: string;
}

export const EmptyPrivateConfiguration: PrivateConfiguration = {
  clova_general_api_key: '',
  clova_general_api_url: '',
  openai_api_key: '',
};
