import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { PolarApiService } from 'src/app/polar/polar-api.service';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { ClovaDomainUrlAndSecret } from 'src/app/polar/entity/PrivateConfiguration';
import { PromptConfiguration } from 'src/app/polar/entity/PromptConfiguration';
import {
  ClovaGeneralWorkflow,
  ClovaRectWorkflow,
  dumpPromptScriptLastGenerated,
  GptWorkflow,
  LogicalWorkflow,
  MapWorkflow,
  RefineWorkflow,
  RegexWorkflow,
  TableExtractionWorkflow,
  WorkflowProcessor,
} from 'src/app/polar/entity/Workflow';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { MasterMapSelectService } from './workflow-map-select/master-map-select.service';
import { MasterDocumentData, MasterGroupDocumentData } from '../../../polar/entity/master';

@Component({
  selector: 'app-workflow-editor',
  templateUrl: './workflow-editor.component.html',
  styleUrls: ['./workflow-editor.component.scss'],
})
export class WorkflowEditorComponent implements OnInit {
  private readonly masterMapSelectService = inject(MasterMapSelectService);
  readonly state$ = this.masterMapSelectService.state$;

  _proc: WorkflowProcessor = {
    read: {},
    write: {},
    fileName: '',
  };
  @Input() set proc(val: WorkflowProcessor) {
    this._proc = val;
    this.procArrEditing = [];
    if (val.read['*'] != null) {
      this.procArrEditing = val.read['*'];
    }
  }

  get proc(): WorkflowProcessor {
    return this._proc;
  }

  procArrEditing: (ClovaGeneralWorkflow | GptWorkflow | MapWorkflow | LogicalWorkflow)[] = [];

  @Output() readSave: EventEmitter<WorkflowProcessor> = new EventEmitter();
  @Output() readRefresh: EventEmitter<WorkflowProcessor> = new EventEmitter();

  workflowSelecting: boolean = false;
  workflowInsertTo: number | undefined = undefined;

  promptConfigs: PromptConfiguration[] = [];
  clovaDomains: ClovaDomainUrlAndSecret[] = [];

  @Input() saving: boolean = false;
  @Input() savingStat: 'loading' | 'completed' | 'failed' = 'loading';
  @Input() keyValues: { [key: string]: string } = {};

  @Input() companyId: string = '';
  @Input() workId: string = '';

  @Input() lastErrId: string = '';

  @Input() estimatedCost?: number = undefined;
  @Input() estimatedCostDetail?: string = undefined;

  mapStrategies: { id: string; name: string }[] = [
    { id: 'all', name: 'all' },
    { id: 'exact', name: 'exact' },
    { id: 'contains-in-csv', name: 'contains-in-csv' },
    { id: 'startswith-csv', name: 'startswith-csv' },
    { id: 'endswith-csv', name: 'endswith-csv' },
    { id: 'contains-in-text', name: 'contains-in-text' },
    { id: 'startswith-text', name: 'startswith-text' },
    { id: 'endswith-text', name: 'endswith-text' },
    { id: 'ai', name: 'ai' },
  ];

  mapClovaRectVersions: { id: string; name: string }[] = [
    { id: '1', name: 'Version 1' },
    { id: '2', name: 'Version 2' },
  ];

  mapGeneralVersions: { id: string; name: string }[] = [
    { id: '1', name: 'Version 1' },
    { id: '2', name: 'Version 2' },
    { id: '3', name: 'Version 3' },
  ];

  mapRefineStrategies: { id: string; name: string }[] = [
    { id: 'hand-written-digit', name: '手書き数字' },
  ];

  mapTableExtractStrategies: { id: string; name: string }[] = [
    { id: 'extract', name: 'Step01: テーブル抽出' },
    { id: 'extract-multiple', name: 'Step01: テーブル抽出 (v2)' },
    { id: 'pretty', name: 'Step02: 抽出したテーブルから整形する' },
  ];

  logicalEngines: { id: string; name: string }[] = [
    { id: 'text-davinci-003', name: '変更がなければこのまま使用できます' },
    { id: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo' },
    { id: 'gpt-4', name: 'GPT-4' },
  ];

  naturalEngines: { id: string; name: string }[] = [
    { id: 'text-davinci-003', name: '2023年中に変更してください' },
    { id: 'gpt-3.5-turbo', name: 'GPT-3.5 Turbo' },
    { id: 'gpt-4', name: 'GPT-4' },
  ];

  loading: boolean = false;

  getKeys(): string[] {
    return Object.keys(this.keyValues);
  }

  getValue(key: string): string {
    return this.keyValues[key];
  }

  get lang(): string {
    return this.translator.currentLang;
  }

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private clova: PolarApiService,
    private translator: TranslateService,
  ) {
  }

  async ngOnInit() {
    (await this.polar.getPromptConfigurations()).subscribe((f) => {
      this.promptConfigs = f;
    });
    (await this.polar.getConfigurationsClovaDomains()).subscribe((f) => {
      this.clovaDomains = f;
    });
  }

  fileChanged(item: MapWorkflow) {
    if (item.master === undefined) {
      item.vectors_file_name = null;
      item.vectors_csv_column_name_last_generated = null;
    } else {
    }
  }

  addWorkflow() {
    this.workflowInsertTo = undefined;
    this.workflowSelecting = true;
  }

  setPrompt(item: GptWorkflow, promptId: string) {
    if (item.base_prompt != promptId) {
      let config = this.getPromptConfig(promptId);
      item.base_prompt = promptId;
      item.args = this.getPromptArgs(config!);
    }
  }

  setEngine(item: GptWorkflow, engineId: string) {
    if (item.engine != engineId) {
      item.engine = engineId;
    }
  }

  setEngineLogical(item: LogicalWorkflow, engineId: string) {
    if (item.engine != engineId) {
      item.engine = engineId;
    }
  }

  getPromptArgs(config: PromptConfiguration): { [key: string]: string } {
    let text = config.prompt!;
    // get variables from text that like {ARG1}
    let args = text.match(/{(.*?)}/g);
    if (args == null) return {};
    let ret: { [key: string]: string } = {};
    for (let arg of args) {
      let key = arg.replace('{', '').replace('}', '');
      ret[key] = '';
    }
    return ret;
  }

  getPromptConfig(id: string): PromptConfiguration | undefined {
    for (let config of this.promptConfigs) {
      if (config.id == id) return config;
    }
    return undefined;
  }

  getLogicalSelectedEngine(item: LogicalWorkflow): string {
    if (item.engine != undefined) {
      return item.engine;
    } else {
      return 'text-davinci-003';
    }
  }

  isLogicalEngineDeprecated(item: LogicalWorkflow): boolean {
    return item.engine == undefined || item.engine == 'text-davinci-003';
  }

  isNaturalEngineDeprecated(item: GptWorkflow): boolean {
    return item.engine == undefined || item.engine == 'text-davinci-003';
  }

  async workflowAdded(type: string) {
    this.workflowSelecting = false;

    let adding:
      | (
      | ClovaGeneralWorkflow
      | TableExtractionWorkflow
      | ClovaRectWorkflow
      | GptWorkflow
      | MapWorkflow
      | LogicalWorkflow
      | RefineWorkflow
      )
      | undefined;

    switch (type) {
      case 'clova-general':
        adding = {
          id: 'general',
          provider: 'clova-general',
          tableRecognition: true,
          convertDashToSolid: false,
          version: '3',
        };
        break;
      case 'table-extraction':
        adding = {
          id: 'table',
          provider: 'table-extraction',
          startRowFinderText: '',
          endRowFinderText: '',
          strategy: 'extract-multiple',
          expectedHeader: '',
          mapper: '',
        };
        break;
      case 'natural':
        if (this.promptConfigs.length == 0) {
          alert('no prompts available');
          return;
        }
        let args = this.getPromptArgs(this.promptConfigs[0]!);
        adding = {
          id: 'natural',
          provider: 'openai-gpt',
          engine: 'gpt-4',
          base_prompt: this.promptConfigs[0].id,
          args: args,
        };
        break;
      case 'logical':
        adding = {
          id: 'logical',
          provider: 'logical',
          engine: 'gpt-4',
          prompt: '',
          input: '',
          script: '',
          promptScriptLastGenerated: null,
          testText: '',
          unifyAlphas: true,
          unifyNumbers: true,
          unifySymbols: true,
        };
        break;
      case 'clova-rect':
        adding = {
          id: 'rect',
          provider: 'clova-rect',
          version: '2',
        };
        break;
      case 'map':
        adding = {
          id: 'map',
          provider: 'map',
        };
        break;
      case 'regex':
        adding = {
          id: 'regex',
          provider: 'regex',
        };
        break;
      case 'refine':
        adding = {
          id: 'refine',
          provider: 'refine',
          target: '',
          strategy: 'hand-written-digit',
          overrideTarget: true,
        };
        break;
    }

    if (adding != undefined) {
      let currentId = adding.id;
      for (let i = 0; i < 9999; i++) {
        let idx = this.procArrEditing.findIndex((f) => f.id == currentId);

        if (idx < 0) {
          break;
        }

        currentId = adding.id + '_' + (i + 1);
      }
      adding.id = currentId;

      if (
        this.workflowInsertTo != undefined &&
        this.procArrEditing.length > this.workflowInsertTo
      ) {
        // insert
        this.procArrEditing.splice(this.workflowInsertTo, 0, adding);
      } else {
        this.procArrEditing.push(adding);
      }
    }

    this.workflowInsertTo = undefined;
  }

  insertWorkflow(insertTo: number) {
    this.workflowInsertTo = insertTo;
    this.workflowSelecting = true;
  }

  deleteWorkflow(id: string) {
    let idx = this.procArrEditing.findIndex((f) => f.id == id);
    this.procArrEditing.splice(idx, 1);
  }

  toClovaGeneral(item: any) {
    return item as ClovaGeneralWorkflow;
  }

  toClovaRect(item: any) {
    return item as ClovaRectWorkflow;
  }

  toTableExtraction(item: any) {
    return item as TableExtractionWorkflow;
  }

  toOpenaiGpt(item: any) {
    return item as GptWorkflow;
  }

  toLogical(item: any) {
    return item as LogicalWorkflow;
  }

  toMap(item: any) {
    return item as MapWorkflow;
  }

  toRegex(item: any) {
    return item as RegexWorkflow;
  }

  toRefine(item: any) {
    return item as RefineWorkflow;
  }

  toList(args: { [index: string]: string }) {
    let keys = Object.keys(args);
    // sort
    keys.sort();
    return keys;
  }

  async tryLogical(item: LogicalWorkflow) {
    this.loading = true;
    try {
      if (item.promptScriptLastGenerated != dumpPromptScriptLastGenerated(item)) {
        item.script = await this.clova.createScriptFromPrompt(
          item.prompt,
          item.unifyNumbers,
          item.unifySymbols,
          item.unifyAlphas,
          item.engine,
        );
        item.promptScriptLastGenerated = dumpPromptScriptLastGenerated(item);
      }

      let script = item.script;
      script = script.replace('<INPUT_DATA>', item.testText.replace(/"/g, '\\"'));

      try {
        eval(script + '\nwindow.__result = output;');
      } catch {
        await this.commonUi.showConfirm({
          title: 'エラー',
          body: '実行できません',
          buttons: ['OK'],
        });
        item.promptScriptLastGenerated = null;
      }

      item.testResult = (window as any).__result;

      (window as any).__result = undefined;
    } catch {
    }
    this.loading = false;
  }

  save() {
    console.log(this.proc);
    this.proc.read['*'] = this.procArrEditing;
    this.readSave.emit(this.proc);
  }

  refresh() {
    this.proc.read['*'] = this.procArrEditing;
    this.readRefresh.emit(this.proc);
  }

  previewText(val: string) {
    this.commonUi.showConfirm({
      body: val,
      buttons: ['OK'],
    });
  }

  async showEstimatedCostDetail() {
    await this.commonUi.showConfirm({
      title: this.translator.instant('コスト'),
      body: this.estimatedCostDetail,
      buttons: ['OK'],
    });
  }

  closeMasterCreatingModal() {
    this.masterMapSelectService.closeMasterCreatingModal();
  }

  closeMasterEditingModal() {
    this.masterMapSelectService.closeMasterEditingModal();
  }

  async masterListUpdated() {
    await this.commonUi.showConfirm({
      title: this.translator.instant('マスタを更新しました'),
      body: this.translator.instant('マスタを更新しました'),
      buttons: ['OK'],
    });

    this.masterMapSelectService.closeMasterCreatingModal();
    this.masterMapSelectService.closeMasterEditingModal();

    // NOTE: マスタの作成・編集で更新があればマスタとグループを再取得する
    //       最新のマスタを利用しているかの状態を更新するために選択状態も更新する
    await this.masterMapSelectService.fetchGroups(this.masterMapSelectService.currentCompanyId!);
  }

  async upgradeMasterManagementStrategy(map: MapWorkflow) {
    const fileRef = map.csv_file_name;
    const result = await this.commonUi.showConfirm({
      title: 'マスタ管理方式のアップグレードを行いますか？',
      body: 'この操作を行うとCSVファイルは失われます。\r\n必要に応じて、CSVファイルを先にダウンロードしてください',
      additionalLinkTitle: 'ダウンロード',
      additionalLinkAction: async () => {
        const url = await this.polar.getCsvMasterFileUrl(fileRef);
        this.download('master_file_download.csv', url);
      },
      buttons: ['Yes', 'No'],
    });

    console.log(map.csv_file_name);

    if (result != 'Yes') {
      return;
    }

    // remove all the keys
    map.csv_file_name = '';
  }

  download(filename: string, link: string) {
    var element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    setTimeout(() => {
      element.click();

      document.body.removeChild(element);
    }, 1000);
  }

  validateLogicalInput(text: string): { alert: boolean; message: string } {
    if (text && text.startsWith('{') && text.endsWith('}')) {
      return { alert: false, message: '' };
    } else {
      return { alert: true, message: 'キーを用いる場合は { } で囲んでください' };
    }
  }
}
