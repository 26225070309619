import { Timestamp } from '@angular/fire/firestore';

export class PolarDocumentData {
  snapshot: any = null;
  id?: string;
  _exists?: boolean;

  findTimestampLikeObjectRecursively(obj: any): any {
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] != undefined &&
        typeof obj[key] == 'object' &&
        obj[key]['_nanoseconds'] != undefined &&
        obj[key]['_seconds'] != undefined
      ) {
        obj[key] = new Timestamp(obj[key]['_seconds'], obj[key]['_nanoseconds']);
      } else if (
        obj[key] != undefined &&
        typeof obj[key] == 'object' &&
        obj[key]['nanoseconds'] != undefined &&
        obj[key]['seconds'] != undefined
      ) {
        obj[key] = new Timestamp(obj[key]['seconds'], obj[key]['nanoseconds']);
      } else if (obj[key] != undefined && typeof obj[key] == 'object') {
        this.findTimestampLikeObjectRecursively(obj[key]);
      }
    });
  }

  constructor(snapshot: any, id: string, exists: boolean, mapIdField?: string) {
    this.snapshot = snapshot;
    this.id = id;
    this._exists = exists;

    if (mapIdField) {
      this.snapshot[mapIdField] = id;
    }

    // new Object for timestamp
    this.findTimestampLikeObjectRecursively(this.snapshot);
  }

  data(options?: {}): any {
    return this.snapshot;
  }

  exists(): boolean {
    return this._exists ?? false;
  }
}
