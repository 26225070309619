{
  "name": "battonai-front",
  "version": "1.20.0",
  "scripts": {
    "ng": "ng",
    "start:stg": "ng serve -c stg",
    "build:stg": "ng build -c stg",
    "build:prod": "ng build -c prod",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "fmt": "prettier --write --ignore-path .gitignore './**/*.{ts,tsx,scss,html}'",
    "fmt:check": "prettier --check --ignore-path .gitignore './**/*.{ts,tsx,scss,html}'",
    "prepare": "husky"
  },
  "lint-staged": {
    "*.{ts,tsx,scss,html}": "prettier --write"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/fire": "^18.0.0",
    "@angular/forms": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@fortawesome/fontawesome-pro": "^6.2.1",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "bulma": "^0.9.4",
    "encoding-japanese": "^2.0.0",
    "iconv-lite": "^0.6.3",
    "jspreadsheet": "^9.2.9",
    "lodash": "^4.17.21",
    "loupe-js": "github:leq6c/loupe-js#feature/proper_offset",
    "ngx-google-analytics": "^14.0.1",
    "prettier": "^3.1.1",
    "rxjs": "~7.8.0",
    "tippy.js": "^6.3.7",
    "tslib": "^2.3.0",
    "x-data-spreadsheet": "github:batton-dev/x-spreadsheet#41e956f2df482707ec2dce967644bc38e3d53831",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.19.2/xlsx-0.19.2.tgz",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular/cli": "~18.2.6",
    "@angular/compiler-cli": "^18.2.5",
    "@trivago/prettier-plugin-sort-imports": "^4.3.0",
    "@types/encoding-japanese": "^2.0.2",
    "@types/jasmine": "~4.3.0",
    "@types/lodash": "^4.17.7",
    "husky": "^9.1.6",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "lint-staged": "^15.2.10",
    "typescript": "~5.4.5"
  }
}
