import { Component } from '@angular/core';

@Component({
  selector: 'app-work-right-bottom-floating',
  templateUrl: './work-right-bottom-floating.component.html',
  styleUrls: ['./work-right-bottom-floating.component.scss'],
})
export class WorkRightBottomFloatingComponent {
  openHelp() {
    window.open('https://batton.notion.site/FAX-8cc93580fdd4465cb7d74e22557ba213');
  }
}
