import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { Encoding, detect, convert } from 'encoding-japanese';
import { CommonUiProviderService } from '../common-ui-provider.service';

@Component({
  selector: 'app-label-and-file-input',
  templateUrl: './label-and-file-input.component.html',
  styleUrls: ['./label-and-file-input.component.scss'],
})
export class LabelAndFileInputComponent {
  @ViewChild('INPUT')
  el?: ElementRef;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiline: boolean = false;
  @Input() grow: number = 4.2;
  @Input() companyId: string = '';
  @Input() workId: string = '';

  @Input()
  set value(val: string) {
    this._ref = val;
  }

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  fileChange = new EventEmitter<void>();

  changeValue(val: string): void {
    this.value = val;
    this.valueChange.emit(val);
  }

  _ref: string = '';
  get ref(): string {
    return this._ref;
  }
  set ref(val: string) {
    this._ref = val;
    this.changeValue(val);
  }

  loading: boolean = false;

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
  ) {}

  selectFile() {
    let elm = this.el!.nativeElement as HTMLInputElement;
    elm.click();
  }

  download(filename: string, link: string) {
    var element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    setTimeout(() => {
      element.click();

      document.body.removeChild(element);
    }, 1000);
  }

  async downloadFile() {
    let path = await this.polar.getCsvMasterFileUrl(this.ref);

    // download url
    this.download('master.csv', path);
  }

  async filePicked(e: any) {
    this.loading = true;
    let file = (this.el!.nativeElement as HTMLInputElement).files![0];
    (this.el!.nativeElement as HTMLInputElement).value = '';

    // load file
    let reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let array = new Uint8Array(reader.result as ArrayBuffer);
        let encoding = detect(array);
        let blob: Blob;
        if (encoding == 'UNICODE') {
          // workaround
          encoding = 'SJIS';
        }
        if (encoding != false) {
          let text = convert(array, 'UTF8', encoding!);
          let fileData = new Uint8Array(text);
          blob = new Blob([fileData], { type: 'text/csv' });
        } else {
          let text = convert(array, 'UTF8');
          let fileData = new Uint8Array(text);
          blob = new Blob([fileData], { type: 'text/csv' });
        }

        // blob to file
        let file = new File([blob], 'master.csv', {
          type: 'text/csv',
        });
        let path = await this.polar.uploadCsvMasterFile(
          this.companyId,
          this.workId,
          'master.csv',
          file,
        );
        this.ref = path;
        this.fileChange.emit();
        this.loading = false;
      } catch {
        this.loading = false;
        this.commonUi.showConfirm({
          title: '失敗しました',
          buttons: ['OK'],
        });
      }
    };
    reader.onerror = () => {
      this.loading = false;
      this.commonUi.showConfirm({
        title: '失敗しました',
        buttons: ['OK'],
      });
    };
    reader.readAsArrayBuffer(file);

    /*
    let path = await this.polar.uploadCsvMasterFile(
      this.companyId,
      this.workId,
      'master.csv',
      file
    );
    this.ref = path;
    */
  }
}
