import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { ConsoleHeaderComponent } from '../console-header/console-header.component';

import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import {
  ClovaDomainUrlAndSecret,
  PrivateConfiguration,
} from 'src/app/polar/entity/PrivateConfiguration';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-console-settings',
  templateUrl: './console-settings.component.html',
  styleUrls: ['./console-settings.component.scss'],
  standalone: true,
  imports: [ConsoleHeaderComponent, InnerProgressComponent, FormsModule],
})
export class ConsoleSettingsComponent implements OnInit {
  configs: PrivateConfiguration = {};
  clovaDomainsObservable$?: Observable<ClovaDomainUrlAndSecret[]>;
  clovaDomains: ClovaDomainUrlAndSecret[] = [];
  deletes: ClovaDomainUrlAndSecret[] = [];

  loading: boolean = false;

  saving: boolean = false;
  savingStat: 'loading' | 'completed' | 'failed' = 'loading';

  constructor(private polar: PolarFirebaseService) {}

  async ngOnInit() {
    this.loading = true;
    this.configs = await this.polar.getConfigurations();
    this.clovaDomainsObservable$ = await this.polar.getConfigurationsClovaDomains();

    // get clova domains once from observable object to list
    this.clovaDomainsObservable$.subscribe((d) => {
      this.deletes = [];
      this.clovaDomains = d;
      this.loading = false;
    });
  }

  addDomain() {
    this.clovaDomains.push({});
  }

  removeDomain(idx: number) {
    this.deletes.push(this.clovaDomains[idx]);
    this.clovaDomains.splice(idx, 1);
  }

  async save() {
    this.saving = true;
    this.savingStat = 'loading';
    try {
      await this.polar.setConfiguration(this.configs);
      await this.deletes.forEach(async (f) => {
        await this.polar.deleteConfigurationsClovaDomain(f);
      });
      await this.clovaDomains.forEach(async (f) => {
        await this.polar.setConfigurationsClovaDomain(f);
      });
      this.deletes = [];
      this.savingStat = 'completed';
    } catch {
      this.savingStat = 'failed';
    } finally {
    }
  }
}
