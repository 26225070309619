import { Component } from '@angular/core';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

import { CallShowConfirm, CommonUiProviderService } from '../common-ui-provider.service';

@Component({
  selector: 'app-common-ui-provider',
  templateUrl: './common-ui-provider.component.html',
  styleUrls: ['./common-ui-provider.component.scss'],
  standalone: true,
  imports: [ConfirmDialogComponent],
})
export class CommonUiProviderComponent {
  showConfirm: boolean = false;
  confirmTitle?: string;
  confirmBody?: string;
  confirmAdditionalLinkTitle?: string;
  confirmAdditionalLinkAction?: () => void;
  confirmButtons: string[] = [];
  showConfirmResolver?: (val: string | undefined) => void;

  constructor(ui: CommonUiProviderService) {
    ui._showConfirm = (val: CallShowConfirm) => {
      return new Promise<string | undefined>((resolve) => {
        this.showConfirmResolver = resolve;
        this.confirmTitle = val.title;
        this.confirmBody = val.body;
        this.confirmAdditionalLinkTitle = val.additionalLinkTitle;
        this.confirmAdditionalLinkAction = val.additionalLinkAction;
        this.confirmButtons = val.buttons;
        this.showConfirm = true;
      });
    };
  }

  confirmClosed(button?: string) {
    this.showConfirm = false;
    this.showConfirmResolver!(button);
  }
}
