import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { PolarFirebaseService } from '../../polar/polar-firebase.service';
import { MasterDocumentData, withoutGroupName } from '../../polar/entity/master';
import { CommonUiProviderService } from '../../common/common-ui-provider.service';
import {
  createCsvValidatedMessage,
  CsvValidatedMessage,
  MAX_GROUP_NAME_LENGTH,
  MAX_MASTER_NAME_LENGTH,
  CsvValidatedResult,
  validateCsv,
  validateInputLength,
} from '../utils';
import { CsvData, CsvInputComponent } from '../csv-input/csv-input.component';
import { CommonModule } from '@angular/common';
import { MasterGroupBreadcrumbsComponent } from '../master-group-breadcrumbs.component';
import { LabelAndTextInputComponent } from '../../common/label-and-text-input/label-and-text-input.component';
import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { TranslateModule } from '@ngx-translate/core';
import { CsvErrorDetailsComponent } from '../csv-error-details/csv-error-details.component';

@Component({
  selector: 'app-add-master-modal',
  standalone: true,
  imports: [
    CommonModule,
    MasterGroupBreadcrumbsComponent,
    LabelAndTextInputComponent,
    CsvInputComponent,
    InnerProgressComponent,
    TranslateModule,
    CsvErrorDetailsComponent,
  ],
  templateUrl: './add-master-modal.component.html',
  styleUrls: ['./add-master-modal.component.scss'],
})
export class AddMasterModalComponent implements OnInit {
  private readonly polar = inject(PolarFirebaseService);
  private readonly commonUi = inject(CommonUiProviderService);

  @Input() companyId!: string;
  @Input() groupId!: string | null;

  @Output() saved: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  loading: boolean = false;

  currentMaster: MasterDocumentData = {} as MasterDocumentData;
  currentGroupName: string = '';

  currentCsvData: CsvData | null = null;
  csvErrorMessages?: CsvValidatedMessage[];

  async ngOnInit() {
    if (this.groupId == null) {
      this.currentGroupName = withoutGroupName;
    } else {
      const group = await this.polar.getMasterGroupsByIds(this.companyId, [this.groupId]);
      if (group.length > 0) {
        this.currentGroupName = group[0].name;
      } else {
        // TODO: グループが取得できなかった場合の処理
      }
    }
  }

  csvUploaded(csvData: CsvData) {
    this.csvErrorMessages = undefined;
    this.currentCsvData = csvData;
  }

  async save() {
    if (!(await this.validate())) {
      return;
    }

    try {
      this.loading = true;

      const csvValidatedResult = validateCsv(this.currentCsvData!.encodedString!);
      if (!(await this.validateUploadingCsv(csvValidatedResult))) {
        return;
      }

      const uploadedPath = await this.polar.uploadCompanyCsvMasterFile(
        this.companyId,
        'master.csv',
        this.groupId!,
        this.currentCsvData!.encodedFile,
      );

      this.currentMaster.csv = {
        filePath: uploadedPath,
        header: csvValidatedResult.header,
      };

      if (this.groupId == null) {
        await this.polar.createMasterWithoutGroup(this.companyId, this.currentMaster);
      } else {
        await this.polar.createMasterWithGroupId(this.companyId, this.currentMaster, this.groupId);
      }
    } catch (e) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '処理中にエラーが発生しました。',
        buttons: ['OK'],
      });

      return;
    } finally {
      this.loading = false;
    }

    this.saved.emit();
  }

  private async validate() {
    if (
      this.groupId == null &&
      !validateInputLength(this.currentGroupName, 0, MAX_GROUP_NAME_LENGTH)
    ) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: `グループ名は所属無しの場合は空にし、グループ名を指定する場合は ${MAX_GROUP_NAME_LENGTH} 文字以内で入力してください`,
        buttons: ['OK'],
      });

      return false;
    }

    if (!validateInputLength(this.currentMaster.name, 1, MAX_MASTER_NAME_LENGTH)) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: `マスタ名は 1 から ${MAX_MASTER_NAME_LENGTH} 文字以内で入力してください`,
        buttons: ['OK'],
      });

      return false;
    }

    if (this.currentCsvData == null) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'CSVがアップロードされていません。',
        buttons: ['OK'],
      });

      return false;
    }

    return true;
  }

  // TODO: 重複
  openModal: boolean = false;
  private async validateUploadingCsv(uploadingResult: CsvValidatedResult) {
    const invalidRows = uploadingResult.bodyRows.filter((row) => row.validatedResult != 'isValid');

    if (invalidRows.length > 0) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'CSV に不正なデータが含まれています。修正して再度選択してください。',
        buttons: ['OK'],
      });

      this.csvErrorMessages = createCsvValidatedMessage(uploadingResult);

      return false;
    }

    return true;
  }
}
