import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-inner-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './inner-progress.component.html',
  styleUrls: ['./inner-progress.component.scss'],
})
export class InnerProgressComponent {
  @Input() status: 'loading' | 'completed' | 'failed' = 'loading';
  @Input() color?: string = undefined;
  @Input() completedCircle: boolean = false;
}
