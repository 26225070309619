import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { InnerProgressComponent } from 'src/app/common/inner-progress/inner-progress.component';

import { TooltipDirective } from '../../tooltip.directive';

import { PolarApiService } from 'src/app/polar/polar-api.service';
import { AccessibleRule } from 'src/app/polar/polar-firebase-auth.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { DateLocalString } from '../date-local-string.pipe';
import { SafeEmailPipe } from '../safe-email.pipe';

import { MasterDocumentData, MasterHistoryDocumentData } from '../../polar/entity/master';
import { DownloadMasterCsvDirective } from '../download-master-csv-directive';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-master-grouped-master-list',
  standalone: true,
  imports: [
    CommonModule,
    DownloadMasterCsvDirective,
    SafeEmailPipe,
    DateLocalString,
    TranslateModule,
    TooltipDirective,
    InnerProgressComponent,
  ],
  templateUrl: './master-grouped-master-list.component.html',
  styleUrls: ['./master-grouped-master-list.component.scss'],
})
export class MasterGroupedMasterListComponent {
  @Input() masters!: MasterDocumentData[];
  @Input() latestMastersDict: { [index: string]: MasterHistoryDocumentData } = {};
  @Input() canCreateAndDeleteItem!: boolean;
  @Input() accessibleMasterGroups?: AccessibleRule[] = undefined;
  @Input() loading: boolean = false;

  @Output() newMasterClicked = new EventEmitter();
  @Output() editMasterClicked: EventEmitter<MasterDocumentData> = new EventEmitter();
  @Output() openHistoryClicked: EventEmitter<MasterDocumentData> = new EventEmitter();
  @Output() deleteMasterClicked: EventEmitter<MasterDocumentData> = new EventEmitter();

  constructor(private polar: PolarFirebaseService) {}

  async downloadLatestMaster(master: MasterDocumentData) {
    const latestMaster = await this.polar.getLatestMaster(
      master.companyId,
      master.masterGroupId,
      master.id!,
      false,
    );
    const path = await this.polar.getCsvMasterFileUrl(latestMaster.csv.filePath!);
    // use a to download the file
    let a = document.createElement('a');
    a.href = path;
    a.download = 'master.csv';
    // simulate a click
    a.click();
    a.remove();
  }

  isReadable(master: MasterDocumentData) {
    return (
      this.accessibleMasterGroups == undefined ||
      this.accessibleMasterGroups.findIndex(
        (f) =>
          f.companyId == master.companyId &&
          (f.id == master.masterGroupId || f.id == '*') &&
          f.readable,
      ) >= 0
    );
  }

  isWritable(master: MasterDocumentData) {
    return (
      this.accessibleMasterGroups == undefined ||
      this.accessibleMasterGroups.findIndex(
        (f) =>
          f.companyId == master.companyId &&
          (f.id == master.masterGroupId || f.id == '*') &&
          f.writable,
      ) >= 0
    );
  }
}
