import { FieldValue, Timestamp } from '@angular/fire/firestore';

export interface CompanyInfo {
  id?: string;
  name?: string;
  memo?: string;
  masterEditable?: boolean;
  authGroupId?: string;

  enableDataRetentionPolicy?: boolean;
  dataRetentionPeriodInDays?: number;
}

export interface CompanyAllowedUser {
  id?: string;
}

export interface WorkInfo {
  id?: string;
  name?: string;
  baseFileName?: string;
  sharedFiles?: { [index: string]: string };
  createdAt?: Timestamp | FieldValue;
  updatedAt?: Timestamp | FieldValue;
  status?: 'training' | 'ready';
  isProduction?: boolean;
  parentWorkId?: string;
}

// UserImageInfo may be duplicated if the file has multiple pages.
// currently, we only supports 1 page. so if the file has multiple pages, it will be duplicated always.
// in the future, we may support multiple pages. then we need to handle the duplicated data.
export interface UserImageInfo {
  id?: string;
  name?: string;
  createdAt?: Timestamp | FieldValue;
  updatedAt?: Timestamp | FieldValue;
  createdBy?: string;
  isFileInvalid?: boolean;
  // failed to execute recognition
  isProcessingFailed?: boolean;
  // failed to retrive template (need hand input)
  isFailed?: boolean;
  rawFilePath?: string;
  status: string;
  // after processing
  convertedFilePaths?: string[];
  imageIndexFrom?: number;
  imageIndexTo?: number;
  statusHistory?: string[];
  uploadingStatus?: 'uploaded' | 'extracted' | 'rotated' | 'failed';
  convertedFileAngles?: number[];
  textAngles?: number[];
  textAngleXs?: number[];
  templateId?: string;
  templateSimilarity?: number;
}

export interface UserImageInnerDataInfo {
  id?: string;
  createdAt?: Timestamp | FieldValue;
  updatedAt?: Timestamp | FieldValue;
  header?: string;
  // ai
  expectedBody?: string;
  expectedBodyConfidence?: string;
  expectedBodyRects?: string;
  // human
  actualBody?: string;
  templateId?: string;
  costs?: { [index: string]: number };
  outputFileName?: string;
}

export interface UserWorkInfo {
  id?: string;
  header?: string;
  defaultRow?: string;
  requiredColumn?: string;
  createdAt?: Timestamp | FieldValue;
  updatedAt?: Timestamp | FieldValue;
  exportType?: string;
  incrementalType?: 'disabled' | 'trailing' | 'leading';
  incrementalColumnName?: string;
  incrementalStartingNumber?: string;
  uploadedFileNameOutputSetting?: UploadedFileNameOutputSetting;
  reflection?: { [index: string]: string[] };
  disableConfidence?: boolean;
}

export const UPLOADED_FILE_NAME_OUTPUT_TYPES = ['disabled', 'trailing', 'leading'];
type UploadedFileNameOutputTypes = (typeof UPLOADED_FILE_NAME_OUTPUT_TYPES)[number];

export interface UploadedFileNameOutputSetting {
  outputType: UploadedFileNameOutputTypes;
  columnNameOfFileName: string;
  fileIndexColumnEnabled: boolean;
  columnNameOfFileIndex: string;
  fileIndexStartingNumber: number;
  isMultiFileDownloadEnabled: boolean;
}

export interface UserWorkDownloadHistoryInfo {
  id?: string;
  createdAt?: Timestamp | FieldValue;
  downloadedIds?: string[];
  dumpPath?: string;
}

export interface UserWorkCostInfo {
  costs: { [index: string]: number };
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface UserUploadFileReference {
  id: string;
  type: string;
  path: string;
  workId: string;
  companyId: string;
  relatedId?: string;
  createdAt?: Timestamp | FieldValue;
}