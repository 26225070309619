import { Component, EventEmitter, Input, Output } from '@angular/core';
import { multiFactor } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Timestamp } from '@angular/fire/firestore';
import { CommonUiProviderService } from '../common/common-ui-provider.service';
import { WorkInfo } from '../polar/entity/CompanyInfo';
import { PolarFirebaseService } from '../polar/polar-firebase.service';

@Component({
  selector: 'app-main-account-modal',
  templateUrl: './main-account-modal.component.html',
  styleUrls: ['./main-account-modal.component.scss'],
})
export class MainAccountModalComponent {
  @Input() companyName?: string = '';
  @Input() companyId?: string = '';
  @Input() works?: WorkInfo[];
  @Input() showAccountBlock: boolean = true;
  @Input() showCompanyBlock: boolean = true;
  @Input() modalTitle: string = 'メニュー';
  @Output() close: EventEmitter<void> = new EventEmitter();
  loading: boolean = false;

  constructor(
    private auth: AngularFireAuth,
    private commonUi: CommonUiProviderService,
    private polar: PolarFirebaseService,
  ) {}

  closeEvent() {
    this.close.emit();
  }

  async resetPassword() {
    let res = await this.commonUi.showConfirm({
      title: '確認',
      body: 'パスワード変更のためのメールを送信します。よろしいですか？',
      buttons: ['OK', 'キャンセル'],
    });
    if (res != 'OK') return;
    this.loading = true;
    // reset password using firebase auth
    await this.auth.sendPasswordResetEmail((await this.auth.currentUser)!.email!);

    this.loading = false;
    await this.commonUi.showConfirm({
      title: '確認',
      body: 'メールを送信しました。メールを確認してパスワードを変更してください。',
      buttons: ['OK'],
    });
  }

  showBillingModal: boolean = false;
  table: any[] = ['1', '1'];
  total: number = 0;
  yearMonth: string = '';

  async checkCosts(months: number) {
    if (this.companyId == undefined) return;
    if (this.works == undefined) return;

    let date = new Date();
    // add months
    date.setMonth(date.getMonth() + months);

    let year = date.getFullYear();
    let month = date.getMonth();

    let startDate = new Date(year, month, 1, 0, 0, 0, 0);
    let endDate = new Date(year, month + 1, 1, 0, 0, 0, 0);

    let res = await this.commonUi.showConfirm({
      title: '確認',
      body: `${year}年${
        month + 1
      }月の利用セル数を確認します。\nこの操作には時間がかかる可能性があります、よろしいですか？`,
      buttons: ['OK', 'キャンセル'],
    });
    if (res != 'OK') return;

    this.loading = true;

    let total = 0;
    let table = [];

    for (let i = 0; i < this.works.length; i++) {
      let work = this.works[i];
      let cost = await this.polar.calcCost(
        this.companyId,
        work.id!,
        Timestamp.fromDate(startDate),
        Timestamp.fromDate(endDate),
      );
      total += cost;
      table.push([work.name, cost]);
    }

    this.table = table;
    this.total = total;
    this.yearMonth = `${year}年${month + 1}月`;
    this.showBillingModal = true;
    console.log(table);
    console.log(total);

    this.loading = false;
  }
}
