import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { InnerProgressComponent } from '../../../common/inner-progress/inner-progress.component';

import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { PolarFirebaseAuthService } from 'src/app/polar/polar-firebase-auth.service';
import { PolarFirebaseTeamAuthService } from 'src/app/polar/polar-firebase-team-auth.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { CompanyAllowedUser, CompanyInfo, WorkInfo } from 'src/app/polar/entity/CompanyInfo';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-console-edit-company',
  templateUrl: './console-edit-company.component.html',
  styleUrls: ['./console-edit-company.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InnerProgressComponent, NgClass],
})
export class ConsoleEditCompanyComponent {
  @Output() closed: EventEmitter<void> = new EventEmitter();
  editCompanyForm: FormGroup;
  addUserForm: FormGroup;
  editRestrictIPForm: FormGroup;
  addWorkForm: FormGroup;

  _selectCompany: CompanyInfo = {};

  @Input() set selectCompanyInfo(company: CompanyInfo) {
    this._selectCompany = company;
    this.update();
  }

  get companyId(): string {
    return this._selectCompany.id as string;
  }

  companyInfo: CompanyInfo = {};
  allowedUsers: CompanyAllowedUser[] = [];
  allowedUsersObservable$?: Observable<CompanyAllowedUser[]>;
  restrictIPs: string[] = [];
  works: WorkInfo[] = [];
  worksObservable$?: Observable<WorkInfo[]>;

  savingCompany: boolean = false;
  savingStatCompany: 'loading' | 'completed' | 'failed' = 'loading';

  savingUser: boolean = false;
  savingStatUser: 'loading' | 'completed' | 'failed' = 'loading';

  savingRestrictIP: boolean = false;
  savingStatRestrictIP: 'loading' | 'completed' | 'failed' = 'loading';

  savingWork: boolean = false;
  savingStatWork: 'loading' | 'completed' | 'failed' = 'loading';

  currentIPEditIndex: number = -1;
  timer: any = null;

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private editCompanyFormBuilder: FormBuilder,
    private addUserFormBuilder: FormBuilder,
    private addWorkFormBuilder: FormBuilder,
    private editRestrictIPFormBuilder: FormBuilder,
    private polarAuthGroup: PolarFirebaseAuthService,
    private polarAuthTeam: PolarFirebaseTeamAuthService,
  ) {
    this.editCompanyForm = this.editCompanyFormBuilder.group({
      companyName: ['', [Validators.required]],
      memo: [''],
      masterEditable: [''],
      enableDataRetentionPolicy: ['', [this.enableDataRetentionPolicyValidator()]],
      dataRetentionPeriodInDays: ['', [this.dataRetentionPeriodInDaysValidator()]],
      authGroupId: [''],
    });
    this.addUserForm = this.addUserFormBuilder.group({
      userId: ['', [Validators.required]],
    });
    this.addWorkForm = this.addWorkFormBuilder.group({
      workName: ['', [Validators.required]],
    });
    this.editRestrictIPForm = this.editRestrictIPFormBuilder.group({
      restrictIP: ['', [Validators.required, this.ipAddressValidator()]],
    });
  }

  isDataRetentionPolicyEnabled(): boolean {
    return this.editCompanyForm.get('enableDataRetentionPolicy')?.value === true;
  }

  dataRetentionPeriodInDaysValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.editCompanyForm == undefined ||
        !this.editCompanyForm.get('enableDataRetentionPolicy')?.value
      ) {
        return null;
      }
      const isValid = !isNaN(control.value) && control.value != '';
      if (!isValid) {
        return { invalidDataRetentionPeriodInDays: true };
      }
      if (parseFloat(control.value) < 0) {
        return { invalidDataRetentionPeriodInDays: true };
      }
      return null;
    };
  }

  enableDataRetentionPolicyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.editCompanyForm?.get('dataRetentionPeriodInDays')?.updateValueAndValidity();
      return null;
    };
  }

  ipAddressValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ipAddressRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
      const isValid = ipAddressRegex.test(control.value);
      const isDoubled = this.restrictIPs.some((ip) => ip === control.value);

      if (isValid && isDoubled) {
        return { isDoubled: true };
      } else if (!isValid) {
        return { invalidIpAddress: true };
      }

      return null;
    };
  }

  async update() {
    this.companyInfo = await this.polar.getCompany(this.companyId);

    this.editCompanyForm.patchValue({
      companyName: this._selectCompany.name,
      memo: this._selectCompany.memo,
      masterEditable: this._selectCompany.masterEditable,
      enableDataRetentionPolicy: this._selectCompany.enableDataRetentionPolicy,
      dataRetentionPeriodInDays: this._selectCompany.dataRetentionPeriodInDays,
      authGroupId: this._selectCompany.authGroupId,
    });

    // get allowed users from polar
    this.allowedUsersObservable$ = await this.polar.getAllowedUsersInCompany(this.companyId);
    this.allowedUsersObservable$!.subscribe((d) => {
      this.allowedUsers = d;
    });

    //get restrict IPs from polat
    this.restrictIPs = await this.polar.getRestrictIPs(this.companyId);
    if (this.restrictIPs == null) {
      this.restrictIPs = [];
    }

    // get works from polar
    this.worksObservable$ = await this.polar.getWorksInCompany(this.companyId);
    this.worksObservable$!.subscribe((d) => {
      this.works = d.filter((f) => !f.isProduction);
    });
  }

  close(e: any) {
    let elm = e.target as HTMLElement;

    if (elm.classList.contains('base')) {
      // close
      this.closed.emit();
    }
  }

  async updateDatasRelatedToAuthGroupId(idPrev?: string, idAfter?: string) {
    if (idPrev === idAfter) return;

    if (idPrev != undefined && idPrev.length > 0) {
      // delete from prev authGroupId
      await this.polarAuthGroup.deleteAuthorizedCompany(idPrev, {
        id: this.companyId,
      });
      await this.polarAuthTeam.deleteCompanyFromAuthGroup(idPrev, this.companyId);
    }

    if (idAfter != undefined && idAfter.length > 0) {
      // add to after authGroupId
      await this.polarAuthGroup.addAuthorizedCompany(idAfter, {
        id: this.companyId,
      });
      await this.polarAuthTeam.addCompanyToAuthGroup(idAfter, this.companyId);
    }
  }

  async updateCompanyInfo() {
    this.savingCompany = true;
    this.savingStatCompany = 'loading';
    if (this.editCompanyForm.valid) {
      try {
        const companyInfoData: CompanyInfo = {
          id: this.companyId,
          name: this.editCompanyForm.value['companyName'],
          memo: this.editCompanyForm.value['memo'],
          masterEditable: this.editCompanyForm.value['masterEditable'] ?? false,
          authGroupId: this.editCompanyForm.value['authGroupId'],
        };

        const enableDataRetentionPolicy = this.editCompanyForm.value['enableDataRetentionPolicy'];
        if (enableDataRetentionPolicy) {
          companyInfoData.enableDataRetentionPolicy = true;
          const dateRetentionPeriodInDays = this.editCompanyForm.value['dataRetentionPeriodInDays'];
          companyInfoData.dataRetentionPeriodInDays = parseFloat(dateRetentionPeriodInDays);
        }

        if (companyInfoData.authGroupId == undefined) {
          companyInfoData.authGroupId = '';
        }
        // update authGroupId
        const prevAuthGroupId = this.companyInfo.authGroupId ?? '';
        const afterAuthGroupId = companyInfoData.authGroupId ?? '';

        if (prevAuthGroupId != afterAuthGroupId) {
          const confirmResult = await this.commonUi.showConfirm({
            title: '確認',
            body:
              '認証グループが変更されます。本当に変更しますか？\n変更前: ' +
              prevAuthGroupId +
              '\n変更後: ' +
              afterAuthGroupId,
            buttons: ['Yes', 'No'],
          });
          if (confirmResult != 'Yes') {
            this.savingStatCompany = 'failed';
            return;
          }
        }
        // set company info
        await this.polar.setCompany(companyInfoData);
        this.companyInfo = companyInfoData;
        // update authGroupId
        await this.updateDatasRelatedToAuthGroupId(prevAuthGroupId, afterAuthGroupId);
        this.savingStatCompany = 'completed';
      } catch (e) {
        console.error(e);
        this.savingStatCompany = 'failed';
      }
    }
  }

  async addUser() {
    this.savingUser = true;
    this.savingStatUser = 'loading';
    if (this.addUserForm.valid) {
      try {
        if (!(await this.polar.checkUserExists(this.addUserForm.value['userId']))) {
          const result = await this.commonUi.showConfirm({
            title: '確認',
            body:
              '使用されたことのないユーザーIDです。\n間違っていないことを確認してOKを押してください。\nユーザーID: ' +
              this.addUserForm.value['userId'],
            buttons: ['OK', 'Cancel'],
          });
          if (result != 'OK') {
            this.savingUser = false;
            this.savingStatUser = 'failed';
            return;
          }
        }
        await this.polar.addAllowedUserInCompany(this.companyInfo.id!, {
          id: this.addUserForm.value['userId'],
        });
        this.savingStatUser = 'completed';
      } catch (e) {
        this.savingStatUser = 'failed';
      }
    }
    this.addUserForm.reset();
  }

  get isExistRestrictedIP(): boolean {
    return this.allowedUsers.some((user) => user.id?.includes('_proxy'));
  }

  async updateRestrictIPList(ipList: string[]) {
    this.savingRestrictIP = true;
    this.savingStatRestrictIP = 'loading';
    try {
      await this.polar.updateRestrictIPList(this.companyInfo.id!, [...ipList]);
      this.savingStatRestrictIP = 'completed';
      this.restrictIPs = [...ipList];
    } catch (e) {
      this.savingStatRestrictIP = 'failed';
    }
    this.savingRestrictIP = false;
  }

  async updateRestrictIP() {
    const newIPList = [...this.restrictIPs];
    if (this.currentIPEditIndex === -1) {
      newIPList.push(this.editRestrictIPForm.value['restrictIP']);
    } else {
      newIPList[this.currentIPEditIndex] = this.editRestrictIPForm.value['restrictIP'];
    }

    await this.updateRestrictIPList(newIPList);

    this.editRestrictIPForm.reset();
    this.currentIPEditIndex = -1;
  }

  async editRestrictIP(ipIndex: number) {
    this.editRestrictIPForm.patchValue({
      restrictIP: this.restrictIPs[ipIndex],
    });
    this.currentIPEditIndex = ipIndex;
  }

  async deleteRestrictIP(ipIndex: number) {
    if (ipIndex === this.currentIPEditIndex) {
      this.editRestrictIPForm.reset();
      this.currentIPEditIndex = -1;
    }
    const newRestrictedIPs = this.restrictIPs.filter((_, index) => ipIndex !== index);

    await this.updateRestrictIPList(newRestrictedIPs);
  }

  async enableIpRestriction(user: CompanyInfo) {
    await this.polar.deleteAllowedUserInCompany(this.companyInfo.id!, user);
    await this.polar.addAllowedIpRestrictionUserInCompany(this.companyInfo.id!, {
      id: user.id,
    });
  }

  async disableIpRestriction(user: CompanyInfo) {
    await this.polar.deleteAllowedIpRestrictionUserInCompany(this.companyInfo.id!, {
      id: user.id,
    });
  }

  async deleteUser(item: CompanyAllowedUser) {
    let result = await this.commonUi.showConfirm({
      title: '確認',
      body: '削除しますか？',
      buttons: ['Yes', 'No'],
    });
    if (result != 'Yes') return;
    await this.polar.deleteAllowedUserInCompany(this.companyInfo.id!, item);
  }

  async addWork() {
    this.savingWork = true;
    this.savingStatWork = 'loading';
    if (this.addWorkForm.valid) {
      try {
        await this.polar.setWorkInCompany(this.companyInfo.id!, {
          name: this.addWorkForm.value['workName'],
        });
        this.savingStatWork = 'completed';
      } catch (e) {
        this.savingStatWork = 'failed';
      }
    }
    this.addWorkForm.reset();
  }

  async deleteWork(item: WorkInfo) {
    let result = await this.commonUi.showConfirm({
      title: '確認',
      body: '削除しますか？',
      buttons: ['Yes', 'No'],
    });
    if (result != 'Yes') return;
    await this.polar.deleteWorkInCompany(this.companyInfo.id!, item);
  }
}
