import {Environment} from '../app/environment';

export const environment: Environment = {
  firebase: {
    locationId: 'asia-northeast1',
    apiKey: 'AIzaSyDHR-0VwoRcg4GpQjGm23VB2OjiwZX2LRw',
    authDomain: 'battonaiocr-staging.firebaseapp.com',
    projectId: 'battonaiocr-staging',
    storageBucket: 'battonaiocr-staging.appspot.com',
    messagingSenderId: '493496703722',
    appId: '1:493496703722:web:acd0903a1fe8105392d593',
    measurementId: 'G-5F8HQRTETK',
  },
  polarApiUrl: 'https://polar-server-z33bnhklta-an.a.run.app',
  production: false,
  version: 'v' + require('../../package.json').version,
  useEmulators: false,
  jspreadsheetCertificate:
    'YjlmZTI1OWI3MTZlZjJmMGFkNTNiZjk4Yjg5OWY3MDk5MThkY2VkMGM3NjU3MmQ5MGVjMTQzYjZhNWRmMGFjZDAzZGFjOTVhYzg4MDVkYTFkMDIyOTNkNWRkZWY1NDU3OGJmYTA0NDRkMWU5NzJhMDA3ODAzNGZkOWFiNDk2OWYsZXlKamJHbGxiblJKWkNJNklqazROVGhsTlRneFkyRmpZakUzTmpsa01ESXlOMk5rWlRNNU9EWmtOalE1TVRsall6aGpORGNpTENKdVlXMWxJam9pWEhVek1HUTNYSFV6TUdVNVhIVXpNR0UwWEhVek1HUmxYSFV6TUdWaElpd2laR0YwWlNJNk1UYzBOVEV3TXpZd01Dd2laRzl0WVdsdUlqcGJJbUpoZEhSdmJtRnBiMk55TFhOMFlXZHBibWN1ZDJWaUxtRndjQ0lzSW1KaGRIUnZibUZwYjJOeUxYQnZZeTF6ZEdGbmFXNW5MbmRsWWk1aGNIQWlMQ0poY0hBdGNHOWpMbVpoZUMxaWRYTjBaWEp6TG1OdmJTSXNJbUpoZEhSdmJpMXpkVzF0WlhJdGFXNTBaWEp1YzJocGNDMWhhVzlqY2k1M1pXSXVZWEJ3SWl3aVltRjBkRzl1WVdsdlkzSXRaR1YyTG5kbFlpNWhjSEFpTENKaVlYUjBiMjR0YzNWdGJXVnlMV2x1ZEdWeWJuTm9hWEF0WkdWMkxuZGxZaTVoY0hBaUxDSmhjSEF0YzNSaFoybHVaeTV2Y21SbGNpMWlkWE4wWlhKekxtTnZiU0lzSW5kbFlpSXNJbXh2WTJGc2FHOXpkQ0pkTENKd2JHRnVJam9pTXpJaUxDSnpZMjl3WlNJNld5SjJOeUlzSW5ZNElpd2lkamtpTENKMk1UQWlMQ0oyTVRFaVhYMD0=',
  fireBridgeApiUrl: 'https://api-p82zc8f6xp.fax-busters.com',
  fireBridgeWebSocketUrl: 'wss://api-p82zc8f6xp.fax-busters.com/ws',
  gtag: '',
  forgetEmailRedirect: '',
};
