export function GetNumberOfColumns(lines: string[]) {
  let actualColumns = 0;
  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];
    let cells = [];
    let inQuote = false;
    let currentCell = '';
    for (let j = 0; j < line.length; j++) {
      let char = line[j];
      if (char == '"') {
        inQuote = !inQuote;
      } else if (char == ',' && !inQuote) {
        cells.push(currentCell);
        currentCell = '';
      } else {
        currentCell += char;
      }
    }
    cells.push(currentCell);

    if (actualColumns < cells.length) {
      actualColumns = cells.length;
    }
  }

  return actualColumns;
}

export function ParseRows(lines: string[], nOfColumns: number) {
  const rows = [];
  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];
    let cells = [];
    let inQuote = false;
    let currentCell = '';
    for (let j = 0; j < line.length; j++) {
      let char = line[j];
      if (char == '"') {
        inQuote = !inQuote;
      } else if (char == ',' && !inQuote) {
        cells.push(currentCell);
        currentCell = '';
      } else {
        currentCell += char;
      }
    }
    cells.push(currentCell);

    while (cells.length < nOfColumns) {
      cells.push('');
    }

    rows.push(cells);
  }

  return rows;
}
