<div class="base">
  <div class="background" (click)="closeEvent()"></div>
  <div class="content">
    <div class="header">
      <div class="title">仕事設定</div>
      <div class="close" (click)="closeEvent()">
        <i class="fa-solid fa-times"></i>
      </div>
    </div>
    <div class="body" style="height: 100%">
      <p>データ設定</p>
      <section>
        <ul>
          <li>
            <!--
            <button class="button" (click)="resetPassword()">変更</button>
            -->
            <app-label-and-combo-box
              [label]="'出力形式'"
              [items]="fileTypes"
              [selectedItem]="fileType"
              [useFieldLayout]="true"
              (selectedItemChange)="fileTypeChange($event)"
              style="width: 100%"
            ></app-label-and-combo-box>
          </li>
          <!--
          <li>
            <p>2段階認証を設定</p>
            <button class="button">設定</button>
          </li>
        --></ul>
      </section>
      <p>連番設定</p>
      <section>
        <ul>
          <li>
            <app-label-and-combo-box
              [label]="'連番付与'"
              [items]="incrementalTypes"
              [selectedItem]="incrementalType"
              [useFieldLayout]="true"
              (selectedItemChange)="incrementalTypeChange($event)"
              style="width: 100%"
            ></app-label-and-combo-box>
          </li>
        </ul>
        <ul>
          @if (incrementalType != 'disabled' && incrementalType != null) {
            <li>
              <p style="font-size: 12px; width: auto; margin-left: 6px">
                項目名: {{ incrementalColumnName }}
              </p>
              <button
                class="button"
                style="font-size: 12px"
                (click)="changeIncrementalColumnName()"
              >
                項目名変更
              </button>
            </li>
          }
        </ul>
        <ul>
          @if (incrementalType != 'disabled' && incrementalType != null) {
            <li>
              <p style="font-size: 12px; width: auto; margin-left: 6px">
                開始番号: {{ incrementalStartingNumber }}
              </p>
              <button
                class="button"
                style="font-size: 12px"
                (click)="changeIncrementalStartingNumber()"
              >
                開始番号変更
              </button>
            </li>
          }
        </ul>
      </section>
      <p>アップロードファイル名出力設定</p>
      <section>
        <ul>
          <li>
            <app-label-and-combo-box
              [label]="'出力設定'"
              [items]="uploadedFileNameOutputTypes"
              [selectedItem]="uploadedFileNameOutputSetting.outputType"
              [useFieldLayout]="true"
              (selectedItemChange)="uploadedFileNameOutputTypeChange($event)"
              style="width: 100%"
            ></app-label-and-combo-box>
          </li>
        </ul>
        @if (uploadedFileNameOutputTypeEnabled()) {
          <ul>
            <li>
              <p class="is-size-7" style="width: 40%">
                項目名: {{ uploadedFileNameOutputSetting.columnNameOfFileName }}
              </p>
              <button class="button is-size-7" (click)="changeUploadedFileNameColumnName()">
                項目名変更
              </button>
            </li>
          </ul>
          <ul>
            <li>
              <p class="is-size-7">
                <label class="checkbox is-flex is-align-content-center">
                  <input
                    type="checkbox"
                    class="mr-1"
                    [checked]="uploadedFileNameOutputSetting.fileIndexColumnEnabled"
                    (click)="toggleUploadedFileIndexColumnEnabled()"
                  />
                  アップロードファイル連番付与
                </label>
              </p>
            </li>
          </ul>
          @if (uploadedFileNameOutputSetting.fileIndexColumnEnabled) {
            <ul>
              <li>
                <p class="is-size-7" style="width: 40%">
                  連番項目名: {{ uploadedFileNameOutputSetting.columnNameOfFileIndex }}
                </p>
                <button class="button is-size-7" (click)="changeUploadedFileIndexColumnName()">
                  連番項目名変更
                </button>
              </li>
            </ul>
            <ul>
              <li>
                <p class="is-size-7" style="width: 40%">
                  開始番号: {{ uploadedFileNameOutputSetting.fileIndexStartingNumber }}
                </p>
                <button class="button is-size-7" (click)="changeUploadedFileIndexStartingNumber()">
                  開始番号変更
                </button>
              </li>
            </ul>
          }
        }
      </section>
      <p>ダウンロード設定</p>
      <section>
        <ul>
          <li>
            <p class="is-size-7" style="width: 100%">
              <label class="checkbox is-flex is-align-content-center">
                <input
                  type="checkbox"
                  class="mr-1"
                  [checked]="uploadedFileNameOutputSetting.isMultiFileDownloadEnabled"
                  (click)="toggleMultifileDownloadEnabled()"
                />
                アップロードしたファイルごとにダウンロード
              </label>
            </p>
          </li>
        </ul>
      </section>
      <p>画面設定</p>
      <section>
        <ul>
          <li>
            <p class="is-size-7" style="width: 100%">
              <label class="checkbox is-flex is-align-content-center">
                <input
                  type="checkbox"
                  class="mr-1"
                  [checked]="showConfidence"
                  (click)="toggleShowConfidence()"
                />
                確認待ち画面で精度を表示
              </label>
            </p>
          </li>
        </ul>
      </section>
    </div>
  </div>
</div>

@if (loading) {
  <div class="loading">
    <app-inner-progress></app-inner-progress>
  </div>
}
