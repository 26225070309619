<div class="">
  <p class="subtitle is-5">{{ masters.length }} 件のマスタ</p>
  @if (canCreateAndDeleteItem) {
    <div class="mb-2">
      <button
        class="button"
        style="width: 150px; font-size: 12px"
        (click)="newMasterClicked.emit()"
        translate
      >
        新規マスタ作成
      </button>
    </div>
  }
  <div class="table-container">
    <table class="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>マスタ名</th>
          <th>更新</th>
          <th></th>
          <th></th>
          <th></th>
          @if (canCreateAndDeleteItem) {
            <!--        <th></th>-->
          }
        </tr>
      </thead>
      <tbody>
        @if (masters.length > 0) {
          @for (master of masters; track master) {
            <tr>
              <td>
                @if (latestMastersDict != null && latestMastersDict[master.id!] != undefined) {
                  <div style="min-width: 200px">
                    {{ latestMastersDict[master.id!].name }}
                  </div>
                } @else {
                  <div style="min-width: 200px">
                    {{ master.name }}
                  </div>
                }
              </td>
              <td>
                <div class="has-text-grey-light" style="white-space: nowrap">
                  <span tooltip="{{ master.createdBy | safeEmail }} が更新しました">
                    {{ master.createdBy | safeEmail }}
                  </span>
                </div>
                <div class="">
                  <span
                    class="has-text-grey-light"
                    tooltip="{{ master.updatedAt | dateLocalString: true }}"
                    tooltipPlacement="bottom"
                    >{{ master.updatedAt | dateLocalString }}</span
                  >
                </div>
              </td>
              <td class="pl-1 pr-1">
                <button
                  (click)="editMasterClicked.emit(master)"
                  class="button action-button"
                  [ngClass]="isWritable(master) ? '' : 'disabled'"
                >
                  修正
                </button>
              </td>
              <td class="pl-1 pr-1">
                @if (master.csv.filePath) {
                  <button
                    class="button action-button"
                    (click)="downloadLatestMaster(master)"
                    [ngClass]="isReadable(master) ? '' : 'disabled'"
                  >
                    ダウンロード
                  </button>
                }
              </td>
              <td class="pl-1 pr-1">
                <button
                  (click)="openHistoryClicked.emit(master)"
                  class="button action-button"
                  [ngClass]="isWritable(master) ? '' : 'disabled'"
                >
                  変更履歴
                </button>
              </td>
              @if (canCreateAndDeleteItem) {
                <!--          <td>-->
                <!--            <button disabled (click)="deleteMasterClicked.emit(master)" class="button is-danger">-->
                <!--              削除-->
                <!--            </button>-->
                <!--          </td>-->
              }
            </tr>
          }
        } @else {
          @if (loading) {
            <app-inner-progress></app-inner-progress>
          }
          @if (!loading) {
            <tr>
              <td colspan="6">マスタがありません</td>
            </tr>
          }
        }
      </tbody>
    </table>
  </div>
</div>
