<div class="">
  <p class="subtitle is-5">{{ masters.length }} 件のマスタ</p>
  <ng-container *ngIf="canCreateAndDeleteItem">
    <div class="mb-2">
      <button
        class="button"
        style="width: 150px; font-size: 12px"
        (click)="newMasterClicked.emit()"
        translate
      >
        新規マスタ作成
      </button>
    </div>
  </ng-container>
  <div class="table-container">
    <table class="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>マスタ名</th>
          <th>更新</th>
          <th></th>
          <th></th>
          <th></th>
          <ng-container *ngIf="canCreateAndDeleteItem">
            <!--        <th></th>-->
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="masters.length > 0; else emptyMasters">
          <tr *ngFor="let master of masters">
            <td>
              <div
                style="min-width: 200px"
                *ngIf="
                  latestMastersDict != null &&
                    latestMastersDict[master.id!] != undefined;
                  else latestMastersUndefined
                "
              >
                {{ latestMastersDict[master.id!].name }}
              </div>
              <ng-template #latestMastersUndefined>
                <div style="min-width: 200px">
                  {{ master.name }}
                </div>
              </ng-template>
            </td>
            <td>
              <div class="has-text-grey-light" style="white-space: nowrap">
                <span
                  tooltip="{{ master.createdBy | safeEmail }} が更新しました"
                >
                  {{ master.createdBy | safeEmail }}
                </span>
              </div>
              <div class="">
                <span
                  class="has-text-grey-light"
                  tooltip="{{ master.updatedAt | dateLocalString : true }}"
                  tooltipPlacement="bottom"
                  >{{ master.updatedAt | dateLocalString }}</span
                >
              </div>
            </td>
            <td class="pl-1 pr-1">
              <button
                (click)="editMasterClicked.emit(master)"
                class="button action-button"
                [ngClass]="isWritable(master) ? '' : 'disabled'"
              >
                修正
              </button>
            </td>
            <td class="pl-1 pr-1">
              <ng-container *ngIf="master.csv.filePath">
                <button
                  class="button action-button"
                  (click)="downloadLatestMaster(master)"
                  [ngClass]="isReadable(master) ? '' : 'disabled'"
                >
                  ダウンロード
                </button>
              </ng-container>
            </td>
            <td class="pl-1 pr-1">
              <button
                (click)="openHistoryClicked.emit(master)"
                class="button action-button"
                [ngClass]="isWritable(master) ? '' : 'disabled'"
              >
                変更履歴
              </button>
            </td>
            <ng-container *ngIf="canCreateAndDeleteItem">
              <!--          <td>-->
              <!--            <button disabled (click)="deleteMasterClicked.emit(master)" class="button is-danger">-->
              <!--              削除-->
              <!--            </button>-->
              <!--          </td>-->
            </ng-container>
          </tr>
        </ng-container>
        <ng-template #emptyMasters>
          <app-inner-progress *ngIf="loading"></app-inner-progress>
          <tr *ngIf="!loading">
            <td colspan="6">マスタがありません</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
