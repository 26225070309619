<app-console-header
  label="Company - 仕事設定"
  [combos]="false"
></app-console-header>
<div class="parent" *ngIf="loading">
  <app-inner-progress *ngIf="loading" stat="loading"></app-inner-progress>
</div>
<div class="parent" *ngIf="!loading">
  <h1>選びましょう</h1>
  <div style="height: 8px"></div>
  <div class="choose">
    <app-label-and-combo-box
      [label]="'会社'"
      [items]="companies"
      [selectedItem]="selectedCompany"
      (selectedItemChange)="selectCompany($event)"
    ></app-label-and-combo-box>
    <div style="height: 16px"></div>
    <app-label-and-combo-box
      label="仕事"
      [items]="works"
      [selectedItem]="selectedWork"
      (selectedItemChange)="selectWork($event)"
    ></app-label-and-combo-box>

    <div style="height: 10px"></div>
    <div style="font-size: 10px; margin-top: 4px">
      {{ selectedCompany }}-{{ selectedWork }}
    </div>
    <div style="height: 20px"></div>
  </div>

  <h1>テンプレート設定</h1>

  <div *ngIf="selectedUserWork != null">
    <article class="message is-link">
      <div class="message-header">
        <p>ヘッダーの設定</p>
      </div>
      <div class="message-body">
        <app-label-and-text-input
          [label]="'ヘッダ設定'"
          [multiline]="true"
          [(value)]="selectedUserWork.header!"
        ></app-label-and-text-input>

        <div class="columns pt-5">
          <div class="column">
            <button class="button is-link" (click)="setHeader()">
              1行ヘッダ自動取得
            </button>
          </div>
          <div class="column">
            <button class="button is-link" (click)="setHeader(true)" disabled>
              2行ヘッダ自動取得(開発中)
            </button>
          </div>
        </div>
      </div>
    </article>
    <div style="height: 16px"></div>
    <app-label-and-text-input
      [label]="'必須カラム'"
      [(value)]="selectedUserWork.requiredColumn!"
    ></app-label-and-text-input>
    <div style="height: 16px"></div>
    <app-label-and-combo-box
      style="width: 252px"
      [label]="'利用可能'"
      [items]="availableItems"
      [selectedItem]="
        selectedWorkInfo?.status == undefined
          ? 'training'
          : selectedWorkInfo!.status!
      "
      (selectedItemChange)="selectStatus($event)"
    ></app-label-and-combo-box>
    <div style="height: 16px"></div>

    <h1>リフレクトの設定</h1>

    <div *ngIf="selectedUserWork != null">
      <article
        class="message is-link"
        *ngFor="let reflectionData of reflectionDatas; let i = index"
      >
        <div class="message-body">
          <app-label-and-text-input
            [label]="'対象カラム'"
            [(value)]="reflectionData.target"
          ></app-label-and-text-input>
          <div class="field is-grouped is-grouped-right pt-3">
            <div>
              <button class="button is-link px-5" (click)="deleteReflection(i)">
                削除
              </button>
            </div>
          </div>
          <app-label-and-text-input
            style="font-size: 14px"
            [label]="'反映先 (カンマ区切り)'"
            [(value)]="reflectionData.reflection"
          ></app-label-and-text-input>
        </div>
      </article>

      <button
        class="button"
        style="width: 120px; font-size: 12px"
        (click)="addReflection()"
      >
        リフレクトの追加
      </button>
    </div>

    <div style="height: 16px"></div>

    <h1>公開仕事情報</h1>
    <app-inner-progress
      *ngIf="publicWorks == null"
      stat="loading"
    ></app-inner-progress>

    <div *ngIf="publicWorks != null">
      <article class="message is-link" *ngFor="let work of publicWorks">
        <div class="message-body">
          <app-label-and-text-input
            [label]="'公開仕事名'"
            [(value)]="work.name!"
          ></app-label-and-text-input>
          <div class="field is-grouped is-grouped-right pt-3">
            <div>
              <button
                class="button is-link px-5"
                (click)="deletePublicWork(work)"
              >
                削除
              </button>
            </div>
          </div>
        </div>
      </article>

      <button
        class="button"
        style="width: 120px; font-size: 12px"
        (click)="addPublicWork()"
      >
        公開仕事の追加
      </button>
    </div>

    <div style="height: 16px"></div>
    <h1>操作</h1>

    <button
      class="button"
      style="margin-left: 32px; width: 240px; font-size: 14px; color: blue"
      (click)="save()"
    >
      上記の内容を保存する
    </button>
    <button
      class="button"
      style="margin-left: 32px; width: 240px; font-size: 14px; color: red"
      (click)="applyTemplates()"
    >
      上記の内容を本番に反映する
    </button>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <p>更新しています...</p>
  <p>{{ stat }}</p>
  <app-inner-progress></app-inner-progress>
</div>
