<div class="parent">
  <div class="lds-roller" *ngIf="status == 'loading'" [ngClass]="color">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>

  <div
    class="completed"
    *ngIf="status == 'completed'"
    [ngClass]="completedCircle ? 'completed-circle' : null"
  >
    <i class="fa-solid fa-check"></i>
  </div>

  <div class="failed" *ngIf="status == 'failed'">
    <i class="fa-solid fa-xmark"></i>
  </div>
</div>
