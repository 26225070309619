<div class="base">
  <div class="background" (click)="closeEvent()"></div>
  <div class="content">
    <div class="header">
      <div class="title">{{ modalTitle }}</div>
      <div class="close" (click)="closeEvent()">
        <i class="fa-solid fa-times"></i>
      </div>
    </div>
    <div class="body">
      @if (showAccountBlock) {
        <p>アカウント設定</p>
        <section>
          <ul>
            <li>
              <p>パスワードを変更</p>
              <button class="button" (click)="resetPassword()">変更</button>
            </li>
            <!--
            <li>
              <p>2段階認証を設定</p>
              <button class="button">設定</button>
            </li>
            -->
          </ul>
        </section>
      }
      @if (showCompanyBlock) {
        @if (companyName != undefined && companyName != '') {
          <p>セル数確認 - {{ companyName }}</p>
        }
        @if (companyName == undefined || companyName == '') {
          <p>セル数確認</p>
        }
        <section>
          <ul>
            <li>
              <p>今月のセル数を確認</p>
              <button class="button" (click)="checkCosts(0)">確認</button>
            </li>
            <li>
              <p>前月のセル数を確認</p>
              <button class="button" (click)="checkCosts(-1)">確認</button>
            </li>
          </ul>
        </section>
      }
    </div>
  </div>
</div>

@if (loading) {
  <div class="loading">
    <app-inner-progress></app-inner-progress>
  </div>
}

@if (showBillingModal) {
  <app-main-billing-modal
    [table]="table"
    [total]="total"
    (close)="showBillingModal = false"
    [yearMonth]="yearMonth"
  ></app-main-billing-modal>
}
