import { Component, OnInit } from '@angular/core';
import { serverTimestamp } from '@firebase/firestore';
import { Subscription } from 'rxjs';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { CompanyInfo, UserWorkInfo, WorkInfo } from 'src/app/polar/entity/CompanyInfo';
import { PolarApiService } from 'src/app/polar/polar-api.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

@Component({
  selector: 'app-console-work-settings',
  templateUrl: './console-work-settings.component.html',
  styleUrls: ['./console-work-settings.component.scss'],
})
export class ConsoleWorkSettingsComponent implements OnInit {
  loading: boolean = false;
  companies: CompanyInfo[] = [];
  works: WorkInfo[] = [];
  publicWorks: WorkInfo[] | null = null;
  reflectionDatas: { target: string; reflection: string }[] = [];

  selectedCompany: string = '';
  selectedWork: string = '';

  stat: string = '';

  sub?: Subscription;

  availableItems: { id: string; name: string }[] = [
    {
      id: 'training',
      name: '準備中',
    },
    {
      id: 'ready',
      name: '使用可能',
    },
  ];

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private clova: PolarApiService,
  ) {}

  async ngOnInit() {
    (await this.polar.getCompanies()).subscribe((d) => {
      console.log(d);
      this.companies = d;

      if (d.length > 0 && this.selectedCompany == '') this.selectCompany(d[0].id!);
    });
    if (this.selectedCompany != '') {
      this.sub = (await this.polar.getWorksInCompany(this.selectedCompany)).subscribe((d) => {
        d = d.filter((f) => !f.isProduction);

        if (d.length > 0 && this.selectedWork == '') this.selectWork(d[0].id!);
      });
    }
  }

  async selectCompany(val: string) {
    this.publicWorks = null;
    this.selectedWork = '';
    this.selectedUserWork = null;
    this.selectedCompany = val;
    localStorage.setItem('last_company_console', val);
    if (this.sub != undefined) this.sub.unsubscribe();
    this.sub = (await this.polar.getWorksInCompany(this.selectedCompany)).subscribe((d) => {
      d = d.filter((f) => !f.isProduction);
      this.works = d;

      if (d.length > 0 && this.selectedWork == '') this.selectWork(d[0].id!);
    });
  }

  selectedWorkInfo?: WorkInfo;
  selectedUserWork?: UserWorkInfo | null;

  async selectWork(val: string) {
    this.publicWorks = null;
    this.selectedUserWork = null;
    this.selectedWork = val;
    this.selectedWorkInfo = await this.polar.getWork(this.selectedCompany, this.selectedWork);

    if (this.selectedWorkInfo == null) {
      alert('エラー. 更新してください');
      return;
    }

    if (val == '') {
      return;
    }

    let sub = (await this.polar.getWorksInCompany(this.selectedCompany)).subscribe((d) => {
      sub.unsubscribe();
      this.publicWorks = d.filter((f) => f.isProduction && f.parentWorkId == this.selectedWork);
    });

    if (!(await this.polar.userWorkExists(this.selectedCompany, this.selectedWork))) {
      await this.polar.setUserWork(this.selectedCompany, this.selectedWork, {
        updatedAt: serverTimestamp(),
        defaultRow: '',
      });
    }

    let userWork = await this.polar.getUserWork(this.selectedCompany, this.selectedWork);
    this.selectedUserWork = userWork;
    this.updateReflectionDatas();
  }

  async selectStatus(e: string) {
    if (e == 'ready') {
      this.selectedWorkInfo!.status = 'ready';
    } else {
      this.selectedWorkInfo!.status = 'training';
    }
  }

  async setHeader(secondHeader?: boolean) {
    const templates = await this.polar.getTemplates(this.selectedCompany, this.selectedWork);
    let sub = templates.subscribe((d) => {
      sub.unsubscribe();
      let headerOfCsv = '';

      const target = d.find(
        (f) =>
          f.workflowProcessor != null &&
          f.workflowProcessor.write != null &&
          f.workflowProcessor.write.spreadsheet != null &&
          f.workflowProcessor.write.spreadsheet.csv != null &&
          f.workflowProcessor.write.spreadsheet.csv != '',
      );
      if (target) {
        const csv = target.workflowProcessor?.write.spreadsheet?.csv;
        if (secondHeader) {
          const csvList = csv!.split('\n');
          headerOfCsv = csvList[0] + '\n' + csvList[1];
        } else {
          headerOfCsv = csv!.split('\n')[0];
        }
        this.selectedUserWork!.header = headerOfCsv;
      }
    });
  }

  async addPublicWork() {
    let cloned = JSON.parse(JSON.stringify(this.selectedWorkInfo)) as WorkInfo;
    cloned.id = '';
    cloned.createdAt = serverTimestamp();
    cloned.updatedAt = serverTimestamp();
    cloned.isProduction = true;
    cloned.parentWorkId = this.selectedWorkInfo?.id;
    this.publicWorks?.push(cloned);
  }

  async deletePublicWork(work: WorkInfo) {
    if (!work.isProduction) return;

    let res = await this.commonUi.showConfirm({
      title: '確認',
      body: '本当に削除しますか？\n即座に削除されます',
      buttons: ['OK', 'キャンセル'],
    });

    if (res != 'OK') return;

    if (work.id != '' && work.id != undefined && work.id != null)
      await this.polar.deleteWorkInCompany(this.selectedCompany, work);
    let idx = this.publicWorks?.findIndex((f) => f == work);
    if (idx != undefined) this.publicWorks?.splice(idx, 1);
  }

  async save() {
    this.stat = '';
    if (!this.selectedUserWork || !this.selectedWork || !this.selectedCompany) return;

    if (!this.selectedUserWork.requiredColumn) {
      this.selectedUserWork.requiredColumn = this.selectedUserWork.requiredColumn?.replace(
        /\"/g,
        '',
      );
    }

    if (!this.selectedUserWork.header) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'ヘッダーが設定されていません',
        buttons: ['OK'],
      });
      return;
    }

    if (!this.selectedUserWork.requiredColumn) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '必須カラムが設定されていません',
        buttons: ['OK'],
      });
      return;
    }

    const res = await this.commonUi.showConfirm({
      title: '確認',
      body: 'この操作は顧客の操作に影響を与える可能性があります。\n本当に実行しますか？\n\nまた、一度作成した本番環境は削除できません',
      buttons: ['OK', 'キャンセル'],
    });

    if (res != 'OK') {
      return;
    }

    this.loading = true;

    this.stat = 'settings works...';

    this.saveReflection();

    await this.polar.setUserWork(this.selectedCompany, this.selectedWork, this.selectedUserWork!);

    await this.polar.setWorkInCompany(this.selectedCompany, this.selectedWorkInfo!);

    for (let i = 0; i < this.publicWorks!.length; i++) {
      let work = this.publicWorks![i];
      work.status = this.selectedWorkInfo?.status;

      if (work.status == undefined) {
        work.status = 'training';
      }

      const id = await this.polar.setWorkInCompany(this.selectedCompany, work);
      work.id = id;
      console.log(work.id);

      let userWorkCloned = JSON.parse(JSON.stringify(this.selectedUserWork)) as UserWorkInfo;
      userWorkCloned.id = id;
      userWorkCloned.createdAt = serverTimestamp();
      userWorkCloned.updatedAt = serverTimestamp();

      await this.polar.setUserWork(this.selectedCompany, id, userWorkCloned);
    }

    this.loading = false;
  }

  async applyTemplates() {
    this.stat = '';
    const idx = this.publicWorks!.findIndex((f) => f.id == '');
    if (idx >= 0) {
      await this.commonUi.showConfirm({
        title: '確認',
        body: '先に保存を行ってください',
        buttons: ['OK'],
      });
      return;
    }

    let res = await this.commonUi.showConfirm({
      title: '確認',
      body: 'この操作は顧客の操作に影響を与える可能性があります。\n本当に実行しますか？',
      buttons: ['OK', 'キャンセル'],
    });

    if (res != 'OK') {
      return;
    }

    this.loading = true;

    this.stat = 'ステージングマスタを反映しています...';

    await this.polar.moveStagedMastersToProductionInCompany(this.selectedCompany);

    this.stat = 'マスタを最新に更新しています...';

    try {
      const masterGroups = await this.polar.getMasterGroups(this.selectedCompany);

      for (let i = 0; i < masterGroups.length; i++) {
        const masterGroup = masterGroups[i];
        const masters = await this.polar.getMastersInGroup(this.selectedCompany, masterGroup.id!);

        for (let j = 0; j < masters.length; j++) {
          const master = masters[j];
          const latestMaster = await this.polar.getLatestMaster(
            this.selectedCompany,
            masterGroup.id!,
            master.id!,
            false,
          );

          await this.clova.createVectorsForCsvMappingToSatisfyRequirement(
            this.selectedCompany,
            masterGroup.id!,
            master.id!,
            latestMaster.id!,
          );
        }
      }
    } catch (error) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: `マスターの更新に失敗しました: ${error}`,
        buttons: ['OK'],
      });
      return;
    }

    this.stat = 'テンプレートを更新しています...';

    const ts = await this.polar.getTemplates(this.selectedCompany, this.selectedWork);
    const sub = ts.subscribe(async (f) => {
      sub.unsubscribe();

      for (let i = 0; i < this.publicWorks!.length; i++) {
        const work = this.publicWorks![i];

        for (let j = 0; j < f.length; j++) {
          await this.polar.addTemplate(this.selectedCompany, work.id!, f[j]);
        }

        const templates = await this.polar.getTemplatesAsArray(this.selectedCompany, work.id!);

        for (let j = 0; j < templates.length; j++) {
          if (f.findIndex((f) => f.id == templates[j].id) >= 0) continue;
          await this.polar.deleteTemplate(this.selectedCompany, work.id!, templates[j]);
        }
      }
      this.loading = false;
    });
  }

  updateReflectionDatas() {
    this.reflectionDatas = [];
    if (this.selectedUserWork?.reflection != null) {
      const keys = Object.keys(this.selectedUserWork?.reflection);
      keys.forEach((key) => {
        this.reflectionDatas.push({
          target: key,
          reflection: this.selectedUserWork!.reflection![key].join(','),
        });
      });
    }
  }

  saveReflection() {
    let reflection: { [index: string]: string[] } = {};
    this.reflectionDatas.forEach((d) => {
      reflection[d.target] = d.reflection.split(',');
    });
    this.selectedUserWork!.reflection = reflection;
  }

  deleteReflection(idx: number) {
    // remove
    this.reflectionDatas.splice(idx, 1);
  }

  addReflection() {
    this.reflectionDatas.push({
      target: '',
      reflection: '',
    });
  }
}
