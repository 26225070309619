<app-console-header
  label="Configuration - 設定"
  [combos]="false"
></app-console-header>
<div class="parent" *ngIf="loading">
  <app-inner-progress *ngIf="loading" stat="loading"></app-inner-progress>
</div>
<div class="parent" *ngIf="!loading">
  <div class="field">
    <label class="label">OpenAI API Key</label>
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Text input"
        [(ngModel)]="configs.openai_api_key"
      />
    </div>
  </div>

  <div class="field">
    <label class="label">Clova General Url</label>
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Text input"
        [(ngModel)]="configs.clova_general_api_url"
      />
    </div>
  </div>

  <div class="field">
    <label class="label">Clova General Key</label>
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Text input"
        [(ngModel)]="configs.clova_general_api_key"
      />
    </div>
  </div>

  <p>Clova Domains</p>

  <div class="columns is-multiline">
    <div class="column is-half" *ngFor="let item of clovaDomains; let idx = index">
      <article class="message is-link">
        <div class="message-body">

          <div class="field">
            <label class="label">Label</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Text input"
                [(ngModel)]="item.name"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Url</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Text input"
                [(ngModel)]="item.url"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Key</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Text input"
                [(ngModel)]="item.secret"
              />
            </div>
          </div>
          <button class="button" (click)="removeDomain(idx)">削除</button>
        </div>
      </article>
    </div>
  </div>

  <button class="button is-link add" (click)="addDomain()">Add</button>

  <div class="field is-grouped">
    <div class="control">
      <button class="button is-link" (click)="save()">保存</button>
    </div>
    <app-inner-progress
      *ngIf="saving"
      [status]="savingStat"
    ></app-inner-progress>
  </div>
</div>
