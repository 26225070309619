<div class="base">
  <div class="background" (click)="requestClose()"></div>
  <div class="confirm-container">
    @if (title != undefined) {
      <div class="header">{{ title }}</div>
    }
    @if (body != undefined) {
      <div class="body">{{ body }}</div>
    }
    @if (additonalLinkTitle != undefined && additonalLinkAction != undefined) {
      <div class="additionalLink">
        <button class="additional-button" (click)="additonalLinkAction!()">
          {{ additonalLinkTitle }}
        </button>
      </div>
    }
    <div class="footer">
      @for (button of buttons; track button) {
        <button class="button" (click)="requestInput(button)">
          {{ button }}
        </button>
      }
    </div>
  </div>
</div>
