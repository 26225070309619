export interface RefineWorkflow {
  id: string;
  provider: string;
  label?: string;
  target: string;
  strategy: string;
  overrideTarget: boolean;
}

export interface ClovaGeneralWorkflow {
  id: string;
  provider: string;
  label?: string;
  tableRecognition?: boolean;
  convertDashToSolid?: boolean;
  version?: string;
}

export interface TableExtractionWorkflow {
  id: string;
  label?: string;
  provider: string;
  startRowFinderText: string;
  endRowFinderText: string;
  strategy: string;
  expectedHeader: string;
  mapper: string;
}

export interface ClovaRectWorkflow {
  id: string;
  provider: string;
  label?: string;
  domain?: string;
  templateIds?: string;
  version?: string;
}

export interface GptWorkflow {
  id: string;
  provider: string;
  label?: string;
  engine: string;
  base_prompt: string;
  args: { [index: string]: string };
}

export interface LogicalWorkflow {
  id: string;
  provider: string;
  label?: string;
  script: string;
  prompt: string;
  promptScriptLastGenerated?: string | null;
  input: string;
  testText: string;
  testResult?: string;
  unifyNumbers: boolean;
  unifySymbols: boolean;
  unifyAlphas: boolean;
  engine: string;
}

export function dumpPromptScriptLastGenerated(item: LogicalWorkflow) {
  if (item.engine == undefined || item.engine == 'text-davinci-003') {
    return JSON.stringify({
      prompt: item.prompt,
      unifyNumbers: item.unifyNumbers,
      unifySymbols: item.unifySymbols,
      unifyAlphas: item.unifyAlphas,
    });
  } else {
    return JSON.stringify({
      prompt: item.prompt,
      unifyNumbers: item.unifyNumbers,
      unifySymbols: item.unifySymbols,
      unifyAlphas: item.unifyAlphas,
      engine: item.engine,
    });
  }
}

export interface MapWorkflow {
  id: string;
  provider: string;
  label?: string;
  map_from: string;
  csv_column_from: string;
  csv_column_to: string;
  strategy: string;
  normalize: string;
  vectors_file_name?: string | null;
  vectors_csv_column_name_last_generated?: string | null;
  vectors_engine?: string;

  // old
  csv_file_name: string;

  // new
  master?: {
    id: string;
    groupId: string;
  };
}

export interface RegexWorkflow {
  id: string;
  provider: string;
  label?: string;
  input: string;
  regex: string;
}

export interface SpreadsheetCellRect {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface SpreadsheetData {
  csv: string;
  loadableDump: string | null;
  rowNumberToRowId?: { [index: number]: number };
  rowIdToText?: { [index: number]: string[] };
  confidenceCsv?: string;
  rectsCsv?: string;
  rects?: { [index: number]: { [index: number]: SpreadsheetCellRect[] } };
}

export function CsvLineSplitter(data: string): string[] {
  // split csv by line but don't split by comma inside double quotes
  let lines = data.split('\n');
  let result = [];
  let currentLine = '';
  for (let line of lines) {
    // count double quotes
    let doubleQuoteCount = 0;
    currentLine += line;
    for (let i = 0; i < currentLine.length; i++) {
      if (currentLine[i] == '"') {
        doubleQuoteCount++;
      }
    }
    if (doubleQuoteCount % 2 == 0) {
      // even number of double quotes
      result.push(currentLine);
      currentLine = '';
    } else {
      // odd number of double quotes
      currentLine += '\n';
    }
  }
  return result;
}

export function CsvLineRowSplitter(lines: string[]): string[][] {
  let ret: string[][] = [];

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i];
    let cells = [];
    let inQuote = false;
    let currentCell = '';
    for (let j = 0; j < line.length; j++) {
      let char = line[j];
      if (char == '"') {
        inQuote = !inQuote;
      } else if (char == ',' && !inQuote) {
        cells.push(currentCell);
        currentCell = '';
      } else {
        currentCell += char;
      }
    }
    cells.push(currentCell);

    ret.push(cells);
  }

  return ret;
}

export interface WorkflowProcessor {
  read: {
    [index: string]: (ClovaGeneralWorkflow | GptWorkflow | MapWorkflow)[];
  };
  write: {
    spreadsheet?: SpreadsheetData;
    export_cache_json_str?: string;
    required_column?: string;
    export_confidence_cache_json_str?: string;
    export_rects_cache_json_str?: string;
  };
  fileName: string;
}
