import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, Firestore, Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';

import { PolarFireswitchService } from './bridge/polar-fireswitch.service';
import { PolarFirebaseAuthService } from './polar-firebase-auth.service';

import { AuthorizedTeam } from './entity/AuthGroupInfo';

export interface AuthGroupTeamAuthorityAccessibleWork {
  interactable: boolean;
}

export interface AuthGroupTeamAuthorityAccessibleMasterGroup {
  writable: boolean;
  readable: boolean;
}

export interface AuthGroupTeamAuthorityAccessibleCompany {
  accessible_master_groups: { [index: string]: AuthGroupTeamAuthorityAccessibleMasterGroup };
  accessible_works: { [index: string]: AuthGroupTeamAuthorityAccessibleWork };
}

export interface AuthGroupTeamAuthority {
  id: string;
  authority: string;
  accessible_companies: { [index: string]: AuthGroupTeamAuthorityAccessibleCompany };
  name: string;
  createdAt?: Timestamp | FieldValue;
  updatedAt?: Timestamp | FieldValue;
}

@Injectable({
  providedIn: 'root',
})
export class PolarFirebaseTeamAuthService {
  constructor(
    private auth: AngularFireAuth,
    private firestore: Firestore,
    private storage: Storage,
    private switcher: PolarFireswitchService,
    private authGroupService: PolarFirebaseAuthService,
  ) {}

  async getPreDefinedCompanies(authGroupId: string): Promise<string[]> {
    // authorized_groups/{authGroupId}/pre_defined_companies
    const collection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/companies`,
    );
    const docs = await this.switcher.getDocs(collection);
    const result: string[] = [];
    docs.forEach((doc) => {
      result.push(doc.id);
    });
    return result;
  }

  async deletePredifinedCompany(authGroupId: string, companyId: string): Promise<void> {
    const collection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/companies`,
    );
    const doc = this.switcher.doc(collection, companyId);
    await this.switcher.deleteDoc(doc);
  }

  async getAccessibleMasterGroups(
    authGroupId: string,
    teamId: string,
    companyId: string,
  ): Promise<{ [index: string]: AuthGroupTeamAuthorityAccessibleMasterGroup }> {
    // authorized_groups/{authGroupId}/teams/{teamId}/accessible_companies/{companyId}/accessible_master_groups
    const collection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams/${teamId}/accessible_companies/${companyId}/accessible_master_groups`,
    );
    const docs = await this.switcher.getDocs(collection);
    const result: { [index: string]: AuthGroupTeamAuthorityAccessibleMasterGroup } = {};
    docs.forEach((doc) => {
      result[doc.id] = doc.data() as AuthGroupTeamAuthorityAccessibleMasterGroup;
    });
    return result;
  }

  async getAccessibleWorks(
    authGroupId: string,
    teamId: string,
    companyId: string,
  ): Promise<{ [index: string]: AuthGroupTeamAuthorityAccessibleWork }> {
    // authorized_groups/{authGroupId}/teams/{teamId}/accessible_companies/{companyId}/accessible_works
    const collection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams/${teamId}/accessible_companies/${companyId}/accessible_works`,
    );
    const docs = await this.switcher.getDocs(collection);
    const result: { [index: string]: AuthGroupTeamAuthorityAccessibleWork } = {};
    docs.forEach((doc) => {
      result[doc.id] = doc.data() as AuthGroupTeamAuthorityAccessibleWork;
    });
    return result;
  }

  async getAccessibleCompanies(
    authGroupId: string,
    teamId: string,
  ): Promise<{ [index: string]: AuthGroupTeamAuthorityAccessibleCompany }> {
    // authorized_groups/{authGroupId}/teams/{teamId}/accessible_companies
    const collection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams/${teamId}/accessible_companies`,
    );
    const docs = await this.switcher.getDocs(collection);
    const result: { [index: string]: AuthGroupTeamAuthorityAccessibleCompany } = {};
    docs.forEach((doc) => {
      result[doc.id] = doc.data() as AuthGroupTeamAuthorityAccessibleCompany;
    });
    return result;
  }

  async getTeamAuthority(authGroupId: string, teamId: string): Promise<AuthGroupTeamAuthority> {
    const team = await this.authGroupService.getTeam(authGroupId, teamId);

    const accessibleCompanies = await this.getAccessibleCompanies(authGroupId, teamId);
    const accessibleCompaniesResult: { [index: string]: AuthGroupTeamAuthorityAccessibleCompany } =
      {};
    for (const companyId in accessibleCompanies) {
      const accessibleMasterGroups = await this.getAccessibleMasterGroups(
        authGroupId,
        teamId,
        companyId,
      );
      const accessibleWorks = await this.getAccessibleWorks(authGroupId, teamId, companyId);
      accessibleCompaniesResult[companyId] = {
        accessible_master_groups: accessibleMasterGroups,
        accessible_works: accessibleWorks,
      };
    }

    return {
      id: team.id!,
      authority: team.authority,
      accessible_companies: accessibleCompaniesResult,
      name: team.name!,
      createdAt: team.createdAt,
      updatedAt: team.updatedAt,
    };
  }

  removeUnnecessaryAuthorities(data: AuthGroupTeamAuthority): AuthGroupTeamAuthority {
    // deep copy
    const result = JSON.parse(JSON.stringify(data)) as AuthGroupTeamAuthority;
    for (const companyId in result.accessible_companies) {
      const company = result.accessible_companies[companyId];
      for (const masterGroupId in company.accessible_master_groups) {
        // remove non-readable and non-writable master groups
        if (
          !company.accessible_master_groups[masterGroupId].readable &&
          !company.accessible_master_groups[masterGroupId].writable
        ) {
          delete company.accessible_master_groups[masterGroupId];
        }
      }
      for (const workId in company.accessible_works) {
        // remove non-interactable works
        if (!company.accessible_works[workId].interactable) {
          delete company.accessible_works[workId];
        }
      }

      // check if works has * and interactable. then remove all the works except *
      let hasAsterisk = false;
      for (const workId in company.accessible_works) {
        if (workId === '*' && company.accessible_works[workId].interactable) {
          hasAsterisk = true;
          break;
        }
      }
      if (hasAsterisk) {
        for (const workId in company.accessible_works) {
          if (workId !== '*') {
            delete company.accessible_works[workId];
          }
        }
      }
      // check if master groups has * and writable. then remove all the master groups except *
      hasAsterisk = false;
      for (const masterGroupId in company.accessible_master_groups) {
        if (masterGroupId === '*' && company.accessible_master_groups[masterGroupId].writable) {
          hasAsterisk = true;
          break;
        }
      }
      if (hasAsterisk) {
        for (const masterGroupId in company.accessible_master_groups) {
          if (masterGroupId !== '*') {
            delete company.accessible_master_groups[masterGroupId];
          }
        }
      }
    }
    return result;
  }

  async updateTeamAuthority(
    authGroupId: string,
    teamId: string,
    data: AuthGroupTeamAuthority,
  ): Promise<void> {
    const collectionBase: string = `authorized_groups/${authGroupId}/teams/${teamId}`;
    const collection = this.switcher.collection(
      this.firestore,
      collectionBase + `/accessible_companies`,
    );
    // update team authority and name
    const teamCollection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams`,
    );
    const teamDoc = this.switcher.doc(teamCollection, teamId);
    if (data.name == undefined) data.name = '';
    await this.switcher.setDoc(
      teamDoc,
      {
        authority: data.authority,
        name: data.name,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    for (const companyId in data.accessible_companies) {
      const company = data.accessible_companies[companyId];
      // add company if not exists
      const companyDoc = this.switcher.doc(collection, companyId);
      await this.switcher.setDoc(companyDoc, {});
      const companyCollectionBase = collectionBase + `/accessible_companies/${companyId}`;
      const masterGroupCollection = this.switcher.collection(
        this.firestore,
        companyCollectionBase + '/accessible_master_groups',
      );
      for (const masterGroupId in company.accessible_master_groups) {
        const masterGroup = company.accessible_master_groups[masterGroupId];
        const masterGroupDoc = this.switcher.doc(masterGroupCollection, masterGroupId);
        await this.switcher.setDoc(masterGroupDoc, masterGroup);
      }
      // remove missing master groups
      const masterGroupDocsSnapshot = await this.switcher.getDocs(masterGroupCollection);
      for (const masterGroupDocSnapshot of masterGroupDocsSnapshot.docs) {
        if (!company.accessible_master_groups[masterGroupDocSnapshot.id]) {
          await this.switcher.deleteDoc(masterGroupDocSnapshot.ref);
        }
      }
      const workCollection = this.switcher.collection(
        this.firestore,
        companyCollectionBase + '/accessible_works',
      );
      for (const workId in company.accessible_works) {
        const work = company.accessible_works[workId];
        const workDoc = this.switcher.doc(workCollection, workId);
        await this.switcher.setDoc(workDoc, work);
      }
      // remove deleted works
      const workDocsSnapshot = await this.switcher.getDocs(workCollection);
      for (const workDocSnapshot of workDocsSnapshot.docs) {
        if (!company.accessible_works[workDocSnapshot.id]) {
          await this.switcher.deleteDoc(workDocSnapshot.ref);
        }
      }
    }

    // remove non-existing companies
    const companyDocsSnapshot = await this.switcher.getDocs(collection);
    for (const companyDocSnapshot of companyDocsSnapshot.docs) {
      if (data.accessible_companies[companyDocSnapshot.id]) {
        // company exists
        continue;
      }
      // here we note: we need to remove all the data before removing the company
      // since sub-collection is not removed automatically
      // remove all the accessible master groups in the company
      const masterGroupCollection = this.switcher.collection(
        this.firestore,
        collectionBase + `/accessible_companies/${companyDocSnapshot.id}/accessible_master_groups`,
      );
      const masterGroupDocsSnapshot = await this.switcher.getDocs(masterGroupCollection);
      for (const masterGroupDocSnapshot of masterGroupDocsSnapshot.docs) {
        await this.switcher.deleteDoc(masterGroupDocSnapshot.ref);
      }
      // remove all the accessible works in the company
      const workCollection = this.switcher.collection(
        this.firestore,
        collectionBase + `/accessible_companies/${companyDocSnapshot.id}/accessible_works`,
      );
      const workDocsSnapshot = await this.switcher.getDocs(workCollection);
      for (const workDocSnapshot of workDocsSnapshot.docs) {
        await this.switcher.deleteDoc(workDocSnapshot.ref);
      }
      // remove the company
      await this.switcher.deleteDoc(companyDocSnapshot.ref);
    }
  }

  async deleteTeam(authGroupId: string, teamId: string): Promise<void> {
    // 1. update team authority to limited and name to deleted
    // this means all the accessible companies, master groups and works are removed
    // so after this we can safely remove the team not worry about the sub-collections
    this.updateTeamAuthority(authGroupId, teamId, {
      id: teamId,
      authority: 'limited',
      accessible_companies: {},
      name: 'deleted',
    });

    // 2. remove users
    const teamUserCollection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams/${teamId}/users`,
    );
    const userDocs = await this.switcher.getDocs(teamUserCollection);
    for (const userDoc of userDocs.docs) {
      await this.switcher.deleteDoc(userDoc.ref);
    }

    // 3. remove the team
    const teamCollection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams`,
    );
    const teamDoc = this.switcher.doc(teamCollection, teamId);
    await this.switcher.deleteDoc(teamDoc);
  }

  async getTeams(authGroupId: string): Promise<AuthorizedTeam[]> {
    const collection = this.switcher.collection(
      this.firestore,
      `authorized_groups/${authGroupId}/teams`,
    );
    const docs = await this.switcher.getDocs(collection);
    const result: AuthorizedTeam[] = [];
    docs.forEach((doc) => {
      //set id as well
      const data = doc.data() as AuthorizedTeam;
      data.id = doc.id;
      result.push(data);
    });
    return result;
  }

  async deleteCompanyFromAuthGroup(authGroupId: string, companyId: string): Promise<void> {
    const teams = await this.getTeams(authGroupId);
    for (const team of teams) {
      const authority = await this.getTeamAuthority(authGroupId, team.id!);
      delete authority.accessible_companies[companyId];
      await this.updateTeamAuthority(authGroupId, team.id!, authority);
    }
  }

  async addCompanyToAuthGroup(authGroupId: string, companyId: string): Promise<void> {
    const teams = await this.getTeams(authGroupId);
    for (const team of teams) {
      const authority = await this.getTeamAuthority(authGroupId, team.id!);
      if (!authority.accessible_companies[companyId]) {
        authority.accessible_companies[companyId] = {
          accessible_master_groups: {},
          accessible_works: {},
        };
      }
      await this.updateTeamAuthority(authGroupId, team.id!, authority);
    }
  }

  async deleteAuthorizedGroup(
    authGroupId: string,
  ): Promise<{ affectedUsers: number; affectedCompanies: number }> {
    if (authGroupId == '' || authGroupId == undefined)
      return { affectedCompanies: 0, affectedUsers: 0 };
    // 1. delete all the teams
    const teams = await this.getTeams(authGroupId);
    for (const team of teams) {
      await this.deleteTeam(authGroupId, team.id!);
    }

    // 2. delete pre defined companies
    const preDefinedCompanies = await this.getPreDefinedCompanies(authGroupId);
    for (const companyId of preDefinedCompanies) {
      await this.deletePredifinedCompany(authGroupId, companyId);
    }

    // 3. delete authorized_group
    const collection = this.switcher.collection(this.firestore, `authorized_groups`);
    const doc = this.switcher.doc(collection, authGroupId);
    await this.switcher.deleteDoc(doc);

    // 4. set the authGroupTeamId to '' for the all users that has the authGroupId
    const userCollection = this.switcher.collection(this.firestore, 'users');
    const userDocs = await this.switcher.getDocs(
      this.switcher.query(
        userCollection,
        this.switcher.where('authorized_group_id', '==', authGroupId),
      ),
    );
    for (const user of userDocs.docs) {
      await this.switcher.setDoc(
        user.ref,
        { authorized_group_id: '', authorized_group_team_id: '' },
        { merge: true },
      );
    }
    console.log(userDocs.docs.length + ' users are updated');

    // 5. se the authGroupId to `` for the all companies that has the authGroupId
    const companyCollection = this.switcher.collection(this.firestore, 'companies');
    const companyDocs = await this.switcher.getDocs(
      this.switcher.query(companyCollection, this.switcher.where('authGroupId', '==', authGroupId)),
    );
    for (const company of companyDocs.docs) {
      await this.switcher.setDoc(company.ref, { authGroupId: '' }, { merge: true });
    }
    console.log(companyDocs.docs.length + ' companies are updated');

    return { affectedCompanies: companyDocs.docs.length, affectedUsers: userDocs.docs.length };
  }
}
