import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { WorkInfo } from 'src/app/polar/entity/CompanyInfo';
import tippy from 'tippy.js';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import {
  AccessibleRule,
  PolarFirebaseAuthService,
} from 'src/app/polar/polar-firebase-auth.service';

@Component({
  selector: 'app-work-list',
  templateUrl: './work-list.component.html',
  styleUrls: ['./work-list.component.scss'],
})
export class WorkListComponent implements OnInit {
  userEmail?: string | null = '...';
  userId?: string = '...';
  works?: WorkInfo[] = undefined;
  companyId?: string = undefined;
  companyName?: string = '...';
  masterEditable: boolean = false;
  showAccountModal: boolean = false;
  showMasterSettingsModal: boolean = false;
  accessibleWorks: AccessibleRule[] | undefined = undefined;
  accessibleMasterGroups: AccessibleRule[] | undefined = undefined;
  enableAuthGroupSettings: boolean = false;

  get version(): string {
    return environment.version;
  }

  constructor(
    private commonUi: CommonUiProviderService,
    private router: Router,
    private auth: AngularFireAuth,
    private polar: PolarFirebaseService,
    private polarAuthGroup: PolarFirebaseAuthService,
  ) {}

  async ngOnInit() {
    let userData = await this.auth.currentUser!;
    this.userEmail = userData?.email;
    this.userId = userData?.uid;

    if (await this.polarAuthGroup.isAuthGroupEnabled()) {
      this.accessibleWorks = await this.polarAuthGroup.getAccessibleWorks();
      this.accessibleMasterGroups = await this.polarAuthGroup.getAccessibleMasterGroups();

      if (await this.polarAuthGroup.isUserInUnlimitedAuthorityTeam(this.userId!)) {
        this.enableAuthGroupSettings = true;
      }
    } else {
      this.accessibleWorks = undefined;
      this.accessibleMasterGroups = undefined;
    }

    this.companyId = await this.polar.getUserCompany();

    if (this.companyId != undefined) {
      let company = await this.polar.getCompany(this.companyId!);
      this.companyName = company.name;
      this.masterEditable = company.masterEditable ?? false;

      if (this.accessibleMasterGroups != undefined && this.accessibleMasterGroups.length == 0) {
        // if no master group is accessible, then masterEditable is false
        this.masterEditable = false;
      }

      (await this.polar.getWorksInCompany(this.companyId!)).subscribe((works) => {
        let wks = works.filter((f) => f.isProduction);
        wks.sort((a, b) => {
          // by name but if name is ends with number, sort by number
          try {
            let aName = a.name!;
            let bName = b.name!;
            let aNum = aName.match(/\d+$/);
            let bNum = bName.match(/\d+$/);
            if (
              aNum != null &&
              bNum != null &&
              aName.replace(aNum[0], '') == bName.replace(bNum[0], '')
            ) {
              let aNumInt = parseInt(aNum[0]);
              let bNumInt = parseInt(bNum[0]);
              if (aNumInt < bNumInt) {
                return -1;
              } else if (aNumInt > bNumInt) {
                return 1;
              } else {
                return 0;
              }
            }
            if (aName < bName) {
              return -1;
            }
            if (aName > bName) {
              return 1;
            }
            return 0;
          } catch {
            return 0;
          }
        });
        if (this.accessibleWorks != undefined) {
          wks = wks.filter((w) => {
            return (
              this.accessibleWorks!.findIndex(
                (a) =>
                  a.companyId == this.companyId && (a.id == w.id || a.id == '*') && a.interactable,
              ) >= 0
            );
          });
        }
        this.works = wks;
      });
    } else {
      this.companyName = undefined;
      this.works = [];
    }
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    return (timestamp as Timestamp).toDate().toLocaleString('ja-JP');
  }

  async goAuthGroupSettings() {
    const user = await this.auth.currentUser;
    const userId = user!.uid;
    const authGroupId = await this.polarAuthGroup.getAuthGroupId(userId);
    this.router.navigate(['/auth-group/' + authGroupId]);
  }

  open(work: WorkInfo) {
    if (work.status == 'ready') {
      this.router.navigate(['/work/' + this.companyId! + '/' + work.id!]);
    } else {
      this.commonUi.showConfirm({
        title: '学習中です',
        body: '学習中のため、この仕事はまだ利用できません。\nもうしばらくお待ちください',
        buttons: ['OK'],
      });
    }
  }
}
