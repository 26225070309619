import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { LabelAndComboBoxComponent } from '../../common/label-and-combo-box/label-and-combo-box.component';

import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { CompanyInfo, WorkInfo } from 'src/app/polar/entity/CompanyInfo';

import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-console-header',
  standalone: true,
  imports: [TranslateModule, LabelAndComboBoxComponent],
  templateUrl: './console-header.component.html',
  styleUrls: ['./console-header.component.scss'],
})
export class ConsoleHeaderComponent implements OnInit {
  companies: CompanyInfo[] = [];
  works: WorkInfo[] = [];

  selectedCompany: string = '';
  selectedWork: string = '';

  @Input() combos: boolean = true;
  @Input() label: string = 'Template - Create';
  @Input() onlyCompany: boolean = false;
  @Input() targetUrl: string = '';

  @Output() companySelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() workSelect: EventEmitter<string> = new EventEmitter<string>();

  sub?: Subscription;

  constructor(
    private polar: PolarFirebaseService,
    private auth: AngularFireAuth,
  ) {}

  goUrl() {
    window.open(this.targetUrl, '_blank');
  }

  async ngOnInit() {
    (await this.polar.getCompanies()).subscribe(async (d) => {
      this.companies = d;

      /* ===== DEMO ===== */
      if (await this.polar.isUserInDemoCollection((await this.auth.currentUser)!.uid)) {
        this.companies = d.filter((f) => f.id == 'XW5cIbcUIFfTBvhkVpbM');
        await this.selectCompany('XW5cIbcUIFfTBvhkVpbM');
        this.selectWork('oa1uC0di0qn79ucmtEMg');
        return;
      }
      /* ===== DEMO ===== */

      if (localStorage.getItem('last_company_console') != null) {
        if (
          this.companies.find((c) => c.id == localStorage.getItem('last_company_console')) != null
        ) {
          this.selectCompany(localStorage.getItem('last_company_console')!);
        }
      }

      if (d.length > 0 && this.selectedCompany == '') this.selectCompany(d[0].id!);
    });
    if (this.selectedCompany != '') {
      this.sub = (await this.polar.getWorksInCompany(this.selectedCompany)).subscribe((d) => {
        d = d.filter((f) => !f.isProduction);

        if (localStorage.getItem('last_work_console') != null) {
          if (this.works.find((c) => c.id == localStorage.getItem('last_work_console')) != null) {
            this.selectWork(localStorage.getItem('last_work_console')!);
          }
        }

        if (d.length > 0 && this.selectedWork == '') this.selectWork(d[0].id!);
      });
    }
  }

  async selectCompany(val: string) {
    this.companySelect.emit(val);
    this.selectedCompany = val;
    localStorage.setItem('last_company_console', val);
    if (this.sub != undefined) this.sub.unsubscribe();
    this.sub = (await this.polar.getWorksInCompany(this.selectedCompany)).subscribe((d) => {
      d = d.filter((f) => !f.isProduction);
      this.works = d;

      if (localStorage.getItem('last_work_console') != null) {
        if (this.works.find((c) => c.id == localStorage.getItem('last_work_console')) != null) {
          this.selectWork(localStorage.getItem('last_work_console')!);
        }
      }

      if (d.length > 0 && this.selectedWork == '') this.selectWork(d[0].id!);
    });
  }

  selectWork(val: string) {
    this.workSelect.emit(val);
    localStorage.setItem('last_work_console', val);
    this.selectedWork = val;
  }
}
