import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-large-checkbox',
  templateUrl: './large-checkbox.component.html',
  styleUrls: ['./large-checkbox.component.scss'],
})
export class LargeCheckboxComponent {
  @Input() checked: boolean = false;

  toggle() {
    this.checked = !this.checked;
  }
}
