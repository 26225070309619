<div class="field is-horizontal">
  <div class="field-label is-normal">
    @if (showNotice) {
      <div class="notice" [tooltip]="noticeMessage">
        <i class="fa-regular fa-triangle-exclamation"></i>
      </div>
    }
    <label class="label" [style.width.px]="useFieldLayout ? 78 : null">{{ label }}</label>
  </div>
  <div class="field-body" [style.flexGrow]="grow">
    <div class="field">
      <p class="control">
        @if (!multiline) {
          <input
            class="input is-small"
            type="text"
            [placeholder]="placeholder"
            [(ngModel)]="ref"
            [ngClass]="disableEdit ? 'disable-edit' : ''"
            [disabled]="disableEdit"
          />
        }
        @if (multiline) {
          <textarea
            class="textarea"
            [placeholder]="placeholder"
            rows="5"
            [(ngModel)]="ref"
            [ngClass]="disableEdit ? 'disable-edit' : ''"
            [disabled]="disableEdit"
          ></textarea>
        }
      </p>
    </div>
  </div>
</div>
