<app-console-header
  label="Configuration - マスタ設定"
  [combos]="true"
  (companySelect)="setCompanyId($event)"
  [onlyCompany]="true"
></app-console-header>

<div class="parent">
    <ng-container *ngIf="currentCompanyId">
      <app-master-settings-main
        [useAsAdmin]="true"
        [companyId]="currentCompanyId"
      >
      </app-master-settings-main>
    </ng-container>
</div>
