<div class="base">
  <header>
    @if (companyName != undefined && works != undefined) {
      <h1 class="company-name">{{ companyName }} {{ '様' | translate }}</h1>
    }

    @if (companyName == undefined) {
      <h1 class="company-name">所属している会社がありません</h1>
    }
    <div style="flex-grow: 1"></div>
    <div>
      @if (enableAuthGroupSettings && works != undefined) {
        <div
          class="user-ope auth-button"
          tooltip="権限設定"
          tooltipPlacement="bottom"
          (click)="goAuthGroupSettings()"
        >
          <i class="fa-regular fa-users"></i>
        </div>
      }
    </div>
    @if (masterEditable && works != undefined) {
      <div>
        @if (companyId) {
          <div
            class="user-ope"
            tooltip="マスタ設定"
            tooltipPlacement="bottom"
            (click)="showMasterSettingsModal = true"
          >
            <i class="fa-regular fa-table-list"></i>
          </div>
        }
      </div>
    }
    <div
      class="user-ope"
      tooltip="メニュー"
      tooltipPlacement="bottom"
      (click)="showAccountModal = true"
    >
      <i class="fa-regular fa-ellipsis"></i>
    </div>
  </header>
  <div class="gradient-line"></div>
  @if (companyName != undefined && works != undefined) {
    <header class="pb-4">
      <h1 style="font-size: 20px; font-weight: bold; color: #333" translate>仕事を選びましょう</h1>
    </header>
  }

  @if (works == undefined) {
    <app-inner-progress></app-inner-progress>
  }

  @if (companyName != undefined && works != undefined && works.length == 0) {
    <p>まだありません</p>
  }
  @if (companyName == undefined) {
    <p style="padding: 24px">表示するデータがありません</p>
  }

  <div class="rows-parent">
    @for (work of works; track work) {
      <div class="row">
        <div class="row-content" (click)="open(work)" tooltip="作業画面に移動">
          <div class="row-title">
            <div class="icon">
              <i class="fa-regular fa-folder-open"></i>
              @if (false) {
                <div #NOTIF class="notif">1</div>
              }
            </div>
            <div class="row-title-text">{{ work.name }}</div>
          </div>
          <div class="gap-row"></div>
          <div class="row-center">
            <p>{{ '作成日:' | translate }} {{ getDate(work.createdAt) }}</p>
            <p>{{ '更新日:' | translate }} {{ getDate(work.updatedAt) }}</p>
          </div>
          <div
            class="row-stat"
            style="pointer-events: all"
            [tooltip]="work.status != 'ready' ? '学習中...' : '利用可能'"
            tooltipPlacement="right"
          >
            @if (work.status != 'ready') {
              <app-inner-progress class="training"></app-inner-progress>
            }
            @if (work.status == 'ready') {
              <app-inner-progress class="completed" status="completed"></app-inner-progress>
            }
          </div>
        </div>
      </div>
    }
  </div>

  <div class="has-text-centered is-size-7 mt-6" style="opacity: 0.7">
    受発注バスターズ {{ version }} - by Batton Inc.
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7">
    {{ userId }}
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7; user-select: all">
    {{ userEmail }}
  </div>
</div>

<app-work-right-bottom-floating></app-work-right-bottom-floating>

@if (showAccountModal) {
  <app-main-account-modal
    [companyId]="companyId"
    [works]="works"
    (close)="showAccountModal = false"
  ></app-main-account-modal>
}

@if (companyId) {
  @if (showMasterSettingsModal) {
    <app-main-master-settings-modal
      [companyId]="companyId"
      (close)="showMasterSettingsModal = false"
    >
    </app-main-master-settings-modal>
  }
}
