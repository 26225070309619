import { Component } from '@angular/core';

import { MasterSettingsMainComponent } from '../../master-settings/master-settings-main.component';
import { ConsoleHeaderComponent } from '../console-header/console-header.component';

import { MASTER_UPDATING_SERVICE } from '../../master-settings/master-updating.service';
import { MasterUpdatingAdminService } from './master-updating-admin.service';

@Component({
  selector: 'app-console-master-settings',
  standalone: true,
  imports: [ConsoleHeaderComponent, MasterSettingsMainComponent],
  templateUrl: './console-master-settings.component.html',
  styleUrls: ['./console-master-settings.component.scss'],
  providers: [{ provide: MASTER_UPDATING_SERVICE, useClass: MasterUpdatingAdminService }],
})
export class ConsoleMasterSettingsComponent {
  currentCompanyId?: string;

  async setCompanyId(val: string) {
    this.currentCompanyId = val;
  }
}
