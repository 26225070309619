import { AfterViewInit, Component, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import * as jspreadsheet from 'jspreadsheet';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { NavigationService } from 'src/app/navigation.service';
import { UserImageInfo, WorkInfo } from 'src/app/polar/entity/CompanyInfo';
import { CsvLineRowSplitter, CsvLineSplitter, SpreadsheetCellRect, SpreadsheetData } from 'src/app/polar/entity/Workflow';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { SortUserImageInfos } from '../work-utils';
import { PolarApiService } from 'src/app/polar/polar-api.service';
import { FirebaseAnalyticsService } from 'src/app/firebase-analytics/firebase-analytics.service';
import { ParseRows } from './CsvUtils';
import { WorkColumnWidthStore } from './WorkColumnWidthStore';
import { ColumnWidthStore } from 'src/app/common/spreadsheet-v2/ColumnWidthMemory';
import { SpreadsheetV2Component } from 'src/app/common/spreadsheet-v2/spreadsheet-v2.component';

@Component({
  selector: 'app-work-check',
  templateUrl: './work-check.component.html',
  styleUrls: ['./work-check.component.scss'],
})
export class WorkCheckComponent implements AfterViewInit, OnInit {
  @ViewChild('IMAGE_LIST')
  el_image_list?: ElementRef;

  @ViewChild("SPREADSHEET")
  spreadsheetInstance!: SpreadsheetV2Component;

  companyId: string = '';
  workId: string = '';
  status: 'failed' | 'succeeded' = 'failed';

  ssData: SpreadsheetData = {
    csv: '',
    confidenceCsv: '',
    rectsCsv: '',
    rects: {},
    loadableDump: null,
  };
  ssDataNew: SpreadsheetData = {
    csv: '',
    confidenceCsv: '',
    rectsCsv: '',
    rects: {},
    loadableDump: null,
  };
  overlayRects: SpreadsheetCellRect[] = [];

  confirmed: UserImageInfo[] = [];
  datas: UserImageInfo[] = [];
  urls: { [index: string]: SafeResourceUrl } = {};

  currentDataIndex: number = 1;
  requiredColumn: string = '';
  header: string = '';
  reflection?: { [index: string]: string[] } = undefined;

  highlightRows: number[] = [];

  workColumnWidthStore: WorkColumnWidthStore;
  currentColumnWidthStore?: ColumnWidthStore;

  loading: boolean = true;
  loadingStat: 'loading' | 'failed' = 'loading';
  dataLoading: boolean = false;

  _selectedImageId: string = '';
  get selectedImageId(): string {
    return this._selectedImageId;
  }

  set selectedImageId(val: string) {
    if (this._selectedImageId != val) {
      this._selectedImageId = val;
      this.scrollToSelectedImage();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private polar: PolarFirebaseService,
    private nav: NavigationService,
    private commonUi: CommonUiProviderService,
    private sanitizer: DomSanitizer,
    private api: PolarApiService,
    private firebaseAnalytics: FirebaseAnalyticsService,
  ) {
    this.companyId = route.snapshot.paramMap.get('company_id')!;
    this.workId = route.snapshot.paramMap.get('work_id')!;
    this.status = route.snapshot.paramMap.get('status')! as any;
    this.workColumnWidthStore = WorkColumnWidthStore.loadLocal();
    this.currentColumnWidthStore = this.workColumnWidthStore.get(this.companyId, this.workId);
  }

  lastRows: string[][] = [];
  imageIdToRowIds: { [index: string]: number[] } = {};
  imageIdToRows: { [index: string]: string[][] } = {};

  scrollerTimeout: any = null;

  async scrollToSelectedImage() {
    //disabled
  }

  async updateColumnWidthStore(store: ColumnWidthStore) {
    this.workColumnWidthStore.set(this.companyId, this.workId, store);
    this.workColumnWidthStore.saveLocal();
    this.currentColumnWidthStore = store;
  }

  @ViewChild('IMAGE')
  rightContent?: ElementRef;

  get rightContentElement(): HTMLElement {
    return this.rightContent!.nativeElement;
  }

  @ViewChild("SPREADSHEET_PARENT")
  leftContent?: ElementRef;

  get leftContentElement(): HTMLElement {
    return this.leftContent!.nativeElement;
  }

  isHorizontalView: boolean = true;

  // for horizontal
  fixedWidth: boolean = false;
  rightContentWidthStart?: number;
  rightContentWidth?: number;

  // for vertical
  fixedHeight: boolean = false;
  bottomContentHeightStart?: number;
  bottomContentHeight?: number;

  isSplitterMouseDown: boolean = false;
  splitterMouseDownStartPosition: number = 0;
  splitterMouseMoveEventFn: any = null;
  splitterMouseUpEventFn: any = null;

  disableLoupe: boolean = false;

  registerEventsForSplitter() {
    this.isSplitterMouseDown = true;
    this.splitterMouseMoveEventFn = (event: MouseEvent) => {
      this.splitterMouseMove(event);
    };
    this.splitterMouseUpEventFn = (event: MouseEvent) => {
      this.splitterMouseUp(event);
    };
    document.addEventListener('mousemove', this.splitterMouseMoveEventFn);
    document.addEventListener('mouseup', this.splitterMouseUpEventFn);
    this.disableLoupe = true;
  }

  unregisterEventsForSplitter() {
    this.isSplitterMouseDown = false;
    document.removeEventListener('mousemove', this.splitterMouseMoveEventFn);
    document.removeEventListener('mouseup', this.splitterMouseUpEventFn);
    this.disableLoupe = false;
  }

  splitterMouseDown(event: MouseEvent) {
    event.preventDefault();
    if (this.isSplitterMouseDown) {
      this.unregisterEventsForSplitter();
      return;
    }
    if (this.isHorizontalView) {
      this.rightContentWidth = this.rightContentElement.getBoundingClientRect().width;
      this.rightContentWidthStart = this.rightContentWidth;
      this.splitterMouseDownStartPosition = event.clientX;
      this.fixedWidth = true;
    } else {
      this.bottomContentHeight = this.leftContentElement.getBoundingClientRect().height;
      this.bottomContentHeightStart = this.bottomContentHeight;
      this.splitterMouseDownStartPosition = event.clientY;
      this.fixedHeight = true;
    }
    this.registerEventsForSplitter();
  }

  splitterMouseMove(event: MouseEvent) {
    if (!this.isSplitterMouseDown) return;

    if (this.isHorizontalView) {
      let diff = event.clientX - this.splitterMouseDownStartPosition;

      const nextWidth = this.rightContentWidthStart! - diff;

      if (document.body.clientWidth - nextWidth < 240 || nextWidth < 240) {
        return;
      } else {
        this.rightContentWidth = nextWidth;
      }
    } else {
      let diff = event.clientY - this.splitterMouseDownStartPosition;

      const nextHeight = this.bottomContentHeightStart! - diff;

      if (document.body.clientHeight - nextHeight < 240 || nextHeight < 240) {
        return;
      } else {
        this.bottomContentHeight = nextHeight;
      }
    }
  }

  splitterMouseUp(event: MouseEvent) {
    this.unregisterEventsForSplitter();
  }

  checkRightContentSize() {
    if (this.rightContentWidth == undefined) {
      return;
    }

    if (this.rightContentWidth < 240 || document.body.clientWidth - this.rightContentWidth < 240) {
      this.rightContentWidth = undefined;
    }
  }

  checkBottomContentSize() {
    if (this.bottomContentHeight == undefined) {
      return;
    }

    if (this.bottomContentHeight < 240 || document.body.clientHeight - this.bottomContentHeight < 240) {
      this.bottomContentHeight = undefined;
    }
  }

  async createInnerData() {
    this.dataLoading = true;
    for (let i = 0; i < this.datas.length; i++) {
      let data = this.datas[i];
      let rowIds = this.imageIdToRowIds[data.id!];

      if (rowIds == undefined) {
        rowIds = [];
      } else {
        // filter disappeared rows
        rowIds = rowIds.filter((f) => this.ssDataNew.rowIdToText![f] !== undefined);
      }

      // create csv
      let csv = [];

      for (let j = 0; j < rowIds.length; j++) {
        let line = [];
        let rowId = rowIds[j];
        let texts = Object.values(this.ssDataNew.rowIdToText![rowId]);
        for (let k = 0; k < texts.length; k++) {
          let text = texts[k].toString();
          if (text == null || text == undefined) {
            line.push('');
          } else {
            text = text.replace(/"/g, '""');
            line.push('"' + text + '"');
          }
        }
        csv.push(line.join(','));
      }

      let dump = csv.join('\n');

      await this.polar.addUserImageInnerData(this.companyId, this.workId, {
        id: data.id!,
        actualBody: dump,
        header: this.header,
        updatedAt: serverTimestamp(),
      });
    }
    this.dataLoading = false;
  }

  onSpreadsheetCellChanged(data: { row: number; col: number; value: string; rowId: number }) {
    let keys = Object.keys(this.imageIdToRowIds);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let rowIds = this.imageIdToRowIds[key];
      let idx = rowIds.findIndex((f) => f == data.rowId);

      if (idx >= 0) {
        let data = this.datas.find((f) => f.id == key);
        if (data != undefined && this.selectedImageId != data.id!) this.setCurrentImage(data);
        break;
      }
    }

    if (this.ssData?.rects != undefined 
      && this.ssData.rects[data.rowId - 1] != undefined 
      && this.ssData.rects[data.rowId - 1][data.col] != undefined) {
        const hoverRects = this.ssData.rects[data.rowId - 1][data.col];
        this.overlayRects = hoverRects;
    } else {
      this.overlayRects = [];
    }
  }

  reflectionOverlayVisible: boolean = false;
  reflectionOverlayX: number = 0;
  reflectionOverlayY: number = 0;

  async onSpreadsheetCellTextChanged(data: {
    elm: HTMLElement;
    row: number;
    col: number;
    oldValue: string;
    newValue: string;
    rowId: number;
    updater: (x: number, y: number, value: string) => void;
  }) {
    if (this.reflection == undefined) return;
    if (Object.keys(this.reflection).length == 0) return;

    let header = this.ssDataNew.csv.split('\n')[0].split(',');
    let colName = header[data.col].replace(/"/g, '');
    let userImageInfo: UserImageInfo | undefined = undefined;

    if (this.reflection[colName] == undefined) return;

    if (this.reflection[colName].length == 0) return;

    // find userImageInfo
    let rowIndex = -1;
    let keys = Object.keys(this.imageIdToRowIds);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      console.log(this.imageIdToRowIds);
      let rowIds = this.imageIdToRowIds[key];
      let idx = rowIds.findIndex((f) => f == data.rowId);

      if (idx >= 0) {
        rowIndex = idx;
        let data = this.datas.find((f) => f.id == key);
        userImageInfo = data;
        break;
      }
    }

    if (rowIndex == -1) return;

    let companyId = this.companyId;
    let workId = this.workId;
    let templateid = userImageInfo!.templateId!;
    let documentId = userImageInfo!.id!;

    const key = rowIndex.toString() + '_' + data.col;
    let rowColMap: any = {};
    rowColMap[key] = data.newValue;
    console.log(rowColMap);

    const bdx = data.elm.getBoundingClientRect();
    this.reflectionOverlayX = bdx.right;
    this.reflectionOverlayY = bdx.top;
    this.reflectionOverlayVisible = true;

    try {
      const result = (
        await this.api.executeReadOne(companyId, workId, templateid, documentId, rowColMap)
      )['export_table'];

      const target_row: string[] = result[rowIndex + 1];

      for (let i = 0; i < target_row.length; i++) {
        if (this.reflection[colName].includes(header[i].replace(/"/g, ''))) {
          data.updater(i, parseInt(data.row as any), target_row[i]);
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.reflectionOverlayVisible = false;
  }

  onSpreadsheetChanged(data: SpreadsheetData) {
    this.ssDataNew = data;

    this.imageIdToRowIds[this.selectedImageId] = [];
    this.imageIdToRows[this.selectedImageId] = [];

    let csv = data.csv;
    let lines = CsvLineSplitter(csv);

    // remove header
    let header = lines.splice(0, 1)[0].split(',');

    let currentHighlightRows = [];
    let currentHighlightRowsRawText = [];

    let rows = [];

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];
      let cells = [];
      let inQuote = false;
      let currentCell = '';
      for (let j = 0; j < line.length; j++) {
        let char = line[j];
        if (char == '"') {
          inQuote = !inQuote;
        } else if (char == ',' && !inQuote) {
          cells.push(currentCell);
          currentCell = '';
        } else {
          currentCell += char;
        }
      }
      cells.push(currentCell);
      rows.push(cells);
    }

    let highlightColumnIndex = header.findIndex(
      (f) => f.replace(/"/g, '') == this.requiredColumn.replace(/"/g, ''),
    );
    let isNewRow = (r: string[], idx: number) => {
      let rowId = data.rowNumberToRowId![idx + 1];

      let keys = Object.keys(this.imageIdToRowIds);
      for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < this.imageIdToRowIds[keys[i]].length; j++) {
          if (this.imageIdToRowIds[keys[i]][j] == rowId) {
            return false;
          }
        }
      }

      return true;
    };

    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];

      // newly created
      if (row[highlightColumnIndex] != '' && isNewRow(row, i)) {
        currentHighlightRows.push(data.rowNumberToRowId![i + 1]);
        currentHighlightRowsRawText.push(row);
      }
    }

    this.imageIdToRowIds[this.selectedImageId] = currentHighlightRows;
    this.imageIdToRows[this.selectedImageId] = currentHighlightRowsRawText;

    this.highlightRows = currentHighlightRows;

    this.lastRows = rows;
  }

  updateUserImageInfo() {
    let data = this.ssDataNew;
    let csv = data.csv;
    let lines = CsvLineSplitter(csv);

    // remove header
    let header = lines.splice(0, 1);

    let currentHighlightRows = [];

    let rows = [];

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];
      let cells = [];
      let inQuote = false;
      let currentCell = '';
      for (let j = 0; j < line.length; j++) {
        let char = line[j];
        if (char == '"') {
          inQuote = !inQuote;
        } else if (char == ',' && !inQuote) {
          cells.push(currentCell);
          currentCell = '';
        } else {
          currentCell += char;
        }
      }
      cells.push(currentCell);
      rows.push(cells);
    }
  }

  setCurrentImage(image: UserImageInfo) {
    this.selectedImageId = image.id!;
    console.log(this.selectedImageId);
    this.onSpreadsheetChanged(this.ssDataNew);
  }

  isConfirmed(image: UserImageInfo): boolean {
    return this.confirmed.findIndex((f) => f.id == image.id) >= 0;
  }

  isConfirmedSelectedImage(): boolean {
    return this.confirmed.findIndex((f) => f.id == this.selectedImageId) >= 0;
  }

  isAllConfirmed(): boolean {
    return this.confirmed.length == this.datas.length;
  }

  async makeAllConfirmed() {
    const name =
      this.confirmText() === '全て入力済みにする'
        ? 'confirm-all-faild'
        : '全て確認済みにする'
          ? 'confirm-all-succeeded'
          : '';
    this.dataLoading = true;
    if (this.confirmed.length == this.datas.length) {
      for (let i = 0; i < this.confirmed.length; i++) {
        let obj = this.confirmed[i];
        obj.status = this.status;
        // TODO: make this transactional
        await this.polar.addUserDataImage(this.companyId, this.workId, obj);
        await this.polar.deleteUserDataImage(this.companyId, this.workId, {
          id: obj.id!,
          status: 'confirmed',
        });
      }
      this.confirmed = [];
    } else {
      for (let i = 0; i < this.datas.length; i++) {
        let obj = this.datas[i];

        if (this.confirmed.findIndex((f) => f.id == obj.id) < 0) {
          this.confirmed.push(obj);
        }

        // TODO: make this transactional
        obj.status = 'confirmed';
        await this.polar.addUserDataImage(this.companyId, this.workId, obj);
        await this.polar.deleteUserDataImage(this.companyId, this.workId, {
          id: obj.id!,
          status: this.status,
        });
      }
    }
    await this.createInnerData();
    this.dataLoading = false;
    if (name !== '') {
      // Tracking the event for click confirm-all button
      this.firebaseAnalytics.trackEvent({
        name: name,
        category: 'button-click',
        label: name,
        value: 1,
        interfaction: true,
      });
    }
  }

  async makeItConfirmed() {
    const name =
      this.confirmText() === '入力済みにする'
        ? 'confirm-faild'
        : '確認済みにする'
          ? 'confirm-succeeded'
          : '';
    if (name !== '') {
      // Tracking the event for click confirm-all button
      this.firebaseAnalytics.trackEvent({
        name: name,
        category: 'button-click',
        label: name,
        value: 1,
        interfaction: true,
      });
    }

    this.dataLoading = true;
    let makeItConfirmed = false;
    let obj = this.datas.find((f) => f.id == this.selectedImageId);
    if (obj != null) {
      if (this.confirmed.findIndex((f) => f.id == this.selectedImageId) < 0) {
        this.confirmed.push(obj);
        makeItConfirmed = true;
      } else {
        let idx = this.confirmed.findIndex((f) => f.id == this.selectedImageId);
        this.confirmed.splice(idx, 1);
        makeItConfirmed = false;
      }
    }

    if (obj != null) {
      if (makeItConfirmed) {
        obj.status = 'confirmed';
        // TODO: make this transactional
        await this.polar.addUserDataImage(this.companyId, this.workId, obj);
        await this.polar.deleteUserDataImage(this.companyId, this.workId, {
          id: this.selectedImageId,
          status: this.status,
        });
      } else {
        // restore
        obj.status = this.status;
        // TODO: make this transactional
        await this.polar.addUserDataImage(this.companyId, this.workId, obj);
        await this.polar.deleteUserDataImage(this.companyId, this.workId, {
          id: this.selectedImageId,
          status: 'confirmed',
        });
      }
    }

    let idxCurrent = this.datas.findIndex((f) => f.id == this.selectedImageId);
    if (idxCurrent < 0) {
      idxCurrent = 0;
    }
    let found = false;
    for (let i = idxCurrent; i < this.datas.length; i++) {
      if (this.confirmed.findIndex((f) => f.id == this.datas[i].id) < 0) {
        this.selectedImageId = this.datas[i].id!;
        found = true;
        break;
      }
    }
    idxCurrent = 0;

    if (!found) {
      for (let i = idxCurrent; i < this.datas.length; i++) {
        if (this.confirmed.findIndex((f) => f.id == this.datas[i].id) < 0) {
          this.selectedImageId = this.datas[i].id!;
          break;
        }
      }
    }

    await this.createInnerData();
    this.onSpreadsheetChanged(this.ssDataNew);
    this.dataLoading = false;

    // Tracking the event for click confirm-one button
    this.firebaseAnalytics.trackEvent({
      name: 'confirm-one',
      category: 'button-click',
      label: 'confirm-data-one',
      value: 1,
      interfaction: true,
    });
  }

  async ngOnInit() {
    this.loading = true;

    // load header of work
    let userWork = await this.polar.getUserWork(this.companyId, this.workId);
    this.header = userWork.header!;
    this.requiredColumn = userWork.requiredColumn!;
    this.reflection = userWork.reflection;

    this.onSpreadsheetChanged(this.ssData);

    // get images once
    let observable = await this.polar.getUserDataImages(this.companyId, this.workId, this.status);
    let isCreated = false;
    let subscription = observable.subscribe(async (f) => {
      if (isCreated) {
        return;
      }
      isCreated = true;
      subscription.unsubscribe();
      f = SortUserImageInfos(f);
      let maxImages = 50;
      if (f.length < maxImages) {
        this.datas = f;
      } else {
        let datas = [];
        for (let i = 0; i < maxImages; i++) {
          datas.push(f[i]);
        }
        this.datas = datas;
      }

      if (f.length > maxImages) {
        this.commonUi.showConfirm({
          title: '確認',
          body: `画像が${maxImages}枚を超えているため、${maxImages}枚のみ表示します。`,
          buttons: ['OK'],
        });
      }

      this.selectedImageId = f[0].id!;

      let csv = this.header;
      let confidenceCsv = this.header;
      let rectsCsv = this.header;

      this.imageIdToRowIds = {};

      let currentRowId = 1;

      let innerDatas = await this.polar.getUserImageInnerDataListByIds(
        this.companyId,
        this.workId,
        this.datas.map((f) => f.id!),
      );

      for (let i = 0; i < this.datas.length; i++) {
        let currentIdx = i;
        (async () => {
          try {
            let url = await this.polar.getUserFileUrl(
              this.companyId,
              this.workId,
              this.datas[currentIdx].convertedFilePaths![0],
            );

            let fetchResult = await fetch(url);
            let blob = await fetchResult.blob();
            this.urls[this.datas[currentIdx].id!] = this.sanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(blob),
            );
          } catch {}
        })();

        let innerData = innerDatas.find((f) => f.id == this.datas[i].id!)!;
        let currentCsv = '';
        if (innerData.actualBody != null) {
          currentCsv = innerData.actualBody;
        } else if (innerData.expectedBody != null) {
          currentCsv = innerData.expectedBody;
        }

        let lines = CsvLineSplitter(currentCsv);
        let confidenceLines: string[] = [];
        let rectsLines: string[] = [];

        if (!userWork.disableConfidence) {
          confidenceLines = CsvLineSplitter(innerData?.expectedBodyConfidence ?? '');
          rectsLines = CsvLineSplitter(innerData?.expectedBodyRects ?? '');
        }

        let rowIds = [];
        for (let j = 0; j < lines.length; j++) {
          if (lines[j] == '') continue;
          if (lines[j].replace(/,/g, '') == '') continue;
          csv += '\n' + lines[j];

          if (!userWork.disableConfidence) {
            if (confidenceLines.length == lines.length) {
              confidenceCsv += '\n' + confidenceLines[j];
            } else {
              confidenceCsv += '\n';
            }
            
            if (rectsLines.length == lines.length) {
              rectsCsv += '\n' + rectsLines[j];
            } else {
              rectsCsv += '\n';
            }
          }
          currentRowId += 1;
          rowIds.push(currentRowId);
        }

        let rows = ParseRows(lines);

        this.imageIdToRowIds[this.datas[i].id!] = rowIds;
        this.imageIdToRows[this.datas[i].id!] = rows;
      }

      const parseRects = (rects: string): { [index: number]: { [index: number]: SpreadsheetCellRect[] } } => {
        const ret: { [index: number]: { [index: number]: SpreadsheetCellRect[] } } = {};

        const datas = CsvLineRowSplitter(CsvLineSplitter(rects));
        for (let i = 0; i < datas.length; i++) {
          if (i == 0) {
            ret[0] = {};
            continue;
          }
          const rects = datas[i];
          ret[i] = {};
          for (let j = 0; j < rects.length; j++) {
            const rect = rects[j].replace(/'/g, '"');
            if (rect == '') {
              ret[i][j] = [];
              continue;
            }
            try {
              const rs = JSON.parse(rect);
              ret[i][j] = rs.map((r: string) => {
                const [x, y, w, h] = r.split(',').map(parseFloat);
                return { x, y, w, h };
              });
            }catch (e){
              console.error(e);
              ret[i][j] = [];
            }
          }
        }

        return ret;
      };

      this.ssData = {
        csv: csv,
        confidenceCsv: userWork.disableConfidence ? '' : confidenceCsv,
        rectsCsv: userWork.disableConfidence ? '' : rectsCsv,
        rects: parseRects(rectsCsv),
        loadableDump: null,
      };

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    });

    window.addEventListener('resize', this.windowResizeEvent);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowResizeEvent);
  }

  windowResizeEvent = () => {
    this.checkRightContentSize();
    this.checkBottomContentSize();
  };

  confirmText() {
    let text = '';

    if (this.status == 'failed') {
      if (this.isConfirmedSelectedImage()) {
        text = '入力済みを解除';
      } else {
        text = '入力済みにする';
      }
    } else if (this.status == 'succeeded') {
      if (this.isConfirmedSelectedImage()) {
        text = '確認済みを解除';
      } else {
        text = '確認済みにする';
      }
    }

    text += ' (' + this.confirmed.length + ' / ' + this.datas.length + ') ';

    return text;
  }

  confirmTextAll() {
    let text = '';

    if (this.status == 'failed') {
      if (this.confirmed.length == this.datas.length) {
        text = '全て入力済みを解除';
      } else {
        text = '全て入力済みにする';
      }
    } else if (this.status == 'succeeded') {
      if (this.confirmed.length == this.datas.length) {
        text = '全て確認済みを解除';
      } else {
        text = '全て確認済みにする';
      }
    }

    text += ' (' + this.confirmed.length + ' / ' + this.datas.length + ') ';

    return text;
  }

  ngAfterViewInit() {}

  back() {
    this.nav.back('work/' + this.companyId + '/' + this.workId);
  }

  isMenuActive: boolean = false;

  activateMenu() {
    this.isMenuActive = !this.isMenuActive;
  }

  async downloadCurrentFile() {
    this.isMenuActive = false;

    const data = this.datas.find(f => f.id == this.selectedImageId);
    if (data == null) return;

    const path = data.rawFilePath!;
    const url = await this.polar.getUserFileUrl(this.companyId, this.workId, path);

    // you need to fetch the file since you cannot let user download the file directly from the url
    const file = await fetch(url);
    const blob = await file.blob();
    const url2 = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url2;
    a.download = data.name!;
    a.target = "_blank";
    a.click();
  }

  downloadCurrentImage() {
    this.isMenuActive = false;

    if (this.selectedImageId == '') return;

    const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.urls[this.selectedImageId])!;

    var a = document.createElement("a");
    a.href = url;
    a.download = "busters_image_download.png";
    a.click();
  }

  async deleteCurrentImage() {
    this.isMenuActive = false;

    const idx = this.datas.findIndex((f) => f.id == this.selectedImageId);
    if (idx < 0) return;
    const data = this.datas[idx];

    if (this.isConfirmed(data)) {
      await this.commonUi.showConfirm({
        title: '確認',
        body: '確認済みの画像は削除できません',
        buttons: ['OK'],
      });
      return;
    }

    const confirmResult = await this.commonUi.showConfirm({
      title: '確認',
      body: '本当に画像を削除しますか？\n元に戻すことはできません',
      buttons: ['OK', 'キャンセル'],
    });
    if (confirmResult != 'OK') {
      return;
    }

    this.loading = true;

    try {
      const highlightRows = this.imageIdToRowIds[data.id!];

      let nextImageIndex = -1;

      if (idx >= 0 && idx < this.datas.length - 1) {
        nextImageIndex = idx + 1;
      } else if (idx > 0) {
        nextImageIndex = idx - 1;
      } else {
        nextImageIndex = -1;
      }

      if (nextImageIndex >= 0) {
        this.setCurrentImage(this.datas[nextImageIndex]);
      } else {
        this.selectedImageId = "";
      }

      this.spreadsheetInstance.deleteRowsWithRowId(highlightRows);
      this.datas.splice(idx, 1);
      await this.polar.deleteUserDataImage(this.companyId, this.workId, {
        id: data.id!,
        status: data.status,
      });

      if (this.selectedImageId == "") {
        await this.commonUi.showConfirm({
          title: '確認',
          body: '全ての画像が削除されました',
          buttons: ['OK'],
        });
        this.router.navigate(['/work/' + this.companyId + '/' + this.workId]);
      }
    } catch {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '画像の削除に失敗しました',
        buttons: ['OK'],
      });
    } finally {
      this.loading = false;
    }
  }
}