import { Directive, HostListener, inject, Input } from '@angular/core';
import { PolarFirebaseService } from '../polar/polar-firebase.service';

@Directive({
  selector: '[download-master-csv]',
  standalone: true,
})
export class DownloadMasterCsvDirective {
  private readonly polar = inject(PolarFirebaseService);
  @Input() filePath!: string;

  @HostListener('click', ['$event'])
  async onClick($event: MouseEvent) {
    $event.stopPropagation();

    const path = await this.polar.getCsvMasterFileUrl(this.filePath);

    const element = document.createElement('a');
    element.setAttribute('href', path);
    element.setAttribute('download', 'master.csv');
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
