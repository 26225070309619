<div class="back" [ngClass]="hiding ? 'hiding' : null" (click)="closeUploadWindow()"></div>
<div class="base" [ngClass]="hiding ? 'hiding' : null">
  <header>
    <h2><i class="fa-regular fa-cloud"></i>ファイルをアップロード</h2>
    <p>PDFまたは画像をアップロードしてください</p>
  </header>
  <div
    class="upload-area"
    [ngClass]="dragging ? 'dragging' : ''"
    (dragover)="dragover($event)"
    (drop)="drop($event)"
    (dragleave)="dragleave()"
  >
    @if (!dragging) {
      <i class="fa-light fa-file-import"></i>
    }
    @if (!dragging) {
      <p>ドラッグ&ドロップでファイルをアップロードする</p>
    }
    @if (!dragging) {
      <p>または</p>
    }
    @if (!dragging) {
      <button class="button" (click)="pickFile()">ファイルを選択</button>
    }
    @if (dragging) {
      <i class="fa-light fa-hand"></i>
    }
    @if (dragging) {
      <p>ドロップ</p>
    }
  </div>
  <div class="uploaded-files-parent">
    <div class="uploaded-files">
      @for (file of files; track file) {
        <div class="uploaded-file">
          <div class="delete-file">
            <button class="delete is-medium" (click)="remove(file)"></button>
          </div>
          @if (file.name.endsWith('.tiff') || file.name.endsWith('.tif')) {
            <div class="icon">
              <img src="assets/tiff-file-icon.svg" alt="tiff-file" class="tiff-file-icon" />
            </div>
          } @else {
            @if (file.name.endsWith('.pdf')) {
              <div class="icon">
                <i class="fa-regular fa-file-pdf"></i>
              </div>
            } @else {
              @if (urls[file.name + file.size]) {
                <img [src]="urls[file.name + file.size]" alt="" />
              }
            }
          }
        </div>
      }
    </div>
    <div class="uploaded-file-blurry"></div>
  </div>
  <div class="line"></div>
  <div class="buttons">
    <button class="button close" (click)="closeUploadWindow()" style="width: 92px">
      キャンセル
    </button>
    <button class="button done" (click)="uploadWorks()" style="width: 112px">アップロード</button>
  </div>
</div>

<input
  #INPUT
  class="file-input"
  type="file"
  accept="text/csv,image/*,application/pdf"
  (change)="filePicked()"
  multiple
/>

@if (loadingFull) {
  <div class="loading">
    @if (uploadStatus == 'loading') {
      <p>ファイルをアップロードしています... {{ percent }}</p>
    }
    <app-inner-progress
      style="filter: brightness(1.5)"
      [status]="uploadStatus"
    ></app-inner-progress>
  </div>
}
