import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-master-group-breadcrumbs',
  standalone: true,
  imports: [],
  template: `
    <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
      <ul>
        <li>
          <span>{{ groupName }}</span>
        </li>
        <li class="is-active master-li">
          <a href="#" aria-current="page">{{ masterName }}</a>
        </li>
      </ul>
    </nav>
  `,
  styles: [
    `
      // NOTE: bulma の content.scss と競合する部分を、ここで打ち消している
      .master-li {
        margin-top: 0;
      }

      .breadcrumb ul {
        margin-top: 0;
        margin-left: 0;
      }

      // NOTE: bulma の breadcrumb は a tag のみサポートだがリンクは不要なので span を追加している
      .breadcrumb span {
        align-items: center;
        color: hsl(229deg, 53%, 53%);
        display: flex;
        justify-content: center;
        padding-right: 0.75em;
        font-weight: normal;
      }

      .breadcrumb a {
        font-weight: normal;
      }
    `,
  ],
})
export class MasterGroupBreadcrumbsComponent {
  @Input() groupName!: string;
  @Input() masterName!: string;
}
