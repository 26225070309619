@if (canCreateAndDeleteItem) {
  <ul class="menu-list">
    <li>
      <button
        class="button"
        style="margin-bottom: 12px; width: 150px; font-size: 12px"
        (click)="newGroupClicked.emit()"
        translate
      >
        新規グループ作成
      </button>
    </li>
    <li>
      <button
        class="button"
        style="width: 150px; font-size: 12px"
        (click)="newMasterClicked.emit()"
        translate
      >
        新規マスタ作成
      </button>
    </li>
  </ul>
}
<p class="menu-label">グループ一覧</p>
@if (loading) {
  <app-inner-progress></app-inner-progress>
}
<ul class="menu-list">
  @for (group of masterAndGroups; track group) {
    @if (selectedGroup?.id === group.id) {
      <li>
        <div class="message is-link">
          <a class="message-body" (click)="groupSelected.emit(group)">{{ group.name }}</a>
        </div>
      </li>
    } @else {
      <li>
        <a (click)="groupSelected.emit(group)">{{ group.name }}</a>
      </li>
    }
  }
</ul>
