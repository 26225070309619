<div class="base">
  <div class="background" (click)="closeEvent()"></div>
  <div class="content">
    <div class="header">
      <div class="title">ダウンロード履歴</div>
      <div class="close" (click)="closeEvent()">
        <i class="fa-solid fa-times"></i>
      </div>
    </div>
    <div class="body">
      <table>
        <thead>
          <tr>
            <th scope="col">履歴ID</th>
            <th scope="col">ダウンロード日</th>
            <th scope="col">データ数</th>
            <th scope="col">復元</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let history of downloadHistories">
            <td scope="row">
              {{ history.id }}
            </td>
            <td>{{ getDate(history.createdAt) }}</td>
            <td>{{ history.downloadedIds!.length }}</td>
            <td>
              <button class="button editer" (click)="makeItAvailable(history)">
                戻す
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <p>復元しています...</p>
  <app-inner-progress></app-inner-progress>
</div>
