import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

import { InnerProgressComponent } from '../../../common/inner-progress/inner-progress.component';

import { PolarApiService } from '../../../polar/polar-api.service';
import { PolarFirebaseService } from '../../../polar/polar-firebase.service';

import { V2CandidateTemplate } from '../../../polar/entity/TemplateInfo';

@Component({
  selector: 'app-console-classification-version-second-selector',
  templateUrl: './console-classification-version-second-selector.component.html',
  styleUrls: ['./console-classification-version-second-selector.component.scss'],
  standalone: true,
  imports: [NgClass, InnerProgressComponent],
})
export class ConsoleClassificationVersionSecondSelectorComponent {
  // ここ帰る必要がある
  @Input() isActiveModal: boolean = false;
  @Input() v2CandidatesData: V2CandidateTemplate[] = [];
  @Input() fileEditingFile?: File;

  @Input() company_id: string = '';
  @Input() work_id: string = '';

  @Input() status: 'rotating' | 'matching' | 'loading' | 'select-rect' | 'select-v2' | 'exists' =
    'loading';

  closeModal() {
    window.location.reload();
  }

  constructor(
    private clova: PolarApiService,
    private polar: PolarFirebaseService,
  ) {}

  async registerForm() {
    this.status = 'loading';
    //NOTE: upload file
    const path = await this.polar.uploadTemplateFile(
      this.company_id,
      this.work_id,
      this.fileEditingFile!,
    );
    const newTemplateInfo = await this.polar.addTemplate(this.company_id, this.work_id, {
      referenceImage: path,
      exampleImages: [],
      createdAt: new Date(),
    });
    const newTemplateId = newTemplateInfo.id;
    await this.clova.registerTemplateV2(
      this.company_id,
      this.work_id,
      newTemplateId,
      this.fileEditingFile!,
    );
    window.location.reload();
  }
}
