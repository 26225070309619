import { Pipe, PipeTransform } from '@angular/core';
import { MasterDocumentData } from '../polar/entity/master';
import { getEmailSnapshotToShow } from './utils';

@Pipe({ name: 'safeEmail', standalone: true })
export class SafeEmailPipe implements PipeTransform {
  transform(value: MasterDocumentData['createdBy']): string {
    return getEmailSnapshotToShow(value);
  }
}
