import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Firestore, serverTimestamp } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';

import { PolarFireswitchService } from './bridge/polar-fireswitch.service';

import {
  AuthGroupInfo,
  AuthorizedCompany,
  AuthorizedTeam,
  AuthorizedUser,
} from './entity/AuthGroupInfo';
import { PolarFirebaseBaseService } from './polar-firebase-base-service';

import { Observable } from 'rxjs';

export interface AccessibleRule {
  companyId: string;
  id: string;
  readable?: boolean;
  writable?: boolean;
  interactable?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PolarFirebaseAuthService extends PolarFirebaseBaseService<AuthGroupInfo> {
  constructor(
    auth: AngularFireAuth,
    firestore: Firestore,
    storage: Storage,
    switcher: PolarFireswitchService,
  ) {
    super(auth, firestore, storage, switcher, 'authorized_groups');
  }

  async setUserName(authGroupId: string, userId: string, userName: string): Promise<void> {
    const col = this.switcher.collection(
      this.firestore,
      'authorized_groups/' + authGroupId + '/teams/all/users',
    );
    const doc = this.switcher.doc(col, userId);
    await this.switcher.setDoc(doc, { name: userName }, { merge: true });
  }

  async isAuthGroupEnabled(): Promise<boolean> {
    const authGroupId = await this.getAuthGroupId((await this.auth.currentUser)!.uid);
    return authGroupId != undefined && authGroupId.length > 0;
  }

  async isUserInUnlimitedAuthorityTeam(userId: string): Promise<boolean> {
    const authGroupId = await this.getAuthGroupId(userId);
    if (authGroupId == undefined && authGroupId == '') {
      return false;
    }

    const teamId = await this.getAuthGroupTeamId(userId);
    if (teamId != '') {
      const team = await this.getTeam(authGroupId, teamId);
      if (team.authority == 'unlimited') {
        return true;
      }
    }

    const allTeam = await this.getTeam(authGroupId, 'all');
    if (allTeam.authority == 'unlimited') {
      return true;
    }

    return false;
  }

  async listAuthorizedCompanies(authGroupId: string): Promise<Observable<AuthorizedCompany[]>> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/companies',
    );
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<AuthorizedCompany[]>;
  }

  async listAuthorizedCompaniesArray(authGroupId: string): Promise<AuthorizedCompany[]> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/companies',
    );
    let data = await this.switcher.getDocs(col);
    return data.docs.map((doc) => {
      let d = doc.data() as AuthorizedCompany;
      d.id = doc.id;
      return d;
    });
  }

  async addAuthorizedCompany(authGroupId: string, val: AuthorizedCompany): Promise<void> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/companies',
    );
    let docRef = this.switcher.doc(col, val.id!);
    // check exists and set createdAt or updatedAt
    let snap = await this.switcher.getDoc(docRef);
    if (snap.exists()) {
      // update
      val.updatedAt = serverTimestamp();
    } else {
      // new
      val.createdAt = serverTimestamp();
      val.updatedAt = serverTimestamp();
    }
    await this.switcher.setDoc(docRef, val);

    // default teams
    await this.addTeam(authGroupId, { id: 'all', authority: 'limited' });
    await this.addTeam(authGroupId, { id: 'admin', authority: 'unlimited' });
  }

  async deleteAuthorizedCompany(authGroupId: string, val: AuthorizedCompany): Promise<void> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/companies',
    );
    let docRef = this.switcher.doc(col, val.id!);
    await this.switcher.deleteDoc(docRef);
  }

  async addTeam(authGroupId: string, val: AuthorizedTeam): Promise<string> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/teams',
    );

    if (val.id == undefined || val.id == '' || val.id == null) {
      val.createdAt = serverTimestamp();
      val.updatedAt = serverTimestamp();
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
      return docRef.id;
    } else {
      val.updatedAt = serverTimestamp();
      let docRef = this.switcher.doc(col, val.id);
      await this.switcher.setDoc(docRef, val);
      return docRef.id;
    }
  }

  async getTeams(authGroupId: string): Promise<AuthorizedTeam[]> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/teams',
    );
    let data = await this.switcher.getDocs(col);
    return data.docs.map((doc) => {
      let d = doc.data() as AuthorizedTeam;
      d.id = doc.id;
      return d;
    });
  }

  async getTeam(authGroupId: string, teamId: string): Promise<AuthorizedTeam> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/teams',
    );
    let docRef = this.switcher.doc(col, teamId);
    let data = (await this.switcher.getDoc(docRef)).data() as AuthorizedTeam;
    data.id = teamId;
    return data;
  }

  async getTeamUsers(authGroupId: string, teamId: string): Promise<AuthorizedUser[]> {
    let col = await this.switcher.collection(
      this.firestore,
      this.baseCollectionName + '/' + authGroupId + '/teams/' + teamId + '/users',
    );
    let data = await this.switcher.getDocs(col);
    return data.docs.map((doc) => {
      let d = doc.data() as AuthorizedUser;
      d.id = doc.id;
      return d;
    });
  }

  async getAuthGroupId(userId: string): Promise<string> {
    const col = this.switcher.collection(this.firestore, 'users');
    const doc = this.switcher.doc(col, userId);
    const snap = await this.switcher.getDoc(doc);

    if (!snap.exists()) {
      return '';
    }

    const data = snap.data();

    return data!['authorized_group_id'];
  }

  async getAuthGroupTeamId(userId: string): Promise<string> {
    const col = this.switcher.collection(this.firestore, 'users');
    const doc = this.switcher.doc(col, userId);
    const snap = await this.switcher.getDoc(doc);

    if (!snap.exists()) {
      return '';
    }

    const data = snap.data();

    return data!['authorized_group_team_id'];
  }

  async getAccessibleDatasInAccessibleCompanies(
    key: string,
    authGroupId: string,
    authGroupTeamId: string,
    companyId: string,
  ): Promise<AccessibleRule[]> {
    const ret: AccessibleRule[] = [];
    const worksCol = this.switcher.collection(
      this.firestore,
      'authorized_groups/' +
        authGroupId +
        '/teams/' +
        authGroupTeamId +
        '/accessible_companies/' +
        companyId +
        '/' +
        key,
    );
    const datas = (await this.switcher.getDocs(worksCol)).docs;
    for (let j = 0; j < datas.length; j++) {
      const data = datas[j];
      ret.push({
        id: data.id,
        companyId: companyId,
        readable: data.data()['readable'],
        writable: data.data()['writable'],
        interactable: data.data()['interactable'],
      });
    }

    return ret;
  }

  async getAccessibleDatas(
    key: string,
    userId: string | undefined = undefined,
  ): Promise<AccessibleRule[]> {
    if (userId == undefined) {
      const user = await this.auth.currentUser;
      userId = user!.uid;
    }
    const authGroupId = await this.getAuthGroupId(userId);
    const authGroupTeamId = await this.getAuthGroupTeamId(userId);

    const ret: AccessibleRule[] = [];

    if (authGroupTeamId != '') {
      const col = this.switcher.collection(
        this.firestore,
        'authorized_groups/' + authGroupId + '/teams/' + authGroupTeamId + '/accessible_companies',
      );
      const docs = (await this.switcher.getDocs(col)).docs;

      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        const companyId = doc.id;
        const datas = await this.getAccessibleDatasInAccessibleCompanies(
          key,
          authGroupId,
          authGroupTeamId,
          companyId,
        );
        for (let j = 0; j < datas.length; j++) {
          const data = datas[j];
          ret.push(data);
        }
      }
    }

    {
      const col = this.switcher.collection(
        this.firestore,
        'authorized_groups/' + authGroupId + '/teams/' + 'all' + '/accessible_companies',
      );
      const docs = (await this.switcher.getDocs(col)).docs;
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        const companyId = doc.id;
        const datas = await this.getAccessibleDatasInAccessibleCompanies(
          key,
          authGroupId,
          'all',
          companyId,
        );
        for (let j = 0; j < datas.length; j++) {
          const data = datas[j];
          ret.push(data);
        }
      }
    }
    return ret;
  }

  async getAccessibleWorks(userId: string | undefined = undefined): Promise<AccessibleRule[]> {
    return await this.getAccessibleDatas('accessible_works', userId);
  }

  async getAccessibleMasterGroups(
    userId: string | undefined = undefined,
  ): Promise<AccessibleRule[]> {
    return await this.getAccessibleDatas('accessible_master_groups', userId);
  }
}
