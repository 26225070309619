<ng-container *ngIf="state$ | async as state">
  <div class="field is-horizontal" style="margin-top: 8px">
    <div class="field-label is-normal">
      <label class="label" translate>CSV</label>
    </div>
    <div class="field-body" [style.flex-grow]="grow">
      <div class="field">
        <p class="control">
          <app-workflow-master-list
            [companyMasters]="state.currentGroupMasters"
            [selectedMaster]="state.selectedMaster"
            (selectedMasterChange)="selectedMasterChanged($event)"
            (newMasterClicked)="openMasterCreatingModal($event)"
            (editMasterClicked)="openMasterEditingModal($event)"
            (deleteMasterClicked)="deleteMaster($event)"
            (useLatestMasterClicked)="selectedMasterChanged($event)"
          >
          </app-workflow-master-list>
        </p>
      </div>
    </div>
  </div>
</ng-container>
