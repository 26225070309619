<app-console-header label="Configuration - プロンプト" [combos]="false"></app-console-header>
@if (loading) {
  <div class="parent">
    @if (loading) {
      <app-inner-progress stat="loading"></app-inner-progress>
    }
  </div>
}
@if (!loading) {
  <div class="parent">
    <div class="columns is-multiline">
      @for (item of promptConfigurations; track item; let idx = $index) {
        <div class="column is-half">
          <article class="message is-link">
            <div class="message-body">
              <div class="field">
                <label class="label">タイトル</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="Text input"
                    [(ngModel)]="item.name"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">説明</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="説明を入力してください"
                    [(ngModel)]="item.description"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">プロンプト</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    rows="10"
                    placeholder="Textarea"
                    [(ngModel)]="item.prompt"
                  ></textarea>
                </div>
              </div>
              <button class="button" (click)="removeDomain(idx)">削除</button>
            </div>
          </article>
        </div>
      }
    </div>
    <button class="button is-link add" (click)="addDomain()">プロンプトを追加する</button>
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" (click)="save()">保存</button>
      </div>
      @if (saving) {
        <app-inner-progress [status]="savingStat"></app-inner-progress>
      }
    </div>
  </div>
}
