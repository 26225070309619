import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { LabelAndComboBoxComponent } from '../../common/label-and-combo-box/label-and-combo-box.component';

import { TooltipDirective } from '../../tooltip.directive';

import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { PolarFirebaseAuthService } from 'src/app/polar/polar-firebase-auth.service';
import { PolarFirebaseUserAuthService } from 'src/app/polar/polar-firebase-user-auth.service';

import { AuthorizedTeam, AuthorizedUser } from 'src/app/polar/entity/AuthGroupInfo';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-group-settings-users-list',
  templateUrl: './auth-group-settings-users-list.component.html',
  styleUrls: ['./auth-group-settings-users-list.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    InnerProgressComponent,
    NgClass,
    TooltipDirective,
    LabelAndComboBoxComponent,
  ],
})
export class AuthGroupSettingsUsersListComponent {
  loading: boolean = false;
  fullLoading: boolean = false;

  maxUsersInPage: number = 6;
  allUsers: AuthorizedUser[] = [];
  currentPage: number = 1;
  currentPageUsers: AuthorizedUser[] = [];
  userSelectedDict: { [index: string]: boolean } = {};
  userTeamIdDict: { [index: string]: string } = {};
  userTeamUpdatingDict: { [index: string]: boolean } = {};
  teams: AuthorizedTeam[] = [];
  editingUserId?: string = undefined;
  editingNameOnStart: string = '';
  editingName: string = '';
  editingTeamId: string = '';
  editingTeamIdOnStart: string = '';
  editingTeamList: { id: string; name: string }[] = [];
  selfUserId: string = '';

  get pages() {
    return Math.ceil(this.allUsers.length / this.maxUsersInPage);
  }

  get hasPrevPage() {
    return this.currentPage > 1;
  }

  get hasNextPage() {
    return this.currentPage < this.pages;
  }

  get teamNameQuoted() {
    return `"${this.teamName}"`;
  }

  _teamId: string = '';

  @Input() authGroupId: string = '';
  @Input() set teamId(val: string) {
    let changed = false;
    if (this._teamId != val) {
      changed = true;
    }
    this._teamId = val;
    this.teamIdUpdated();
  }
  get teamId(): string {
    return this._teamId;
  }
  @Input() parentLoading: boolean = false;
  @Input() teamName: string = '';

  _searchText: string = '';
  set searchText(val: string) {
    let updated = false;
    if (this._searchText != val) {
      updated = true;
    }
    this._searchText = val;

    if (updated) {
      this.updateSearchFilter();
    }
  }
  get searchText() {
    return this._searchText;
  }

  constructor(
    private auth: AngularFireAuth,
    private polarAuthGroup: PolarFirebaseAuthService,
    private polarUserAuthGroup: PolarFirebaseUserAuthService,
    private commonUi: CommonUiProviderService,
  ) {}

  usersListInitialLoading: boolean = true;

  async ngOnInit() {
    await this.update();
    this.usersListInitialLoading = false;
    this.selfUserId = (await this.auth.currentUser)!.uid!;
  }

  async update(showSpinner: boolean = false) {
    if (showSpinner) {
      this.usersListInitialLoading = true;
    }
    this.allUsers = await this.polarAuthGroup.getTeamUsers(this.authGroupId, 'all');
    this.teams = await this.polarAuthGroup.getTeams(this.authGroupId);
    await this.updateTeamList();
    await this.updateCurrentPageUsers();
    if (showSpinner) {
      this.usersListInitialLoading = false;
    }
  }

  async updateTeamList() {
    for (const team of this.teams) {
      if (team.id == 'all') {
        continue;
      }
      const users = await this.polarAuthGroup.getTeamUsers(this.authGroupId, team.id!);
      for (const user of users) {
        this.userTeamIdDict[user.id!] = team.id!;
      }
    }
  }

  async updateCurrentPageUsers() {
    let targetAllUsers = this.allUsers;
    if (this.searchText.trim() != '') {
      targetAllUsers = targetAllUsers.filter(
        (f) =>
          (f.name != undefined && f.name.toLowerCase().includes(this.searchText.toLowerCase())) ||
          (f.email != undefined && f.email.toLowerCase().includes(this.searchText.toLowerCase())),
      );
    }
    // teamId チーム所属 -> その他ユーザー の順でソート
    const front = targetAllUsers.filter((f) => this.userTeamIdDict[f.id!] == this.teamId);
    const back = targetAllUsers.filter((f) => this.userTeamIdDict[f.id!] != this.teamId);

    targetAllUsers = front.concat(back);
    this.currentPageUsers = targetAllUsers.slice(
      (this.currentPage - 1) * this.maxUsersInPage,
      this.currentPage * this.maxUsersInPage,
    );
  }

  teamIdUpdated() {
    this.currentPage = 1;
    this.updateCurrentPageUsers();
  }

  updateSearchFilter() {
    this.currentPage = 1;
    this.updateCurrentPageUsers();
  }

  async nextPage() {
    if (this.currentPage < this.pages) {
      this.currentPage++;
      this.updateCurrentPageUsers();
    }
  }

  async prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateCurrentPageUsers();
    }
  }

  isUserSelected(user: AuthorizedUser) {
    return this.userSelectedDict[user.id!] ?? false;
  }

  toggleUserSelected(user: AuthorizedUser) {
    this.userSelectedDict[user.id!] = !this.userSelectedDict[user.id!];
  }

  countSelectedUsers() {
    return Object.keys(this.userSelectedDict).filter((f) => this.userSelectedDict[f]).length;
  }

  getUserTeamName(user: AuthorizedUser) {
    const team = this.teams.find((f) => f.id == this.userTeamIdDict[user.id!]);
    if (team == undefined) {
      return 'なし';
    }

    if (team.id == 'admin') {
      return '管理者';
    }

    if (team.id == 'all') {
      return 'エラー';
    }

    return team.name;
  }

  getUserTeamId(user: AuthorizedUser): string {
    const id = this.userTeamIdDict[user.id!];
    if (id == undefined) {
      return 'all';
    } else {
      return id;
    }
  }

  userTrackBy(index: number, user: AuthorizedUser) {
    return user.id;
  }

  async confirmUserToRemoveUnlimitedAuthority(user: AuthorizedUser, nextTeamId: string) {
    if (user.id != this.selfUserId) {
      return true;
    }

    const nextTeam = this.teams.find((f) => f.id == nextTeamId);
    if (nextTeam?.authority == 'unlimited') {
      return true;
    }

    const currentTeamId = this.userTeamIdDict[user.id!];
    if (currentTeamId == undefined || currentTeamId == '') {
      return true;
    }
    const currentTeam = this.teams.find((f) => f.id == currentTeamId);
    if (currentTeam?.authority == 'unlimited') {
      await this.commonUi.showConfirm({
        title: '権限変更',
        body: '自分のアカウントを管理者権限のチームから、ユーザー権限のチームに移すことはできません',
        buttons: ['OK'],
      });
      return false;
    }

    return false;
  }

  async updateUserTeam(
    user: AuthorizedUser,
    teamId: string,
    updateAfterSuccess: boolean = true,
  ): Promise<boolean> {
    //this.fullLoading = true;
    let success = false;
    this.userTeamUpdatingDict[user.id!] = true;
    try {
      if (!(await this.confirmUserToRemoveUnlimitedAuthority(user, teamId))) {
        this.userTeamUpdatingDict[user.id!] = false;
        return false;
      }
      success = await this.polarUserAuthGroup.setUserTeam(user.id!, this.authGroupId, teamId);
    } catch {
      success = false;
    }
    if (!success) {
      this.commonUi.showConfirm({
        title: '権限エラー',
        body: 'ユーザの所属チームの変更に失敗しました',
        buttons: ['OK'],
      });
    } else {
      if (updateAfterSuccess) {
        await this.update();
      }
    }
    this.userTeamUpdatingDict[user.id!] = false;
    //this.fullLoading = false;
    return success;
  }

  async updateUserTeamSelected(teamId: string) {
    this.fullLoading = true;
    try {
      const keys = Object.keys(this.userSelectedDict);
      for (const key of keys) {
        if (this.userSelectedDict[key]) {
          const user = this.allUsers.find((f) => f.id == key);
          if (user == undefined) {
            continue;
          }

          if (!(await this.updateUserTeam(user, teamId, false))) {
            break;
          } else {
            this.userSelectedDict[key] = false;
          }
        }
      }
    } catch {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'ユーザの所属チームの変更に失敗しました',
        buttons: ['OK'],
      });
    }

    await this.update();
    this.fullLoading = false;
  }

  async deleteUserSelected() {
    const modalResult = await this.commonUi.showConfirm({
      title: 'ユーザ削除',
      body: '選択されたユーザを削除しますか？',
      buttons: ['OK', 'キャンセル'],
    });
    if (modalResult != 'OK') {
      return;
    }
    this.fullLoading = true;
    try {
      const keys = Object.keys(this.userSelectedDict);
      for (const key of keys) {
        if (this.userSelectedDict[key]) {
          const user = this.allUsers.find((f) => f.id == key);
          if (user == undefined) {
            continue;
          }
          if (user.id == this.selfUserId) {
            await this.commonUi.showConfirm({
              title: 'エラー',
              body: '自分自身を削除することはできません',
              buttons: ['OK'],
            });
            break;
          }
          if (!(await this.polarUserAuthGroup.deactivateUser(user.id!, this.authGroupId))) {
            await this.commonUi.showConfirm({
              title: 'エラー',
              body: 'ユーザの削除に失敗しました',
              buttons: ['OK'],
            });
            break;
          } else {
            this.userSelectedDict[key] = false;
            this.userTeamIdDict[key] = '';
          }
        }
      }
    } catch {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'ユーザの削除に失敗しました',
        buttons: ['OK'],
      });
    }

    await this.update();
    this.fullLoading = false;
  }

  createEditingTeam() {
    const editingTeams: { id: string; name: string }[] = [];

    editingTeams.push({
      id: '',
      name: 'なし',
    });

    for (const team of this.teams) {
      if (team.id == 'all') {
        continue;
      } else if (team.id == 'admin') {
        editingTeams.push({ id: team.id!, name: '管理者' });
      } else {
        editingTeams.push({ id: team.id!, name: team.name! });
      }
    }

    this.editingTeamList = editingTeams;
  }

  async editUser(user: AuthorizedUser) {
    this.editingUserId = user.id;
    // user name
    this.editingName = user.name;
    this.editingNameOnStart = user.name;

    // team
    this.createEditingTeam();
    if (this.userTeamIdDict[user.id!] == undefined) {
      this.editingTeamId = '';
    } else {
      this.editingTeamId = this.userTeamIdDict[user.id!];
    }
    this.editingTeamIdOnStart = this.editingTeamId;
  }

  async saveEditingUser() {
    if (this.editingNameOnStart != this.editingName) {
      this.fullLoading = true;
      try {
        if (this.editingUserId != undefined) {
          await this.polarAuthGroup.setUserName(
            this.authGroupId,
            this.editingUserId,
            this.editingName,
          );
        }
      } catch {
        this.commonUi.showConfirm({
          title: 'エラー',
          body: 'ユーザ名の変更に失敗しました',
          buttons: ['OK'],
        });
      }
    }
    if (this.editingTeamIdOnStart != this.editingTeamId) {
      if (
        await this.updateUserTeam(
          this.allUsers.find((f) => f.id == this.editingUserId)!,
          this.editingTeamId,
          false,
        )
      ) {
        this.userTeamIdDict[this.editingUserId!] = this.editingTeamId;
      }
    }
    this.editingUserId = undefined;
    this.editingName = '';
    await this.update();
    this.fullLoading = false;
  }

  tryRemoveFocus() {
    try {
      if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
    } catch {}
  }

  addingUser: boolean = false;
  addingUserName: string = '';
  addingUserEmail: string = '';
  addingUserSendEmail: boolean = true;

  async startAddingUser() {
    this.addingUserName = '';
    this.addingUserEmail = '';
    this.addingUser = true;
  }

  async stopAddingUser() {
    this.addingUser = false;
  }

  async addUser() {
    if (
      this.addingUserEmail == '' ||
      this.addingUserEmail.includes(' ') ||
      !this.addingUserEmail.includes('@')
    ) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'メールアドレスが正しくありません',
        buttons: ['OK'],
      });
      return;
    }

    this.tryRemoveFocus();

    this.fullLoading = true;

    let success = false;

    try {
      const addUserResult = await this.polarUserAuthGroup.addUser(
        this.addingUserEmail,
        this.authGroupId,
      );

      if (addUserResult.success_to_join_group) {
        if (this.addingUserSendEmail) {
          await this.auth.sendPasswordResetEmail(this.addingUserEmail);
        }
      }

      if (!addUserResult.success_to_join_group) {
        this.commonUi.showConfirm({
          title: 'エラー',
          body: 'ユーザの追加に失敗しました',
          buttons: ['OK'],
        });
      }
      const uid = addUserResult.uid;
      // update user name
      if (this.addingUserName != '') {
        await this.polarAuthGroup.setUserName(this.authGroupId, uid, this.addingUserName);
      }

      success = true;
    } catch {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'ユーザの追加に失敗しました',
        buttons: ['OK'],
      });
    }

    if (success) {
      await this.update();
      this.addingUser = false;
    }

    this.fullLoading = false;
  }

  async exportCsv() {
    const result = await this.commonUi.showConfirm({
      title: 'CSV出力',
      body: 'ユーザ情報をCSVファイルで出力しますか？',
      buttons: ['OK', 'キャンセル'],
    });
    if (result != 'OK') {
      return;
    }
    let csv = 'UserName,UserID,Email,Team,CreatedAt,UpdatedAt\n';
    for (const user of this.allUsers) {
      csv += `${user.name},${user.id},${user.email},${this.getUserTeamName(user)},${this.getDate(
        user.createdAt,
      )},${this.getDate(user.updatedAt)}\n`;
    }
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'users.csv';
    a.click();
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    if (timestamp == undefined) {
      return '-';
    } else {
      return (timestamp as Timestamp).toDate().toLocaleString('ja-JP');
    }
  }

  isUserInSelectedTeam(user: AuthorizedUser): boolean {
    if (this.teamId == 'all') {
      return true;
    }

    const teamId = this.userTeamIdDict[user.id!];

    return teamId == this.teamId;
  }
}
