<div class="base">
  <div class="background" (click)="close.emit()"></div>

  <div class="content is-flex" style="overflow: hidden">
    <div class="header">
      <div class="close" (click)="close.emit()">
        <i class="fa-solid fa-times"></i>
      </div>
      <div class="title">マスタ修正</div>
    </div>

    <div class="body mt-4" style="height: 100%">
      @if (currentMaster) {
        <section>
          <div style="margin-bottom: 20px">
            <app-master-group-breadcrumbs
              [groupName]="currentGroup.name"
              [masterName]="originalName"
            >
            </app-master-group-breadcrumbs>
          </div>
          <app-label-and-text-input
            [label]="'マスタ名' | translate"
            [placeholder]="'マスタ名' | translate"
            [(value)]="currentMaster.name"
            style="width: 100%"
          ></app-label-and-text-input>
          <div class="field is-horizontal mt-2 mb-2">
            <div class="field-label is-normal">
              <label class="label" style="width: 78px">
                {{ '最新CSV' | translate }}
              </label>
            </div>
            <div class="field-body" style="flex-grow: 4.2">
              <div class="field">
                <p class="control">
                  <button download-master-csv [filePath]="currentMaster.csv.filePath" class="save">
                    <p translate>CSVダウンロード</p>
                  </button>
                </p>
              </div>
            </div>
          </div>
          <app-csv-input
            [label]="'修正後CSV'"
            (csvUploaded)="csvUploaded($event)"
            placeholder=""
            [multiline]="true"
            [companyId]="companyId"
          >
          </app-csv-input>
          @if (csvErrorMessages) {
            <div class="message is-danger">
              <div class="message-body csv-error csv-error-button">
                <div (click)="openModal = true">CSV のエラーの詳細をみる</div>
              </div>
            </div>
            <app-csv-error-details
              [openModal]="openModal"
              [csvErrorMessages]="csvErrorMessages"
              (closeModal)="openModal = false"
            >
            </app-csv-error-details>
          }
          <button class="button" (click)="save()">保存</button>
        </section>
      }
    </div>
  </div>
</div>

@if (loading) {
  <div class="loading">
    <app-inner-progress></app-inner-progress>
  </div>
}
