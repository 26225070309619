import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-label-and-check-box',
  templateUrl: './label-and-check-box.component.html',
  styleUrls: ['./label-and-check-box.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgClass],
})
export class LabelAndCheckBoxComponent {
  @Input() label: string = 'Undefined';
  @Input() useFieldLayout: boolean = false;
  @Input() set checked(val: boolean) {
    this.update(val);
  }
  @Input() disabled: boolean = false;

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('CHECKBOX') checkbox!: ElementRef;

  _checked: boolean = false;

  constructor() {}

  ngAfterViewInit() {
    this.checkbox.nativeElement.checked = this._checked;
  }

  toggle() {
    if (this.disabled) return;
    this.checkbox.nativeElement.checked = !this.checkbox.nativeElement.checked;
    this._checked = this.checkbox.nativeElement.checked;
    this.checkedChange.emit(this._checked);
  }

  checkChanged(e: any) {
    this.checkedChange.emit(e.target.checked);
  }

  update(val: boolean) {
    this._checked = val;
    if (
      this.checkbox != undefined &&
      this.checkbox.nativeElement != undefined &&
      this.checkbox.nativeElement.checked != val
    ) {
      this.checkbox.nativeElement.checked = val;
    }
  }
}
