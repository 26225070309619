<div class="base">
  <!--ヘッダ-->
  <header>
    <button class="button back-button" (click)="back()">
      <i class="fa-regular fa-chevron-left"></i>
    </button>
    <h1 style="font-size: 24px; font-weight: bold" *ngIf="authGroupInfo != undefined">
      権限設定 - {{ authGroupInfo.name }}
    </h1>
    <h1 style="font-size: 24px; font-weight: bold" *ngIf="authGroupInfo == undefined">...</h1>
  </header>

  <!--表示前のローディング-->
  <div class="content" *ngIf="loading">
    <app-inner-progress></app-inner-progress>
  </div>

  <!--チーム一覧の表示-->
  <div class="content list-teams" *ngIf="!loading">
    <div class="group-container">
      <div class="company-stamps">
        <div class="company-stamp" *ngFor="let companyName of companyNames">
          {{ companyName }}
        </div>
      </div>
      <h1 class="title" *ngIf="authGroupInfo != undefined">チーム</h1>
      <div class="team-grid-parent">
        <div
          class="team-grid-item"
          *ngFor="let team of teams"
          [ngClass]="selectedTeamId == team.id ? 'selected' : ''"
          (click)="selectTeam(team)"
        >
          {{ getTeamName(team) }}
          <div class="team-grid-settings" tooltip="権限設定" (click)="editTeam(team)">
            <i class="fa-light fa-sliders-up"></i>
          </div>
        </div>
        <div class="team-grid-item team-grid-item-plus" (click)="addTeam()">
          <i class="fa-solid fa-plus"></i>
        </div>
      </div>
    </div>
  </div>

  <!--メンバー一覧-->
  <app-auth-group-settings-users-list
    #USERSLIST
    [authGroupId]="authGroupId"
    [teamId]="selectedTeamId"
    [teamName]="selectedTeamName"
    [parentLoading]="loading"
  ></app-auth-group-settings-users-list>
</div>

<app-auth-group-settings-authority-modal
  *ngIf="isAuthorityModalShown"
  (hide)="hideAuthorityModal()"
  (willHide)="willHideAuthorityModal()"
  [authGroupId]="authGroupId"
  [targetTeam]="authorityModalTargetAuthorizedTeam"
></app-auth-group-settings-authority-modal>
