import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CsvLineSplitter, SpreadsheetData } from 'src/app/polar/entity/Workflow';
import Spreadsheet, { Cell } from 'x-data-spreadsheet';

@Component({
  selector: 'app-spreadsheet',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.scss'],
})
export class SpreadsheetComponent implements AfterViewInit {
  @ViewChild('spreadsheet') el?: ElementRef;
  ss?: Spreadsheet;

  @Output() changed: EventEmitter<SpreadsheetData> = new EventEmitter<SpreadsheetData>();

  @Input() readonly: boolean = false;

  @Output() activateStatusChanged: EventEmitter<boolean> = new EventEmitter();

  lastDump: string = '';
  lastConfidenceDump?: string = '';

  _data: SpreadsheetData | null | undefined;

  get data(): SpreadsheetData | null | undefined {
    return this._data;
  }

  @Input() set data(val: SpreadsheetData | null | undefined) {
    console.log(val);
    this._data = val;

    if (val == null && this.ss != null) {
      this.ss.loadData({});
      this.lastDump = '';
    } else if (
      this.ss != null &&
      val != null &&
      this.lastDump != val.csv &&
      val.loadableDump != null
    ) {
      this.ss.loadData(JSON.parse(val.loadableDump));
      this.lastDump = val.csv;
    } else if (
      this.ss != null &&
      val != null &&
      (this.lastDump != val.csv || this.lastConfidenceDump != val.confidenceCsv)
    ) {
      console.log(val.csv);
      this.ss.loadData({});
      // parse csv considering double quotes
      let lines = CsvLineSplitter(val.csv);
      let confidenceLines = CsvLineSplitter(val.confidenceCsv ?? '');

      const styles = [
        {
          bgcolor: 'white',
        },
      ];

      // 10 steps gradient color (Red)
      for (let i = 1; i <= 10; i++) {
        styles.push({
          bgcolor: `rgba(255, 0, 0, ${i / 10})`,
        });
      }

      let data: any = {
        name: 'sheet',
        freeze: 'A1',
        styles: styles,
        merges: [],
        rows: {
          len: 200,
        },
        cols: {
          len: 120,
        },
        validations: [],
        autofilter: [],
      };
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        let confidenceLine = confidenceLines[i];
        let cells = [];
        let cellsConfidence = confidenceLine != null ? confidenceLine.split(',') : [];
        let inQuote = false;
        let currentCell = '';
        for (let j = 0; j < line.length; j++) {
          let char = line[j];
          if (char == '"') {
            inQuote = !inQuote;
          } else if (char == ',' && !inQuote) {
            cells.push(currentCell);
            currentCell = '';
          } else {
            currentCell += char;
          }
        }
        cells.push(currentCell);

        for (let j = 0; j < cells.length; j++) {
          //(this.ss.cellText(i, j, cells[j]) as any).reRender();
          let confidence = 1;
          if (
            cellsConfidence.length > j &&
            cellsConfidence[j] != null &&
            cellsConfidence[j] != ''
          ) {
            confidence = parseFloat(cellsConfidence[j]);
          }
          let errorRate = 1 - confidence;
          const style = Math.floor(errorRate * 10);
          if (data['rows'][i.toString()] == null) {
            data['rows'][i.toString()] = {
              cells: {},
            };
          }
          data['rows'][i.toString()]['cells'][j.toString()] = {
            text: cells[j],
            style: style,
          };
        }
      }

      this.ss.loadData(data);
      this.lastDump = val.csv;
      this.lastConfidenceDump = val.confidenceCsv;
    }

    if (this.ss == null && val != null && this.lastDump != val.csv) {
      // refresh again if this.ss is available later
      this._data = null;
      let int = setInterval(() => {
        if (this.ss != null) {
          this.data = val;
          clearInterval(int);
        }
      }, 100);
    }
  }

  isActivated() {
    return (this.ss as any).sheet.editor.el.el.style.display !== 'none';
  }

  activateInputBox() {
    if (this.isActivated()) return;
    var ss = this.ss as any;
    ss.sheet.editor.clear();
    const editor = ss.sheet.editor;
    const data = ss.sheet.data;
    data.getSelectedRect();
    const sOffset = data.getSelectedRect();
    const tOffset = ss.sheet.getTableOffset();
    let sPosition = 'top';
    if (sOffset.top > tOffset.height / 2) {
      sPosition = 'bottom';
    }
    editor.setOffset(sOffset, sPosition);
    editor.setCell(data.getSelectedCell(), data.getSelectedValidator());
  }

  focusing = false;
  currentCell?: Cell;

  isActivatedSelf() {}

  startEditSelf() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ss = new Spreadsheet(this.el?.nativeElement, {
        view: {
          width: () => this.el!.nativeElement.clientWidth,
          height: () => this.el!.nativeElement.clientHeight,
        },
        showToolbar: false,
        showBottomBar: false,
        style: {
          bgcolor: '#ffffff',
          align: 'left',
          valign: 'middle',
          textwrap: false,
          strike: false,
          underline: false,
          color: '#0a0a0a',
          font: {
            name: 'Helvetica',
            size: 10,
            bold: false,
            italic: false,
          },
        },
        mode: this.readonly ? 'read' : 'edit',
        row: {
          len: 200,
          height: 24,
        },
        col: {
          len: 120,
          width: 100,
          indexWidth: 60,
          minWidth: 60,
        },
      } as any);

      this.ss.change((f: any) => {
        this.lastDump = '';
        let largestRow = 0;
        let largestCol = 0;
        Object.keys(f.rows).forEach((key: string) => {
          if (key == 'len') {
            return;
          }
          if (parseInt(key) > largestRow) {
            largestRow = parseInt(key);
          }
          let data = f.rows[key];

          // Once a value is entered, even if it is deleted, it remains as a blank cell, so ignore the trailing blank cells and find the largest column.
          type CellText = { text: string };
          const cellListDescendingOrderByCellIndex = Object.entries(data.cells)
            .map(([key, value]) => {
              const cellText = (value as CellText).text;
              return {
                index: parseInt(key),
                isEmpty: cellText == null || cellText === '',
              };
            })
            .sort((a, b) => b.index - a.index);

          const lastIndexNotEmptyCellValue = cellListDescendingOrderByCellIndex.find(
            (v) => !v.isEmpty,
          );

          const tmpLargestCol = lastIndexNotEmptyCellValue?.index ?? 0;

          if (tmpLargestCol > largestCol) {
            largestCol = tmpLargestCol;
          }
        });

        let csv = [];

        for (let i = 0; i <= largestRow; i++) {
          let line = [];
          for (let j = 0; j <= largestCol; j++) {
            if (f.rows[i.toString()] && f.rows[i.toString()].cells[j.toString()]) {
              let text = f.rows[i.toString()].cells[j.toString()].text as string;
              if (text == null || text == undefined) {
                line.push('');
              } else {
                text = text.replace(/"/g, '""');
                line.push('"' + text + '"');
              }
            } else {
              line.push('');
            }
          }
          csv.push(line.join(','));
        }

        this.lastDump = csv.join('\n');

        this.changed.emit({
          csv: csv.join('\n'),
          loadableDump: JSON.stringify(f),
        });
      });

      setInterval(() => {
        this.activateStatusChanged.emit(this.isActivated());
      }, 100);
    }, 50);

    window.addEventListener('mousedown', (e) => {
      if ((this.el!.nativeElement as HTMLElement).contains(e.target as any)) {
        this.focusing = true;
      } else {
        this.focusing = false;
      }
    });
  }
}
