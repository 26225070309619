import { ColumnWidthStore } from "src/app/common/spreadsheet-v2/ColumnWidthMemory";

export class WorkColumnWidthStore {
    values: {[key: string]: ColumnWidthStore} = {};

    constructor(values?: {[key: string]: ColumnWidthStore}) {
        if (values) {
            this.values = values;
        }
    }

    constructKey(companyId: string, workId: string) {
        return `${companyId}-${workId}`;
    }

    set(companyId: string, workId: string, store: ColumnWidthStore) {
        this.values[this.constructKey(companyId, workId)] = store;
    }

    get(companyId: string, workId: string): ColumnWidthStore {
        const ret = this.values[this.constructKey(companyId, workId)];

        return ret ?? {value: {}};
    }

    saveLocal() {
        localStorage.setItem('columnWidthStore', JSON.stringify(this.values));
    }

    static loadLocal(): WorkColumnWidthStore {
        let store = localStorage.getItem('columnWidthStore');
        if (store) {
            return new WorkColumnWidthStore(JSON.parse(store));
        } else {
            return new WorkColumnWidthStore();
        }
    }
}