import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';

import { InnerProgressComponent } from '../common/inner-progress/inner-progress.component';

import { CommonUiProviderService } from '../common/common-ui-provider.service';
import { PolarFirebaseService } from '../polar/polar-firebase.service';
import { FirebaseAnalyticsService } from 'src/app/firebase-analytics/firebase-analytics.service';

import { UserImageInfo } from '../polar/entity/CompanyInfo';

@Component({
  selector: 'app-modal-list-files',
  templateUrl: './modal-list-files.component.html',
  styleUrls: ['./modal-list-files.component.scss'],
  standalone: true,
  imports: [InnerProgressComponent],
})
export class ModalListFilesComponent {
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() images: UserImageInfo[] = [];
  @Input() imagesUpdatable: UserImageInfo[] = [];
  @Input() failProperty: string = '';
  @Input() companyId: string = '';
  @Input() workId: string = '';

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private firebaseAnalytics: FirebaseAnalyticsService,
  ) {}

  closeEvent() {
    this.close.emit();
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    return (timestamp as Timestamp).toDate().toLocaleString();
  }

  getFailedFlag(_image: UserImageInfo): boolean {
    if (this.failProperty == '') {
      return false;
    }
    let image = this.imagesUpdatable.find((f) => f.id == _image.id);
    return (image as any)[this.failProperty] === true;
  }

  getSucceededFlag(image: UserImageInfo): boolean {
    if (this.imagesUpdatable.findIndex((f) => f.id == image.id) >= 0) {
      return false;
    } else {
      return true;
    }
  }

  getStatus(image: UserImageInfo): 'completed' | 'failed' | 'loading' {
    if (image.uploadingStatus == 'failed') {
      return 'failed';
    }
    if (this.getSucceededFlag(image)) {
      return 'completed';
    }

    if (this.getFailedFlag(image)) {
      return 'failed';
    }

    return 'loading';
  }

  async goBackStatus(image: UserImageInfo) {
    console.log(image.id);
    let result = await this.commonUi.showConfirm({
      title: '確認',
      body: 'ひとつ前のステップに戻してもよろしいですか？',
      buttons: ['OK', 'キャンセル'],
    });
    if (result != 'OK') return;

    let idx = this.images.findIndex((f) => f.id == image.id);
    this.images.splice(idx, 1);

    let prevStatus = '';
    let name = '';
    if (image.status == 'confirmed') {
      name = 'revert-confirmed';
      if (image.isFailed) {
        prevStatus = 'failed';
      } else {
        prevStatus = 'succeeded';
      }
    } else if (image.status == 'failed' || image.status == 'succeeded') {
      prevStatus = 'converted';
      name = image.status === 'failed' ? 'revert-failed' : 'revert-succeeded';
      try {
        delete image.templateId;
        delete image.templateSimilarity;
      } catch {}
    } else if (image.status == 'converted') {
      name = 'revert-converted';
      prevStatus = 'uploaded';
    } else {
      alert('NOT SUPPORTED');
      return;
    }

    let curStat = image.status;
    image.status = prevStatus;
    await this.polar.addUserDataImage(this.companyId, this.workId, image);
    await this.polar.deleteUserDataImage(this.companyId, this.workId, {
      id: image.id,
      status: curStat,
    });

    if (
      name === 'revert-converted' ||
      name === 'revert-failed' ||
      name === 'revert-succeeded' ||
      name === 'revert-confirmed'
    ) {
      this.firebaseAnalytics.trackEvent({
        name: name,
        category: 'button-click',
        label: name,
        value: 1,
        interfaction: true,
      });
    }
  }

  async deleteItem(image: UserImageInfo) {
    let result = await this.commonUi.showConfirm({
      title: '確認',
      body: '削除してもよろしいですか？',
      buttons: ['OK', 'キャンセル'],
    });
    if (result != 'OK') return;
    let idx = this.images.findIndex((f) => f.id == image.id);
    this.images.splice(idx, 1);
    await this.polar.deleteUserDataImage(this.companyId, this.workId, image);

    const name = 'delete-' + image.status;
    if (
      name === 'delete-uploaded' ||
      name === 'delete-converted' ||
      name === 'delete-failed' ||
      name === 'delete-succeeded' ||
      name === 'delete-confirmed'
    ) {
      // Tracking delete event when delete item
      this.firebaseAnalytics.trackEvent({
        name: name,
        category: 'button-click',
        label: name,
        value: 1,
        interfaction: true,
      });
    }
  }

  async save(image: UserImageInfo) {
    let url = await this.polar.getUserFileUrl(this.companyId, this.workId, image.rawFilePath!);

    var downLoadLink = document.createElement('a');
    downLoadLink.download = image.name!;
    downLoadLink.href = url;
    downLoadLink.target = '_blank';
    downLoadLink.click();
  }
}
