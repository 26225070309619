import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';

import { PolarFirebridgeSocketService } from './polar-firebridge-socket.service';

import { ENVIRONMENT } from '../../applicationEnvironment';
import { PolarDocumentData } from './PolarDocumentData';
import {
  PolarCollectionPointer,
  PolarCollectionQuery,
  PolarCollectionQueryOptions,
  PolarDocumentPointer,
  PolarQueryConstraint,
  PolarStorageReference,
  SetDocOptions,
} from './interfaces';
import { AutoId } from './utils';

import { Observable, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolarFirebridgeService {
  applicationEnvironment = inject(ENVIRONMENT);
  host = '';

  constructor(
    private auth: AngularFireAuth,
    private socket: PolarFirebridgeSocketService,
  ) {
    this.host = this.applicationEnvironment.environment.fireBridgeApiUrl;
  }

  collection(_: Firestore, collectionName: string): PolarCollectionPointer {
    return {
      collectionName: collectionName,
    };
  }

  doc(col: PolarCollectionPointer, docName: string | undefined = undefined): PolarDocumentPointer {
    return {
      id: docName ?? AutoId.newId(),
      col: col,
    };
  }

  async getToken(): Promise<string> {
    let user = await this.auth.currentUser;
    let token = await user?.getIdToken()!;
    return token;
  }

  async checkIpStatus(): Promise<boolean> {
    const resp = await fetch(this.host + '/checkuser', {
      method: 'POST',
      headers: {
        Authorization: await this.getToken(),
      },
    });

    if (resp.status === 200) {
      try {
        await this.socket.connect();
      } catch {}
      return true;
    } else {
      return false;
    }
  }

  async getDoc(doc: PolarDocumentPointer): Promise<PolarDocumentData> {
    // networking and get Document
    const resp = await fetch(this.host + '/getDoc', {
      method: 'POST',
      body: JSON.stringify({
        collection: doc.col.collectionName,
        docId: doc.id,
      }),
      headers: {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 500) {
      throw new Error('Internal Error');
    }

    const json = await resp.json();
    return new PolarDocumentData(json['raw'], json['id'], json['exists']);
  }

  async setDoc(
    doc: PolarDocumentPointer,
    data: any,
    options: SetDocOptions | undefined = undefined,
  ): Promise<void> {
    // networking and set Document
    const resp = await fetch(this.host + '/setDoc', {
      method: 'POST',
      body: JSON.stringify({
        collection: doc.col.collectionName,
        docId: doc.id,
        data: data,
        options: options,
      }),
      headers: {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 500) {
      throw new Error('Internal Error');
    }
  }

  async deleteDoc(doc: PolarDocumentPointer): Promise<void> {
    // networking and delete Document
    const resp = await fetch(this.host + '/deleteDoc', {
      method: 'POST',
      body: JSON.stringify({
        collection: doc.col.collectionName,
        docId: doc.id,
      }),
      headers: {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 500) {
      throw new Error('Internal Error');
    }
  }

  collectionData<T = any>(
    query: PolarCollectionPointer | PolarCollectionQuery,
    options?: PolarCollectionQueryOptions,
  ): Observable<T[]> {
    // peoredically fetch data with this.getDocs and notify to subscriber
    if (this.socket.authorized) {
      return this.socket.collectionData<T>(query, options);
    } else {
      return timer(0, 10000).pipe(
        switchMap(async (_) => (await this.getDocs(query, options)).map<T>((f) => f.data())),
      );
    }
  }

  async getDocs(
    query: PolarCollectionPointer | PolarCollectionQuery,
    options: PolarCollectionQueryOptions | undefined = undefined,
  ): Promise<PolarDocumentData[]> {
    // networking and get Documents
    const resp = await fetch(this.host + '/getDocs', {
      method: 'POST',
      body: JSON.stringify({
        collection: query.collectionName,
        constraints: query.constraints,
      }),
      headers: {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 500) {
      throw new Error('Internal Error');
    }

    const json: any[] = await resp.json();
    const data: any = json.map<PolarDocumentData>(
      (f) => new PolarDocumentData(f['raw'], f['id'], f['exists'], options?.idField),
    );
    // compatibility
    data.docs = data;
    return data;
  }

  // fieldPath is a key
  // opstr is a operator string like '==', '>', '<', '>=', '<=', 'array-contains', 'array-contains-any', 'in', 'not-in'
  // value is a value
  where(fieldPath: string, opStr: string, value: any): PolarQueryConstraint {
    return { type: 'where', fieldPath, opStr, value };
  }

  orderBy(fieldPath: string): PolarQueryConstraint {
    return { type: 'orderBy', fieldPath };
  }

  documentId = () => {
    return '%%$documentId%%$';
  };

  query(
    collection: PolarCollectionPointer,
    ...queryConstraints: PolarQueryConstraint[]
  ): PolarCollectionQuery {
    return {
      collectionName: collection.collectionName,
      constraints: queryConstraints,
    };
  }

  ref(storage: Storage, path: string): PolarStorageReference {
    return {
      path: path,
    };
  }

  async uploadBytes(ref: PolarStorageReference, file: File) {
    // use fetch api and multipart/form-data
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', ref.path);

    const resp = await fetch(this.host + '/uploadBytes', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: await this.getToken(),
      },
    });

    if (resp.status === 500) {
      throw new Error('Internal Error');
    }
  }

  async getDownloadURL(ref: PolarStorageReference): Promise<string> {
    const resp = await fetch(this.host + '/getDownloadURL', {
      method: 'POST',
      body: JSON.stringify({
        path: ref.path,
      }),
      headers: {
        Authorization: await this.getToken(),
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 500) {
      throw new Error('Internal Error');
    }

    return (await resp.json())['url'];
  }
}
