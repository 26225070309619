<app-console-left-menu
  [current]="current"
  (itemSelected)="leftMenuSelected($event)"
></app-console-left-menu>
<div class="main">
  @if (current == 'template-create') {
    <app-console-create-template></app-console-create-template>
  }
  @if (current == 'template-test') {
    <app-console-try-template></app-console-try-template>
  }
  @if (current == 'config-config') {
    <app-console-settings></app-console-settings>
  }
  @if (current == 'config-prompt') {
    <app-console-prompt-settings></app-console-prompt-settings>
  }
  @if (current == 'config-master') {
    <app-console-master-settings></app-console-master-settings>
  }
  @if (current == 'company-create') {
    <app-console-create-company></app-console-create-company>
  }
  @if (current == 'work-settings') {
    <app-console-work-settings></app-console-work-settings>
  }
  @if (current == 'auth-settings') {
    <app-console-auth-groups></app-console-auth-groups>
  }
</div>
