import { Component, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { PolarFirebaseService } from './polar/polar-firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { PolarFireswitchService } from './polar/bridge/polar-fireswitch.service';
import { CommonUiProviderService } from './common/common-ui-provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'battonai-front';
  signedIn?: boolean = undefined;

  get isBusters(): boolean {
    return !this.router.url.startsWith('/console');
  }

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private polar: PolarFirebaseService,
    private translator: TranslateService,
    private switcher: PolarFireswitchService,
    private commonUi: CommonUiProviderService,
  ) {
    try {
      translator.setDefaultLang('ja');
      if (navigator.language.includes('ja')) {
        translator.use('ja');
      } else {
        translator.use('en');
      }
      if (!isDevMode()) {
        console.log = () => {};
      }
    } catch {}
    // check if signed in with angularFire
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        await polar.initUser();

        // check user protection type
        try {
          const protectionType = await polar.getUserProtectionType();

          if (protectionType == 'ip') {
            const ipStatus = await this.switcher.checkIpStatus();

            if (!ipStatus) {
              // you dont have permission to access this page
              this.signedIn = false;
              await this.commonUi.showConfirm({
                title: 'エラー',
                body: 'アクセス権限がありません',
                buttons: ['OK'],
              });
              await this.auth.signOut();
              location.reload();
              return;
            } else {
              // success. use bridge
              this.switcher.useBridge = true;
            }
          }
        } catch {
          // failed to validate. just ignore
        }

        // console is only accessible for admin
        if (!this.isBusters) {
          // check if user is admin
          if (!(await polar.isAdmin())) {
            // you dont have permission to access this page
            await this.router.navigate(['main']);
          }
        }

        // release
        this.signedIn = true;
      } else {
        // No user is signed in.
        this.signedIn = false;
      }
    });
  }
}
