import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { InnerProgressComponent } from '../inner-progress/inner-progress.component';

import { Loupe, LoupeOptions, enableLoupe } from 'loupe-js';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  standalone: true,
  imports: [InnerProgressComponent],
})
export class ImageViewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('IMG')
  el?: ElementRef;

  loupe?: Loupe;

  rawImgWidth: number = -1;
  rawImgHeight: number = -1;
  lastImgWidth: number = -1;
  lastImgHeight: number = -1;

  overlayWidthPer: number = 0;
  overlayHeightPer: number = 0;

  int: any = null;

  _image: SafeResourceUrl | null = null;
  get image(): SafeResourceUrl | null {
    return this._image;
  }
  @Input() set image(val: SafeResourceUrl | null) {
    this._image = val;

    if (this.el == undefined || this.el.nativeElement == undefined) {
      // it would be initialized in ngAfterViewInit since the element is not ready yet
      return;
    }
    this.createLoupe();
  }

  _disableLoupe: boolean = false;
  @Input() set disableLoupe(val: boolean) {
    if (this._disableLoupe == val) return;
    this._disableLoupe = val;
    if (val) {
      try {
        console.log('UNMOUNT');
        this.loupe?.unmount();
      } catch {}
    } else {
      this.createLoupe();
    }
  }

  get disableLoupe(): boolean {
    return this._disableLoupe;
  }

  @Input() drawRectangle: boolean = false;
  _selectedDrawing: string = '';
  @Input() set selectedDrawing(val: string) {
    if (val == '' && val != this._selectedDrawing) {
      this.clearRectangle();
    }
    this._selectedDrawing = val;
  }

  @Output() imageCropped = new EventEmitter<SafeResourceUrl>();
  @Input() overlayRects: { x: number; y: number; w: number; h: number }[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  updateOverlay(elm: HTMLElement) {
    const elmWidth = elm.clientWidth;
    const elmHeight = elm.clientHeight;

    const elmRatio = elmWidth / elmHeight;
    const imgRatio = this.rawImgWidth / this.rawImgHeight;

    if (elmRatio > imgRatio) {
      this.overlayHeightPer = 100;
      this.overlayWidthPer = (imgRatio / elmRatio) * 100;
    } else {
      this.overlayWidthPer = 100;
      this.overlayHeightPer = (elmRatio / imgRatio) * 100;
    }
  }

  ngAfterViewInit(): void {
    this.int = setInterval(() => {
      if (this.el == undefined) {
        return;
      }

      let elm = this.el?.nativeElement as HTMLElement;

      if (elm == undefined) {
        return;
      }

      if (this.disableLoupe) return;

      if (this.lastImgWidth != elm.clientWidth || this.lastImgHeight != elm.clientHeight) {
        this.lastImgWidth = elm.clientWidth;
        this.lastImgHeight = elm.clientHeight;

        this.createLoupe();
      }

      this.updateOverlay(elm);
    }, 1000);
  }

  ngOnDestroy(): void {
    try {
      clearInterval(this.int);
      this.loupe?.unmount();
    } catch {}
  }

  async createLoupe() {
    try {
      this.loupe?.unmount();
    } catch {}

    if (this.image == undefined) return;

    let url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.image)!;

    const tempImg = new Image();
    tempImg.src = url;
    const tempImageWidth = tempImg.naturalWidth;

    this.rawImgWidth = tempImg.naturalWidth;
    this.rawImgHeight = tempImg.naturalHeight;

    let width = 800;
    let height = 300;
    const options: LoupeOptions = {
      magnification: (0.5 * 1920) / tempImageWidth, //Note: morimoto ( rate * windowSize)/ imageSize
      width: width,
      height: height,
      additionalClassName: 'customLoupeClass',
      style: {
        boxShadow: '4px 5px 5px 4px rgba(0,0,0,0.5)',
        opacity: '0.96',
      },
      shape: 'rectangle',
      container: undefined,
    };

    let currentLoupe = new Loupe(options); // or just `new Loupe()` to use default options

    try {
      this.loupe?.unmount();
    } catch {}

    this.loupe = currentLoupe;

    //enableLoupe(this.el?.nativeElement, this.image.toString(), this.loupe);
    enableLoupe(this.el?.nativeElement, url, this.loupe);

    let int = setInterval(() => {
      if (!currentLoupe.container.contains(currentLoupe.elem)) {
        // already deleted
        clearInterval(int);
      } else if (currentLoupe != this.loupe) {
        // already replaced
        try {
          currentLoupe.unmount();
        } catch {}
        clearInterval(int);
      }
    }, 1000);
  }

  drawing: boolean = false;
  startLocationX: number = -100;
  startLocationY: number = -100;
  endLocationX: number = -100;
  endLocationY: number = -100;

  drawRectangleMoseDown(e: MouseEvent) {
    this.drawing = true;
    this.startLocationX = e.clientX;
    this.startLocationY = e.clientY;
    this.endLocationX = e.clientX;
    this.endLocationY = e.clientY;
  }

  drawRectangleMouseMove(e: MouseEvent) {
    if (!this.drawing) return;
    this.endLocationX = e.clientX;
    this.endLocationY = e.clientY;
  }

  drawRectangleMouseUp(e: MouseEvent) {
    if (!this.drawing) return;
    this.drawing = false;
    this.endLocationX = e.clientX;
    this.endLocationY = e.clientY;

    this.cropImage();
  }

  clearRectangle() {
    this.startLocationX = -100;
    this.startLocationY = -100;
    this.endLocationX = -100;
    this.endLocationY = -100;
  }

  cropImage() {
    // note: image is using object-fit: cointain and object-position: 50% 50%
    // we need to calculate the actual position of the image based on that
    let imgElement = this.el!.nativeElement as HTMLImageElement;

    console.log(imgElement.naturalHeight);

    const bdx = imgElement.getBoundingClientRect();
    const imgX = bdx.x;
    const imgY = bdx.y;
    const imgWidth = imgElement.naturalWidth;
    const imgHeight = imgElement.naturalHeight;
    const imgContainerWidth = imgElement.clientWidth;
    const imgContainerHeight = imgElement.clientHeight;

    let scale = 0;
    let offsetX = 0;
    let offsetY = 0;

    if (imgWidth / imgHeight > imgContainerWidth / imgContainerHeight) {
      // image is wider
      scale = imgContainerWidth / imgWidth;
      offsetY = (imgContainerHeight - imgHeight * scale) / 2;
    } else {
      // image is taller
      scale = imgContainerHeight / imgHeight;
      offsetX = (imgContainerWidth - imgWidth * scale) / 2;
    }

    const relativeStartLocationX = this.startLocationX - imgX;
    const relativeStartLocationY = this.startLocationY - imgY;
    const relativeEndLocationX = this.endLocationX - imgX;
    const relativeEndLocationY = this.endLocationY - imgY;

    const actualStartLocationX = (relativeStartLocationX - offsetX) / scale;
    const actualStartLocationY = (relativeStartLocationY - offsetY) / scale;
    const actualEndLocationX = (relativeEndLocationX - offsetX) / scale;
    const actualEndLocationY = (relativeEndLocationY - offsetY) / scale;

    console.log('Cropped Image');
    console.log('Start X:', actualStartLocationX);
    console.log('Start Y:', actualStartLocationY);
    console.log('End X:', actualEndLocationX);
    console.log('End Y:', actualEndLocationY);

    console.log('Image size');
    console.log('Image Width:', imgWidth);
    console.log('Image Height:', imgHeight);

    // create a canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    canvas.width = actualEndLocationX - actualStartLocationX;
    canvas.height = actualEndLocationY - actualStartLocationY;

    ctx.drawImage(
      imgElement,
      actualStartLocationX,
      actualStartLocationY,
      actualEndLocationX - actualStartLocationX,
      actualEndLocationY - actualStartLocationY,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    // convert to blob
    canvas.toBlob((blob) => {
      if (blob == null) {
        console.error('Failed to crop image');
        return;
      }

      const url = URL.createObjectURL(blob);
      this.imageCropped.emit(this.sanitizer.bypassSecurityTrustResourceUrl(url));
    });
  }
}
