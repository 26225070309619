import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { FirebaseAnalyticsService } from 'src/app/firebase-analytics/firebase-analytics.service';
import { AuthorizedTeam } from 'src/app/polar/entity/AuthGroupInfo';
import { PolarFirebaseAuthService } from 'src/app/polar/polar-firebase-auth.service';
import { WorkInfo } from 'src/app/polar/entity/CompanyInfo';
import { MasterGroup, MasterGroupDocumentData } from 'src/app/polar/entity/master';
import {
  AuthGroupTeamAuthority,
  AuthGroupTeamAuthorityAccessibleMasterGroup,
  PolarFirebaseTeamAuthService,
} from 'src/app/polar/polar-firebase-team-auth.service';

@Component({
  selector: 'app-auth-group-settings-authority-modal',
  templateUrl: './auth-group-settings-authority-modal.component.html',
  styleUrls: ['./auth-group-settings-authority-modal.component.scss'],
})
export class AuthGroupSettingsAuthorityModalComponent {
  hiding: boolean = false;
  uploadStatus: 'loading' | 'failed' | 'completed' = 'loading';
  loadingFull: boolean = false;

  teamTypes = [
    { id: 'limited', name: 'ユーザー権限' },
    { id: 'unlimited', name: '管理者権限' },
  ];

  @Input() targetTeam?: AuthorizedTeam = undefined;
  @Input() authGroupId: string = '';
  isNew: boolean = false;

  @Output() willHide: EventEmitter<void> = new EventEmitter();
  @Output() hide: EventEmitter<void> = new EventEmitter();

  // editing
  team: AuthGroupTeamAuthority = {
    id: '',
    name: '',
    authority: 'limited',
    accessible_companies: {},
  };

  companies: { id: string; name: string }[] = [];

  _currentCompanyId: string = '';
  get currentCompanyId(): string {
    return this._currentCompanyId;
  }
  set currentCompanyId(value: string) {
    if (value == '') return;
    let changed = value != this._currentCompanyId;
    if (changed) {
      this.updateFromFlags();
    }
    this._currentCompanyId = value;
    if (changed) {
      this.updateToFlags();
    }
  }

  // flags that will be collected before saving
  allowAllWorks: boolean = false;
  allowAllMasterGroups: boolean = false;

  constructor(
    private commonUi: CommonUiProviderService,
    private polar: PolarFirebaseService,
    private polarAuthGroup: PolarFirebaseAuthService,
    private polarAuthTeam: PolarFirebaseTeamAuthService,
    private auth: AngularFireAuth,
    private firebaseAnalytics: FirebaseAnalyticsService,
  ) {}

  async ngOnInit() {
    this.startLoading();

    this.isNew = this.targetTeam === undefined;
    console.log(this.targetTeam);
    if (this.targetTeam) {
      this.team = await this.polarAuthTeam.getTeamAuthority(this.authGroupId, this.targetTeam.id!);

      if (this.targetTeam!.id == 'all') {
        this.targetTeam!.name = '全員';
        this.team.name = '全員';
      } else if (this.targetTeam!.id == 'admin') {
        this.targetTeam!.name = '管理者';
        this.team.name = '管理者';
      }
    } else {
      this.team = {
        id: '',
        name: '',
        authority: 'limited',
        accessible_companies: {},
      };
    }

    if (this.team.id == 'all') {
      this.teamTypes = [
        {
          id: 'limited',
          name: 'ユーザー権限',
        },
      ];
    }

    const companyIds = await this.polarAuthTeam.getPreDefinedCompanies(this.authGroupId);
    const companies: { id: string; name: string }[] = [];
    for (const companyId of companyIds) {
      const company = await this.polar.getCompany(companyId);
      companies.push({ id: companyId, name: company.name! });
    }
    this.companies = companies;
    this.currentCompanyId = companyIds[0];

    // add predefined companies if not exists
    for (const companyId of companyIds) {
      if (this.team.accessible_companies[companyId] == undefined) {
        this.team.accessible_companies[companyId] = {
          accessible_master_groups: {},
          accessible_works: {},
        };
      }
    }

    await this.updateToFlags();
    await this.addPredefinedVariables();
    await this.updateCompanyRelatedData();

    this.endLoading();
  }

  async addPredefinedVariables() {
    // add predefined works if not exists
    for (const companyId in this.team.accessible_companies) {
      const data = this.team.accessible_companies[companyId];
      const works = await this.polar.getWorksInCompanyArray(companyId);
      for (const work of works) {
        if (data.accessible_works[work.id!] == undefined) {
          data.accessible_works[work.id!] = { interactable: false };
        }
      }
    }

    // add predefined master groups if not exists
    for (const companyId in this.team.accessible_companies) {
      const data = this.team.accessible_companies[companyId];
      const masterGroups = await this.polar.getMasterGroups(companyId);
      for (const masterGroup of masterGroups) {
        if (data.accessible_master_groups[masterGroup.id!] == undefined) {
          data.accessible_master_groups[masterGroup.id!] = { writable: false, readable: false };
        }
      }
    }
  }

  productionWorksLoading: boolean = true;
  productionWorksDict: { [index: string]: WorkInfo[] } = {};
  productionMasterGroupsLoading: boolean = true;
  productionMasterGroupsDict: { [index: string]: MasterGroupDocumentData[] } = {};

  async updateCompanyRelatedData() {
    for (const companyId of this.companies.map((f) => f.id)) {
      const currentProductionWorks = (await this.polar.getWorksInCompanyArray(companyId)).filter(
        (f) => f.isProduction,
      );
      const currentProductionMasterGroups = await this.polar.getMasterGroups(companyId);

      this.productionWorksDict[companyId] = currentProductionWorks;
      this.productionMasterGroupsDict[companyId] = currentProductionMasterGroups;
    }
  }

  async startLoading() {
    this.productionWorksLoading = true;
    this.productionMasterGroupsLoading = true;
  }

  async endLoading() {
    this.productionWorksLoading = false;
    this.productionMasterGroupsLoading = false;
  }

  updateToFlags() {
    const team = this.team;
    this.allowAllWorks = false;
    this.allowAllMasterGroups = false;

    const data = team.accessible_companies[this.currentCompanyId];
    if (data == undefined) {
      return;
    }

    if (data.accessible_works['*'] != undefined && data.accessible_works['*'].interactable) {
      this.allowAllWorks = true;
    }

    if (
      data.accessible_master_groups['*'] != undefined &&
      data.accessible_master_groups['*'].writable
    ) {
      this.allowAllMasterGroups = true;
    }
  }

  updateFromFlags() {
    if (this.currentCompanyId == '') return;
    const team = this.team;
    let data = team.accessible_companies[this.currentCompanyId];
    if (data == undefined) {
      team.accessible_companies[this.currentCompanyId] = {
        accessible_master_groups: {},
        accessible_works: {},
      };
      data = team.accessible_companies[this.currentCompanyId];
    }

    if (this.allowAllWorks) {
      data.accessible_works['*'] = { interactable: true };
    } else {
      delete data.accessible_works['*'];
    }

    if (this.allowAllMasterGroups) {
      data.accessible_master_groups['*'] = { writable: true, readable: true };
    } else {
      delete data.accessible_master_groups['*'];
    }

    team.accessible_companies[this.currentCompanyId] = data;
  }

  async closeModal() {
    this.willHide.emit();
    this.hiding = true;
    setTimeout(() => {
      this.hide.emit();
    }, 400);
  }

  async save() {
    if (this.targetTeam?.authority == 'unlimited' && this.team.authority == 'limited') {
      // check self is joined
      const teamUsers = await this.polarAuthGroup.getTeamUsers(
        this.authGroupId,
        this.targetTeam!.id!,
      );
      const user = await this.auth.currentUser;
      const userId = user!.uid;
      if (teamUsers.findIndex((f) => f.id == userId) >= 0) {
        this.commonUi.showConfirm({
          title: 'エラー',
          body: '自分自身が所属しているチームの権限を、\n管理者からユーザーに下げることはできません\n\n先に他のチームに移動してから実行してください',
          buttons: ['OK'],
        });
        return;
      }
    }
    this.updateFromFlags();

    if (this.team!.name == '' || this.team!.name == undefined) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'チーム名を入力してください',
        buttons: ['OK'],
      });
      return;
    }

    const authorityUpdater = this.polarAuthTeam.removeUnnecessaryAuthorities(this.team);

    this.loadingFull = true;
    try {
      if (this.isNew) {
        const team: AuthorizedTeam = {
          id: '',
          name: this.team.name,
          authority: this.team.authority,
        };
        const teamId = await this.polarAuthGroup.addTeam(this.authGroupId, team);
        console.log('teamId: ' + teamId);
        this.team.id = teamId;
        this.isNew = false;
      }
      if (this.team.id == undefined || this.team.id == '') {
        alert('Unexpected error');
        this.uploadStatus = 'failed';
        return;
      }
      // now update authority
      authorityUpdater.id = this.team.id!;
      await this.polarAuthTeam.updateTeamAuthority(
        this.authGroupId,
        this.team.id!,
        authorityUpdater,
      );

      // completed
      this.loadingFull = false;
      this.closeModal();
    } catch (e) {
      console.error(e);
      this.commonUi.showConfirm({
        title: 'エラー',
        body: '保存に失敗しました',
        buttons: ['OK'],
      });
      this.loadingFull = false;
      return;
    }
  }

  async deleteTeam() {
    if (this.isNew) return;
    if (this.targetTeam == undefined) return;
    if (this.targetTeam!.id == undefined || this.targetTeam!.id == '') return;

    const modalResult = await this.commonUi.showConfirm({
      title: '確認',
      body: 'チームを削除しますか？',
      buttons: ['キャンセル', '削除'],
    });

    if (modalResult != '削除') return;

    const teamUsers = await this.polarAuthGroup.getTeamUsers(
      this.authGroupId,
      this.targetTeam!.id!,
    );

    if (teamUsers.length > 0) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'ユーザーが所属しているチームは削除できません。\n先にユーザーを別のチームに移してから実行してください',
        buttons: ['OK'],
      });
      return;
    }

    this.loadingFull = true;

    try {
      await this.polarAuthTeam.deleteTeam(this.authGroupId, this.targetTeam!.id!);
    } catch {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: '削除に失敗しました',
        buttons: ['OK'],
      });
      this.loadingFull = false;
      return;
    }

    this.loadingFull = false;

    this.willHide.emit();
    this.hide.emit();
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    if (timestamp == undefined) {
      return '-';
    } else {
      return (timestamp as Timestamp).toDate().toLocaleString('ja-JP');
    }
  }

  updateMasterWritableFlag(companyId: string, masterGroupId: string, value: boolean) {
    this.team.accessible_companies[companyId].accessible_master_groups[masterGroupId].writable =
      value;

    if (value) {
      // if writable is true, readable is also true
      this.team.accessible_companies[companyId].accessible_master_groups[masterGroupId].readable =
        true;
    }
  }
}
