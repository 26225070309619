import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ConsoleAuthGroupsComponent } from './console-auth-groups/console-auth-groups.component';
import { ConsoleCreateCompanyComponent } from './console-create-company/console-create-company.component';
import { ConsoleCreateTemplateComponent } from './console-create-template/console-create-template.component';
import { ConsoleLeftMenuComponent } from './console-left-menu/console-left-menu.component';
import { ConsoleMasterSettingsComponent } from './console-master-settings/console-master-settings.component';
import { ConsolePromptSettingsComponent } from './console-prompt-settings/console-prompt-settings.component';
import { ConsoleSettingsComponent } from './console-settings/console-settings.component';
import { ConsoleTryTemplateComponent } from './console-try-template/console-try-template.component';
import { ConsoleWorkSettingsComponent } from './console-work-settings/console-work-settings.component';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss'],
  standalone: true,
  imports: [
    ConsoleLeftMenuComponent,
    ConsoleCreateTemplateComponent,
    ConsoleTryTemplateComponent,
    ConsoleSettingsComponent,
    ConsolePromptSettingsComponent,
    ConsoleMasterSettingsComponent,
    ConsoleCreateCompanyComponent,
    ConsoleWorkSettingsComponent,
    ConsoleAuthGroupsComponent,
  ],
})
export class ConsoleComponent implements OnInit {
  current: string = 'template-create';

  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle('受発注バスターズ - Console');
  }

  leftMenuSelected(val: string) {
    this.current = val;
  }
}
