import { Injectable } from '@angular/core';

export interface CallShowConfirm {
  title?: string;
  body?: string;
  buttons: string[];
  additionalLinkTitle?: string;
  additionalLinkAction?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class CommonUiProviderService {
  _showConfirm?: (val: CallShowConfirm) => Promise<string | undefined>;

  constructor() {}

  async showConfirm(val: CallShowConfirm): Promise<string | undefined> {
    return await this._showConfirm!(val);
  }
}
