import { NgClass } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InnerProgressComponent } from '../common/inner-progress/inner-progress.component';
import { AuthGroupSettingsAuthorityModalComponent } from './auth-group-settings-authority-modal/auth-group-settings-authority-modal.component';
import { AuthGroupSettingsUsersListComponent } from './auth-group-settings-users-list/auth-group-settings-users-list.component';

import { TooltipDirective } from '../tooltip.directive';

import { CommonUiProviderService } from '../common/common-ui-provider.service';
import { NavigationService } from '../navigation.service';
import { PolarFirebaseAuthService } from '../polar/polar-firebase-auth.service';
import { PolarFirebaseTeamAuthService } from '../polar/polar-firebase-team-auth.service';
import { PolarFirebaseService } from '../polar/polar-firebase.service';

import { AuthGroupInfo, AuthorizedTeam, AuthorizedUser } from '../polar/entity/AuthGroupInfo';

@Component({
  selector: 'app-auth-group-settings',
  templateUrl: './auth-group-settings.component.html',
  styleUrls: ['./auth-group-settings.component.scss'],
  standalone: true,
  imports: [
    InnerProgressComponent,
    NgClass,
    TooltipDirective,
    AuthGroupSettingsUsersListComponent,
    AuthGroupSettingsAuthorityModalComponent,
  ],
})
export class AuthGroupSettingsComponent {
  loading: boolean = true;
  authGroupId: string;
  authGroupInfo?: AuthGroupInfo;
  teams: AuthorizedTeam[] = [];

  selectedTeamId: string = 'all';
  selectedTeamName: string = '全員';

  authorityModalTargetAuthorizedTeam?: AuthorizedTeam = undefined;
  isAuthorityModalShown: boolean = false;

  @ViewChild('USERSLIST')
  usersListComponent?: AuthGroupSettingsUsersListComponent;
  companyNames: string[] = [];

  constructor(
    private router: Router,
    private commonUi: CommonUiProviderService,
    private route: ActivatedRoute,
    private polar: PolarFirebaseService,
    private polarAuthGroup: PolarFirebaseAuthService,
    private nav: NavigationService,
    private polarAuthTeam: PolarFirebaseTeamAuthService,
  ) {
    this.authGroupId = this.route.snapshot.paramMap.get('auth_group_id')!;
  }

  getTeamName(team: AuthorizedTeam): string {
    if (team.id == 'all') {
      return '全員';
    } else if (team.id == 'admin') {
      return '管理者';
    }
    return team.name!;
  }

  selectTeam(team: AuthorizedTeam) {
    this.selectedTeamId = team.id!;
    this.selectedTeamName = this.getTeamName(team);
  }

  addTeam() {
    if (this.companyNames.length == 0) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'チームを作成するには、会社を登録してください',
        buttons: ['OK'],
      });
      return;
    }

    this.authorityModalTargetAuthorizedTeam = undefined;
    this.showAuthorityModal();
  }

  editTeam(team: AuthorizedTeam) {
    if (this.companyNames.length == 0) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: 'チームを編集するには、会社を登録してください',
        buttons: ['OK'],
      });
      return;
    }
    this.authorityModalTargetAuthorizedTeam = team;
    this.showAuthorityModal();
  }

  async ngOnInit() {
    this.authGroupInfo = await this.polarAuthGroup.get(this.authGroupId);

    const predefinedCompanies = await this.polarAuthTeam.getPreDefinedCompanies(this.authGroupId);
    const companyNames = [];
    for (const companyId of predefinedCompanies) {
      const company = await this.polar.getCompany(companyId);
      companyNames.push(company.name!);
    }
    this.companyNames = companyNames;

    await this.update();
  }

  async update() {
    this.loading = true;
    const teams = await this.polarAuthGroup.getTeams(this.authGroupId);
    // sort by created_at
    this.teams = teams.sort((a, b) => {
      if (a.id == 'all') {
        return -1;
      } else if (b.id == 'all') {
        return 1;
      } else if (a.id == 'admin') {
        return -1;
      } else if (b.id == 'admin') {
        return 1;
      }
      let aValue = 0;
      let bValue = 0;

      if (a.createdAt && (a.createdAt as any).seconds) {
        aValue = (a.createdAt as any).seconds;
      }
      if (b.createdAt && (b.createdAt as any).seconds) {
        bValue = (b.createdAt as any).seconds;
      }
      return aValue - bValue;
    });
    this.loading = false;

    // check if selected team is still available
    const selectedTeam = this.teams.find((team) => team.id == this.selectedTeamId);
    if (selectedTeam == undefined) {
      this.selectTeam(this.teams[0]);
    }
  }

  back() {
    this.nav.back('main');
  }

  showAuthorityModal() {
    this.isAuthorityModalShown = true;
  }

  hideAuthorityModal() {
    this.isAuthorityModalShown = false;
  }

  async willHideAuthorityModal() {
    await this.update();
    await this.usersListComponent?.update();
  }
}
