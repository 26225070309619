<div class="base">
  <div class="background" (click)="close.emit()"></div>
  <div class="container">
    <div class="header">
      <div class="close" (click)="close.emit()">
        <i class="fa-solid fa-times"></i>
      </div>
      <div class="title">マスタ設定</div>
      @if (companyName != undefined && companyName != '') {
        <p>{{ companyName }}</p>
      }
    </div>
    <div class="body">
      <app-master-settings-main [useAsAdmin]="false" [companyId]="companyId">
      </app-master-settings-main>
    </div>
  </div>
</div>
