import { constructorParametersDownlevelTransform } from '@angular/compiler-cli';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { PolarFirebaseService } from './polar-firebase.service';

import { ENVIRONMENT } from '../applicationEnvironment';

@Injectable({
  providedIn: 'root',
})
export class PolarApiService {
  pathPerformGeneralOcr: string = '';
  pathAutoRotateImage: string = '';
  pathPdfToImage: string = '';
  pathRunRead: string = '';
  pathRunReadOne: string = '';
  pathRunWrite: string = '';
  pathRunGetMatchedTemplateId: string = '';
  pathRunGetMatchedV2TemplateId: string = '';
  pathRunCreateTemplateV2: string = '';
  pathRunDeleteTemplateV2FromId: string = '';
  pathCreateVectorsForCsvMapping: string = '';
  pathCreateScriptFromPrompt: string = '';
  pathAddMasterTrainingRequirement: string = '';
  pathCreateVectorsForCsvMappingToSatisfyRequirement: string = '';
  pathCheckIfVectorizingRequiredForTheMaster: string = '';
  pathCreateSupportTicket: string = '';
  pathBatchRegisterTemplateToPineCone: string = '';
  pathReleasePublicTemplateMatchV2: string = '';

  applicationEnvironment = inject(ENVIRONMENT);

  constructor(
    private polar: PolarFirebaseService,
    private auth: AngularFireAuth,
  ) {
    let host = this.applicationEnvironment.environment.polarApiUrl;

    this.pathPerformGeneralOcr = host + '/perform_general_ocr';
    this.pathAutoRotateImage = host + '/auto_rotate_image';
    this.pathPdfToImage = host + '/pdf_to_image';
    this.pathRunRead = host + '/read';
    this.pathRunReadOne = host + '/read_one';
    this.pathRunWrite = host + '/write';
    this.pathRunGetMatchedTemplateId = host + '/match';
    this.pathRunGetMatchedV2TemplateId = host + '/match-v2';
    this.pathRunDeleteTemplateV2FromId = host + '/match-v2-delete';
    this.pathRunCreateTemplateV2 = host + '/match-v2-create';
    this.pathCreateVectorsForCsvMapping = host + '/create_vectors_for_csv_mapping';
    this.pathCreateScriptFromPrompt = host + '/create_script_from_prompt';
    this.pathAddMasterTrainingRequirement = host + '/add_master_training_requirement';
    this.pathCreateVectorsForCsvMappingToSatisfyRequirement =
      host + '/create_vectors_for_csv_mapping_to_satisfy_requirement';
    this.pathCheckIfVectorizingRequiredForTheMaster =
      host + '/check_if_vectorizing_required_for_the_master';
    this.pathCreateSupportTicket = host + '/support/create';
    this.pathBatchRegisterTemplateToPineCone = host + '/batch_register_template_to_pinecone';
    this.pathReleasePublicTemplateMatchV2 = host + '/release_template_match';
  }

  async createSupportTicket(
    subject: string,
    tableData: string,
    description: string,
    image?: File,
    image2?: File,
    rawFile?: File,
    rawFileExtension?: string,
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const user = await this.auth.currentUser;
      const token = await user?.getIdToken()!;

      const formData = new FormData();
      formData.append('subject', subject);
      formData.append('table_data', tableData);
      formData.append('description', description);
      if (image != null) formData.append('file', image);
      if (image2 != null) formData.append('file2', image2);
      if (rawFile != null) formData.append('file3', rawFile);
      if (rawFileExtension != null) formData.append('file3_extension', rawFileExtension);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathCreateSupportTicket, param)
        .then(async (res) => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  // check_if_vectorizing_required_for_the_master
  // same parameters as create_vectors_for_csv_mapping_to_satisfy_requirement
  checkIfVectorizingRequiredForTheMaster(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    masterHistoricalId: string,
    engine: string = 'text-embedding-ada-002',
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;

      const formData = new FormData();
      formData.append('engine', engine);
      formData.append('company_id', companyId);
      formData.append('master_group_id', masterGroupId);
      formData.append('master_id', masterId);
      formData.append('master_historical_id', masterHistoricalId);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathCheckIfVectorizingRequiredForTheMaster, param)
        .then(async (res) => {
          // return if body is "1" or "0" when 200
          if (res.status === 200) {
            resolve((await res.text()) === '1');
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  }

  createVectorsForCsvMappingToSatisfyRequirement(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    masterHistoricalId: string,
    engine: string = 'text-embedding-ada-002',
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const user = await this.auth.currentUser;
      const token = await user?.getIdToken()!;

      const formData = new FormData();
      formData.append('engine', engine);
      formData.append('company_id', companyId);
      formData.append('master_group_id', masterGroupId);
      formData.append('master_id', masterId);
      formData.append('master_historical_id', masterHistoricalId);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathCreateVectorsForCsvMappingToSatisfyRequirement, param)
        .then(async (res) => {
          if (res.status === 500) {
            try {
              reject(await res.json());
            } catch {
              reject();
            }
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  }

  addMasterTrainingRequirement(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    targetColumnName: string,
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const user = await this.auth.currentUser;
      const token = await user?.getIdToken()!;

      const formData = new FormData();
      formData.append('company_id', companyId);
      formData.append('master_group_id', masterGroupId);
      formData.append('master_id', masterId);
      formData.append('target_column_name', targetColumnName);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathAddMasterTrainingRequirement, param)
        .then(async (res) => {
          if (res.status === 500) {
            try {
              reject(await res.json());
            } catch {
              reject();
            }
          } else {
            resolve(await res.text());
          }
        })
        .catch((error) => {
          reject();
        });
    });
  }

  getMatchedTemplate(
    company_id: string,
    work_id: string,
    image: File,
    rotate: boolean,
  ): Promise<{ id: string; similarity: number; combination: any }> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;

      // 送信データの準備
      const formData = new FormData();
      formData.append('file', image); // ファイル内容を詰める
      formData.append('auto_rotate', rotate ? '1' : '0');
      formData.append('company_id', company_id);
      formData.append('work_id', work_id);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      // アップロードする
      fetch(this.pathRunGetMatchedTemplateId, param)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          // 通信が成功した際の処理
          resolve(json);
        })
        .catch((error) => {
          reject();
        });
    });
  }

  async getMatchedV2Template(
    company_id: string,
    work_id: string,
    image: File,
  ): Promise<{
    id: string;
    template_id: string;
    preview: string;
    score: number;
    image_ref: string;
  }> {
    try {
      const user = await this.auth.currentUser;
      const token = (await user?.getIdToken()) as string;
      const formData = new FormData();

      formData.append('company_id', company_id);
      formData.append('work_id', work_id);
      formData.append('file', image);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      const response = await fetch(this.pathRunGetMatchedV2TemplateId, param);

      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      console.error('Error get matched v2 template', error);
      throw new Error('Error getting matched v2 template');
    }
    throw new Error('Error getting matched v2 template');
  }

  async registerTemplateV2(company_id: string, work_id: string, template_id: string, image: File) {
    try {
      const user = await this.auth.currentUser;
      const token = (await user?.getIdToken()) as string;
      const formData = new FormData();

      formData.append('company_id', company_id);
      formData.append('work_id', work_id);
      formData.append('template_id', template_id);
      formData.append('file', image);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      const response = await fetch(this.pathRunCreateTemplateV2, param);
      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      console.error('Error create matched v2 template', error);
      throw new Error('Error create matched v2 template');
    }
    throw new Error('Error create matched v2 template');
  }

  async deleteTemplateV2(company_id: string, work_id: string, template_id: string) {
    try {
      const user = await this.auth.currentUser;
      const token = (await user?.getIdToken()) as string;
      const formData = new FormData();

      formData.append('company_id', company_id);
      formData.append('work_id', work_id);
      formData.append('template_id', template_id);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      const response = await fetch(this.pathRunDeleteTemplateV2FromId, param);
      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      console.error('Error delete matched v2 template', error);
      throw new Error('Error delete matched v2 template');
    }
    throw new Error('Error delete matched v2 template');
  }

  async createVectorsForCsvMapping(
    csvFileName: string,
    targetColumn: string,
    engine: string,
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('csv_file_name', csvFileName);
      formData.append('target_column', targetColumn);
      formData.append('engine', engine);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathCreateVectorsForCsvMapping, param)
        .then(async (res) => {
          // return result as Image
          if (res.status === 500) {
            try {
              reject(await res.json());
            } catch {
              reject();
            }
          } else {
            resolve(await res.text());
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  async createScriptFromPrompt(
    prompt: string,
    unifyNumbers: boolean,
    unifySymbols: boolean,
    unifyAlphas: boolean,
    engine: string,
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('unify_numbers', unifyNumbers ? '1' : '0');
      formData.append('unify_symbols', unifySymbols ? '1' : '0');
      formData.append('unify_alphas', unifyAlphas ? '1' : '0');
      if (engine == undefined || engine == '' || engine == null) {
        formData.append('engine', '');
      } else {
        formData.append('engine', engine);
      }

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathCreateScriptFromPrompt, param)
        .then(async (res) => {
          if (res.status === 500) {
            try {
              reject();
            } catch {
              reject();
            }
          } else {
            resolve(await res.text());
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  async executeReadWrite(
    companyId: string,
    workId: string,
    templateId: string,
    rotate: boolean,
    image?: File,
  ): Promise<any> {
    let data = await this.executeRead(companyId, workId, templateId, rotate, image);
    let frozenJson = JSON.stringify(data['export_dict']);
    let rectsJson = JSON.stringify(data['export_rects_dict']);
    let writeResult = await this.executeWrite(
      companyId,
      workId,
      templateId,
      frozenJson,
      undefined,
      rectsJson,
    );
    return writeResult;
  }

  async executeRead(
    companyId: string,
    workId: string,
    templateId: string,
    rotate: boolean,
    image?: File,
    image_path?: string,
    staged?: boolean,
  ): Promise<{
    export_dict: any;
    actual_costs: any;
    export_confidence_dict: any;
    export_rects_dict: any;
  }> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      if (image != null) formData.append('file', image);
      if (image_path != null) formData.append('file_path', image_path);
      if (staged != null) formData.append('staged', staged ? '1' : '0');
      formData.append('company_id', companyId);
      formData.append('work_id', workId);
      formData.append('template_id', templateId);
      formData.append('auto_rotate', rotate ? '1' : '0');

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathRunRead, param)
        .then(async (res) => {
          // return result as Image
          if (res.status === 500) {
            try {
              reject(await res.json());
            } catch {
              reject();
            }
          } else {
            resolve(await res.json());
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  async executeWrite(
    companyId: string,
    workId: string,
    templateId: string,
    input_json: string,
    writeCsv?: string,
    required_column?: string,
    input_data_confidence?: string,
    input_data_rects?: string,
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('company_id', companyId);
      formData.append('work_id', workId);
      formData.append('template_id', templateId);
      formData.append('input_data', input_json);
      if (writeCsv != null && writeCsv != undefined) formData.append('write_csv', writeCsv);
      if (required_column != null && required_column != undefined)
        formData.append('required_column', required_column);
      if (input_data_confidence != null && input_data_confidence != undefined)
        formData.append('input_data_confidence', input_data_confidence);
      if (input_data_rects != null && input_data_rects != undefined)
        formData.append('input_data_rects', input_data_rects);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathRunWrite, param)
        .then(async (res) => {
          // return result as Image
          resolve(await res.json());
        })
        .catch(() => {
          reject();
        });
    });
  }

  // call api with File and return Image
  async rotateImageAuto(image: File): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('file', image);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathAutoRotateImage, param)
        .then(async (res) => {
          // return result as Image
          resolve(await res.blob());
        })
        .catch(() => {
          reject();
        });
    });
  }

  async pdfToImage(pdf: File): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('file', pdf);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathPdfToImage, param)
        .then(async (res) => {
          // return result as Image
          resolve(await res.blob());
        })
        .catch(() => {
          reject();
        });
    });
  }

  async executeReadOne(
    companyId: string,
    workId: string,
    templateId: string,
    documentId: string,
    rowColMap: { [key: string]: string },
  ): Promise<{ export_table: any }> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('company_id', companyId);
      formData.append('work_id', workId);
      formData.append('template_id', templateId);
      formData.append('document_id', documentId);
      formData.append('row_col_map', JSON.stringify(rowColMap));

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathRunReadOne, param)
        .then(async (res) => {
          // return result as Image
          if (res.status === 500) {
            try {
              reject(await res.json());
            } catch {
              reject();
            }
          } else {
            resolve(await res.json());
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  callGeneral(image: File, rotate: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;

      // 送信データの準備
      const formData = new FormData();
      formData.append('file', image); // ファイル内容を詰める

      formData.append('auto_rotate', rotate ? '1' : '0');

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      // アップロードする
      fetch(this.pathPerformGeneralOcr, param)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          // 通信が成功した際の処理
          resolve(json);
        })
        .catch((error) => {
          reject();
        });
    });
  }

  async batchRegisterTemplateToPineCone(companyId: string, workId: string) {
    try {
      const user = await this.auth.currentUser;
      const token = (await user?.getIdToken()) as string;
      const formData = new FormData();

      formData.append('company_id', companyId);
      formData.append('work_id', workId);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      const response = await fetch(this.pathBatchRegisterTemplateToPineCone, param);
      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      console.error('Fetch Error', error);
      throw new Error('Cloud register template to PineCone');
    }
  }

  async release_public_template_match_v2(companyId: string, workId: string, templateIds: string[]) {
    try {
      const user = await this.auth.currentUser;
      const token = (await user?.getIdToken()) as string;
      const formData = new FormData();

      formData.append('company_id', companyId);
      formData.append('work_id', workId);
      formData.append('template_ids', JSON.stringify(templateIds));

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };
      const response = await fetch(this.pathReleasePublicTemplateMatchV2, param);
      if (response.ok) {
        return response.json();
      }
    } catch (error) {
      console.error('Error Release public template match v2', error);
      throw new Error('Error Release public template match v2');
    }
  }
}
