import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { AuthGroupSettingsComponent } from './auth-group-settings/auth-group-settings.component';
import { ConsoleComponent } from './console/console.component';
import { MainComponent } from './main/main.component';
import { WorkCheckComponent } from './work/work-check/work-check.component';
import { WorkDashboardComponent } from './work/work-dashboard/work-dashboard.component';

const routes: Routes = [
  // path: main, component: MainComponent
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', component: MainComponent },
  { path: 'work/:company_id/:work_id', component: WorkDashboardComponent },
  {
    path: 'work/:company_id/:work_id/check/:status',
    component: WorkCheckComponent,
  },
  { path: 'console', component: ConsoleComponent },
  { path: 'auth-group/:auth_group_id', component: AuthGroupSettingsComponent },
  { path: '**', redirectTo: '/main' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserAnimationsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
