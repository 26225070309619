<script src="../console-create-company.component.spec.ts" xmlns="http://www.w3.org/1999/html"></script>
<script src="../console-create-company.component.ts"></script>
<div class="base" (click)="close($event)">
  <div class="container">
    <article class="message is-link">
      <div class="message-header">
        <p>会社名：「{{editCompanyForm.get('companyName')?.value}}」を編集する</p>
      </div>
      <div class="message-body">
        <form name="editCompany" [formGroup]="editCompanyForm" (submit)="updateCompanyInfo()">
          <div class="field">
            <label class="label">会社名</label>
            <div class="control">
              <input
                name="companyName"
                class="input"
                type="text"
                placeholder="会社名を入力してください"
                formControlName="companyName"
              />
              <p
                class="help formError"
                *ngIf="editCompanyForm.get('companyName')?.touched && editCompanyForm.get('companyName')?.hasError('required')">
                会社名は必須です。
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">メモ</label>
            <div class="control">
              <textarea
                class="textarea"
                type="text"
                placeholder="メモを入力してください"
                formControlName="memo"></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">認証グループID</label>
            <div class="control">
              <input
                name="authGroupId"
                class="input"
                type="text"
                placeholder="認証グループID設定"
                formControlName="authGroupId"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">
              マスタ編集可能
              <input type="checkbox"
                     formControlName="masterEditable"/>
            </label>
          </div>

          <div class="field">
            <label class="label">
              データ保持ポリシーの有効
              <input type="checkbox"
                     formControlName="enableDataRetentionPolicy"/>
            </label>
          </div>

          <div class="field" *ngIf="isDataRetentionPolicyEnabled()">
            <label class="label">データ保持期間 (日数)</label>
            <div class="control">
              <input
                name="dataRetentionPeriodInDays"
                class="input"
                type="text"
                placeholder="日数"
                formControlName="dataRetentionPeriodInDays"
              />
              <p
                class="help formError"
                *ngIf="editCompanyForm.get('dataRetentionPeriodInDays')?.hasError('invalidDataRetentionPeriodInDays')">
                データ保持期間が無効です
              </p>
            </div>
          </div>

          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="editCompanyForm.invalid">保存</button>
            </div>
            <app-inner-progress
              *ngIf="savingCompany"
              [status]="savingStatCompany"
            ></app-inner-progress>
          </div>
        </form>
      </div>
    </article>

    <article class="pt-8 message is-link">
      <div class="message-header">
        <p>ユーザーを追加する</p>
      </div>
      <div class="message-body">
        <form name="addUser" [formGroup]="addUserForm" (submit)="addUser()">
          <div class="field">
            <label class="label">ユーザーID</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="firebaseのユーザーIDを入力"
                formControlName="userId"
              />
              <p
                class="help formError"
                *ngIf="addUserForm.get('userId')?.touched && addUserForm.get('userId')?.hasError('required')">
                会社名は必須です。
              </p>
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="addUserForm.invalid">追加</button>
            </div>
            <app-inner-progress
              *ngIf="savingUser"
              [status]="savingStatUser"
            ></app-inner-progress>
          </div>
        </form>
        <div class="title py-4">登録されてるユーザー一覧</div>
        <table class="table is-fullwidth">
          <thead>
          <th>利用ユーザー</th>
          <th></th>
          <th></th>
          </thead>
          <tbody>
          <tr *ngFor="let allowedUser of allowedUsers">
            <th>
              <span class="tag is-warning"
                    *ngIf="allowedUser.id?.includes('_proxy'); else elseBlockTag">IP制限モード</span>
              <ng-template #elseBlockTag>
                <span class="tag is-light">通常モード</span>
              </ng-template>
              {{allowedUser.id}}
            </th>
            <th>
              <button class="button px-2" *ngIf="allowedUser.id?.includes('_proxy'); else elseBlock"
                      (click)="disableIpRestriction(allowedUser)">
                IP制限を解除する
              </button>
              <ng-template #elseBlock>
                <button class="button px-2" (click)="enableIpRestriction(allowedUser)">
                  IP制限モードに変更
                </button>
              </ng-template>
            </th>
            <th>
              <button class="button px-2" (click)="deleteUser(allowedUser)">
                削除
              </button>
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
    <article class="pt-8 message is-link" *ngIf="isExistRestrictedIP">
      <div class="message-header">
        <p>IP制限リスト</p>
      </div>
      <div class="message-body">
        <form name="editRestrictIP" [formGroup]="editRestrictIPForm" (submit)="updateRestrictIP()">
          <div class="field">
            <label class="label">IP</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="制限IPを入力"
                formControlName="restrictIP"
              />
              <p class="help formError" *ngIf="editRestrictIPForm.get('restrictIP')?.touched">
                <span *ngIf="editRestrictIPForm.get('restrictIP')?.hasError('required')">
                  IPは必須です。
                </span>
                <span *ngIf="!editRestrictIPForm.get('restrictIP')?.hasError('required') && editRestrictIPForm.get('restrictIP')?.hasError('invalidIpAddress')">
                  IPは有効な形式ではありません。
                </span>
                <span *ngIf="currentIPEditIndex === -1 && editRestrictIPForm.get('restrictIP')?.hasError('isDoubled')">
                  既に登録されています。
                </span>
              </p>
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="editRestrictIPForm.invalid">{{currentIPEditIndex === -1 ? '追加':'保管'}}</button>
            </div>
            <app-inner-progress
              *ngIf="savingRestrictIP"
              [status]="savingStatRestrictIP"
            ></app-inner-progress>
          </div>
        </form>
        <div class="title py-4">登録されてるIP</div>
        <table class="table is-fullwidth">
          <thead>
          <th>IP List</th>
          <th></th>
          <th></th>
          </thead>
          <tbody>
          <tr *ngFor="let restrictIP of restrictIPs; let index = index" [ngClass] = "{'ip-edit-bg': index === currentIPEditIndex}">
            <th>
              {{restrictIP}}
            </th>
            <th class="button-container">
              <button class="button px-7" (click)="editRestrictIP(index)">
                編集
              </button>
            </th>
            <th class="button-container">
              <button class="button px-7" (click)="deleteRestrictIP(index)">
                削除
              </button>
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
    <article class="pt-8 message is-link">
      <div class="message-header">
        <p>仕事を追加する</p>
      </div>
      <div class="message-body">
        <form class="addWork" [formGroup]="addWorkForm" (submit)="addWork()">
          <div class="field" style="flex-grow: 1; margin-right: 12px">
            <label class="label">仕事名</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="仕事名を入力"
                formControlName="workName"
              />
              <p
                class="help formError"
                *ngIf="addWorkForm.get('workName')?.touched && addWorkForm.get('workName')?.hasError('required')">
                仕事名は必須です。
              </p>
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="addWorkForm.invalid">追加</button>
            </div>
            <app-inner-progress
              *ngIf="savingWork"
              [status]="savingStatWork"
            ></app-inner-progress>
          </div>
        </form>

        <div class="title py-4">登録されてる仕事一覧</div>
        <table class="table is-fullwidth">
          <thead>
          <th>仕事名</th>
          <th></th>
          </thead>
          <tbody>
          <tr *ngFor="let work of works">
            <th>{{work.name}}</th>
            <th>
              <button class="button" (click)="deleteWork(work)">
                削除
              </button>
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</div>
