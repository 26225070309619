import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';

import { CommonUiProviderService } from '../../common/common-ui-provider.service';
import { PolarFirebaseService } from '../../polar/polar-firebase.service';
import { PolarApiService } from 'src/app/polar/polar-api.service';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-console-image-classification-uploader',
  templateUrl: './console-image-classification-uploader.component.html',
  styleUrls: ['./console-image-classification-uploader.component.scss'],
  standalone: true,
  imports: [TranslateModule, InnerProgressComponent],
})
export class ConsoleImageClassificationUploaderComponent {
  @ViewChild('INPUT')
  el?: ElementRef;

  @Output() fileSelect: EventEmitter<File> = new EventEmitter();
  @Output() fileSelectMulti: EventEmitter<File[]> = new EventEmitter();

  @Input() companyId: string = '';
  @Input() workId: string = '';

  @Input() multiple: boolean = false;

  classificationVersion: string = '';

  constructor(
    private polar: PolarFirebaseService,
    private clova: PolarApiService,
    private commonUi: CommonUiProviderService,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    const workInfo = await this.polar.getWork(this.companyId, this.workId);
    if (workInfo.classificationVersion) {
      this.classificationVersion = workInfo.classificationVersion.toString();
    } else {
      this.classificationVersion = 'v1';
    }
  }

  pickFile() {
    let elm = this.el!.nativeElement as HTMLInputElement;
    elm.click();
  }

  loading: boolean = false;

  async filePicked(e: any) {
    this.loading = true;
    let elm = this.el!.nativeElement as HTMLInputElement;
    let files = elm.files;

    let list: File[] = [];
    for (let i = 0; i < files!.length; i++) {
      if (files![i].type.toLowerCase() == 'application/pdf') {
        // pdf. should be converted
        let image = await this.clova.pdfToImage(files![i]);
        let newFile = new File([image], 'filename', { type: 'image/png' });
        list.push(newFile);
      } else {
        // image. as it is
        list.push(files![i]);
      }
    }

    if (this.multiple) {
      this.fileSelectMulti.emit(list);
    } else {
      this.fileSelect.emit(list![0]);
    }
    elm.value = '';
    this.loading = false;
  }

  async onChangeClassificationVersion() {
    switch (this.classificationVersion) {
      case 'v2-error':
        await this.commonUi.showConfirm({
          title: '確認',
          body: `分類切り替え時に何らかの問題が発生しています。開発チームに連絡してください`,
          buttons: ['Yes'],
        });
        return;
      case 'v2-processing':
        await this.commonUi.showConfirm({
          title: '確認',
          body: `分類切り替え時に何らかの問題が発生しています。開発チームに連絡してください`,
          buttons: ['Yes'],
        });
        return;
      case 'v1':
        const result = await this.commonUi.showConfirm({
          title: '確認',
          body: `分類を「v2」に切り替えますか？`,
          buttons: ['Yes', 'No'],
        });
        if (result != 'Yes') return;
        // NOTE: Request /batch_register_template_to_pinecone at polar-server
        try {
          const response = await this.clova.batchRegisterTemplateToPineCone(
            this.companyId,
            this.workId,
          );
          if (response['success']) {
            await this.commonUi.showConfirm({
              title: '確認',
              body: `切り替えをリクエストをしました。しばらくお待ちください。`,
              buttons: ['Yes'],
            });
            // TODO: statusを更新するために、リロードする。
            window.location.reload();
          } else {
            await this.commonUi.showConfirm({
              title: '確認',
              body: `切り替えに失敗しました。開発チームに連絡してください。`,
              buttons: ['Yes'],
            });
          }
        } catch (e) {
          await this.commonUi.showConfirm({
            title: '確認',
            body: `切り替えに失敗しました。開発チームに連絡してください。エラーコード: ${e}`,
            buttons: ['Yes'],
          });
        }
        return;
      case 'v2':
        return;
    }
  }
}
