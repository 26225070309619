import { inject, Injectable } from '@angular/core';
import { PolarFirebaseService } from '../../polar/polar-firebase.service';
import {
  MasterDocumentData,
  MasterGroupDocumentData,
  MasterHistoryDocumentData,
} from '../../polar/entity/master';
import {
  MasterUpdatingService,
  UpdatingMasterCsv,
} from '../../master-settings/master-updating.service';

@Injectable()
export class MasterUpdatingAdminService implements MasterUpdatingService {
  private readonly polar = inject(PolarFirebaseService);

  getCurrent() {
    console.log('current from admin');
  }

  async getMaster(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Promise<[MasterGroupDocumentData, MasterDocumentData] | undefined> {
    const masters = await this.polar.getMastersByIds(companyId, masterGroupId, [masterId]);
    const groups = await this.polar.getMasterGroupsByIds(companyId, [masterGroupId]);

    if (masters.length == 0 || groups.length == 0) {
      return undefined;
    }

    return [groups[0], masters[0]];
  }

  async updateMaster(
    companyId: string,
    masterGroupId: string,
    master: MasterHistoryDocumentData,
    csv?: UpdatingMasterCsv,
  ) {
    if (csv) {
      const uploadedPath = await this.polar.uploadCompanyCsvMasterFile(
        companyId,
        'master.csv',
        masterGroupId,
        csv.csvFile,
      );

      master.csv = {
        filePath: uploadedPath,
        header: csv.csvHeader,
      };
    }

    return this.polar.updateMaster(companyId, masterGroupId, master);
  }

  confirmMessageWhenUpdate(): string {
    return 'マスタを更新しても本番環境に適用しなければデータ化処理には反映されません。\n本当に実行しますか？';
  }

  confirmMessageWhenRevert(): string {
    return 'マスタを元に戻しても本番環境に適用しなければデータ化処理には反映されません。\n本当に実行しますか？';
  }

  applyToProduction(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    stagedId: string | undefined,
  ): Promise<void> {
    // NOTE: no-op
    return Promise.resolve();
  }

  async revertMaster(
    companyId: string,
    master: MasterDocumentData,
    history: MasterHistoryDocumentData,
  ): Promise<void> {
    await this.polar.revertMaster(companyId, master, history);
  }

  async deleteMasterHistory(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    stagedId: string,
  ): Promise<void> {
    await this.polar.deleteMasterHistory(companyId, masterGroupId, masterId, stagedId);
  }
}
