import { AfterViewChecked, AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHorizontalScrollEnabled]',
})
export class HorizontalScrollEnabledDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    // enable horizontal scrolling with mouse
    this.el.nativeElement.addEventListener('wheel', (e: WheelEvent) => {
      e.preventDefault();
      this.el.nativeElement.scrollLeft += e.deltaY;
    });
  }
}
