import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InnerProgressComponent } from '../../../common/inner-progress/inner-progress.component';

import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';

import { TranslateModule } from '@ngx-translate/core';

interface ImageRect {
  x: number;
  y: number;
  width: number;
  height: number;
  field: any;
  title: string;
}

@Component({
  selector: 'app-console-bounding-box-selector',
  templateUrl: './console-bounding-box-selector.component.html',
  styleUrls: ['./console-bounding-box-selector.component.scss'],
  standalone: true,
  imports: [TranslateModule, InnerProgressComponent, FormsModule],
})
export class ConsoleBoundingBoxSelectorComponent implements OnInit, OnDestroy {
  rects: ImageRect[] = [];
  @Input() preSelectedRects: any[] = [];
  selectedRects: ImageRect[] = [];

  _rawData: any;
  @Input() set rawData(val: any) {
    this._rawData = val;
    this.update();
  }

  get rawData(): any {
    return this._rawData;
  }

  _image?: File;
  @Input() set image(val: File | undefined) {
    this._image = val;
    this.renderImage();
  }

  get image(): File | undefined {
    return this._image;
  }

  // NOTE: select-v2 is not use classification-version-1
  @Input() status: 'rotating' | 'matching' | 'loading' | 'select-rect' | 'select-v2' | 'exists' =
    'loading';
  @Input() csv: string = '';

  @Input() imageUrl: string = '';

  @Output() selectRectsComplete: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() create: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('IMAGE')
  el_image?: ElementRef;

  showText: boolean = false;

  constructor(private commonUi: CommonUiProviderService) {}

  resized: () => void = () => {
    this.update();
  };

  ngOnInit(): void {
    window.addEventListener('resize', this.resized);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resized);
  }

  baseClick(e: MouseEvent) {
    if ((e.target as HTMLElement).classList.contains('base')) {
      this.close.emit();
    }
  }

  renderImage() {
    this.imageUrl = '';
    if (this.image == null) return;
    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };

    reader.readAsDataURL(this.image!);
  }

  rectClick(item: ImageRect) {
    if (this.selectedRects.includes(item)) {
      let idx = this.selectedRects.findIndex((f) => f == item);
      this.selectedRects.splice(idx, 1);
      return;
    }
    this.selectedRects.push(item);
  }

  async preview(item: ImageRect) {
    await this.commonUi.showConfirm({
      body: item.title,
      buttons: ['OK'],
    });
  }

  update() {
    if (this.el_image == null || this.el_image.nativeElement == null) {
      setTimeout(() => {
        this.update();
      }, 1000);
      return;
    }
    let elm = this.el_image!.nativeElement as HTMLImageElement;
    let width = elm.clientWidth;
    let height = elm.clientHeight;

    let imageWidth = this.rawData.images[0].convertedImageInfo.width;
    let imageHeight = this.rawData.images[0].convertedImageInfo.height;

    let perWidth = imageWidth / width;
    let perHeight = imageHeight / height;

    let preSelectedRectsConverted: ImageRect[] = [];
    this.selectedRects = [];
    this.preSelectedRects.forEach((field: any) => {
      let vert = field.boundingPoly.vertices; // 4 points with x and y
      let rect = {
        x: vert[0].x,
        y: vert[0].y,
        width: vert[2].x - vert[0].x,
        height: vert[2].y - vert[0].y,
        field: field,
        title: field.inferText,
      };

      // convert to elm size
      rect.x = rect.x / perWidth;
      rect.y = rect.y / perHeight;
      rect.width = rect.width / perWidth;
      rect.height = rect.height / perHeight;

      preSelectedRectsConverted.push(rect);
    });

    this.rects = [];
    this.rawData.images[0].fields.forEach((field: any) => {
      let vert = field.boundingPoly.vertices; // 4 points with x and y
      let rect = {
        x: vert[0].x,
        y: vert[0].y,
        width: vert[2].x - vert[0].x,
        height: vert[2].y - vert[0].y,
        field: field,
        title: field.inferText,
      };

      // convert to elm size
      rect.x = rect.x / perWidth;
      rect.y = rect.y / perHeight;
      rect.width = rect.width / perWidth;
      rect.height = rect.height / perHeight;

      this.rects.push(rect);

      let selected = preSelectedRectsConverted.find(
        (f) => f.x == rect.x && f.y == rect.y && f.width == rect.width && f.height == rect.height,
      );
      if (selected != undefined) {
        this.selectedRects.push(rect);
      }
    });
  }

  done() {
    if (this.selectedRects.length <= 2) {
      alert('3つ以上選択してください');
      return;
    }
    this.selectRectsComplete.emit(this.selectedRects.map((f) => f.field));
  }
}
