<div class="base">
  <header>
    <button class="button back-button" (click)="back()">
      <i class="fa-regular fa-arrow-left"></i>
    </button>
    <div class="icon">
      <i class="fa-regular fa-folder-open"></i>
    </div>

    @if (workInfo != undefined) {
      <h1 style="font-size: 24px; font-weight: bold">
        {{ workInfo!.name }}
      </h1>
    }
    @if (workInfo == undefined) {
      <h1 style="font-size: 24px; font-weight: bold">...</h1>
    }
    <button
      class="button history-button"
      tooltip="ダウンロード履歴を表示"
      tooltipPlacement="top"
      (click)="showDownloadHistory()"
    >
      <i class="fa-regular fa-clock-rotate-left"></i>
    </button>
    <button
      class="button history-button"
      tooltip="設定"
      tooltipPlacement="top"
      (click)="showSettings()"
      style="margin-left: 0"
    >
      <i class="fa-regular fa-gear"></i>
    </button>
  </header>

  @if (loading) {
    <div class="content">
      <app-inner-progress></app-inner-progress>
    </div>
  }

  @if (!loading) {
    <div class="content">
      <div class="tile-parent">
        <div class="tile upload" (click)="showUploader($event)" tooltip="新しいファイルを追加">
          <div class="tile-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <defs>
                <style>
                  .fa-secondary {
                    opacity: 0.4;
                  }
                </style>
              </defs>
              <!--var(--main-gradient)-->
              <g fill="var(--main-color)">
                <path
                  class="fa-primary"
                  d="M384 0v128h128L384 0zM296.1 215c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94L302.1 288H24C10.75 288 0 298.8 0 312s10.75 24 24 24h278.1l-39.03 39.03C258.3 379.7 256 385.8 256 392s2.344 12.28 7.031 16.97c9.375 9.375 24.56 9.375 33.94 0l80-80c9.375-9.375 9.375-24.56 0-33.94L296.1 215z"
                />
              </g>
              <g fill="var(--main-color)">
                <path
                  class="fa-secondary"
                  d="M512 128v336c0 26.51-21.49 48-48 48h-288C149.5 512 128 490.5 128 464v-128h174.1l-39.03 39.03C258.3 379.7 256 385.8 256 392s2.344 12.28 7.031 16.97c9.375 9.375 24.56 9.375 33.94 0l80-80c9.375-9.375 9.375-24.56 0-33.94l-80-80c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94L302.1 288H128V48C128 21.49 149.5 0 176 0H384v128H512z"
                />
              </g>
            </svg>
          </div>
          <div class="tile-text">アップロード</div>
          @if (uploaded.length > 0) {
            <div class="notif">
              {{ uploaded.length }}
            </div>
          }
          @if (uploaded.length > 0) {
            <div class="upload-loader-icon">
              <app-inner-progress></app-inner-progress>
            </div>
          }
          <button
            (click)="showListUploaded()"
            class="button list-button"
            tooltip="処理中のファイル一覧を表示"
            tooltipPlacement="right"
          >
            <i class="fa-regular fa-list"></i>
          </button>
        </div>
        <!--
          <div class="tile append">
            <i class="fa-regular fa-plus"></i>
          </div>
        -->
      </div>
      <div class="arrow">
        <i class="fa-regular fa-angles-down"></i>
      </div>
      <div class="tile-parent handy" [ngClass]="converted.length > 0 ? 'shown' : null">
        <div class="tile processing" style="background: none; border: none">
          <img
            src="assets/robot.svg"
            style="pointer-events: all; cursor: initial"
            tooltip="認識中..."
          />
          <div class="ai-child">
            <app-inner-progress color="white"></app-inner-progress>
          </div>
          @if (converted.length > 0) {
            <div class="notif" style="right: 10px; top: 0px">
              {{ converted.length }}
            </div>
          }
          <button
            class="button list-button"
            tooltip="認識中のファイル一覧を表示"
            tooltipPlacement="right"
            (click)="showListConverted()"
          >
            <i class="fa-regular fa-list"></i>
          </button>
        </div>
      </div>
      <div class="arrow" style="transform: none">
        <i class="fa-regular fa-angles-down" style="transform: rotate(45deg)"></i>
        <div style="width: 64px"></div>
        <i class="fa-regular fa-angles-down" style="transform: rotate(-45deg)"></i>
      </div>
      <div
        class="tile-parent"
        [ngClass]="failed.length == 0 && succeeded.length == 0 ? 'tile-parent-not-shown' : null"
      >
        <div
          class="tile checking hand-input handy"
          [ngClass]="failed.length > 0 ? 'shown' : null"
          style="margin-right: 12px"
          (click)="goCheck($event, 'failed')"
          tooltip="手入力してください"
        >
          <div class="tile-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <defs>
                <style>
                  .fa-secondary {
                    opacity: 0.4;
                  }
                </style>
              </defs>
              <g fill="var(--main-color)">
                <path
                  class="fa-primary"
                  d="M557.2 235.7C572.8 220.1 598.1 220.1 613.8 235.7L628.1 250.1C643.8 265.7 643.8 291 628.1 306.7L598.7 336.1L527.8 265.1L557.2 235.7zM576.1 358.7L446.9 487.9C442.8 492 437.6 494.9 432 496.3L371.9 511.4C366.4 512.7 360.7 511.1 356.7 507.2C352.7 503.2 351.1 497.4 352.5 491.1L367.5 431.8C368.9 426.2 371.8 421.1 375.9 416.1L505.1 287.8L576.1 358.7z"
                />
                <path
                  class="fa-secondary"
                  d="M.0003 128C.0003 92.65 28.65 64 64 64H512C547.3 64 576 92.65 576 128V192.6C560.8 194.6 546.2 201.4 534.6 213.1L353.3 394.3C345.1 402.5 339.3 412.8 336.5 424.1L330.5 448H64C28.65 448 0 419.3 0 384L.0003 128zM112 320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H112C103.2 288 96 295.2 96 304C96 312.8 103.2 320 112 320zM112 192C103.2 192 96 199.2 96 208C96 216.8 103.2 224 112 224H432C440.8 224 448 216.8 448 208C448 199.2 440.8 192 432 192H112z"
                />
              </g>
            </svg>
          </div>
          <div class="tile-text">手入力待ち</div>
          @if (failed.length > 0) {
            <div class="notif">{{ failed.length }}</div>
          }
          <button
            class="button list-button"
            tooltip="ファイル一覧を表示"
            tooltipPlacement="right"
            (click)="showListFailed()"
          >
            <i class="fa-regular fa-list"></i>
          </button>
        </div>
        <div
          class="tile checking confirm-input handy"
          [ngClass]="succeeded.length > 0 ? 'shown' : null"
          style="margin-left: 12px"
          (click)="goCheck($event, 'succeeded')"
          tooltip="内容を確認してください"
        >
          <div class="tile-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <defs>
                <style>
                  .fa-secondary {
                    opacity: 0.4;
                  }
                </style>
              </defs>
              <g fill="var(--main-color)">
                <path
                  class="fa-primary"
                  d="M280 264C280 277.3 269.3 288 256 288C242.7 288 232 277.3 232 264V152C232 138.7 242.7 128 256 128C269.3 128 280 138.7 280 152V264zM224 352C224 334.3 238.3 320 256 320C273.7 320 288 334.3 288 352C288 369.7 273.7 384 256 384C238.3 384 224 369.7 224 352z"
                />
                <path
                  class="fa-secondary"
                  d="M222.1 18.41C240.8-.3309 271.2-.3309 289.9 18.41L335.5 64H400C426.5 64 448 85.49 448 112V176.5L493.6 222.1C512.3 240.8 512.3 271.2 493.6 289.9L448 335.5V400C448 426.5 426.5 448 400 448H335.5L289.9 493.6C271.2 512.3 240.8 512.3 222.1 493.6L176.5 448H112C85.49 448 64 426.5 64 400V335.5L18.41 289.9C-.3328 271.2-.3328 240.8 18.41 222.1L64 176.5V112C64 85.49 85.49 64 112 64H176.5L222.1 18.41zM232 152V264C232 277.3 242.7 288 256 288C269.3 288 280 277.3 280 264V152C280 138.7 269.3 128 256 128C242.7 128 232 138.7 232 152zM256 320C238.3 320 224 334.3 224 352C224 369.7 238.3 384 256 384C273.7 384 288 369.7 288 352C288 334.3 273.7 320 256 320z"
                />
              </g>
            </svg>
          </div>
          <div class="tile-text">確認待ち</div>
          @if (succeeded.length > 0) {
            <div class="notif">
              {{ succeeded.length }}
            </div>
          }
          <button
            class="button list-button"
            tooltip="ファイル一覧を表示"
            tooltipPlacement="right"
            (click)="showListSucceeded()"
          >
            <i class="fa-regular fa-list"></i>
          </button>
        </div>
      </div>
      <div class="arrow">
        <i class="fa-regular fa-angles-down"></i>
      </div>
      <div class="tile-parent handy" [ngClass]="confirmed.length > 0 ? 'shown' : null">
        <div class="tile upload" (click)="download($event)" tooltip="認識済みのファイル">
          <div class="tile-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <defs>
                <style>
                  .fa-secondary {
                    opacity: 0.4;
                  }
                </style>
              </defs>
              <g fill="var(--main-color)">
                <path
                  class="fa-primary"
                  d="M448 416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V160H208V32H240V160H448V416zM320.1 280.1C330.3 271.6 330.3 256.4 320.1 247C311.6 237.7 296.4 237.7 287 247L192 342.1L152.1 303C143.6 293.7 128.4 293.7 119 303C109.7 312.4 109.7 327.6 119 336.1L175 392.1C184.4 402.3 199.6 402.3 208.1 392.1L320.1 280.1z"
                />
                <path
                  class="fa-secondary"
                  d="M208 32V160H0L50.73 58.53C58.86 42.27 75.48 32 93.67 32H208zM354.3 32C372.5 32 389.1 42.27 397.3 58.53L448 160H240V32H354.3z"
                />
              </g>
            </svg>
          </div>
          <div class="tile-text">ダウンロード</div>
          @if (confirmed.length > 0) {
            <div class="notif">
              {{ confirmed.length }}
            </div>
          }
          <button
            class="button list-button"
            tooltip="ファイル一覧を表示"
            tooltipPlacement="right"
            (click)="showListConfirmed()"
          >
            <i class="fa-regular fa-list"></i>
          </button>
        </div>
      </div>
    </div>
  }
</div>

<app-work-right-bottom-floating></app-work-right-bottom-floating>

@if (uploaderShowing) {
  <app-work-upload-file
    (hide)="hideUploader()"
    (fileCount)="handleFileCount($event)"
    [companyId]="companyId"
    [workId]="workId"
  ></app-work-upload-file>
}

@if (listShowing) {
  <app-modal-list-files
    (close)="hideList()"
    [images]="listingImages"
    [imagesUpdatable]="listingImagesUpdatable"
    [failProperty]="listingImagesFailTargetProperty"
    [companyId]="companyId"
    [workId]="workId"
  ></app-modal-list-files>
}

@if (downloadHistoryShowing) {
  <app-modal-list-download-history
    (close)="hideDownloadHistory()"
    [companyId]="companyId"
    [workId]="workId"
  >
  </app-modal-list-download-history>
}

@if (showWorkSettingsModal) {
  <app-main-work-settings-modal
    (close)="hideSettings()"
    [companyId]="companyId"
    [workId]="workId"
  ></app-main-work-settings-modal>
}

@if (downloading) {
  <div class="loading">
    <p>ダウンロードしています... {{ downloadProgress }}</p>
    <app-inner-progress></app-inner-progress>
  </div>
}
