import { inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { PolarFirebaseService } from '../polar/polar-firebase.service';
import {
  MasterGroupDocumentData,
  MasterDocumentData,
  MasterHistoryDocumentData,
} from '../polar/entity/master';
import { BehaviorSubject, first, map, Observable, Subscription } from 'rxjs';
import { AccessibleRule, PolarFirebaseAuthService } from '../polar/polar-firebase-auth.service';

type MasterSettingMode = 'Admin' | 'User';

interface MasterSettingState {
  masterSettingMode: MasterSettingMode;

  currentCompanyId?: string;

  groups$: Observable<MasterGroupDocumentData[]>;
  selectedGroup?: MasterGroupDocumentData;
  mastersInSelectedGroup: MasterDocumentData[];
  latestMasterDict: { [key: string]: MasterHistoryDocumentData };

  showMasterCreatingModal: boolean;
  newMasterBelongingGroupId?: string | null;
  showMasterEditingModal: boolean;
  currentEditingMasterId?: string;
  showGroupCreatingModal: boolean;
  showHistories: boolean;
  showingHistoriesMasterId?: string;

  accessibleMasterGroups?: AccessibleRule[];

  updating: boolean;
  updatingGroups: boolean;
}

const initialState: MasterSettingState = {
  masterSettingMode: 'User',

  currentCompanyId: undefined,

  groups$: new Observable<MasterGroupDocumentData[]>(),
  mastersInSelectedGroup: [],
  latestMasterDict: {},
  selectedGroup: undefined,

  showMasterCreatingModal: false,
  newMasterBelongingGroupId: undefined,
  showMasterEditingModal: false,
  showGroupCreatingModal: false,
  showHistories: false,
  showingHistoriesMasterId: undefined,

  accessibleMasterGroups: undefined,

  updating: false,
  updatingGroups: false,
};

const canCreateAndDeleteItem = (state: MasterSettingState) => state.masterSettingMode == 'Admin';

@Injectable()
export class MasterSettingsService {
  // NOTE: ユーザー側、コンソール側のそれぞれで利用するが、シングルトンで利用するためのガード
  constructor(@Optional() @SkipSelf() parent?: MasterSettingsService) {
    if (parent) {
      console.log(
        `[MasterSettingsService]: trying to create multiple instances,
        but this service should be a singleton.`,
      );
    }
  }

  private readonly polar = inject(PolarFirebaseService);
  private readonly polarAuthGroup = inject(PolarFirebaseAuthService);

  private readonly store = new BehaviorSubject<MasterSettingState>(initialState);
  private readonly _currentCompanyId = new BehaviorSubject<string | undefined>(undefined);

  private groupMastersUnsubscribe?: Subscription;

  get state$() {
    return this.store.asObservable();
  }

  get currentCompanyId() {
    return this._currentCompanyId.value;
  }

  set currentCompanyId(val: string | undefined) {
    this._currentCompanyId.next(val);
  }

  async initialize(useAsAdmin: boolean) {
    if (useAsAdmin && !(await this.polar.isAdmin())) {
      throw new Error('has no permission');
    }

    this.store.next({
      ...this.store.value,
      masterSettingMode: useAsAdmin ? 'Admin' : 'User',
      updatingGroups: true,
    });

    // TODO unsubscribe
    this._currentCompanyId.subscribe(async (val) => {
      if (val == undefined) {
        return;
      }

      this.store.next({
        ...this.store.value,
        currentCompanyId: val,
      });

      await this.fetchGroups(val);

      this.store.next({
        ...this.store.value,
        updatingGroups: false,
      });

      // NOTE: ユーザー側からマスター設定画面に遷移した場合、デフォルトで先頭のグループの一覧を表示する
      if (this.store.value.masterSettingMode == 'User') {
        const sub = this.store.value.groups$.pipe(first()).subscribe((x) => {
          sub.unsubscribe();
          this.changeGroup(x[0]);
        });
      }
    });
  }

  finalize() {
    if (this.groupMastersUnsubscribe != undefined) {
      this.groupMastersUnsubscribe.unsubscribe();
    }
  }

  async fetchGroups(companyId: string) {
    // NOTE: 所属無しを先頭に持ってくる
    const orderByAlphabetically = (a: MasterGroupDocumentData, b: MasterGroupDocumentData) => {
      if (a.type === 'withoutGroup') return -1;
      return a.name.localeCompare(b.name);
    };

    let accessibleMasterGroups: AccessibleRule[] | undefined = undefined;

    if (await this.polarAuthGroup.isAuthGroupEnabled()) {
      accessibleMasterGroups = await this.polarAuthGroup.getAccessibleMasterGroups();
    }

    if (accessibleMasterGroups != undefined) {
      const groups$ = this.polar
        .getMasterGroupsWithListening(companyId)
        .pipe(
          map((groups) =>
            groups
              .sort(orderByAlphabetically)
              .filter(
                (g) =>
                  accessibleMasterGroups!.findIndex(
                    (a) =>
                      a.companyId == companyId &&
                      (a.id == g.id || a.id == '*') &&
                      (a.readable || a.writable),
                  ) >= 0,
              ),
          ),
        );
      // this.store.next({ ...this.store.value, groups: [], groups$: groups$ });
      this.store.next({
        ...this.store.value,
        groups$: groups$,
        accessibleMasterGroups: accessibleMasterGroups,
      });
    } else {
      const groups$ = this.polar
        .getMasterGroupsWithListening(companyId)
        .pipe(map((groups) => groups.sort(orderByAlphabetically)));
      // this.store.next({ ...this.store.value, groups: [], groups$: groups$ });
      this.store.next({ ...this.store.value, groups$: groups$ });
    }
  }

  deleteMaster(companyId: string, deletingMaster: MasterDocumentData) {
    return this.polar.deleteCompanyMappingMaster(this.currentCompanyId!, deletingMaster);
  }

  changeGroup(group: MasterGroupDocumentData | undefined) {
    if (group == undefined) {
      this.store.next({
        ...this.store.value,
        selectedGroup: undefined,
        mastersInSelectedGroup: [],
        latestMasterDict: {},
      });

      return;
    }

    this.store.next({
      ...this.store.value,
      selectedGroup: group,
      updating: true,
    });

    if (this.groupMastersUnsubscribe != undefined) {
      this.groupMastersUnsubscribe.unsubscribe();
    }

    const orderByAlphabetically = (a: MasterDocumentData, b: MasterDocumentData) => {
      return a.name.localeCompare(b.name);
    };

    // FIXME: ここを subscribe にしているのは、コンソール側でのグループの追加・削除をリアルタイムに反映させるため
    //       ただ、ユーザー側ではその必要が無いので、更新時に refresh にしても良いかもしれない
    this.groupMastersUnsubscribe = this.polar
      .getMastersInGroupWithListening(this.currentCompanyId!, group.id!)
      .pipe(map((masters) => masters.sort(orderByAlphabetically)))
      .subscribe(async (masters) => {
        const latestMasterDict: { [key: string]: MasterHistoryDocumentData } = {};
        try {
          for (const master of masters) {
            const latestMaster = await this.polar.getLatestMaster(
              this.currentCompanyId!,
              master.masterGroupId,
              master.id!,
              false,
            );
            if (latestMaster != null) {
              latestMasterDict[master.id!] = latestMaster;
            }
          }
        } catch {
          console.log('err');
        }
        this.store.next({
          ...this.store.value,
          mastersInSelectedGroup: masters as MasterDocumentData[],
          latestMasterDict: latestMasterDict,
          updating: false,
        });
      });
  }

  openMasterCreatingModal(belongingGroupId?: string | null) {
    this.store.next({
      ...this.store.value,
      showMasterCreatingModal: true,
      newMasterBelongingGroupId: belongingGroupId,
    });
  }

  closeMasterCreatingModal() {
    this.store.next({
      ...this.store.value,
      showMasterCreatingModal: false,
      newMasterBelongingGroupId: undefined,
    });
  }

  openMasterEditingModal(currentEditingMasterId: string) {
    this.store.next({
      ...this.store.value,
      showMasterEditingModal: true,
      currentEditingMasterId: currentEditingMasterId,
    });
  }

  closeMasterEditingModal() {
    this.store.next({
      ...this.store.value,
      showMasterEditingModal: false,
      currentEditingMasterId: undefined,
    });
  }

  openGroupCreatingModal() {
    this.store.next({
      ...this.store.value,
      showGroupCreatingModal: true,
    });
  }

  closeGroupCreatingModal() {
    this.store.next({
      ...this.store.value,
      showGroupCreatingModal: false,
    });
  }

  openHistory(showingHistoryMaster: MasterDocumentData) {
    this.store.next({
      ...this.store.value,
      showHistories: true,
      showingHistoriesMasterId: showingHistoryMaster.id,
    });
  }

  closeHistory() {
    this.store.next({
      ...this.store.value,
      showHistories: false,
      showingHistoriesMasterId: undefined,
    });
  }
}

export { canCreateAndDeleteItem };
