import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PolarApiService } from 'src/app/polar/polar-api.service';

@Component({
  selector: 'app-console-image-classification-uploader',
  templateUrl: './console-image-classification-uploader.component.html',
  styleUrls: ['./console-image-classification-uploader.component.scss'],
})
export class ConsoleImageClassificationUploaderComponent {
  @ViewChild('INPUT')
  el?: ElementRef;

  @Output() fileSelect: EventEmitter<File> = new EventEmitter();
  @Output() fileSelectMulti: EventEmitter<File[]> = new EventEmitter();

  @Input() multiple: boolean = false;

  constructor(private clova: PolarApiService) {}

  pickFile() {
    let elm = this.el!.nativeElement as HTMLInputElement;
    elm.click();
  }

  loading: boolean = false;

  async filePicked(e: any) {
    this.loading = true;
    let elm = this.el!.nativeElement as HTMLInputElement;
    let files = elm.files;

    let list: File[] = [];
    for (let i = 0; i < files!.length; i++) {
      if (files![i].type.toLowerCase() == 'application/pdf') {
        // pdf. should be converted
        let image = await this.clova.pdfToImage(files![i]);
        let newFile = new File([image], 'filename', { type: 'image/png' });
        list.push(newFile);
      } else {
        // image. as it is
        list.push(files![i]);
      }
    }

    if (this.multiple) {
      this.fileSelectMulti.emit(list);
    } else {
      this.fileSelect.emit(list![0]);
    }
    elm.value = '';
    this.loading = false;
  }
}
