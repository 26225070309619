import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { detect, convert } from 'encoding-japanese';
import { CommonUiProviderService } from '../../common/common-ui-provider.service';
import { CommonModule } from '@angular/common';

export type CsvData = {
  encodedString: string;
  encodedFile: File;
  originalFileName: string;
};

@Component({
  selector: 'app-csv-input',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './csv-input.component.html',
  styleUrls: ['./csv-input.component.scss'],
})
export class CsvInputComponent {
  private readonly polar = inject(PolarFirebaseService);
  private readonly commonUi = inject(CommonUiProviderService);

  loading: boolean = false;
  preview: string | null = null;
  currentCsvData: CsvData | null = null;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiline: boolean = false;
  @Input() grow: number = 4.2;
  @Input() companyId: string = '';

  @Output() csvUploaded = new EventEmitter<CsvData>();

  @ViewChild('INPUT')
  el?: ElementRef;

  ngAfterViewInit() {}

  selectFile() {
    const elm = this.el!.nativeElement as HTMLInputElement;
    elm.value = '';
    elm.click();
  }

  downloadNewFile() {
    const { originalFileName, encodedFile } = this.currentCsvData!;
    const url = URL.createObjectURL(encodedFile);
    const link = document.createElement('a');
    link.download = originalFileName;
    link.href = url;

    setTimeout(() => {
      link.click();

      URL.revokeObjectURL(url);
    }, 1000);
  }

  async fileSelected(fileList: FileList | null) {
    if (fileList == null || fileList.length > 1) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'アップロードできるファイルは 1 つです。',
        buttons: ['OK'],
      });

      return;
    }

    try {
      const { str, arr } = await this.readFromFile(fileList[0]);

      this.currentCsvData = {
        encodedString: str,
        encodedFile: this.createFile(arr),
        originalFileName: fileList[0].name,
      };

      this.csvUploaded.emit(this.currentCsvData);
    } catch (error) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'ファイルの読み込みに失敗しました。CSV ファイルであるか確認してください。',
        buttons: ['OK'],
      });

      return;
    }
  }

  private async readFromFile(file: File) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    await new Promise<void>((resolve) => (reader.onload = () => resolve()));

    const array = new Uint8Array(reader.result as ArrayBuffer);
    let encoding = detect(array);

    // workaround
    if (encoding == 'UNICODE') {
      encoding = 'SJIS';
    }

    const fileData = new Uint8Array(convert(array, 'UTF8', encoding ? encoding : undefined));

    const jsStr = convert(array, {
      from: encoding ? encoding : undefined,
      to: 'UNICODE',
      type: 'string',
    });

    return {
      str: jsStr,
      arr: fileData,
    };
  }

  private createFile(fileData: Uint8Array) {
    const mediaType = { type: 'text/csv' };
    const fileName = 'master.csv';

    const blob = new Blob([fileData], mediaType);

    // blob to file
    return new File([blob], fileName, mediaType);
  }
}
