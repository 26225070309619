import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-workflow-select-modal',
  templateUrl: './workflow-select-modal.component.html',
  styleUrls: ['./workflow-select-modal.component.scss'],
})
export class WorkflowSelectModalComponent {
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  close(e: any) {
    let elm = e.target as HTMLElement;

    if (elm.classList.contains('base')) {
      // close
      this.closed.emit();
    }
  }
}
