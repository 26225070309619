<div class="templates">
  <div class="title" translate>テンプレート</div>
  <div class="block transparent scrollbar" appHorizontalScrollEnabled>
    @for (item of templates; track item; let idx = $index) {
      <div
        class="template"
        [ngClass]="selectedTemplate == item.id && !readonly ? 'active-template' : null"
        (click)="templateSelect.emit(item)"
        (mouseenter)="mouseOver(item)"
        (mousemove)="mouseMove(item, $event)"
        (mouseleave)="mouseLeave()"
        (contextmenu)="contextMenu($event, item)"
      >
        @if (item.id == 'default') {
          <i class="fa-solid fa-infinity"></i>
        }
        @if (item.id != 'default' && imageUrls[item.id!] != null) {
          <div class="img-parent">
            @if (item.id != 'default' && imageUrls[item.id!] != null) {
              <img [src]="imageUrls[item.id!]" />
            }
          </div>
        }
        @if (item.id != 'default' && imageUrls[item.id!] == null) {
          <app-inner-progress status="loading"></app-inner-progress>
        }
        <div class="idx-display">
          {{ idx + 1 }}
        </div>
        @if (!readonly) {
          <div class="delete-template">
            <button class="delete is-medium" (click)="deleteTemplate(item)"></button>
          </div>
        }
      </div>
    }
  </div>
</div>

@if (mouseOverTemplate != undefined) {
  <app-console-zoom-image
    [imageUrl2]="imageUrls[mouseOverTemplate.id!]"
    [offsetX]="templateOffsetX"
    [offsetY]="templateOffsetY"
  ></app-console-zoom-image>
}
