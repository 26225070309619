<div class="app-container" [ngClass]="isDemo ? 'container-demo' : null">
  <div class="logo">
    <img src="assets/logo_whiten.png" />
  </div>

  <section>
    <div class="section-title">
      TEMPLATE
      <div class="line"></div>
    </div>
    <button
      [ngClass]="current == 'template-create' ? 'active' : null"
      (click)="itemClick('template-create')"
      class="allow-demo"
    >
      <div class="circle" style="background-color: #13e0fe"></div>
      <div class="text" translate>作成</div>
    </button>
    <button
      class="allow-demo"
      [ngClass]="current == 'template-test' ? 'active' : null"
      (click)="itemClick('template-test')"
    >
      <div class="circle" style="background-color: #f19ec2"></div>
      <div class="text" translate>テスト</div>
    </button>
  </section>

  <section>
    <div class="section-title">
      CONFIGURATION
      <div class="line"></div>
    </div>

    <button
      [ngClass]="current == 'config-config' ? 'active' : null"
      (click)="itemClick('config-config')"
    >
      <div class="circle" style="background-color: #13e0fe"></div>
      <div class="text" translate>設定</div>
    </button>

    <button
      [ngClass]="current == 'config-prompt' ? 'active' : null"
      (click)="itemClick('config-prompt')"
    >
      <div class="circle" style="background-color: #f19ec2"></div>
      <div class="text" translate>プロンプト</div>
    </button>

    <button
      [ngClass]="current == 'config-master' ? 'active' : null"
      (click)="itemClick('config-master')"
    >
      <div class="circle" style="background-color: #c101c2"></div>
      <div class="text" translate>マスタ</div>
    </button>
  </section>

  <section>
    <div class="section-title">
      COMPANY
      <div class="line"></div>
    </div>

    <button
      [ngClass]="current == 'company-create' ? 'active' : null"
      (click)="itemClick('company-create')"
    >
      <div class="circle" style="background-color: #13e0fe"></div>
      <div class="text" translate>会社設定</div>
    </button>

    <button
      [ngClass]="current == 'work-settings' ? 'active' : null"
      (click)="itemClick('work-settings')"
    >
      <div class="circle" style="background-color: #f19ec2"></div>
      <div class="text" translate>仕事設定</div>
    </button>

    <button
      [ngClass]="current == 'auth-settings' ? 'active' : null"
      (click)="itemClick('auth-settings')"
    >
      <div class="circle" style="background-color: #c101c2"></div>
      <div class="text" translate>認証グループ</div>
    </button>
  </section>

  <div style="flex-grow: 1"></div>

  <section class="last">
    <button (click)="logout()" class="allow-demo">
      <div class="circle" style="background-color: #f19ec2"></div>
      <div class="text" translate>サインアウト</div>
    </button>
  </section>
</div>

<app-overlay-loading *ngIf="loggingOut"></app-overlay-loading>
