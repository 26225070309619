import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss'],
})
export class ConsoleComponent implements OnInit {
  current: string = 'template-create';

  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle('受発注バスターズ - Console');
  }

  leftMenuSelected(val: string) {
    this.current = val;
  }
}
