import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { TemplateInfo } from 'src/app/polar/entity/TemplateInfo';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-console-template-list',
  templateUrl: './console-template-list.component.html',
  styleUrls: ['./console-template-list.component.scss'],
})
export class ConsoleTemplateListComponent implements OnInit {
  _companyId: string = '';
  @Input() set companyId(val: string) {
    this._companyId = val;
    this.update();
  }
  get companyId(): string {
    return this._companyId;
  }
  _workId: string = '';
  @Input() set workId(val: string) {
    this._workId = val;
    this.update();
  }
  get workId(): string {
    return this._workId;
  }
  @Input() selectedTemplate?: string = '';
  @Input() readonly: boolean = false;

  @Output() templateSelect: EventEmitter<TemplateInfo | undefined> = new EventEmitter<
    TemplateInfo | undefined
  >();
  @Output() templateResetRects: EventEmitter<TemplateInfo> = new EventEmitter<TemplateInfo>();

  templates: TemplateInfo[] = [];
  templatesObservable$?: Observable<TemplateInfo[]>;
  sub?: Subscription;
  imageUrls: { [key: string]: string } = {};

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private translator: TranslateService,
  ) {}

  async ngOnInit() {
    await this.update();
  }

  async deleteTemplate(item: TemplateInfo) {
    if (this.readonly) return;
    let result = await this.commonUi.showConfirm({
      title: this.translator.instant('確認'),
      body: this.translator.instant('削除しますか？'),
      buttons: ['Yes', 'No'],
    });
    if (result != 'Yes') return;

    if (this.selectedTemplate == item.id) this.templateSelect.emit(undefined);
    this.mouseOverTemplate = undefined;
    this.polar.deleteTemplate(this.companyId, this.workId, item);
  }

  mouseOverTemplate?: TemplateInfo = undefined;
  templateOffsetX: number = 0;
  templateOffsetY: number = 0;

  async mouseOver(item: TemplateInfo) {
    if (this.readonly) return;
    if (this.selectedTemplate != null && this.selectedTemplate == item.id) {
      console.log(item.id);
      this.templateOffsetX = 0;
      this.templateOffsetY = 0;
      this.mouseOverTemplate = item;
    } else {
      this.mouseOverTemplate = undefined;
    }
  }

  async mouseMove(item: TemplateInfo, e: MouseEvent) {
    if (this.readonly) return;
    if (!(e.target as HTMLElement).classList.contains('template')) {
      return;
    }
    if (this.selectedTemplate != null && this.selectedTemplate == item.id) {
      let centerWidth = (e.target as HTMLElement).clientWidth / 2;
      let centerHeight = (e.target as HTMLElement).clientHeight / 2;
      let x = 0;
      if (e.offsetX >= centerWidth) {
        x = ((e.offsetX - centerWidth) / centerWidth) * -1;
      } else {
        x = 1 - e.offsetX / centerWidth;
      }
      let y = 0;
      if (e.offsetY >= centerHeight) {
        y = ((e.offsetY - centerHeight) / centerHeight) * -1;
      } else {
        y = 1 - e.offsetY / centerHeight;
      }
      let offsetX = x * 100;
      let offsetY = y * 100;
      this.templateOffsetX = offsetX;
      this.templateOffsetY = offsetY;
    }
  }

  async mouseLeave() {
    if (this.readonly) return;
    this.mouseOverTemplate = undefined;
  }

  async update() {
    this.sub?.unsubscribe();

    if (this.companyId == '' || this.workId == '') return;

    this.selectedTemplate = '';

    this.templatesObservable$ = await this.polar.getTemplates(this.companyId, this.workId);
    this.sub = this.templatesObservable$.subscribe((d) => {
      // sort d with createdAt
      d.sort((a, b) => {
        if (a.createdAt == null) return 1;
        if (b.createdAt == null) return -1;
        return a.createdAt > b.createdAt ? -1 : 1;
      });
      this.templates = d;

      this.templates.forEach(async (f) => {
        if (f.referenceImage != null) {
          let file = await this.polar.getTemplateFile(
            this.companyId,
            this.workId,
            f.referenceImage!,
          );
          this.imageUrls[f.id!] = file;
          console.log(f.id!);
        }
      });

      if (this.selectedTemplate == '' && d.length > 0) {
        this.selectedTemplate = d[0].id!;
        this.templateSelect.emit(d[0]);
      }
      if (d.length == 0) {
        this.selectedTemplate = '';
        this.templateSelect.emit(undefined);
      }
    });
  }

  async contextMenu(e: MouseEvent, item: TemplateInfo) {
    if (item.id != this.selectedTemplate) return;
    e.preventDefault();

    let res = await this.commonUi.showConfirm({
      title: this.translator.instant('確認'),
      body: this.translator.instant('分類の修正/確認をしますか？'),
      buttons: ['Yes', 'No'],
    });

    if (res != 'Yes') return;

    this.templateResetRects.emit(item);
  }
}
