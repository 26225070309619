@if (state$ | async; as state) {
  @if (state.currentCompanyId) {
    <div class="mt-4">
      <div class="columns">
        <div class="column is-one-quarter grow-background">
          <app-master-group-list
            [masterAndGroups]="(state.groups$ | async) || []"
            [selectedGroup]="state.selectedGroup"
            [canCreateAndDeleteItem]="canCreateAndDeleteItem(state)"
            [loading]="state.updatingGroups"
            (newMasterClicked)="openMasterCreatingModal(null)"
            (newGroupClicked)="openGroupCreatingModal()"
            (groupSelected)="selectGroup($event)"
          >
          </app-master-group-list>
        </div>
        <div class="column is-two-quarter">
          @if (state.selectedGroup) {
            <app-master-group-detail [selectedGroup]="state.selectedGroup">
              <app-master-grouped-master-list
                [masters]="state.mastersInSelectedGroup"
                [latestMastersDict]="state.latestMasterDict"
                [accessibleMasterGroups]="state.accessibleMasterGroups"
                [loading]="state.updating"
                [canCreateAndDeleteItem]="canCreateAndDeleteItem(state)"
                (newMasterClicked)="openMasterCreatingModal(state.selectedGroup)"
                (editMasterClicked)="openMasterEditingModal($event)"
                (deleteMasterClicked)="deleteMaster(state.currentCompanyId, $event)"
                (openHistoryClicked)="openHistory($event)"
              >
              </app-master-grouped-master-list>
            </app-master-group-detail>
          }
        </div>
      </div>
    </div>
    <!-- modals -->
    @if (state.showingHistoriesMasterId) {
      @if (state.showHistories) {
        <app-master-history-container
          [selectedGroupId]="state.selectedGroup?.id!"
          [selectedMasterId]="state.showingHistoriesMasterId"
          [currentCompanyId]="state.currentCompanyId"
          (close)="closeHistory()"
          (revertCompleted)="revertCompleted()"
          [isConsole]="useAsAdmin"
        ></app-master-history-container>
      }
    }
    @if (state.currentEditingMasterId) {
      @if (state.showMasterEditingModal) {
        <app-edit-master-modal
          (close)="closeMasterEditingModal()"
          [companyId]="state.currentCompanyId"
          [groupId]="state.selectedGroup?.id!"
          [masterId]="state.currentEditingMasterId"
          (saved)="masterListUpdated()"
        ></app-edit-master-modal>
      }
    }
    @if (canCreateAndDeleteItem(state)) {
      @if (state.showMasterCreatingModal) {
        <app-add-master-modal
          (close)="closeMasterCreatingModal()"
          [companyId]="state.currentCompanyId"
          [groupId]="state.newMasterBelongingGroupId ?? null"
          (saved)="masterListUpdated()"
        >
        </app-add-master-modal>
      }
      @if (state.showGroupCreatingModal) {
        <app-add-master-group-modal
          (close)="closeGroupCreatingModal()"
          [companyId]="state.currentCompanyId"
          (saved)="groupListUpdated()"
        >
        </app-add-master-group-modal>
      }
    }
    <!-- /modals -->
  }
}
