<div class="base" (click)="close($event)">
  <div class="container">
    <button class="button" (click)="selected.emit('clova-general')">General</button>
    <button class="button" (click)="selected.emit('clova-rect')" translate>矩形選択</button>
    <button class="button" (click)="selected.emit('natural')">Natural</button>
    <button class="button" (click)="selected.emit('logical')">Logical</button>
    <button class="button" (click)="selected.emit('map')" translate>マスタ読み込み</button>
    <button class="button" (click)="selected.emit('regex')" translate>正規表現</button>
    <button class="button" (click)="selected.emit('table-extraction')" translate>
      テーブル抽出
    </button>
    <button class="button" (click)="selected.emit('refine')" translate>Refine</button>
  </div>
</div>
