<div class="base">
  <header>
    @if (authGroupName != undefined) {
      <h1 class="company-name">{{ authGroupName }} {{ '様' | translate }}</h1>
    }

    @if (authGroupName == undefined) {
      <h1 class="company-name">所属している会社がありません</h1>
    }
    <div style="flex-grow: 1"></div>
    <div>
      @if (enableAuthGroupSettings && !loading) {
        <div
          class="user-ope auth-button"
          tooltip="権限設定"
          tooltipPlacement="bottom"
          (click)="goAuthGroupSettings()"
        >
          <i class="fa-regular fa-users" style="color: white"></i>
        </div>
      }
    </div>
    <div
      class="user-ope"
      tooltip="ユーザーについて"
      tooltipPlacement="bottom"
      (click)="showAccountModalWithAuthGroup()"
    >
      <i class="fa-regular fa-user-shield"></i>
    </div>
  </header>
  <div class="gradient-line"></div>

  @if (loading) {
    <app-inner-progress></app-inner-progress>
  }

  @for (company of accessibleCompanies; track company) {
    @if (company.companyName != undefined && company.works != undefined) {
      <header class="pb-4">
        <h1 style="font-size: 20px; font-weight: bold; color: #333" translate>
          {{ company.companyName }}
        </h1>
        <div style="flex-grow: 1"></div>
        @if (company.masterEditable) {
          <div>
            @if (company.masterEditable) {
              <div
                class="user-ope smaller"
                tooltip="マスタ設定"
                tooltipPlacement="bottom"
                (click)="showMasterSettingsModalWithCompany(company)"
              >
                <i class="fa-regular fa-table-list"></i>
              </div>
            }
          </div>
        }
        @if (enableCompanyCostReference) {
          <div
            class="user-ope smaller"
            tooltip="会社について"
            tooltipPlacement="bottom"
            (click)="showAccountModalWithCompany(company)"
          >
            <i class="fa-regular fa-ellipsis"></i>
          </div>
        }
      </header>
    }
    @if (
      company.companyName != undefined && company.works != undefined && company.works.length == 0
    ) {
      <p>まだ仕事がありません</p>
    }
    @if (company.companyName == undefined) {
      <p style="padding: 24px">表示するデータがありません</p>
    }
    <div class="rows-parent">
      @for (work of company.works; track work) {
        <div class="row">
          <div class="row-content" (click)="open(work)" tooltip="作業画面に移動">
            <div class="row-title">
              <div class="icon">
                <i class="fa-regular fa-folder-open"></i>
                @if (false) {
                  <div #NOTIF class="notif">1</div>
                }
              </div>
              <div class="row-title-text">{{ work.name }}</div>
            </div>
            <div class="gap-row"></div>
            <div class="row-center">
              <p>{{ '作成日:' | translate }} {{ getDate(work.createdAt) }}</p>
              <p>{{ '更新日:' | translate }} {{ getDate(work.updatedAt) }}</p>
            </div>
            <div
              class="row-stat"
              style="pointer-events: all"
              [tooltip]="work.status != 'ready' ? '学習中...' : '利用可能'"
              tooltipPlacement="right"
            >
              @if (work.status != 'ready') {
                <app-inner-progress class="training"></app-inner-progress>
              }
              @if (work.status == 'ready') {
                <app-inner-progress class="completed" status="completed"></app-inner-progress>
              }
            </div>
          </div>
        </div>
      }
    </div>
  }

  <div class="has-text-centered is-size-7 mt-6" style="opacity: 0.7">
    受発注バスターズ {{ version }} - by Batton Inc.
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7">
    {{ userId }}
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7; user-select: all">
    {{ userEmail }}
  </div>
</div>

<app-work-right-bottom-floating></app-work-right-bottom-floating>

@if (showAccountModal) {
  <app-main-account-modal
    [companyName]="selectedAccessibleCompany?.companyName"
    [companyId]="selectedAccessibleCompany?.companyId"
    [works]="selectedAccessibleCompany?.works"
    [showAccountBlock]="showAccountSettingsOnAccountModal"
    [showCompanyBlock]="showCompanyInfoOnAccountModal"
    (close)="showAccountModal = false"
    [modalTitle]="accountModalTitle"
  ></app-main-account-modal>
}

@if (selectedAccessibleCompany?.companyId) {
  @if (showMasterSettingsModal) {
    <app-main-master-settings-modal
      [companyName]="selectedAccessibleCompany?.companyName"
      [companyId]="selectedAccessibleCompany!.companyId"
      (close)="showMasterSettingsModal = false"
    >
    </app-main-master-settings-modal>
  }
}
