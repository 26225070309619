import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { TemplateInfo } from 'src/app/polar/entity/TemplateInfo';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-console-test-images-list',
  templateUrl: './console-test-images-list.component.html',
  styleUrls: ['./console-test-images-list.component.scss'],
})
export class ConsoleTestImagesListComponent {
  @Input() selectedTestImage: string = '';
  @Input() selectableImages: { url: string; path: string }[] = [];
  @Input() selectedTemplate?: TemplateInfo;
  @Input() loadingTemplate: undefined | 'loading' | 'failed' = undefined;

  @Output() deleteSelectedImage: EventEmitter<string> = new EventEmitter();
  @Output() selectImage: EventEmitter<{ url: string; path: string }> = new EventEmitter();
  @Output() pickFile: EventEmitter<void> = new EventEmitter();
  @Output() checkMatch: EventEmitter<string> = new EventEmitter();

  mouseOverPath?: string | null = null;
  templateOffsetX: number = 0;
  templateOffsetY: number = 0;

  constructor(
    private commonUi: CommonUiProviderService,
    private translator: TranslateService,
  ) {}

  async mouseOver(item: { url: string; path: string }) {
    if (this.selectedTestImage != item.path) {
      this.mouseOverPath = null;
      return;
    }
    this.mouseOverPath = item.url;
    this.templateOffsetX = 0;
    this.templateOffsetY = 0;
  }

  async mouseMove(item: { url: string; path: string }, e: MouseEvent) {
    if (!(e.target as HTMLElement).classList.contains('overable')) {
      return;
    }
    if (this.selectedTestImage != item.path) return;
    let centerWidth = (e.target as HTMLElement).clientWidth / 2;
    let centerHeight = (e.target as HTMLElement).clientHeight / 2;
    let x = 0;
    if (e.offsetX >= centerWidth) {
      x = ((e.offsetX - centerWidth) / centerWidth) * -1;
    } else {
      x = 1 - e.offsetX / centerWidth;
    }
    let y = 0;
    if (e.offsetY >= centerHeight) {
      y = ((e.offsetY - centerHeight) / centerHeight) * -1;
    } else {
      y = 1 - e.offsetY / centerHeight;
    }
    let offsetX = x * 100;
    let offsetY = y * 100;
    this.templateOffsetX = offsetX;
    this.templateOffsetY = offsetY;
  }

  async mouseLeave() {
    this.mouseOverPath = null;
  }

  async contextMenu(e: MouseEvent, item: { url: string; path: string }) {
    e.preventDefault();

    let res = await this.commonUi.showConfirm({
      title: this.translator.instant('確認'),
      body: this.translator.instant('分類の精度を確認しますか？'),
      buttons: ['Yes', 'No'],
    });

    if (res != 'Yes') return;

    this.checkMatch.emit(item.url);
  }
}
