<div class="base">
  <header>
    <button class="button back-button" (click)="back()">
      <i class="fa-regular fa-arrow-left"></i>
    </button>
    <div class="icon">
      @if (status == 'succeeded') {
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512 ">
          <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <defs>
            <style>
              .fa-secondary {
                opacity: 0.4;
              }
            </style>
          </defs>
          <g fill="var(--main-color)">
            <path
              class="fa-primary"
              d="M280 264C280 277.3 269.3 288 256 288C242.7 288 232 277.3 232 264V152C232 138.7 242.7 128 256 128C269.3 128 280 138.7 280 152V264zM224 352C224 334.3 238.3 320 256 320C273.7 320 288 334.3 288 352C288 369.7 273.7 384 256 384C238.3 384 224 369.7 224 352z"
            />
            <path
              class="fa-secondary"
              d="M222.1 18.41C240.8-.3309 271.2-.3309 289.9 18.41L335.5 64H400C426.5 64 448 85.49 448 112V176.5L493.6 222.1C512.3 240.8 512.3 271.2 493.6 289.9L448 335.5V400C448 426.5 426.5 448 400 448H335.5L289.9 493.6C271.2 512.3 240.8 512.3 222.1 493.6L176.5 448H112C85.49 448 64 426.5 64 400V335.5L18.41 289.9C-.3328 271.2-.3328 240.8 18.41 222.1L64 176.5V112C64 85.49 85.49 64 112 64H176.5L222.1 18.41zM232 152V264C232 277.3 242.7 288 256 288C269.3 288 280 277.3 280 264V152C280 138.7 269.3 128 256 128C242.7 128 232 138.7 232 152zM256 320C238.3 320 224 334.3 224 352C224 369.7 238.3 384 256 384C273.7 384 288 369.7 288 352C288 334.3 273.7 320 256 320z"
            />
          </g>
        </svg>
      }
      @if (status == 'failed') {
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <defs>
            <style>
              .fa-secondary {
                opacity: 0.4;
              }
            </style>
          </defs>
          <g fill="var(--main-color)">
            <path
              class="fa-primary"
              d="M557.2 235.7C572.8 220.1 598.1 220.1 613.8 235.7L628.1 250.1C643.8 265.7 643.8 291 628.1 306.7L598.7 336.1L527.8 265.1L557.2 235.7zM576.1 358.7L446.9 487.9C442.8 492 437.6 494.9 432 496.3L371.9 511.4C366.4 512.7 360.7 511.1 356.7 507.2C352.7 503.2 351.1 497.4 352.5 491.1L367.5 431.8C368.9 426.2 371.8 421.1 375.9 416.1L505.1 287.8L576.1 358.7z"
            />
            <path
              class="fa-secondary"
              d="M.0003 128C.0003 92.65 28.65 64 64 64H512C547.3 64 576 92.65 576 128V192.6C560.8 194.6 546.2 201.4 534.6 213.1L353.3 394.3C345.1 402.5 339.3 412.8 336.5 424.1L330.5 448H64C28.65 448 0 419.3 0 384L.0003 128zM112 320H336C344.8 320 352 312.8 352 304C352 295.2 344.8 288 336 288H112C103.2 288 96 295.2 96 304C96 312.8 103.2 320 112 320zM112 192C103.2 192 96 199.2 96 208C96 216.8 103.2 224 112 224H432C440.8 224 448 216.8 448 208C448 199.2 440.8 192 432 192H112z"
            />
          </g>
        </svg>
      }
    </div>

    @if (status == 'failed') {
      <h1 style="font-size: 18px; font-weight: bold">手入力</h1>
    }
    @if (status == 'succeeded') {
      <h1 style="font-size: 18px; font-weight: bold">確認待ち</h1>
    }
    <div style="flex-grow: 1"></div>
    @if (!loading && isHorizontalView) {
      <div class="flip-button" tooltip="上下表示に切り替える" (click)="isHorizontalView = false">
        <i class="fa-regular fa-arrow-up-arrow-down"></i>
      </div>
    }
    @if (!loading && !isHorizontalView) {
      <div class="flip-button" tooltip="左右表示に切り替える" (click)="isHorizontalView = true">
        <i class="fa-regular fa-arrow-right-arrow-left"></i>
      </div>
    }
    @if (!loading) {
      <button
        class="button all-done"
        (click)="makeAllConfirmed()"
        [style.background]="isAllConfirmed() ? 'gray' : null"
      >
        {{ confirmTextAll() }}
      </button>
    }
  </header>

  @if (loading) {
    <div class="content-loading" style="position: absolute">
      <app-inner-progress></app-inner-progress>
      <p>読み込んでいます...</p>
    </div>
  }
  <div
    class="content"
    [ngClass]="isHorizontalView ? null : 'vertical'"
    [style.opacity]="loading ? 0 : null"
    [style.pointerEvents]="loading ? 'none' : null"
  >
    <div
      #SPREADSHEET_PARENT
      class="tile spreadsheet"
      [style.pointerEvents]="isSplitterMouseDown ? 'none' : null"
      [style.maxHeight.px]="
        !isHorizontalView && bottomContentHeight != undefined ? bottomContentHeight : undefined
      "
      [style.minHeight.px]="
        !isHorizontalView && bottomContentHeight != undefined ? bottomContentHeight : undefined
      "
      [style.zIndex]="reportErrorsSelectSpreadsheetCells ? 11 : undefined"
    >
      <div class="spreadsheet-body">
        <app-spreadsheet-v2
          #SPREADSHEET
          [highlightRows]="highlightRows"
          [highlightHeader]="requiredColumn"
          [data]="ssData"
          [columnWidthMemory]="currentColumnWidthStore"
          (changed)="onSpreadsheetChanged($event)"
          (cellChanged)="onSpreadsheetCellChanged($event)"
          (cellTextChanged)="onSpreadsheetCellTextChanged($event)"
          (columnWidthMemoryUpdated)="updateColumnWidthStore($event)"
          [fixedHeaders]="0"
          [enableCellSelectionChanged]="reportErrorsSelectSpreadsheetCells"
          (cellSelectionChanged)="onCellSelectionChangedForReporting($event)"
        ></app-spreadsheet-v2>
      </div>

      @if (reportErrorsSelectImageLocation) {
        <div class="report-modal">
          <div class="report-modal-content">
            <h2>サポートに報告 (2/3)</h2>
            <p>画像の中で問題に関係する部分を囲んでください</p>
            <p>{{ reportErrorsIsImageCropped }}</p>
            <div class="report-modal-footer">
              <button class="button" (click)="reportErrorsGoToSelectSpreadsheetCells()">
                戻る
              </button>
              <button
                class="button"
                (click)="reportErrorsGoToSelectWriteText()"
                [disabled]="reportErrorsImageCropped == ''"
              >
                次へ
              </button>
            </div>
          </div>
        </div>
      }
    </div>
    <div
      class="splitter"
      (mousedown)="splitterMouseDown($event)"
      [style.zIndex]="reportErrorsOverlay ? 11 : undefined"
    >
      <div
        class="knob"
        [style.height.px]="isHorizontalView && isSplitterMouseDown ? 42 : undefined"
        [style.width.px]="!isHorizontalView && isSplitterMouseDown ? 42 : undefined"
      ></div>
    </div>
    <div
      class="tile image"
      #IMAGE
      [style.maxWidth.px]="
        isHorizontalView && rightContentWidth != undefined ? rightContentWidth : undefined
      "
      [style.minWidth.px]="
        isHorizontalView && rightContentWidth != undefined ? rightContentWidth : undefined
      "
      [style.pointerEvents]="isSplitterMouseDown ? 'none' : null"
    >
      <div class="checker">
        <button
          class="button all-done"
          style="width: 190px; height: 30px; margin-left: 2px; margin-top: 2px"
          (click)="makeItConfirmed()"
          [style.background]="isConfirmedSelectedImage() ? 'gray' : null"
        >
          <i class="fa-solid fa-check" style="margin-right: 4px"></i>
          {{ confirmText() }}
        </button>
        <div style="flex-grow: 1"></div>

        <!-- MENU -->
        <div class="image-menu">
          <button
            class="button image-menu-button"
            [tooltip]="isMenuActive ? '閉じる' : 'メニュー'"
            [tooltipDynamic]="isMenuActive ? '閉じる' : 'メニュー'"
            tooltipPlacement="left"
            (click)="activateMenu()"
          >
            @if (!isMenuActive) {
              <i class="fa-regular fa-ellipsis"></i>
            }
            @if (isMenuActive) {
              <i class="fa-regular fa-xmark"></i>
            }
          </button>

          <!-- MENU CONTENT -->
          <div class="dropdown" [ngClass]="isMenuActive ? 'is-active' : undefined">
            <div
              class="dropdown-menu"
              id="dropdown-menu"
              role="menu"
              [style.left]="'auto'"
              [style.right]="'0'"
            >
              <div class="dropdown-content">
                <a class="dropdown-item" (click)="downloadCurrentFile()"
                  ><i class="fa-light fa-file-arrow-down"></i> 元ファイルを保存</a
                >
                <a class="dropdown-item" (click)="downloadCurrentImage()"
                  ><i class="fa-light fa-file-arrow-down"></i> 画像を保存</a
                >
                <a class="dropdown-item" (click)="deleteCurrentImage()"
                  ><i class="fa-light fa-trash" style="color: red"></i> 削除</a
                >
                <hr class="dropdown-divider" />
                <a class="dropdown-item" (click)="reportErrors()"
                  ><i class="fa-light fa-triangle-exclamation"></i> サポートに報告</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- MENU END -->
      </div>
      <app-image-viewer
        [image]="urls[selectedImageId]"
        [disableLoupe]="disableLoupe || reportErrorsSelectImageLocation || isMenuActive"
        [style.zIndex]="reportErrorsSelectImageLocation ? 11 : undefined"
        [drawRectangle]="reportErrorsSelectImageLocation"
        (imageCropped)="reportErrorsSetImageCropped($event)"
        [selectedDrawing]="reportErrorsImageCropped == '' ? '' : 'selected'"
        [overlayRects]="overlayRects"
      ></app-image-viewer>
      <div #IMAGE_LIST class="image-list" appHorizontalScrollEnabled>
        <div class="image-list-scrollable">
          <div style="width: 8px"></div>
          @for (image of datas; track image) {
            <div
              class="image-list-item"
              [ngClass]="selectedImageId == image.id ? 'selected' : null"
              (click)="setCurrentImage(image)"
            >
              @if (urls[image.id!] != undefined) {
                <img [src]="urls[image.id!]" />
              }
              @if (urls[image.id!] == undefined) {
                <img src="assets/logo.png" />
              }
              @if (isConfirmed(image)) {
                <div class="checked-image">
                  <div class="checked-image-icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </div>
                </div>
              }
            </div>
          }
          <div style="width: 8px"></div>
        </div>
      </div>

      @if (reportErrorsSelectSpreadsheetCells) {
        <div class="report-modal">
          <div class="report-modal-content">
            <h2>サポートに報告 (1/3)</h2>
            <p>スプレッドシートで問題のある個所を選択してください</p>
            <p class="detail">選択箇所: {{ reportErrorsBodySpreadsheetRange }}</p>
            <p class="detail" style="color: red">{{ reportErrorsCellSelectionError }}</p>
            <div class="report-modal-footer">
              <button class="button" (click)="reportErrorsCancel()">キャンセル</button>
              <button
                class="button"
                (click)="reportErrorsGoToSelectImage()"
                [disabled]="reportErrorsCellSelectionError != ''"
              >
                次へ
              </button>
            </div>
          </div>
        </div>
      }
    </div>
    @if (reportErrorsOverlay) {
      <div class="report-overlay"></div>
    }
    @if (reportErrorsWriteText) {
      <div class="report-modal">
        <div class="report-modal-content">
          <h2>サポートに報告 (3/3)</h2>
          <p>問題の内容を入力してください</p>
          <div class="report-modal-preview">
            <div class="report-modal-text">{{ reportErrorsBodySpreadsheetRangeText }}</div>
            @if (reportErrorsImageCropped != '') {
              <img [src]="reportErrorsImageCropped" />
            }
          </div>
          <textarea
            class="textarea"
            placeholder="問題の内容を入力してください"
            [(ngModel)]="reportErrorsBodyText"
          ></textarea>
          <div class="report-modal-footer">
            <button class="button" (click)="reportErrorsGoToSelectImage()">戻る</button>
            <button class="button" (click)="reportErrorsGoToFinalize()">完了</button>
          </div>
        </div>
      </div>
    }
  </div>
</div>

@if (reflectionOverlayVisible) {
  <div class="overlay">
    <div
      class="reflect_row"
      [tooltip]="'同じ行のデータを更新中...'"
      [style.top.px]="reflectionOverlayY"
      [style.left.px]="reflectionOverlayX"
    >
      <i class="fa-solid fa-spinner" style="margin-right: 2px"></i>更新中...
    </div>
  </div>
}

@if (dataLoading) {
  <div class="loading">
    <p>データをアップデートしています...</p>
    <app-inner-progress></app-inner-progress>
  </div>
}

@if (reportErrorsUploading) {
  <div class="loading">
    <p>報告をアップロードしています...</p>
    <app-inner-progress></app-inner-progress>
  </div>
}
