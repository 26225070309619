<div class="base">
  <div class="background" (click)="close.emit()"></div>

  <div class="content">
    <div class="header">
      <div class="close" (click)="close.emit()">
        <i class="fa-solid fa-times"></i>
      </div>
      <div class="title">変更履歴</div>
    </div>

    @if (master$ | async; as masters) {
      @if (histories$ | async; as histories) {
        @if (stagedHistories$ | async; as stagedHistories) {
          @if (masters[0]; as master) {
            <div class="body">
              <app-master-history
                [selectedMaster]="master"
                [selectedLatestMaster]="latestMaster"
                [histories]="histories"
                [stagedHistories]="stagedHistories"
                [isConsole]="isConsole"
                [currentCompanyId]="currentCompanyId"
                (revert)="revert(master, $event)"
                (close)="close.emit()"
              ></app-master-history>
            </div>
          }
        }
      }
    }
  </div>
</div>

@if (loading) {
  <div class="loading">
    <app-inner-progress></app-inner-progress>
  </div>
}
