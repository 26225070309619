<app-header *ngIf="isBusters" [showSignOut]="signedIn ?? true"></app-header>
<div class="app-container" *ngIf="signedIn === true">
  <router-outlet></router-outlet>
</div>

<app-login *ngIf="signedIn === false"></app-login>

<app-overlay-loading *ngIf="signedIn === undefined"></app-overlay-loading>

<app-common-ui-provider></app-common-ui-provider>
