import { InjectionToken } from '@angular/core';

import {
  MasterDocumentData,
  MasterGroupDocumentData,
  MasterHistoryDocumentData,
} from '../polar/entity/master';

export interface MasterUpdatingService {
  getCurrent(): void;

  getMaster(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Promise<[MasterGroupDocumentData, MasterDocumentData] | undefined>;

  updateMaster(
    companyId: string,
    masterGroupId: string,
    master: MasterHistoryDocumentData,
    csv?: UpdatingMasterCsv,
  ): Promise<string>;

  confirmMessageWhenUpdate(): string;

  confirmMessageWhenRevert(): string;

  applyToProduction(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    stagedId: string | undefined,
  ): Promise<void>;

  revertMaster(
    companyId: string,
    master: MasterDocumentData,
    history: MasterHistoryDocumentData,
  ): Promise<void>;

  deleteMasterHistory(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    stagedId: string,
  ): Promise<void>;
}

export type UpdatingMasterCsv = {
  csvFile: File;
  csvHeader: string;
};

export const MASTER_UPDATING_SERVICE = new InjectionToken<MasterUpdatingService>(
  'MasterUpdatingService',
);
