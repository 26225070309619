import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

interface ImageRect {
  x: number;
  y: number;
  width: number;
  height: number;
  field: any;
}

@Component({
  selector: 'app-console-zoom-image',
  templateUrl: './console-zoom-image.component.html',
  styleUrls: ['./console-zoom-image.component.scss'],
  standalone: true,
})
export class ConsoleZoomImageComponent {
  rects: ImageRect[] = [];
  selectedRects: ImageRect[] = [];

  _rawData: any;
  @Input() set rawData(val: any) {
    this._rawData = val;
    this.update();
  }
  get rawData(): any {
    return this._rawData;
  }
  _image?: File;
  @Input() set image(val: File | undefined) {
    this._image = val;
    this.renderImage();
  }
  get image(): File | undefined {
    return this._image;
  }
  @Input() imageUrl2: string = '';
  imageUrl: string = '';

  @Output() selectRectsComplete: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() offsetX: number = 0;
  @Input() offsetY: number = 0;

  @ViewChild('IMAGE')
  el_image?: ElementRef;

  constructor() {}

  resized: () => void = () => {
    this.update();
  };

  ngOnInit(): void {
    window.addEventListener('resize', this.resized);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resized);
  }

  renderImage() {
    this.imageUrl = '';
    if (this.image == null) return;
    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };

    reader.readAsDataURL(this.image!);
  }

  rectClick(item: ImageRect) {
    if (this.selectedRects.includes(item)) {
      let idx = this.selectedRects.findIndex((f) => f == item);
      this.selectedRects.splice(idx, 1);
      return;
    }
    this.selectedRects.push(item);
  }

  update() {
    if (this.el_image == null || this.el_image.nativeElement == null) {
      setTimeout(() => {
        this.update();
      }, 1000);
      return;
    }
    let elm = this.el_image!.nativeElement as HTMLImageElement;
    let width = elm.clientWidth;
    let height = elm.clientHeight;

    let imageWidth = this.rawData.images[0].convertedImageInfo.width;
    let imageHeight = this.rawData.images[0].convertedImageInfo.height;

    let perWidth = imageWidth / width;
    let perHeight = imageHeight / height;

    this.rects = [];
    this.rawData.images[0].fields.forEach((field: any) => {
      let vert = field.boundingPoly.vertices; // 4 points with x and y
      let rect = {
        x: vert[0].x,
        y: vert[0].y,
        width: vert[2].x - vert[0].x,
        height: vert[2].y - vert[0].y,
        field: field,
      };

      // convert to elm size
      rect.x = rect.x / perWidth;
      rect.y = rect.y / perHeight;
      rect.width = rect.width / perWidth;
      rect.height = rect.height / perHeight;

      this.rects.push(rect);
    });
  }

  done() {
    if (this.selectedRects.length <= 2) {
      alert('3つ以上選択してください');
      return;
    }
    this.selectRectsComplete.emit(this.selectedRects.map((f) => f.field));
  }
}
