<div class="parent">
  @if (status == 'loading') {
    <div class="lds-roller" [ngClass]="color">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  }

  @if (status == 'completed') {
    <div class="completed" [ngClass]="completedCircle ? 'completed-circle' : null">
      <i class="fa-solid fa-check"></i>
    </div>
  }

  @if (status == 'failed') {
    <div class="failed">
      <i class="fa-solid fa-xmark"></i>
    </div>
  }
</div>
