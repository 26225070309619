<app-console-header
  label="Configuration - マスタ設定"
  [combos]="true"
  (companySelect)="setCompanyId($event)"
  [onlyCompany]="true"
></app-console-header>

<div class="parent">
  @if (currentCompanyId) {
    <app-master-settings-main [useAsAdmin]="true" [companyId]="currentCompanyId">
    </app-master-settings-main>
  }
</div>
