<div style="display: flex; flex-direction: row; align-items: center">
  <div class="title" style="width: auto; margin-right: 24px" translate>
    ワークフロー
  </div>
  <button (click)="save()" class="save">
    <p translate>保存</p>
  </button>
  <button (click)="refresh()" class="save" style="margin-left: 8px">
    <p translate>更新</p>
  </button>
  <p
    *ngIf="estimatedCost != undefined && lang == 'ja'"
    class="card-price"
    (click)="showEstimatedCostDetail()"
  >
    {{ estimatedCost.toLocaleString() }}円
  </p>
  <p
    *ngIf="estimatedCost != undefined && lang == 'en'"
    class="card-price"
    (click)="showEstimatedCostDetail()"
  >
    ${{ estimatedCost.toLocaleString() }}
  </p>
  <app-inner-progress
    *ngIf="saving"
    [status]="savingStat"
    style="margin-left: 12px"
  ></app-inner-progress>
</div>

<div class="block scrollbar">
  <div class="workflow-space"></div>
  <div *ngFor="let item of procArrEditing; let itemIndex = index">
    <div class="workflow-row" *ngIf="item.provider == 'clova-general'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #2c995d">
          GENERAL
        </div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete" (click)="deleteWorkflow(item.id)">
        <button class="delete is-medium"></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          label="ID"
          placeholder="Id"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>

        <app-label-and-combo-box
          [useFieldLayout]="true"
          style="margin-top: 4px"
          [items]="mapGeneralVersions"
          [label]="'バージョン' | translate"
          [(selectedItem)]="toClovaGeneral(item).version!"
        >
        </app-label-and-combo-box>

        <div class="field is-horizontal mb-4">
          <div class="field-label is-normal">
            <label class="label" translate>テーブル認識</label>
          </div>

          <div class="field-body">
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="toClovaGeneral(item).tableRecognition"
                  />
                </label>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal mb-4">
          <div class="field-label is-normal">
            <label class="label" translate>点線を線として認識</label>
          </div>

          <div class="field-body">
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="toClovaGeneral(item).convertDashToSolid"
                  />
                </label>
              </p>
            </div>
          </div>
        </div>

        <div class="cap" translate>
          *.text, *.row というデータが抽出されます。
        </div>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div class="workflow-row" *ngIf="item.provider == 'table-extraction'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #69c2b5">TABLE</div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <div
          class="notification has-text-weight-light mb-2 is-size-7"
          translate
        >
          <div
            *ngIf="toTableExtraction(item).strategy === 'extract' || toTableExtraction(item).strategy == 'extract-multiple'">
            <strong>Step01：</strong
            >画像の中のテーブルからテーブルを抽出します。下記入力欄の<br />
            <strong>・「開始カラム」</strong
            >に、アップロードした画像の中の取得したいテーブルのヘッダーカラムの一番左端の値を入力。<br />
            <strong>・「終了カラム」</strong
            >にヘッダーカラムの一番右の値を入力。<br />
            上記を行ったらワークフローを更新してください。
            <img
              class="mt-2"
              src="assets/console/workflow/table/table-step01.jpg"
              alt="sample"
            />
          </div>
          <span *ngIf="toTableExtraction(item).strategy === 'pretty'">
            <strong>Step02：</strong
            >カラムヘッダーに空欄がある場合は、空欄も含めてください
            <strong>・「対象ヘッダ」</strong
            >に、Step01で出力したカラムヘッダーを入力。<br />
            <strong>・「キー設定」</strong
            >に、Tableで取得したい1列のカラムヘッダーを入力。<br />
            上記を行ったらワークフローを更新してください。
            <img
              class="mt-2"
              src="assets/console/workflow/table/table-step02.jpg"
              alt="sample"
            />
          </span>
        </div>

        <app-label-and-text-input
          label="ID"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'strategy'"
          [items]="mapTableExtractStrategies"
          [(selectedItem)]="toTableExtraction(item).strategy"
        ></app-label-and-combo-box>
        <app-label-and-text-input
          *ngIf="
            toTableExtraction(item).strategy == undefined ||
            toTableExtraction(item).strategy == 'extract' ||
            toTableExtraction(item).strategy == 'extract-multiple'
          "
          style="margin-top: 4px"
          [label]="'開始カラム名' | translate"
          [placeholder]="'ヘッダ名' | translate"
          [(value)]="toTableExtraction(item).startRowFinderText!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          *ngIf="
            toTableExtraction(item).strategy == undefined ||
            toTableExtraction(item).strategy == 'extract' ||
            toTableExtraction(item).strategy == 'extract-multiple'
          "
          style="margin-top: 4px"
          [label]="'終了カラム名' | translate"
          [placeholder]="'最後まで読む場合は空欄' | translate"
          [(value)]="toTableExtraction(item).endRowFinderText!"
        ></app-label-and-text-input>

        <app-label-and-text-input
          *ngIf="toTableExtraction(item).strategy == 'pretty'"
          class="mt-1"
          [label]="'対象ヘッダ' | translate"
          [placeholder]="'商品名,数量,...' | translate"
          [(value)]="toTableExtraction(item).expectedHeader!"
        ></app-label-and-text-input>

        <app-label-and-text-input
          *ngIf="toTableExtraction(item).strategy == 'pretty'"
          style="margin-top: 4px"
          [label]="'キー設定' | translate"
          [placeholder]="'商品名,数量,...' | translate"
          [(value)]="toTableExtraction(item).mapper!"
        ></app-label-and-text-input>
      </div>
      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div class="workflow-row" *ngIf="item.provider == 'clova-rect'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #80c269">RECT</div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          label="ID"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          style="margin-top: 4px"
          [items]="clovaDomains"
          [label]="'ドメイン' | translate"
          [(selectedItem)]="toClovaRect(item).domain!"
        >
        </app-label-and-combo-box>
        <app-label-and-text-input
          style="margin-top: 4px"
          [label]="'テンプレID' | translate"
          placeholder="13"
          [(value)]="toClovaRect(item).templateIds!"
        ></app-label-and-text-input>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          style="margin-top: 4px"
          [items]="mapClovaRectVersions"
          [label]="'バージョン' | translate"
          [(selectedItem)]="toClovaRect(item).version!"
        >
        </app-label-and-combo-box>
        <div class="cap" translate>
          *.text, *.row というデータが抽出されます。
        </div>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div
      class="workflow-row"
      *ngIf="item.provider == 'openai-gpt' || item.provider == 'openai-gpt3'"
    >
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #556fb5">
          NATURAL
        </div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          [label]="'ID'"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <!--
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'エンジン'"
          [items]="[{ name: 'gpt-turbo-instruct', id: 'gpt-turbo-instruct' }]"
          [selectedItem]="'gpt-turbo-instruct'"
        ></app-label-and-combo-box>
        -->
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'プロンプト' | translate"
          [items]="promptConfigs"
          [selectedItem]="toOpenaiGpt(item).base_prompt"
          (selectedItemChange)="setPrompt(toOpenaiGpt(item), $event)"
        ></app-label-and-combo-box>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'エンジン' | translate"
          [items]="naturalEngines"
          [selectedItem]="toOpenaiGpt(item).engine"
          (selectedItemChange)="setEngine(toOpenaiGpt(item), $event)"
          [notice]="isNaturalEngineDeprecated(toOpenaiGpt(item))"
        ></app-label-and-combo-box>
        <app-label-and-text-input
          *ngFor="let argKey of toList(toOpenaiGpt(item).args)"
          [label]="argKey"
          [(value)]="toOpenaiGpt(item).args[argKey]"
          style="margin-top: 8px"
          [multiline]="true"
        ></app-label-and-text-input>
        <div
          class="cap"
          *ngIf="
            item != null &&
            toOpenaiGpt(item) != null &&
            toOpenaiGpt(item).base_prompt != null &&
            getPromptConfig(toOpenaiGpt(item).base_prompt) != null
          "
        >
          {{ getPromptConfig(toOpenaiGpt(item).base_prompt)!.description }}
        </div>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div class="workflow-row" *ngIf="item.provider == 'logical'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #6a55b5">
          LOGICAL
        </div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          [label]="'ID'"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'エンジン'"
          [items]="logicalEngines"
          [selectedItem]="getLogicalSelectedEngine(toLogical(item))"
          (selectedItemChange)="setEngineLogical(toLogical(item), $event)"
        ></app-label-and-combo-box>
        <app-label-and-text-input
          [validateFunction]="validateLogicalInput"
          [label]="'入力' | translate"
          [placeholder]="'{table.入力データ_*}' | translate"
          style="margin-top: 8px"
          [(value)]="toLogical(item).input!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          [label]="'プロンプト' | translate"
          [placeholder]="'カンマや空白を削除して整数に変換する' | translate"
          [(value)]="toLogical(item).prompt"
          style="margin-top: 8px"
          [multiline]="true"
        ></app-label-and-text-input>
        <div class="field is-horizontal" style="margin-top: 8px">
          <div class="field-label is-normal">
            <label class="label" translate>記号を半角</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="toLogical(item).unifySymbols"
                  />
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" translate>数字を半角</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="toLogical(item).unifyNumbers"
                  />
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" translate>英字を半角</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="toLogical(item).unifyAlphas"
                  />
                </label>
              </p>
            </div>
          </div>
        </div>
        <app-label-and-text-input
          [label]="'テスト文字' | translate"
          placeholder="1,20 0"
          [(value)]="toLogical(item).testText"
          style="margin-top: 8px"
          [multiline]="false"
        ></app-label-and-text-input>
        <app-label-and-button
          [label]="'テスト' | translate"
          [buttonText]="'試す' | translate"
          style="margin-top: 8px"
          [multiline]="true"
          (buttonClick)="tryLogical(toLogical(item))"
        ></app-label-and-button>
        <p style="text-align: right">
          {{ "結果" | translate }}:{{ toLogical(item).testResult }}
        </p>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div class="workflow-row" *ngIf="item.provider == 'map'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #b57a55">MAP</div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          [label]="'ID'"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          [label]="'入力' | translate"
          [placeholder]="'入力' | translate"
          [(value)]="toMap(item).map_from"
        ></app-label-and-text-input>
        <app-label-and-text-input
          [label]="'CSV from'"
          placeholder="CSV from"
          [(value)]="toMap(item).csv_column_from"
        ></app-label-and-text-input>
        <app-label-and-text-input
          [label]="'CSV to'"
          placeholder="CSV to"
          [(value)]="toMap(item).csv_column_to"
        ></app-label-and-text-input>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'strategy'"
          [items]="mapStrategies"
          [(selectedItem)]="toMap(item).strategy"
        ></app-label-and-combo-box>
        <app-label-and-text-input
          [label]="'ノーマライズ' | translate"
          [placeholder]="'0か1' | translate"
          [(value)]="toMap(item).normalize"
        ></app-label-and-text-input>

        <ng-container *ngIf="toMap(item).csv_file_name; else newMasterFileForm">
          <app-label-and-file-input
            [label]="'CSV'"
            placeholder=""
            [(value)]="toMap(item).csv_file_name"
            [multiline]="true"
            [companyId]="companyId"
            [workId]="workId"
            (fileChange)="fileChanged(toMap(item))"
          ></app-label-and-file-input>
          <app-label-and-button
            [label]="'マスタ方式'"
            [buttonText]="'アップデート'"
            (buttonClick)="upgradeMasterManagementStrategy(toMap(item))"
          ></app-label-and-button>
        </ng-container>

        <ng-template #newMasterFileForm>
          <ng-container *ngIf="state$ | async as state">
            <app-workflow-map-select
              [companyMasters]="state.groupMastersList"
              [(selectedMaster)]="toMap(item).master"
              (selectedMasterChange)="fileChanged(toMap(item))"
            >
            </app-workflow-map-select>
          </ng-container>
        </ng-template>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div class="workflow-row" *ngIf="item.provider == 'regex'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #a7b555">Regex</div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          [label]="'ID'"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          [label]="'入力' | translate"
          [placeholder]="'入力' | translate"
          [(value)]="toRegex(item).input"
        ></app-label-and-text-input>
        <app-label-and-text-input
          [label]="'正規表現' | translate"
          placeholder="regex"
          [(value)]="toRegex(item).regex"
        ></app-label-and-text-input>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>

    <div class="workflow-row" *ngIf="item.provider == 'refine'">
      <div class="workflow-row-header">
        <div class="workflow-row-title" style="background: #70c7cf">REFINE</div>
        <div class="workflow-row-label">
          <i
            *ngIf="lastErrId == item.id"
            class="fa-solid fa-xmark failed-mark"
          ></i>
        </div>
      </div>
      <div class="workflow-row-delete">
        <button
          class="delete is-medium"
          (click)="deleteWorkflow(item.id)"
        ></button>
      </div>
      <div class="workflow-row-body">
        <app-label-and-text-input
          [label]="'ID'"
          placeholder="ID"
          [(value)]="item.id!"
        ></app-label-and-text-input>
        <app-label-and-text-input
          class="label-memo"
          [label]="'メモ'"
          placeholder="memo"
          [(value)]="item.label!"
        ></app-label-and-text-input>
        <app-label-and-combo-box
          [useFieldLayout]="true"
          [label]="'strategy'"
          [items]="mapRefineStrategies"
          [(selectedItem)]="toRefine(item).strategy"
        ></app-label-and-combo-box>
        <app-label-and-text-input
          [label]="'対象' | translate"
          [placeholder]="'対象' | translate"
          [(value)]="toRefine(item).target"
          style="margin-top: 6px"
        ></app-label-and-text-input>
        <div class="field is-horizontal" style="margin-top: 8px">
          <div class="field-label is-normal">
            <label class="label" translate>対象を置換</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="toRefine(item).overrideTarget"
                  />
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>

      <app-workflow-editor-control-header
        [itemIndex]="itemIndex"
        [itemLength]="procArrEditing.length"
        (insertWorkflow)="insertWorkflow(itemIndex)"
      ></app-workflow-editor-control-header>
    </div>
  </div>

  <div class="workflow-new-row">
    <button (click)="addWorkflow()">
      <i class="fa-thin fa-layer-plus"></i>
      <p translate>ワークフローを追加</p>
    </button>
  </div>
  <div class="workflow-space"></div>
  <!-- tabular data -->
  <div
    style="
      width: 100%;
      padding: 12px;
      background: rgba(0, 0, 0, 0.02);
      border-radius: 8px;
    "
  >
    <table class="table" style="width: 100%; background: transparent">
      <thead>
      <tr>
        <th><abbr title="キー" translate>キー</abbr></th>
        <th><abbr title="値" translate>値</abbr></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let key of getKeys()">
        <th style="user-select: all">{{ key }}</th>
        <td (click)="previewText(getValue(key))">
          <div
            style="
                max-height: 90px;
                overflow: auto;
                max-width: 280px;
                white-space: pre;
              "
          >
            {{ getValue(key) }}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="workflow-space"></div>
</div>

<div *ngIf="!workflowSelecting"></div>
<app-workflow-select-modal
  *ngIf="workflowSelecting"
  (closed)="workflowSelecting = false; workflowInsertTo = undefined"
  (selected)="workflowAdded($event)"
></app-workflow-select-modal>

<ng-container *ngIf="state$ | async as state">
  <ng-container *ngIf="state.currentCompanyId">
    <ng-container *ngIf="state.currentEditingMasterId">
      <app-edit-master-modal
        *ngIf="state.showMasterEditingModal"
        (close)="closeMasterEditingModal()"
        [companyId]="state.currentCompanyId"
        [groupId]="state.currentEditingMasterGroupId!"
        [masterId]="state.currentEditingMasterId"
        (saved)="masterListUpdated()"
      ></app-edit-master-modal>
    </ng-container>

    <app-add-master-modal
      *ngIf="state.showMasterCreatingModal"
      (close)="closeMasterCreatingModal()"
      [companyId]="state.currentCompanyId"
      [groupId]="state.newMasterBelongingGroupId ?? null"
      (saved)="masterListUpdated()"
    >
    </app-add-master-modal>
  </ng-container>
</ng-container>

<div class="loading" *ngIf="loading">
  <p translate>学習しています...</p>
  <app-inner-progress></app-inner-progress>
</div>
