import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import tippy, { Placement } from 'tippy.js';

@Directive({
  selector: '[tooltip]',
  standalone: true,
})
export class TooltipDirective implements AfterViewInit {
  @Input() tooltip: string = 'Undefined';
  @Input() tooltipPlacement?: Placement = undefined;

  @Input() set tooltipDynamic(value: string) {
    if (value != undefined && value != '') {
      this.tippyInstance?.setContent(value);
    }
  }

  tippyInstance?: any;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.tippyInstance = tippy(this.el.nativeElement, {
      content: this.tooltip,
      arrow: true,
      placement: this.tooltipPlacement,
    });
  }
}
