import { Component, EventEmitter, Input, Output } from '@angular/core';

import { InnerProgressComponent } from '../common/inner-progress/inner-progress.component';

@Component({
  selector: 'app-main-billing-modal',
  templateUrl: './main-billing-modal.component.html',
  styleUrls: ['./main-billing-modal.component.scss'],
  standalone: true,
  imports: [InnerProgressComponent],
})
export class MainBillingModalComponent {
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() table: any[] = [];
  @Input() total: number = 0;
  @Input() yearMonth: string = '';
  loading: boolean = false;

  closeEvent() {
    this.close.emit();
  }
}
