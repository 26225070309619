import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-label-and-combo-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './label-and-combo-box.component.html',
  styleUrls: ['./label-and-combo-box.component.scss'],
})
export class LabelAndComboBoxComponent {
  _isActive: boolean = false;
  @Input() selectedItem: string = '';
  @Input() items: { id?: string; name?: string }[] = [];
  @Input() showNew: boolean = false;
  @Input() useRight: boolean = false;
  @Input() useFieldLayout: boolean = false;
  @Input() label: string = 'Undefined';
  @Input() notice: boolean = false;
  @Input() maxWidth?: string = undefined;
  @Input() scrollable: boolean = false;
  @Input() disableEdit: boolean = false;

  @Output() selectedItemChange: EventEmitter<string> = new EventEmitter<string>();

  isUp: boolean = false;

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(val: boolean) {
    if (val) this.updateLocationFlag();
    this._isActive = val;
  }

  constructor(private el: ElementRef) {}

  selectItem(item: string) {
    this.selectedItemChange.emit(item);
    this.isActive = false;
  }

  getName(id: string) {
    return this.items.find((item) => item.id === id)?.name;
  }

  updateLocationFlag() {
    if (this.el == undefined || this.el.nativeElement == undefined) return;

    const elm = this.el.nativeElement as HTMLElement;
    const button = elm.querySelector('button');
    const bdx = button?.getBoundingClientRect();

    if (bdx == undefined) return;

    if (this.scrollable) {
      if (bdx.top > window.innerHeight - 220) {
        this.isUp = true;
      } else {
        this.isUp = false;
      }
    }
  }

  getClassName(): string {
    const classNames = [];

    if (this.isActive) {
      classNames.push('is-active');
    }

    if (this.isUp) {
      classNames.push('is-up');
    }

    if (this.disableEdit) {
      classNames.push('disable-edit');
    }

    return classNames.join(' ');
  }
}
