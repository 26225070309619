import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { LabelAndTextInputComponent } from '../../common/label-and-text-input/label-and-text-input.component';
import { CsvErrorDetailsComponent } from '../csv-error-details/csv-error-details.component';
import { CsvData, CsvInputComponent } from '../csv-input/csv-input.component';
import { MasterGroupBreadcrumbsComponent } from '../master-group-breadcrumbs.component';

import { CommonUiProviderService } from '../../common/common-ui-provider.service';
import { MASTER_UPDATING_SERVICE } from '../master-updating.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import {
  MasterDocumentData,
  MasterGroupDocumentData,
  MasterHistoryDocumentData,
} from '../../polar/entity/master';
import { DownloadMasterCsvDirective } from '../download-master-csv-directive';
import {
  CsvValidatedMessage,
  CsvValidatedResult,
  MAX_MASTER_NAME_LENGTH,
  createCsvValidatedMessage,
  validateCsv,
  validateInputLength,
} from '../utils';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-master-modal',
  standalone: true,
  imports: [
    InnerProgressComponent,
    CsvInputComponent,
    DownloadMasterCsvDirective,
    LabelAndTextInputComponent,
    MasterGroupBreadcrumbsComponent,
    TranslateModule,
    CsvErrorDetailsComponent,
  ],
  templateUrl: './edit-master-modal.component.html',
  styleUrls: ['./edit-master-modal.component.scss'],
})
export class EditMasterModalComponent implements OnInit {
  private readonly masterUpdatingService = inject(MASTER_UPDATING_SERVICE);
  private readonly commonUi = inject(CommonUiProviderService);

  @Input() companyId!: string;
  @Input() groupId!: string;
  @Input() masterId!: string;

  @Output() saved: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  loading: boolean = false;

  originalName: string = '';
  originalMaster!: MasterDocumentData;
  currentMaster!: MasterHistoryDocumentData;
  currentGroup!: MasterGroupDocumentData;
  currentCsvData: CsvData | null = null;

  csvErrorMessages?: CsvValidatedMessage[];
  openModal: boolean = false;

  constructor(private polar: PolarFirebaseService) {}

  async ngOnInit() {
    const result = await this.masterUpdatingService.getMaster(
      this.companyId,
      this.groupId,
      this.masterId,
    );

    if (result == undefined) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '修正対象のマスタが取得できませんでした。やり直してください。',
        buttons: ['OK'],
      });

      this.close.emit();
      return;
    }

    this.currentGroup = { ...result[0] };
    this.currentMaster = await this.polar.getLatestMaster(
      this.companyId,
      this.groupId,
      this.masterId,
      false,
    );
    this.originalMaster = result[1];
    this.originalName = this.currentMaster.name;
  }

  csvUploaded(csvData: CsvData) {
    this.csvErrorMessages = undefined;
    this.currentCsvData = csvData;
  }

  async save() {
    if (!(await this.validate())) {
      return;
    }

    const res = await this.commonUi.showConfirm({
      title: '確認',
      body: this.masterUpdatingService.confirmMessageWhenUpdate(),
      buttons: ['OK', 'キャンセル'],
    });

    if (res != 'OK') {
      this.close.emit();
      return;
    }

    try {
      this.loading = true;

      let stagedId: string | undefined = undefined;

      if (this.currentCsvData == null) {
        stagedId = await this.masterUpdatingService.updateMaster(
          this.companyId,
          this.currentGroup.id!,
          this.currentMaster,
        );
      } else {
        const csvValidatedResult = validateCsv(this.currentCsvData.encodedString!);
        if (!(await this.validateUploadingCsv(csvValidatedResult))) {
          return;
        }

        stagedId = await this.masterUpdatingService.updateMaster(
          this.companyId,
          this.currentGroup.id!,
          this.currentMaster,
          {
            csvFile: this.currentCsvData.encodedFile!,
            csvHeader: csvValidatedResult.header,
          },
        );
      }

      try {
        await this.masterUpdatingService.applyToProduction(
          this.companyId,
          this.currentGroup.id!,
          this.currentMaster.masterId!,
          stagedId,
        );
      } catch (e) {
        // fail to apply
        // delete master to rollback
        await this.masterUpdatingService.deleteMasterHistory(
          this.companyId,
          this.currentGroup.id!,
          this.currentMaster.masterId!,
          stagedId,
        );
        throw e;
      }
    } catch (e) {
      console.dir(e);
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '処理中にエラーが発生しました。',
        buttons: ['OK'],
      });

      return;
    } finally {
      this.loading = false;
    }

    this.saved.emit();
  }

  private async validate() {
    if (!validateInputLength(this.currentMaster.name, 1, MAX_MASTER_NAME_LENGTH)) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: `マスタ名は 1 から ${MAX_MASTER_NAME_LENGTH} 文字以内で入力してください`,
        buttons: ['OK'],
      });

      return false;
    }

    if (this.currentMaster.name == this.originalMaster.name) {
      if (this.currentCsvData == null && this.currentMaster.csv == this.originalMaster.csv) {
        await this.commonUi.showConfirm({
          title: 'エラー',
          body: '変更されていません。',
          buttons: ['OK'],
        });

        return false;
      }
    }

    return true;
  }

  private async validateUploadingCsv(uploadingResult: CsvValidatedResult) {
    const invalidRows = uploadingResult.bodyRows.filter((row) => row.validatedResult != 'isValid');

    if (this.currentMaster.csv.header != uploadingResult.header) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'CSV のヘッダが修正前と異なっています。修正して再度選択してください。',
        buttons: ['OK'],
      });

      return false;
    }

    if (invalidRows.length > 0) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'CSV に不正なデータが含まれています。修正して再度選択してください。',
        buttons: ['OK'],
      });

      this.csvErrorMessages = createCsvValidatedMessage(uploadingResult);

      return false;
    }

    return true;
  }
}
