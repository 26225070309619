<div class="base">
  <div class="background" (click)="closeEvent()"></div>
  <div class="content">
    <div class="header">
      <div class="title">ファイル一覧</div>
      <div class="close" (click)="closeEvent()">
        <i class="fa-solid fa-times"></i>
      </div>
    </div>
    <div class="body">
      <table>
        <thead>
          <tr>
            <th scope="col">ファイル名</th>
            <th scope="col">アップロード日</th>
            <th scope="col">更新日</th>
            <th scope="col">ステータス</th>
            <th scope="col">削除</th>
            <th scope="col">修正</th>
          </tr>
        </thead>
        <tbody>
          @for (image of images; track image) {
            <tr>
              <td scope="row" (click)="save(image)">
                <p class="image-name">
                  {{ image.name }}
                </p>
              </td>
              <td>{{ getDate(image.createdAt) }}</td>
              <td>{{ getDate(image.updatedAt) }}</td>
              <td class="stat">
                @if (image.status != 'confirmed') {
                  <app-inner-progress [status]="getStatus(image)"></app-inner-progress>
                }
                @if (image.status == 'confirmed' && getStatus(image) != 'completed') {
                  <p>未ダウンロード</p>
                }
                @if (image.status == 'confirmed' && getStatus(image) == 'completed') {
                  <app-inner-progress [status]="'completed'"></app-inner-progress>
                }
              </td>
              <td>
                @if (getStatus(image) != 'completed') {
                  <button class="button deleter" (click)="deleteItem(image)">削除</button>
                }
              </td>
              @if (
                image.status == 'confirmed' ||
                image.status == 'succeeded' ||
                image.status == 'failed'
              ) {
                <td>
                  <button class="button editer" (click)="goBackStatus(image)">戻す</button>
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
