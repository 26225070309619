import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CollectionReference, DocumentData, Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { PolarFireswitchService } from './bridge/polar-fireswitch.service';
import { Observable } from 'rxjs';

export abstract class PolarFirebaseBaseService<T extends { id?: string }> {
  constructor(
    protected auth: AngularFireAuth,
    protected firestore: Firestore,
    protected storage: Storage,
    protected switcher: PolarFireswitchService,
    protected baseCollectionName: string,
  ) {}

  baseCollection(): CollectionReference<DocumentData> {
    return this.switcher.collection(this.firestore, this.baseCollectionName);
  }

  async get(id: string): Promise<T> {
    let col = this.baseCollection();
    let docRef = this.switcher.doc(col, id);
    let docData = (await this.switcher.getDoc(docRef)).data({}) as T;
    docData.id = id;
    return docData as T;
  }

  async list(): Promise<Observable<T[]>> {
    let col = this.baseCollection();
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<T[]>;
  }

  async set(val: T): Promise<string> {
    let col = this.baseCollection();
    if (val.id == undefined || val.id == '' || val.id == null) {
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
      return docRef.id;
    } else {
      let docRef = this.switcher.doc(col, val.id);
      await this.switcher.setDoc(docRef, val, { merge: true });
      return docRef.id;
    }
  }

  async delete(val: T) {
    let col = this.baseCollection();
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }
}
