import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { WorkListAuthGroupEnabledComponent } from '../work/work-list-auth-group-enabled/work-list-auth-group-enabled.component';
import { WorkListComponent } from '../work/work-list/work-list.component';

import { PolarFirebaseAuthService } from '../polar/polar-firebase-auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [WorkListComponent, WorkListAuthGroupEnabledComponent],
})
export class MainComponent {
  workListType: 'normal' | 'auth-group-enabled' | '' = '';

  constructor(
    private auth: AngularFireAuth,
    private polarAuthGroup: PolarFirebaseAuthService,
  ) {}

  async ngOnInit() {
    try {
      let userData = await this.auth.currentUser!;

      if (await this.polarAuthGroup.isAuthGroupEnabled()) {
        this.workListType = 'auth-group-enabled';
      } else {
        this.workListType = 'normal';
      }
    } catch {
      this.workListType = 'normal';
    }
  }
}
