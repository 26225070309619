import { Component, Input, OnInit } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { AuthGroupInfo, AuthorizedCompany } from 'src/app/polar/entity/AuthGroupInfo';
import { PolarFirebaseAuthService } from 'src/app/polar/polar-firebase-auth.service';
import { PolarFirebaseTeamAuthService } from 'src/app/polar/polar-firebase-team-auth.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

@Component({
  selector: 'app-console-auth-groups',
  templateUrl: './console-auth-groups.component.html',
  styleUrls: ['./console-auth-groups.component.scss'],
})
export class ConsoleAuthGroupsComponent {
  saving: boolean = false;
  savingStat: 'loading' | 'completed' | 'failed' = 'loading';
  authGroups: AuthGroupInfo[] = [];
  authGroupsObservable$?: Observable<AuthGroupInfo[]>;
  authorizedCompanies: { [index: string]: string } = {};
  createAuthGroupForm: FormGroup;
  fullLoading: boolean = false;

  constructor(
    private polar: PolarFirebaseService,
    private polarAuthGroup: PolarFirebaseAuthService,
    private polarAuthGroupTeam: PolarFirebaseTeamAuthService,
    private commonUi: CommonUiProviderService,
    private createAuthGroupFormBuilder: FormBuilder,
  ) {
    this.createAuthGroupForm = this.createAuthGroupFormBuilder.group({
      authGroupName: ['', [Validators.required]],
    });
  }

  getCompanyNameColumn(id?: string) {
    if (id == undefined) {
      return '';
    } else {
      return this.authorizedCompanies[id] ?? '';
    }
  }

  async ngOnInit() {
    const companies = await this.polar.getCompaniesArray();
    this.authGroupsObservable$ = await this.polarAuthGroup.list();
    this.authGroupsObservable$.subscribe((d) => {
      this.authGroups = d;
      d.forEach(async (authGroup) => {
        const array = await this.polarAuthGroup.listAuthorizedCompaniesArray(authGroup.id!);
        const companyNames = array.map((f) => companies.find((c) => c.id == f.id)!.name);
        this.authorizedCompanies[authGroup.id!] = companyNames.join(', ');
      });
    });
  }

  async createAuthGroup() {
    if (this.createAuthGroupForm.valid) {
      this.saving = true;
      this.savingStat = 'loading';
      try {
        const authGroupInfoData: AuthGroupInfo = {
          name: this.createAuthGroupForm.value['authGroupName'],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };
        const authGroupId = await this.polarAuthGroup.set(authGroupInfoData);
        await this.polarAuthGroup.addTeam(authGroupId, {
          id: 'all',
          name: '',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          authority: 'limited',
        });
        await this.polarAuthGroup.addTeam(authGroupId, {
          id: 'admin',
          name: '',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          authority: 'unlimited',
        });

        this.savingStat = 'completed';
      } catch (e) {
        this.savingStat = 'failed';
      }
    }
    this.createAuthGroupForm.reset();
  }

  async goAuthGroup(authGroup: AuthGroupInfo) {
    window.open(`/auth-group/${authGroup.id}`, '_blank');
  }

  async deleteAuthGroup(authGroup: AuthGroupInfo) {
    const deleteComfarmModal = await this.commonUi.showConfirm({
      title: '確認',
      body: `認証グループ「${authGroup.name}」を削除しますか？`,
      buttons: ['Yes', 'No'],
    });
    if (deleteComfarmModal != 'Yes') return;
    this.fullLoading = true;
    const result = await this.polarAuthGroupTeam.deleteAuthorizedGroup(authGroup.id!);
    this.fullLoading = false;
    this.commonUi.showConfirm({
      title: '完了',
      body:
        '認証グループを削除しました。\n\n影響のあったユーザー: ' +
        result.affectedUsers +
        '件\n' +
        '影響のあった会社: ' +
        result.affectedCompanies +
        '件',
      buttons: ['OK'],
    });
  }

  async updateName(authGroup: AuthGroupInfo) {
    const name = prompt('新しい名前を入力してください', authGroup.name);

    if (name == null) return;

    authGroup.name = name;
    authGroup.updatedAt = serverTimestamp();
    await this.polarAuthGroup.set(authGroup);
  }
}
