<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">{{ label }}</label>
  </div>
  <div class="field-body" [style.flexGrow]="grow">
    <div class="field">
      <div class="control">
        <div
          style="margin-top: 4px; font-weight: 100; font-size: 14px"
          *ngIf="script == ''"
        >
          未入力
        </div>
        <div
          style="margin-top: 4px; font-weight: 100; font-size: 14px"
          *ngIf="script != ''"
        >
          {{ prompt }}
        </div>

        <button class="button" (click)="updatePrompt()">変更する</button>
      </div>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <p>ファイルをアップロードしています...</p>
  <app-inner-progress></app-inner-progress>
</div>
