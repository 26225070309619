import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { serverTimestamp } from '@angular/fire/firestore';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { FirebaseAnalyticsService } from 'src/app/firebase-analytics/firebase-analytics.service';
import { UserImageInfo } from 'src/app/polar/entity/CompanyInfo';

@Component({
  selector: 'app-work-upload-file',
  templateUrl: './work-upload-file.component.html',
  styleUrls: ['./work-upload-file.component.scss'],
})
export class WorkUploadFileComponent {
  maxFiles: number = 100;

  dragging: boolean = false;
  files: File[] = [];
  urls: { [index: string]: SafeResourceUrl | undefined } = {};
  hiding: boolean = false;

  @Input() companyId: string = '';
  @Input() workId: string = '';
  @Output() hide: EventEmitter<void> = new EventEmitter();
  @Output() fileCount = new EventEmitter<number>();

  constructor(
    private commonUi: CommonUiProviderService,
    private polar: PolarFirebaseService,
    private auth: AngularFireAuth,
    private firebaseAnalytics: FirebaseAnalyticsService,
  ) {}

  dragover(e: DragEvent) {
    this.dragging = true;
    e.preventDefault();
  }

  dragleave() {
    this.dragging = false;
  }

  drop(e: DragEvent) {
    this.dragging = false;
    e.preventDefault();
    const files = e.dataTransfer!.files;

    if (this.files.length + files.length > this.maxFiles) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: `一度に${this.maxFiles}件以上アップロードすることはできません`,
        buttons: ['OK'],
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      // check if file is image or pdf
      if (file.type.startsWith('image/') || file.type === 'application/pdf') {
        this.url(file).then((f) => {
          this.urls[file.name + file.size] = f;
        });
        this.files.push(file);
      }
    }
  }

  remove(file: File) {
    const idx = this.files.findIndex((f) => f == file);
    this.files.splice(idx, 1);
  }

  async url(file: File): Promise<SafeResourceUrl | undefined> {
    return new Promise<SafeResourceUrl | undefined>((resolve) => {
      if (file.type === 'application/pdf') {
        resolve(undefined);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as SafeResourceUrl);
      };
      reader.readAsDataURL(file);
    });
  }

  async closeUploadWindow() {
    if (this.files.length > 0) {
      const result = await this.commonUi.showConfirm({
        title: '確認',
        body: '選択中のファイルはアップロードされません。よろしいですか？',
        buttons: ['OK', 'キャンセル'],
      });

      if (result != 'OK') {
        return;
      }
    }
    this.hiding = true;
    setTimeout(() => {
      this.hide.emit();
    }, 400);
  }

  async uploadWorks() {
    if (this.files.length === 0) {
      await this.commonUi.showConfirm({
        title: '確認',
        body: 'ファイルが選択されていません。',
        buttons: ['OK'],
      });
      return;
    }
    let userId = (await this.auth.currentUser)?.uid;
    this.uploadStatus = 'loading';
    this.percent = '0%';
    this.loadingFull = true;

    let totalFiles = this.files.length;

    try {
      let processed = 0;
      while (this.files.length > 0) {
        this.percent = Math.floor((processed / totalFiles) * 100) + '%';

        const userImageInfoStatus = "uploaded";

        // prepare
        const setUserDataImagePrepared = await this.polar.prepareAddUserDataImage(this.companyId, this.workId, userImageInfoStatus);
        const uploadUserFilePrepared = await this.polar.prepareUploadUserFile(this.companyId, this.workId, setUserDataImagePrepared.id);

        // update ref first
        await this.polar.createUploadFileReferencePrepared(uploadUserFilePrepared);

        // now upload the actual file
        await this.polar.uploadUserFilePrepared(uploadUserFilePrepared, this.files[0]);

        // add user data image
        await this.polar.setUserDataImagePrepared(setUserDataImagePrepared, {
          rawFilePath: uploadUserFilePrepared.path,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          name: this.files[0].name,
          isFailed: false,
          status: 'uploaded',
          uploadingStatus: 'uploaded',
          createdBy: userId,
        });

        // mark as completed
        this.files.splice(0, 1);
        processed += 1;
      }
      this.percent = '100%';
      this.uploadStatus = 'completed';

      this.fileCount.emit(totalFiles);

      // Tracking the event for click upload button
      this.firebaseAnalytics.trackEvent({
        name: 'uploaded-basic-file',
        category: 'button-click',
        label: 'uploaded-data',
        value: 1,
        interfaction: true,
        customValues: {
          fileCount: totalFiles,
        },
      });
    } catch {
      this.uploadStatus = 'failed';
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'アップロードに失敗したファイルがあります。',
        buttons: ['OK'],
      });
    }
    setTimeout(() => {
      this.loadingFull = false;
      this.uploadStatus = 'loading';
      this.closeUploadWindow();
    }, 1000);
  }

  uploadStatus: 'loading' | 'failed' | 'completed' = 'loading';
  loadingFull: boolean = false;
  percent: string = '';

  @ViewChild('INPUT')
  el?: ElementRef;

  pickFile() {
    this.el?.nativeElement.click();
  }

  async filePicked() {
    this.loadingFull = true;
    const elm = this.el!.nativeElement as HTMLInputElement;
    const files = elm.files;

    if (this.files.length + files!.length > this.maxFiles) {
      this.commonUi.showConfirm({
        title: 'エラー',
        body: `一度に${this.maxFiles}件以上アップロードすることはできません`,
        buttons: ['OK'],
      });
      this.loadingFull = false;
      return;
    }

    for (let i = 0; i < files!.length; i++) {
      const file = files![i];
      this.url(file).then((f) => {
        this.urls[file.name + file.size] = f;
      });
      this.files.push(file);
    }

    elm.value = '';
    this.loadingFull = false;
  }
}
