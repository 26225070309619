import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { GoogleAnalyticsService } from 'ngx-google-analytics';

type EventCategoryType = 'button-click' | 'time-track' | 'modal-track';
type EventNameType =
  // Here are 'button-click' category's events.
  | 'show-uploader'
  | 'uploaded-basic-file' //upload file
  | 'hide-uploaded-icon' //hide uploaded icon
  | 'hide-converted-icon' //hide uploaded icon
  | 'revert' //revert file from the work-file list
  | 'delete' //delete file in the work-file list
  | 'confirm-one' //confirm one file
  | 'confirm-all' //confirm all file
  | 'show-confirm-succeeded-component' //confirm succeeded file(確認待ち)
  | 'show-confirm-failed-component' //confirm failed file(手入力待ち)
  | 'confirm-succeeded' //confirm succeeded file(確認待ち確認完了)
  | 'confirm-faild' //confirm failed file(手入力完了)
  | 'confirm-all-succeeded' //confirm all succeeded file(確認待ち全て完了)
  | 'confirm-all-faild' //confirm all failed file(手入力全て完了)
  | 'download' //download file
  // These are 'modal-open' category's events
  | 'uploaded-list-modal' //show uploaded file list modal
  | 'converting-list-modal' // show converting file list modal
  | 'failed-list-modal' // show failed file list modal
  | 'succeeded-list-modal' // show succeeded file list modal
  | 'confirmed-list-modal' // show confirmed file list modal
  | 'delete-uploaded' //delete uploaded file
  | 'delete-converted' //delete converted file
  | 'delete-failed' //delete failed file
  | 'delete-succeeded' //delete succeeded file
  | 'delete-confirmed' //delete confirmed file
  | 'revert-converted' //revert converted file
  | 'revert-failed' //revert failed file
  | 'revert-succeeded' //revert succeeded file
  | 'revert-confirmed'; //revert confirmed file

/**
 * Tracked Event type
 * @param {EventNameType} name - event's name
 * @param {EventCategoryType} category - event's category
 * @param {string} label - event's label
 * @param {number} value - value
 * @param {boolean} interfaction - if user's interaction is exist or not
 * @param {object} customValues - user customized value to store with tracked event
 */
export interface EventInfo {
  name: EventNameType;
  category: EventCategoryType;
  label: string;
  value: number;
  interfaction: boolean;
  customValues?: object;
}

/**
 *  This is the service to track events in Firebase Analytics.
 */
@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  userId?: string = '';
  companyId?: string = '';

  constructor(
    private analyticsService: GoogleAnalyticsService,
    private auth: AngularFireAuth,
    private polar: PolarFirebaseService,
  ) {
    this.getIDData();
  }

  // This is the function to get userID and companyID
  async getIDData() {
    const userData = await this.auth.currentUser!;
    this.userId = userData?.uid;
    this.companyId = await this.polar.getUserCompany();
  }

  // This is the function to send the target event to Google Analytics server.
  async trackEvent(eventInfo: EventInfo) {
    const { name, category, label, interfaction, value, customValues } = eventInfo;
    const IDInfo: any = { companyId: this.companyId, userId: this.userId };
    const payloads: any = customValues ? { ...IDInfo, ...customValues } : IDInfo;

    //send request using the built-in GoogleAnalyticsService
    this.analyticsService.event(name, category, label, value, interfaction, payloads);
  }
}
