<div class="base" (click)="baseClick($event)">
  <div class="container" *ngIf="status == 'exists'">
    <p translate>テンプレートが見つかりました</p>
    <img [src]="imageUrl" />
    <button (click)="close.emit()" translate>閉じる</button>
    <button (click)="create.emit()" translate>新規追加</button>
    <div>{{ csv }}</div>
  </div>
  <div
    class="container"
    *ngIf="status == 'loading' || status == 'rotating' || status == 'matching'"
  >
    <p *ngIf="status == 'loading'" translate>認識中です...</p>
    <p *ngIf="status == 'matching'" translate>テンプレートを探しています...</p>
    <p *ngIf="status == 'rotating'" translate>回転しています...</p>
    <app-inner-progress status="loading"></app-inner-progress>
  </div>
  <div class="container" *ngIf="status == 'select-rect'">
    <div class="field is-horizontal" style="margin-top: 8px; width: 160px">
      <div class="field-label is-normal">
        <label class="label" translate>テキスト表示</label>
      </div>
      <div class="field-body" style="flex-grow: 0.1">
        <div class="field">
          <p class="control" style="margin: 4px">
            <label class="checkbox">
              <input
                style="transform: scale(1.3) translate(-2px, 2px)"
                type="checkbox"
                [(ngModel)]="showText"
              />
            </label>
          </p>
        </div>
      </div>
    </div>
    <p translate>３つ以上のマーカーを選択してください</p>
    <div style="position: relative">
      <img #IMAGE [src]="imageUrl" />
      <div
        class="rect"
        *ngFor="let rect of rects"
        [style.left.px]="rect.x"
        [style.top.px]="rect.y"
        [style.width.px]="rect.width"
        [style.height.px]="rect.height"
        [style.borderColor]="selectedRects.includes(rect) ? 'blue' : null"
        (click)="rectClick(rect)"
      ></div>
      <div *ngIf="showText">
        <div
          class="rect-field"
          *ngFor="let rect of rects"
          [style.left.px]="rect.x"
          [style.top.px]="rect.y"
          [style.width.px]="rect.width"
          (click)="preview(rect)"
        >
          {{ rect.title }}
        </div>
      </div>
    </div>
    <button (click)="done()" translate>決定</button>
  </div>
</div>
