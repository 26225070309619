export function ParseRows(lines: string[]) {
  let rows = [];

  for (let k = 0; k < lines.length; k++) {
    if (lines[k] == '') {
      continue;
    }
    if (lines[k].replace(/,/g, '') == '') continue;
    let line = lines[k];
    let cells = [];
    let inQuote = false;
    let currentCell = '';
    for (let j = 0; j < line.length; j++) {
      let char = line[j];
      if (char == '"') {
        inQuote = !inQuote;
      } else if (char == ',' && !inQuote) {
        cells.push(currentCell);
        currentCell = '';
      } else {
        currentCell += char;
      }
    }
    cells.push(currentCell);
    rows.push(cells);
  }

  return rows;
}
