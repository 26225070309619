import { Pipe, PipeTransform } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';

import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'dateLocalString', standalone: true })
export class DateLocalString implements PipeTransform {
  constructor(public translate: TranslateService) {}

  transform(timestamp: Timestamp | FieldValue | undefined | null, hasTime?: boolean): string {
    if (!timestamp) {
      return '';
    }

    let options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    if (hasTime) {
      options['hour'] = '2-digit';
      options['minute'] = '2-digit';
    }

    return (timestamp as Timestamp).toDate().toLocaleString(this.translate.currentLang, options);
  }
}
