<div class="console-header">
  <div class="console-top-left-text">{{ label }}</div>
  <div class="grow"></div>
  @if (combos) {
    <div class="header-combos">
      <!--COMPANY-->
      <app-label-and-combo-box
        [label]="'会社' | translate"
        [items]="companies"
        [selectedItem]="selectedCompany"
        (selectedItemChange)="selectCompany($event)"
        [useRight]="true"
      >
      </app-label-and-combo-box>
      <!--WORK-->
      @if (!onlyCompany) {
        <app-label-and-combo-box
          [label]="'仕事' | translate"
          [items]="works"
          [selectedItem]="selectedWork"
          (selectedItemChange)="selectWork($event)"
          [useRight]="true"
          style="margin-left: 16px"
        ></app-label-and-combo-box>
      }
      <!--
      <button class="go-work" *ngIf="targetUrl != ''" (click)="goUrl()">
        <i class="fa-solid fa-chevrons-right"></i>
      </button>
    --></div>
  }
</div>
