import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapWorkflow } from '../../../../polar/entity/Workflow';
import { GroupMasters } from './master-map-select.service';
import { MasterDocumentData } from '../../../../polar/entity/master';

interface MasterSelectState {
  selectedMaster?: MapWorkflow['master'];
  selectedMasterDocData?: MasterDocumentData;
  selectedMasterFullName?: string;
  currentGroupMasters: GroupMasters[];
}

const initialState: MasterSelectState = {
  currentGroupMasters: [],
};

@Injectable()
export class MasterSelectStateService {
  private readonly store = new BehaviorSubject<MasterSelectState>(initialState);

  get state$() {
    return this.store.asObservable();
  }

  set groupMasters(val: GroupMasters[]) {
    this.store.next({ ...this.store.value, currentGroupMasters: val });

    // NOTE: 先に selectMaster が呼び出される場合がある
    if (this.store.value.selectedMaster) {
      this.selectMaster(this.store.value.selectedMaster);
    }
  }

  selectMaster(master: MapWorkflow['master']) {
    if (!master) return;

    if (!!this.store.value.currentGroupMasters && this.store.value.currentGroupMasters.length > 0) {
      const masters = this.store.value.currentGroupMasters.flatMap((group) => group.masters);

      const selectedMasterDocData = masters.find((m) => m.id === master.id);
      if (!selectedMasterDocData) {
        return;
      }
      const selectedMasterGroup = this.store.value.currentGroupMasters.find(
        (group) => group.group.id === selectedMasterDocData.masterGroupId,
      )?.group;
      if (!selectedMasterGroup) {
        return;
      }
      const selectedMasterFullName =
        selectedMasterGroup.type === 'withoutGroup'
          ? selectedMasterDocData.name
          : `${selectedMasterGroup.name} - ${selectedMasterDocData.name}`;

      this.store.next({
        ...this.store.value,
        selectedMaster: master,
        selectedMasterDocData,
        selectedMasterFullName,
      });
    } else {
      // NOTE: 先に selectMaster が呼び出される場合は、currentGroupMasters がセットされるまで待つ
      this.store.next({ ...this.store.value, selectedMaster: master });
    }
  }
}
