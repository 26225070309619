import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  inject,
} from '@angular/core';

import { CsvLineSplitter, SpreadsheetData } from 'src/app/polar/entity/Workflow';

import { ENVIRONMENT } from '../../applicationEnvironment';
import { ColumnWidthStore } from './ColumnWidthMemory';
import { GetNumberOfColumns, ParseRows } from './CsvUtils';

import jspreadsheet from 'jspreadsheet';

@Component({
  selector: 'app-spreadsheet-v2',
  templateUrl: './spreadsheet-v2.component.html',
  styleUrls: ['./spreadsheet-v2.component.scss'],
  standalone: true,
})
export class SpreadsheetV2Component implements AfterViewInit, OnDestroy {
  @ViewChild('spreadsheet') el?: ElementRef;

  ss?: jspreadsheet.worksheetInstance;

  @Output() changed: EventEmitter<SpreadsheetData> = new EventEmitter<SpreadsheetData>();

  @Output() cellChanged: EventEmitter<{
    row: number;
    col: number;
    value: string;
    rowId: number;
  }> = new EventEmitter();

  @Output() cellSelectionChanged: EventEmitter<{
    selectionRange: string;
    selectionCsv: string;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    startRowId: number;
    endRowId: number;
  }> = new EventEmitter();

  @Output() cellTextChanged: EventEmitter<{
    elm: HTMLElement;
    row: number;
    col: number;
    oldValue: string;
    newValue: string;
    rowId: number;
    updater: (x: number, y: number, value: string) => void;
  }> = new EventEmitter();

  @Input() enableCellSelectionChanged: boolean = false;

  @Input() readonly: boolean = false;

  @Input() highlightHeader: string = '';

  @Input() fixedHeaders: number = 100;

  @Input() columnWidthMemory?: ColumnWidthStore;

  @Output() columnWidthMemoryUpdated: EventEmitter<ColumnWidthStore> = new EventEmitter();

  _highlightRows: number[] = [];
  get highlightRows(): number[] {
    return this._highlightRows;
  }
  @Input() set highlightRows(val: number[]) {
    this._highlightRows = val;
    this.updateHighlightRows();
  }

  @Output() activateStatusChanged: EventEmitter<boolean> = new EventEmitter();

  lastDump: string = '';

  _data: SpreadsheetData | null | undefined;

  get data(): SpreadsheetData | null | undefined {
    return this._data;
  }

  currentMaxRowId: number = 0;
  currentColumns: number = 0;

  @Input() set data(val: SpreadsheetData | null | undefined) {
    this._data = val;

    if (val == null && this.ss != null) {
      this.ss.loadData([]);
      this.ss.resetSelection();
      this.lastDump = '';
    } else if (this.ss != null && val != null && this.lastDump != val.csv) {
      // reset
      this.currentMaxRowId = 0;

      this.ss.resetSelection();
      // parse csv considering double quotes
      let lines = CsvLineSplitter(val.csv);

      // align cells as fixedHeders
      let nOfColumns = this.fixedHeaders;

      // calc columns
      let actualColumns = GetNumberOfColumns(lines);

      if (actualColumns > nOfColumns) {
        nOfColumns = actualColumns;
      }

      // re-create worksheet
      let newCols: any[] = [];
      for (let i = 0; i < nOfColumns; i++) {
        newCols.push({
          name: i.toString(),
          type: 'text',
          autoCasting: false,
          align: 'center',
        });
      }
      let spreadsheetParent = this.ss?.parent;
      if (spreadsheetParent != null) {
        spreadsheetParent?.deleteWorksheet(0);
        this.ss = spreadsheetParent?.createWorksheet(this.getWorksheetOptions(newCols));
      } else {
        this.ss?.deleteWorksheet(0);
        this.ss?.createWorksheet(this.getWorksheetOptions(newCols));
      }

      this.currentColumns = nOfColumns;

      const rows = ParseRows(lines, nOfColumns);
      // extend rows to 100
      for (let i = 0; i < 100; i++) {
        let cells = [];
        for (let j = 0; j < nOfColumns; j++) {
          cells.push('');
        }
        rows.push(cells);
      }

      if (val?.confidenceCsv != null) {
        const confidenceRows = ParseRows(CsvLineSplitter(val.confidenceCsv), nOfColumns);
        console.log('confidence:');
        console.log(confidenceRows);

        for (let i = 0; i < confidenceRows.length; i++) {
          for (let j = 0; j < confidenceRows[i].length; j++) {
            const cellname = this.getExcelNameFromXY(j, i);
            let errorRate = 1 - parseFloat(confidenceRows[i][j]);
            if (rows[i][j] == '!ERROR!') {
              errorRate = 0.6;
            }
            if (errorRate >= 0.1) {
              this.ss.setStyle(cellname, 'background', `rgba(255, 0, 0, ${errorRate})`);
            }
            if (rows[i][j] == '') {
              this.ss.setStyle(cellname, 'background', `rgba(0, 0, 0, 0.1)`);
            }
          }
        }
      }

      for (let i = 0; i < nOfColumns; i++) {
        let cellname = this.getExcelNameFromXY(i, 0);
        this.ss.setStyle(cellname, 'background', '#7148b5');
        this.ss.setStyle(cellname, 'color', 'white');

        if (rows.length > 0 && rows[0].length > i) {
          if (this.highlightHeader != '' && this.highlightHeader == rows[0][i]) {
            this.ss.setStyle(cellname, 'font-weight', 'bold');
          }
        }
      }

      this.ss.loadData(rows);
      this.ss.resetSelection();
      this.ss.setFreezeRows(1);
      this.lastDump = val.csv;

      // this should be keeped!!!!
      for (let i = 0; i < rows.length; i++) {
        this.currentMaxRowId += 1;
        this.ss.setRowId(i, this.currentMaxRowId);
      }

      // mark header as readOnly
      for (let i = 0; i < nOfColumns; i++) {
        let cellname = this.getExcelNameFromXY(i, 0);
        this.ss.setReadOnly(cellname, true);
      }

      setTimeout(() => {
        this.handleChanged();
        this.updateColumnWidthWithMemory();
      }, 100);
    }

    if (this.ss == null && val != null && this.lastDump != val.csv) {
      // refresh again if this.ss is available later
      this._data = null;
      let int = setInterval(() => {
        if (this.ss != null) {
          this.data = val;
          clearInterval(int);
        }
      }, 100);
    }
  }

  lastHighlightRows: number[] = [];

  updateHighlightRows() {
    if (this.ss == null) {
      setTimeout(() => {
        this.updateHighlightRows();
      }, 1000);
      return;
    }

    let resetTargets = this.lastHighlightRows.filter(
      (f) => this.highlightRows.includes(f) == false,
    );
    let paintTargets = this.highlightRows.filter(
      (f) => this.lastHighlightRows.includes(f) == false,
    );

    let resetCoordinator = [];

    for (let i = 0; i < resetTargets.length; i++) {
      let row = resetTargets[i];
      let targetRow = this.ss.getRowById(row, true) as { y: number };
      for (let j = 0; j < this.currentColumns; j++) {
        let cellname = this.getExcelNameFromXY(j, targetRow.y);
        if (!this.checkIfCellHasFixedPaint(cellname)) {
          resetCoordinator.push(cellname);
        }
      }
    }

    let paintCoordinator: { [index: string]: string } = {};

    for (let i = 0; i < paintTargets.length; i++) {
      let row = paintTargets[i];
      let targetRow = this.ss.getRowById(row, true) as { y: number };
      for (let j = 0; j < this.currentColumns; j++) {
        let cellname = this.getExcelNameFromXY(j, targetRow.y);
        if (!this.checkIfCellHasFixedPaint(cellname)) {
          paintCoordinator[cellname] = `background: #ceedff; background-color: #ceedff;`;
        } else {
          //console.log('ignore paint: ' + cellname + 'due to : ' + this.ss?.getStyle(cellname));
        }
      }
    }

    this.ss.resetStyle(resetCoordinator as any);
    this.ss.setStyle(paintCoordinator);

    this.lastHighlightRows = this.highlightRows;
  }

  checkIfCellHasFixedPaint(cellname: string) {
    const style = this.ss?.getStyle(cellname);

    if (style == undefined || style == null) return false;

    return style.toString().startsWith('background: rgba(');
  }

  getExcelNameFromXY(x: number, y: number) {
    let name = '';
    while (x >= 0) {
      name = String.fromCharCode((x % 26) + 65) + name;
      x = Math.floor(x / 26) - 1;
    }
    return name + (y + 1);
  }

  focusing = false;

  applicationEnvironment = inject(ENVIRONMENT);

  constructor() {}

  getWorksheetOptions(cols: jspreadsheet.Column[]): jspreadsheet.Worksheet {
    return {
      selectionCopy: false,
      editable: !this.readonly,
      tableOverflow: true,
      data: [],
      columns: cols,
      defaultColWidth: 90,
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowComments: false,
      virtualizationX: false,
      virtualizationY: true,
      // never use spare rows or columns. it has a bug.
    };
  }

  ngAfterViewInit() {
    jspreadsheet.setLicense(this.applicationEnvironment.environment.jspreadsheetCertificate);

    let cols: any[] = [];

    if (this.fixedHeaders != 0) {
      for (let i = 0; i < this.fixedHeaders; i++) {
        cols.push({
          name: i.toString(),
          type: 'text',
          autoCasting: false,
          align: 'center',
        });
      }
    }

    this.ss = jspreadsheet(this.el?.nativeElement, {
      worksheets: [this.getWorksheetOptions(cols)],
      onchange: (_, cell, x, y, newValue, oldValue) => {
        const colname = this.getExcelNameFromXY(parseInt(x as any), parseInt(y as any));
        this.ss?.resetStyle(colname);
        if (this.highlightRows.includes(this.ss!.getRowId(parseInt(y as any)))) {
          this.ss?.setStyle(colname, 'background', '#ceedff');
        } else {
          this.ss?.setStyle(colname, 'background', 'white');
        }
        this.handleChanged();
        try {
          this.cellTextChanged.emit({
            elm: cell,
            col: x,
            row: y,
            oldValue: oldValue,
            newValue: newValue,
            rowId: this.ss!.getRowId(y),
            updater: (x, y, value) => {
              const excelName = this.getExcelNameFromXY(x, y);
              this.ss!.setValue(excelName, value, false);
            },
          });
        } catch {
          console.log('error while updating cellTextChanged event');
        }
      },
      onfocus: (worksheet) => {
        this.activateStatusChanged.emit(true);
      },
      onblur: (worksheet) => {
        this.activateStatusChanged.emit(false);
      },
      oninsertrow: (
        worksheet,
        rowNumber,
        numOfRows: number,
        rowData: [],
        insertBefore: boolean,
      ) => {
        let offset = 0;
        if (insertBefore) {
        } else {
          offset = 1;
        }
        for (let i = 0; i < numOfRows; i++) {
          this.currentMaxRowId += 1;
          this.ss?.setRowId(offset + rowNumber + i, this.currentMaxRowId);
        }
        this.handleChanged();
      },
      ondeleterow: (worksheet, rowNumber) => {
        this.handleChanged();
      },
      onbeforedeleterow(worksheet, rowNumber, numOfRows) {
        if (rowNumber == 0) {
          alert('ヘッダを削除することはできません');
          return false;
        }
        return true;
      },
      onbeforeinsertrow(worksheet, rowNumber, numOfRows, insertBefore) {
        if (insertBefore == true && rowNumber == 0) {
          alert('ヘッダの前に行を挿入することはできません');
          return false;
        }
        return true;
      },
      onselection: (worksheet, px, py, ux, uy, origin) => {
        let rowId = this.ss!.getRowId(uy);
        this.cellChanged.emit({
          row: uy,
          col: ux,
          rowId: rowId,
          value: this.ss!.getValue(this.getExcelNameFromXY(ux, uy), false),
        });

        if (this.enableCellSelectionChanged) {
          this.cellSelectionChanged.emit({
            selectionRange: this.ss!.getRange(),
            selectionCsv: this.getCsvFromSelection(px, py, ux, uy),
            startX: px,
            startY: py,
            endX: ux,
            endY: uy,
            startRowId: this.ss!.getRowId(py),
            endRowId: this.ss!.getRowId(uy),
          });
        }
      },
      contextMenu: (o, x, y, e, items, section, a1, a2) => {
        return this.contextMenu(o, x, y, e, section, a1, a2) as any;
      },
      onresizecolumn: (worksheet, column, width, oldWidth) => {
        if (!this.resizingColumnAuto) {
          this.setColumnWidthMemory(column, width);
        }
      },
    })[0];

    this.ss.setData([['']]);
    this.ss.resetSelection();

    // register resize
    this.resizeInterval = setInterval(() => {
      if (
        this.lastWidth != this.ss!.element.offsetWidth ||
        this.lastHeight != this.ss!.element.offsetHeight
      ) {
        this.lastWidth = this.ss!.element.offsetWidth;
        this.lastHeight = this.ss!.element.offsetHeight;
        this.windowResized();
      }
    }, 500);
  }

  resetColumnWidthMemory() {
    this.columnWidthMemory = { value: {} };
    this.columnWidthMemoryUpdated.emit(this.columnWidthMemory);
    this.handleWindowResized();
  }

  setColumnWidthMemory(column: number | number[], width: number | number[]) {
    if (!this.columnWidthMemory) return;

    if (typeof column == 'number') {
      this.columnWidthMemory.value[column] = width as number;
    } else {
      for (let i = 0; i < column.length; i++) {
        if (typeof width == 'number') {
          this.columnWidthMemory.value[column[i]] = width;
        } else {
          this.columnWidthMemory.value[column[i]] = width[i];
        }
      }
    }

    this.columnWidthMemoryUpdated.emit(this.columnWidthMemory);
  }

  ngOnDestroy(): void {
    clearInterval(this.resizeInterval);
  }

  getCsvFromSelection(px: number, py: number, ux: number, uy: number) {
    let csv = '';
    let datas = this.ss?.getData() as string[][];

    const MAX_CELLS = 1000;
    let n = 0;

    for (let i = py; i <= uy; i++) {
      let line = '';
      for (let j = px; j <= ux; j++) {
        n += 1;
        if (n > MAX_CELLS) {
          break;
        }
        let text = datas[i][j].toString();
        if (text == null || text == undefined) {
          line += ',';
        } else {
          text = text.replace(/"/g, '""');
          line += '"' + text + '",';
        }
      }

      if (n > MAX_CELLS) {
        break;
      }

      csv += line + '\n';
    }

    return csv;
  }

  getAllData() {
    return this.ss!.getData() as string[][];
  }

  resizeInterval: any;
  lastWidth: number = -1;
  lastHeight: number = -1;
  resizingColumnAuto: boolean = false;

  windowResized = () => {
    this.handleWindowResized();
  };

  handleWindowResized() {
    console.log('resized');
    let cols = [];

    // gen 0 ~ currentColumns
    for (let i = 0; i < this.currentColumns; i++) {
      if (this.columnWidthMemory?.value[i]) {
        continue;
      }
      cols.push(i);
    }

    this.resizingColumnAuto = true;
    this.ss?.setWidth(cols, 90);
    this.resizingColumnAuto = false;
  }

  updateColumnWidthWithMemory() {
    if (this.columnWidthMemory == null) return;

    let keys = Object.keys(this.columnWidthMemory.value);
    for (let i = 0; i < keys.length; i++) {
      this.ss?.setWidth(parseInt(keys[i]), this.columnWidthMemory.value[parseInt(keys[i])]);
    }
  }

  handleChanged() {
    this.lastDump = '';
    let largestRow = 0;
    let largestCol = 0;

    let datas = this.ss?.getData() as string[][];

    for (let i = 0; i < datas.length; i++) {
      let keys = Object.keys(datas[i]);
      for (let j = 0; j < keys.length; j++) {
        if (datas[i][j] != '') {
          // has data
          if (largestRow < i) {
            largestRow = i;
          }
          if (largestCol < j) {
            largestCol = j;
          }
        }
      }
    }

    let csv = [];

    for (let i = 0; i <= largestRow; i++) {
      let line = [];
      for (let j = 0; j <= largestCol; j++) {
        let text = datas[i][j].toString();
        if (text == null || text == undefined) {
          line.push('');
        } else {
          text = text.replace(/"/g, '""');
          line.push('"' + text + '"');
        }
      }
      csv.push(line.join(','));
    }

    this.lastDump = csv.join('\n');

    let rowNumberToRowId: { [index: number]: number } = {};
    let rowIdToText: { [index: number]: string[] } = {};

    for (let i = 0; i < datas.length; i++) {
      if (i > largestRow) {
        break;
      }
      rowNumberToRowId[i] = this.ss!.getRowId(i);
      let row = Object.values(datas[i]);
      rowIdToText[rowNumberToRowId[i]] = row.slice(0, largestCol + 1);
    }

    this.changed.emit({
      csv: csv.join('\n'),
      loadableDump: null,
      rowNumberToRowId: rowNumberToRowId,
      rowIdToText: rowIdToText,
    });
  }

  deleteRowsWithRowId(rowIds: number[]) {
    rowIds.forEach((rowId) => {
      const row: any = this.ss!.getRowById(rowId, true);
      this.ss!.deleteRow(row.y, 1);
    });
  }

  contextMenu = (obj: any, x: any, y: any, e: any, section: string, a1: any, a2: any) => {
    const T = (str: string) => {
      return str;
    };

    var items = [];
    var o = obj.parent.config;

    // Click in the tabs
    if (section == 'tabs') {
      if (o.allowRenameWorksheet == true) {
        items.push({
          title: T('Rename this worksheet'),
          onclick: function () {
            var newName = prompt(T('Rename this worksheet'), e.target.innerText);
            if (newName) {
              obj.parent.renameWorksheet(a1, newName);
            }
          },
        });
      }

      if (o.allowDeleteWorksheet == true) {
        items.push({
          title: T('Delete this worksheet'),
          onclick: function () {
            if (confirm(T('Are you sure?') + '\n' + e.target.innerText)) {
              obj.parent.deleteWorksheet(a1);
            }
          },
        });
      }

      items.push({ type: 'line' });
    }

    // Nested header only
    if (section == 'nested') {
      // Rename nested headers
      items.push({
        title: T('Rename this cell'),
        onclick: function () {
          // Get the new title
          var text = prompt(T('Rename this cell'), e.target.innerText);
          // Update the nested cell title
          obj.setNestedCell(a1, a2, { title: text as string | undefined });
        },
      });

      items.push({ type: 'line' });
    }

    // Sections that affect the selection
    if (section == 'header' || section == 'row' || section == 'cell' || section == 'nested') {
      // Cut
      items.push({
        title: T('切り取り'),
        icon: 'content_cut',
        shortcut: 'Ctrl + X',
        onclick: function () {
          obj.cut();
        },
      });

      // Copy
      items.push({
        title: T('コピー'),
        icon: 'content_copy',
        shortcut: 'Ctrl + C',
        onclick: function () {
          obj.copy();
        },
      });

      // Paste
      if (navigator && navigator.clipboard && navigator.clipboard.readText) {
        items.push({
          title: T('貼り付け'),
          icon: 'content_paste',
          shortcut: 'Ctrl + V',
          onclick: function () {
            if (obj.selectedCell) {
              navigator.clipboard.readText().then(function (text) {
                if (text) {
                  obj.paste(obj.selectedCell[0], obj.selectedCell[1], text);
                }
              });
            }
          },
        });
      }

      items.push({ type: 'line' });
    }

    // Clicking in the headers
    if (section == 'header') {
      /*
      // Insert a new column
      if (obj.options.allowInsertColumn == true) {
        items.push({
          title: T('Insert a new column before'),
          onclick: function () {
            obj.insertColumn(1, parseInt(x), 1);
          },
        });
      }

      if (obj.options.allowInsertColumn == true) {
        items.push({
          title: T('Insert a new column after'),
          onclick: function () {
            obj.insertColumn(1, parseInt(x), 0);
          },
        });
      }

      // Delete a column
      if (obj.options.allowDeleteColumn == true) {
        items.push({
          title: T('Delete selected columns'),
          onclick: function () {
            obj.deleteColumn(
              obj.getSelectedColumns().length ? undefined : parseInt(x)
            );
          },
        });
      }*/

      // Rename column
      if (obj.options.allowRenameColumn == true) {
        /*
        items.push({
          title: T('Rename this column'),
          onclick: function () {
            obj.setHeader(x);
          },
        });
        */
      }

      // Sorting
      /*
      if (obj.options.columnSorting == true) {
        // Line
        items.push({ type: 'line' });

        items.push({
          title: T('Order ascending'),
          onclick: function () {
            obj.orderBy(x, 0);
          },
        });
        items.push({
          title: T('Order descending'),
          onclick: function () {
            obj.orderBy(x, 1);
          },
        });

        items.push({ type: 'line' });
      }
      */
    }

    // Row only
    if (section == 'cell' || section == 'row') {
      // Insert new row
      if (obj.options.allowInsertRow == true) {
        items.push({
          title: T('前に行を挿入'),
          onclick: function () {
            obj.insertRow(1, parseInt(y), 1);
          },
        });

        items.push({
          title: T('後ろに行を挿入'),
          onclick: function () {
            obj.insertRow(1, parseInt(y));
          },
        });
      }

      if (obj.options.allowDeleteRow == true) {
        items.push({
          title: T('選択行を削除'),
          onclick: function () {
            obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
          },
        });
      }

      items.push({ type: 'line' });
    }

    // Click in the cell
    if (section == 'cell') {
      /*
      if (obj.options.allowComments == true) {
        var title = obj.records[a2][a1].element.getAttribute('title') || '';

        items.push({
          title: title ? T('Edit comments') : T('Add comments'),
          icon: 'notes',
          onclick: function () {
            var cell = jspreadsheet.helpers.getColumnNameFromCoords(a1, a2);
            var comment = prompt(T('Comments'), title);
            if (comment) {
              obj.setComments(cell, comment);
            }
          },
        });

        if (title) {
          items.push({
            title: T('Clear comments'),
            onclick: function () {
              var cell = jspreadsheet.helpers.getColumnNameFromCoords(a1, a2);
              obj.setComments(cell, '');
            },
          });
        }
      }

      // Line
      items.push({ type: 'line' });
      */
    }

    // Save
    if (o.allowExport) {
      items.push({
        title: T('保存'),
        icon: 'save',
        shortcut: 'Ctrl + S',
        onclick: function () {
          obj.download();
        },
      });
    }

    // About
    if (o.about) {
      /*
      items.push({
        title: T('About'),
        icon: 'info',
        onclick: function () {
          if (o.about === true) {
            alert(jspreadsheet.version());
          } else {
            alert(o.about);
          }
        },
      });*/
    }

    items.push({
      title: T('一番下まで反映'),
      icon: 'info',
      onclick: () => {
        const selected = this.ss!.getSelected(false);
        console.log(selected);
        const maxRows = 9999;
        const val = selected[0].v;
        for (let i = selected[0].y; i < maxRows; i++) {
          const excelCellName = this.getExcelNameFromXY(selected[0].x, i);
          const currentValue = this.ss!.getValue(excelCellName, false);
          if (currentValue == null || currentValue == undefined || currentValue == '') {
            break;
          }
          this.ss!.setValue(excelCellName, val, false);
        }
      },
    });

    items.push({
      title: T('幅設定をリセット'),
      icon: 'restore',
      onclick: () => {
        this.resetColumnWidthMemory();
      },
    });

    return items;
  };
}
