import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';

import { OverlayLoadingComponent } from '../modal/overlay-loading/overlay-loading.component';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [TranslateModule, FormsModule, OverlayLoadingComponent],
})
export class LoginComponent {
  email: string = '';
  password: string = '';
  errMessage: string = '';
  processing: boolean = false;

  constructor(private auth: AngularFireAuth) {}

  async submit() {
    if (this.processing) return;
    this.errMessage = '';
    this.processing = true;
    try {
      await this.auth.signInWithEmailAndPassword(this.email, this.password);
    } catch (e) {
      let errMessage = '';
      switch ((e as any).code) {
        case 'auth/invalid-email':
          errMessage = 'メールアドレスが不正です';
          break;
        case 'auth/user-disabled':
          errMessage = 'ユーザーが無効です';
          break;
        case 'auth/user-not-found':
          errMessage = 'ユーザーが見つかりません';
          break;
        case 'auth/wrong-password':
          errMessage = 'パスワードが間違っています';
          break;
        default:
          errMessage = 'ログインに失敗しました';
          break;
      }
      this.errMessage = errMessage;
      this.processing = false;
    } finally {
    }
  }
}
