<div class="modal" [ngClass]="{ 'is-active': this.isActiveModal }">
  <div class="modal-background"></div>
  <div class="modal-card custom-width">
    <header class="modal-card-head">
      @if (status == 'rotating') {
        <p class="modal-card-title">回転しています...</p>
      }
      @if (status == 'matching') {
        <p class="modal-card-title">Version2で分類中しています...</p>
      }
      @if (status == 'select-v2') {
        <p class="modal-card-title">テンプレートの候補が見つかりました。</p>
      }
    </header>
    <section class="modal-card-body">
      @if (status == 'rotating' || status == 'matching') {
        <article
          class="is-flex is-justify-content-center is-align-items-center"
          style="height: 100%"
        >
          <app-inner-progress status="loading"></app-inner-progress>
        </article>
      }
      @if (v2CandidatesData.length === 0) {
        <article
          class="is-flex is-justify-content-center is-align-items-center"
          style="height: 100%"
        >
          <p>候補が見つかりませんでした。新規登録してください</p>
        </article>
      }
      @if (v2CandidatesData.length > 0) {
        <article>
          <div class="columns is-multiline">
            @for (candidate of v2CandidatesData; track candidate) {
              <div class="column is-one-third">
                <div class="card">
                  <div class="card-image">
                    <figure class="image is-3by4">
                      <img
                        [src]="candidate.image_url"
                        alt="Candidate image"
                        class="fixed-size-image"
                      />
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-6">ID: {{ candidate.template_id }}</p>
                        <p class="subtitle is-6">類似スコア: {{ candidate.score }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </article>
      }
    </section>
    @if (status !== 'loading') {
      <footer class="modal-card-foot">
        <button class="button" (click)="closeModal()">キャンセル</button>
        @if (status == 'select-v2') {
          <button class="button" (click)="registerForm()">新規テンプレート登録</button>
        }
      </footer>
    }
  </div>
</div>
