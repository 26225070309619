import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterGroupDocumentData } from '../../polar/entity/master';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-master-group-detail',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './master-group-detail.component.html',
  styleUrls: ['./master-group-detail.component.scss'],
})
export class MasterGroupDetailComponent {
  @Input() selectedGroup!: MasterGroupDocumentData;
}
