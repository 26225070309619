@if (!useFieldLayout) {
  <div class="is-horizontal non-field-layout">
    <div class="field-body">
      <div class="field">
        <p class="control">
          <label class="checkbox">
            <input
              #CHECKBOX
              type="checkbox"
              (change)="checkChanged($event)"
              [disabled]="disabled"
            />
          </label>
        </p>
      </div>
    </div>
    <label
      class="label"
      translate
      (click)="toggle()"
      [ngClass]="disabled ? 'label-disabled' : undefined"
      >{{ label }}</label
    >
  </div>
}

@if (useFieldLayout) {
  <div class="field is-horizontal" style="margin-top: 8px">
    <div class="field-label is-normal">
      <label
        class="label"
        translate
        (click)="toggle()"
        [ngClass]="disabled ? 'label-disabled' : undefined"
        >{{ label }}</label
      >
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control">
          <label class="checkbox">
            <input
              #CHECKBOX
              type="checkbox"
              (change)="checkChanged($event)"
              [disabled]="disabled"
            />
          </label>
        </p>
      </div>
    </div>
  </div>
}
