<!-- Simple hader with Logo using div -->
<div class="header">
  <img src="assets/logo.png" alt="logo" (click)="goMain()" />
  <div style="flex-grow: 1"></div>
  <button
    style="width: 100px; font-size: 12px"
    class="button"
    (click)="signOut()"
    *ngIf="showSignOut"
  >
    <i
      class="fa-light fa-arrow-right-from-bracket"
      style="font-size: 16px; margin-right: 4px"
    ></i>
    サインアウト
  </button>
</div>
