import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

import { TooltipDirective } from '../../tooltip.directive';

import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { PolarApiService } from 'src/app/polar/polar-api.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { DateLocalString } from '../date-local-string.pipe';
import { SafeEmailPipe } from '../safe-email.pipe';

import { MasterDocumentData, MasterHistoryDocumentData } from '../../polar/entity/master';
import { DownloadMasterCsvDirective } from '../download-master-csv-directive';

@Component({
  selector: 'app-master-history',
  standalone: true,
  imports: [
    CommonModule,
    SafeEmailPipe,
    DateLocalString,
    TooltipDirective,
    DownloadMasterCsvDirective,
  ],
  templateUrl: './master-history.component.html',
  styleUrls: ['./master-history.component.scss'],
})
export class MasterHistoryComponent {
  @Input() currentCompanyId!: string;
  @Input() selectedMaster!: MasterDocumentData;
  @Input() selectedLatestMaster?: MasterHistoryDocumentData;
  @Input() histories!: MasterHistoryDocumentData[];
  @Input() stagedHistories?: MasterHistoryDocumentData[];
  @Input() isConsole: boolean = false;

  @Output() revert = new EventEmitter();

  get latestMaster(): MasterHistoryDocumentData {
    // sort by createdAt desc
    return this.histories.sort((a, b) => {
      if (a.createdAt == null) return 1;
      if (b.createdAt == null) return 1;
      return (b.createdAt as Timestamp).toMillis() - (a.createdAt as Timestamp).toMillis();
    })[0];
  }

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
  ) {}

  async removeStagedMaster(item: MasterHistoryDocumentData) {
    const result = await this.commonUi.showConfirm({
      title: '削除確認',
      body: 'この履歴を削除しますか？',
      buttons: ['Yes', 'No'],
    });
    if (result != 'Yes') return;
    await this.polar.deleteMasterHistory(
      this.currentCompanyId,
      item.masterGroupId,
      item.masterId,
      item.id!,
    );
  }
}
