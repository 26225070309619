import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.router.events.subscribe((event) => {
      console.log(event);
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(defaultBackUrl: string): void {
    if (defaultBackUrl.length > 0 && defaultBackUrl[0] != '/') {
      defaultBackUrl = '/' + defaultBackUrl;
    }

    this.history.pop();
    if (this.history.length > 0 && this.history[this.history.length - 1] == defaultBackUrl) {
      this.location.back();
    } else {
      this.router.navigateByUrl(defaultBackUrl);
    }
  }
}
