<div class="back" [ngClass]="hiding ? 'hiding' : null" (click)="closeModal()"></div>
<div class="base content-scroll" [ngClass]="hiding ? 'hiding' : null">
  <header>
    <h2>
      <i class="fa-light fa-sliders-up"></i>チームの設定
      <i style="font-size: 12px" *ngIf="isNew">新規</i>
    </h2>
    <p>チームの設定を行ってください</p>
  </header>
  <div class="block">
    <h3>基本情報</h3>
    <ng-container *ngIf="!productionMasterGroupsLoading">
      <app-label-and-text-input
        [(value)]="team.name"
        label="名前"
        [useFieldLayout]="true"
        [disableEdit]="team.id == 'admin' || team.id == 'all'"
      ></app-label-and-text-input>
      <app-label-and-combo-box
        label="権限"
        [(selectedItem)]="team.authority"
        [items]="teamTypes"
        [useFieldLayout]="true"
        [scrollable]="true"
        [disableEdit]="team.id == 'all'"
      ></app-label-and-combo-box>
      <app-label-and-button
        *ngIf="!isNew"
        [smallButton]="true"
        label="作成日"
        [buttonText]="getDate(team.createdAt)"
        [nonInteractible]="true"
      ></app-label-and-button>
      <app-label-and-button
        *ngIf="!isNew"
        [smallButton]="true"
        label="更新日"
        [buttonText]="getDate(team.updatedAt)"
        [nonInteractible]="true"
      ></app-label-and-button>
    </ng-container>
    <app-inner-progress *ngIf="productionMasterGroupsLoading"></app-inner-progress>
  </div>
  <div class="block-container">
    <div class="block">
      <app-label-and-combo-box
        label="会社"
        [items]="companies"
        [(selectedItem)]="currentCompanyId"
        [scrollable]="true"
      ></app-label-and-combo-box>
    </div>
    <div class="block">
      <h3>仕事のアクセス権限</h3>
      <app-label-and-check-box
        label="全て許可"
        [useFieldLayout]="true"
        [(checked)]="allowAllWorks"
        *ngIf="!productionWorksLoading"
      ></app-label-and-check-box>
      <app-inner-progress *ngIf="productionWorksLoading"></app-inner-progress>
      <div class="rows" *ngIf="!allowAllWorks && !productionWorksLoading && currentCompanyId != ''">
        <div *ngIf="productionWorksDict[currentCompanyId].length == 0">仕事なし</div>
        <div class="row" *ngFor="let work of productionWorksDict[currentCompanyId]">
          <div class="row-label">{{ work.name }}</div>
          <div class="row-ope">
            <app-label-and-check-box
              label="操作"
              [(checked)]="
                team.accessible_companies[currentCompanyId].accessible_works[work.id!].interactable
              "
            ></app-label-and-check-box>
          </div>
          <div class="row-border"></div>
        </div>
      </div>
    </div>
    <div class="block">
      <h3>マスタのアクセス権限</h3>
      <app-label-and-check-box
        label="全て許可"
        [useFieldLayout]="true"
        [(checked)]="allowAllMasterGroups"
        *ngIf="!productionMasterGroupsLoading"
      ></app-label-and-check-box>
      <app-inner-progress *ngIf="productionMasterGroupsLoading"></app-inner-progress>
      <div
        class="rows"
        *ngIf="!allowAllMasterGroups && !productionMasterGroupsLoading && currentCompanyId != ''"
      >
        <div *ngIf="productionMasterGroupsDict[currentCompanyId].length == 0">マスタなし</div>
        <div class="row" *ngFor="let masterGroup of productionMasterGroupsDict[currentCompanyId]">
          <div class="row-label">{{ masterGroup.name }}</div>
          <div class="row-ope">
            <app-label-and-check-box
              label="閲覧"
              [(checked)]="
                team.accessible_companies[currentCompanyId].accessible_master_groups[
                  masterGroup.id!
                ].readable
              "
              [disabled]="
                team.accessible_companies[currentCompanyId].accessible_master_groups[
                  masterGroup.id!
                ].writable
              "
            ></app-label-and-check-box>
            <app-label-and-check-box
              label="編集"
              [checked]="
                team.accessible_companies[currentCompanyId].accessible_master_groups[
                  masterGroup.id!
                ].writable
              "
              (checkedChange)="updateMasterWritableFlag(currentCompanyId, masterGroup.id!, $event)"
            ></app-label-and-check-box>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="block"
    *ngIf="!isNew && team.id != 'all' && team.id != 'admin' && !productionMasterGroupsLoading"
  >
    <h3>操作</h3>
    <div class="delete-team" (click)="deleteTeam()">このチームを削除する</div>
  </div>
  <div class="line"></div>
  <div class="buttons">
    <button class="button close" (click)="closeModal()" style="width: 92px">キャンセル</button>
    <button class="button done" style="width: 112px" (click)="save()">保存</button>
  </div>
</div>

<div class="loading" *ngIf="loadingFull">
  <p>保存しています...</p>
  <app-inner-progress style="filter: brightness(1.5)"></app-inner-progress>
</div>
