import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TooltipDirective } from 'src/app/tooltip.directive';

@Component({
  selector: 'app-label-and-text-input',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipDirective],
  templateUrl: './label-and-text-input.component.html',
  styleUrls: ['./label-and-text-input.component.scss'],
})
export class LabelAndTextInputComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiline: boolean = false;
  @Input() grow: number = 4.2;
  @Input() useFieldLayout: boolean = false;
  @Input() disableEdit: boolean = false;
  @Input() validateFunction?: (val: string) => { alert: boolean; message: string };

  showNotice: boolean = false;
  noticeMessage: string = '';

  @Input()
  set value(val: string) {
    this._ref = val;
    this.validate();
  }

  @Output()
  valueChange = new EventEmitter<string>();

  changeValue(val: string): void {
    this.value = val;
    this.valueChange.emit(val);

    this.validate();
  }

  _ref: string = '';
  get ref(): string {
    return this._ref;
  }
  set ref(val: string) {
    this._ref = val;
    this.changeValue(val);
  }

  validate() {
    const result = this.validateFunction?.(this.ref);

    if (result) {
      this.showNotice = result.alert;
      this.noticeMessage = result.message;
    } else {
      this.showNotice = false;
      this.noticeMessage = '';
    }
  }
}
