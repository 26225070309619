<app-console-header label="Auth Groups - 作成" [combos]="false"></app-console-header>
<div class="parent">
  <article class="message is-link">
    <div class="message-header">
      <p>認証グループを作成する</p>
    </div>
    <div class="message-body">
      <form name="createAuthGroup" [formGroup]="createAuthGroupForm" (submit)="createAuthGroup()">
        <div class="field">
          <label class="label">認証グループ名 (必須)</label>
          <input
            name="authGroupName"
            class="input"
            type="text"
            placeholder="認証グループ名を入力してください"
            formControlName="authGroupName"
          />
          <p
            class="help formError"
            *ngIf="
              createAuthGroupForm.get('authGroupname')?.touched &&
              createAuthGroupForm.get('authGroupName')?.hasError('required')
            "
          >
            認証グループ名は必須です。
          </p>
        </div>

        <div class="field is-grouped is-grouped-right">
          <div class="control">
            <button
              class="px-5 button is-link"
              type="submit"
              [disabled]="createAuthGroupForm.invalid"
            >
              作成
            </button>
          </div>
          <app-inner-progress *ngIf="saving" [status]="savingStat"></app-inner-progress>
        </div>
      </form>
    </div>
  </article>

  <div class="title py-4">登録されている認証グループ一覧</div>
  <table class="table is-fullwidth">
    <thead>
      <th style="width: 240px">認証グループ名</th>
      <th style="width: 20px">ID</th>
      <th>会社</th>
      <th style="width: 32px">設定</th>
      <th style="width: 32px">操作</th>
    </thead>

    <tbody>
      <tr *ngFor="let authGroup of authGroups">
        <th (click)="updateName(authGroup)">
          {{ authGroup.name }}<i class="fa-regular fa-pen-to-square"></i>
        </th>
        <th>{{ authGroup.id }}</th>
        <th>{{ getCompanyNameColumn(authGroup.id) }}</th>
        <th>
          <button class="button px-2" (click)="goAuthGroup(authGroup)">設定</button>
        </th>
        <th>
          <button class="button px-2" (click)="deleteAuthGroup(authGroup)">削除</button>
        </th>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="fullLoading"><app-inner-progress></app-inner-progress></div>
