import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsvValidatedMessage } from '../utils';

// この component を入れる上位に overflow:hidden が必要
@Component({
  selector: 'app-csv-error-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './csv-error-details.component.html',
  styleUrls: ['./csv-error-details.component.scss'],
})
export class CsvErrorDetailsComponent {
  @Input() csvErrorMessages?: CsvValidatedMessage[];
  @Input() openModal: boolean = false;
  @Output() closeModal = new EventEmitter();
}
