<div class="base">
  <!--ヘッダ-->
  <header>
    <button class="button back-button" (click)="back()">
      <i class="fa-regular fa-chevron-left"></i>
    </button>
    @if (authGroupInfo != undefined) {
      <h1 style="font-size: 24px; font-weight: bold">権限設定 - {{ authGroupInfo.name }}</h1>
    }
    @if (authGroupInfo == undefined) {
      <h1 style="font-size: 24px; font-weight: bold">...</h1>
    }
  </header>

  <!--表示前のローディング-->
  @if (loading) {
    <div class="content">
      <app-inner-progress></app-inner-progress>
    </div>
  }

  <!--チーム一覧の表示-->
  @if (!loading) {
    <div class="content list-teams">
      <div class="group-container">
        <div class="company-stamps">
          @for (companyName of companyNames; track companyName) {
            <div class="company-stamp">
              {{ companyName }}
            </div>
          }
        </div>
        @if (authGroupInfo != undefined) {
          <h1 class="title">チーム</h1>
        }
        <div class="team-grid-parent">
          @for (team of teams; track team) {
            <div
              class="team-grid-item"
              [ngClass]="selectedTeamId == team.id ? 'selected' : ''"
              (click)="selectTeam(team)"
            >
              {{ getTeamName(team) }}
              <div class="team-grid-settings" tooltip="権限設定" (click)="editTeam(team)">
                <i class="fa-light fa-sliders-up"></i>
              </div>
            </div>
          }
          <div class="team-grid-item team-grid-item-plus" (click)="addTeam()">
            <i class="fa-solid fa-plus"></i>
          </div>
        </div>
      </div>
    </div>
  }

  <!--メンバー一覧-->
  <app-auth-group-settings-users-list
    #USERSLIST
    [authGroupId]="authGroupId"
    [teamId]="selectedTeamId"
    [teamName]="selectedTeamName"
    [parentLoading]="loading"
  ></app-auth-group-settings-users-list>
</div>

@if (isAuthorityModalShown) {
  <app-auth-group-settings-authority-modal
    (hide)="hideAuthorityModal()"
    (willHide)="willHideAuthorityModal()"
    [authGroupId]="authGroupId"
    [targetTeam]="authorityModalTargetAuthorizedTeam"
  ></app-auth-group-settings-authority-modal>
}
