<div class="classification">
  <div class="title" translate>分類</div>
  <div class="block">
    <div class="upload-box" (click)="pickFile()">
      <i class="fa-regular fa-plus"></i>
      <p translate>ファイル選択</p>
    </div>
  </div>
</div>
<input
  #INPUT
  type="file"
  [multiple]="multiple"
  accept="image/*,application/pdf"
  (change)="filePicked($event)"
/>

<div class="loading" *ngIf="loading">
  <p translate>ファイルを変換しています...</p>
  <app-inner-progress></app-inner-progress>
</div>
