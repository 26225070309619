<app-console-header label="Configuration - 設定" [combos]="false"></app-console-header>
@if (loading) {
  <div class="parent">
    @if (loading) {
      <app-inner-progress stat="loading"></app-inner-progress>
    }
  </div>
}
@if (!loading) {
  <div class="parent">
    <div class="field">
      <label class="label">OpenAI API Key</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Text input"
          [(ngModel)]="configs.openai_api_key"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Clova General Url</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Text input"
          [(ngModel)]="configs.clova_general_api_url"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Clova General Key</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Text input"
          [(ngModel)]="configs.clova_general_api_key"
        />
      </div>
    </div>
    <p>Clova Domains</p>
    <div class="columns is-multiline">
      @for (item of clovaDomains; track item; let idx = $index) {
        <div class="column is-half">
          <article class="message is-link">
            <div class="message-body">
              <div class="field">
                <label class="label">Label</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="Text input"
                    [(ngModel)]="item.name"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">Url</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="Text input"
                    [(ngModel)]="item.url"
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">Key</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="Text input"
                    [(ngModel)]="item.secret"
                  />
                </div>
              </div>
              <button class="button" (click)="removeDomain(idx)">削除</button>
            </div>
          </article>
        </div>
      }
    </div>
    <button class="button is-link add" (click)="addDomain()">Add</button>
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" (click)="save()">保存</button>
      </div>
      @if (saving) {
        <app-inner-progress [status]="savingStat"></app-inner-progress>
      }
    </div>
  </div>
}
