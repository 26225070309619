import { FieldValue, Timestamp } from '@angular/fire/firestore';

import { CsvLineSplitter } from '../polar/entity/Workflow';
import { MasterDocumentData } from '../polar/entity/master';

import { WorkBook } from 'xlsx';
import * as XLSX from 'xlsx';

const getDate = (timestamp: Timestamp | FieldValue | undefined | null) => {
  if (timestamp == null) return '';
  return (timestamp as Timestamp).toDate().toLocaleString();
};

const MAX_MASTER_NAME_LENGTH: number = 50;
const MAX_GROUP_NAME_LENGTH: number = 50;

const validateInputLength = (text: string | null | undefined, min: number, max: number) => {
  if (text == null) return false;

  const trimmedText = text.trim();

  return min <= trimmedText.length && trimmedText.length <= max;
};

const getEmailSnapshotToShow = (masterCreator: MasterDocumentData['createdBy']) => {
  if (masterCreator == null) return '';

  return masterCreator.isAdminSnapshot ? 'batton 管理者' : masterCreator.emailSnapshot || '';
};

export type CsvValidatedMessage = {
  message: string;
  row: string;
};

const createCsvValidatedMessage = (csv: CsvValidatedResult): CsvValidatedMessage[] => {
  return csv.bodyRows
    .filter((row) => row.validatedResult != 'isValid')
    .map((row) => {
      const rowIndex = row.rowIndex + 2;
      switch (row.validatedResult) {
        case 'isEmptyRow':
          return { message: `${rowIndex} 行目が空行です。`, row: row.row };
        case 'isInvalidRow':
          return {
            message: `${rowIndex} 行目が CSV として読み込めませんでした。`,
            row: row.row,
          };
        case 'hasInvalidCell':
          return {
            message: `${rowIndex} 行目に値の無い箇所が含まれています。`,
            row: row.row,
          };
        case 'hasDifferentNumberOfColumnsRow':
          return {
            message: `${rowIndex} 行目はヘッダ行と列数が異なっています。`,
            row: row.row,
          };
        default:
          return {
            message: `${rowIndex} 行目が CSV として読み込めませんでした。`,
            row: row.row,
          };
      }
    });
};

const regexToParseCsv = /(?<=,|^)(?<quote>"?)(?<value>.*?)\k<quote>(?=,|$)/g;

type CsvRowValidatedResult =
  | 'isValid'
  | 'isEmptyRow'
  | 'isInvalidRow'
  | 'hasInvalidCell'
  | 'hasDifferentNumberOfColumnsRow';

export type CsvValidatedResult = {
  header: string;
  numberOfColumns: number;
  bodyRows: {
    rowIndex: number;
    row: string;
    validatedResult: CsvRowValidatedResult;
    cells: {
      value: string;
      isInvalid: boolean;
    }[];
  }[];
};

const validateCsv = (csv: string): CsvValidatedResult => {
  const [head, ...rest] = CsvLineSplitter(csv);

  const header = head.replaceAll(/[\r\n]/g, '');
  const match = header.matchAll(regexToParseCsv);
  const headerMatches = Array.from(match);
  console.dir(headerMatches);
  const numberOfColumns = headerMatches.length;

  // remove trailing empty rows
  while (rest.length > 0 && rest[rest.length - 1].trim() === '') {
    rest.pop();
  }

  const bodyRows = rest.map((line, i) => {
    const newLine = line.replaceAll(/[\r\n]/g, '');

    if (newLine === '') {
      return {
        rowIndex: i,
        row: line,
        validatedResult: 'isEmptyRow' as CsvRowValidatedResult,
        cells: [],
      };
    }

    const match = newLine.matchAll(regexToParseCsv);

    const cells: CsvValidatedResult['bodyRows'][number]['cells'][number][] = [];
    const rowMatches = Array.from(match);

    if (rowMatches.length !== numberOfColumns) {
      return {
        rowIndex: i,
        row: line,
        validatedResult: 'hasDifferentNumberOfColumnsRow' as CsvRowValidatedResult,
        cells: [],
      };
    }

    for (const m of rowMatches) {
      if (!m.input) {
        return {
          rowIndex: i,
          row: line,
          validatedResult: 'isInvalidRow' as CsvRowValidatedResult,
          cells: [],
        };
      }

      cells.push({
        value: m.input,
        isInvalid: m.groups == null || m.groups?.['value'] === '',
      });
    }

    return {
      rowIndex: i,
      row: line,
      validatedResult: cells.some((cell) => cell.isInvalid)
        ? 'hasInvalidCell'
        : ('isValid' as CsvRowValidatedResult),
      cells,
    };
  });

  return {
    header,
    numberOfColumns,
    bodyRows,
  };
};

export {
  getDate,
  validateInputLength,
  validateCsv,
  createCsvValidatedMessage,
  getEmailSnapshotToShow,
  regexToParseCsv,
  MAX_MASTER_NAME_LENGTH,
  MAX_GROUP_NAME_LENGTH,
};
