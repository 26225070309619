<div class="columns">
  <div class="column">
    @if (selectedLatestMaster == null) {
      <p class="subtitle is-5">
        {{ selectedMaster.name }}
      </p>
    }
    @if (selectedLatestMaster != null) {
      <p class="subtitle is-5">
        {{ selectedLatestMaster.name }}
      </p>
    }
    <div class="table-container">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>マスタ名</th>
            <th>更新</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!--STAGED-->
          @if (stagedHistories != null && isConsole) {
            @for (item of stagedHistories; track item) {
              <tr class="staged-master">
                <td style="position: relative">
                  <div style="min-width: 200px">{{ item.name }}</div>
                  <div class="staged-master-tip">未反映</div>
                </td>
                <td class="no-opacity">
                  <div class="has-text-grey-light" style="white-space: nowrap">
                    <span tooltip="{{ item.createdBy | safeEmail }} が更新しました">
                      {{ item.createdBy | safeEmail }}
                    </span>
                  </div>
                  <div class="">
                    <span
                      class="has-text-grey-light"
                      tooltip="{{ item.createdAt | dateLocalString: true }}"
                      tooltipPlacement="bottom"
                      >{{ item.createdAt | dateLocalString }}</span
                    >
                  </div>
                </td>
                <td>
                  <ng-container> </ng-container>
                </td>
                <td class="no-opacity">
                  <ng-container>
                    <button class="button" (click)="removeStagedMaster(item)">取り消し</button>
                  </ng-container>
                </td>
              </tr>
            }
          }

          @for (item of histories; track item) {
            <tr>
              <td>
                <div style="min-width: 200px">{{ item.name }}</div>
              </td>
              <td>
                <div class="has-text-grey-light" style="white-space: nowrap">
                  <span tooltip="{{ item.createdBy | safeEmail }} が更新しました">
                    {{ item.createdBy | safeEmail }}
                  </span>
                </div>
                <div class="">
                  <span
                    class="has-text-grey-light"
                    tooltip="{{ item.createdAt | dateLocalString: true }}"
                    tooltipPlacement="bottom"
                    >{{ item.createdAt | dateLocalString }}</span
                  >
                </div>
              </td>
              <td>
                @if (item.id !== latestMaster.id) {
                  <div class="is-inline-flex">
                    <button (click)="revert.emit(item)" class="button">復元</button>
                  </div>
                } @else {
                  <p class="has-text-centered">現在のマスタ</p>
                }
              </td>
              <td>
                @if (item.csv.filePath) {
                  <button download-master-csv [filePath]="item.csv.filePath" class="button">
                    ダウンロード
                  </button>
                } @else {
                  <button disabled class="button is-small">ダウンロード</button>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
