<!-- Simple Login Form with vanilla html -->
<div class="login">
  <div class="login-triangle"></div>
  <h2 class="login-header" translate>ログイン</h2>
  <form class="login-container" (submit)="submit()">
    <p>
      <input
        class="input"
        name="email"
        type="email"
        placeholder="メールアドレスを入力してください"
        [(ngModel)]="email"
        [disabled]="processing"
      />
    </p>
    <p>
      <input
        class="input"
        name="password"
        type="password"
        placeholder="パスワード入力してください"
        [(ngModel)]="password"
        [disabled]="processing"
      />
    </p>
    <p>
      <input
        type="submit"
        [value]="'ログイン' | translate"
        [disabled]="processing"
      />
    </p>
    <p class="error">{{ errMessage }}</p>
    <div class="spinner" *ngIf="processing">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </form>
</div>

<app-overlay-loading *ngIf="processing"></app-overlay-loading>
