import { InjectionToken } from '@angular/core';
import { Environment } from './environment';
import { environment } from '../environments/environment';

export const ENVIRONMENT = new InjectionToken<ApplicationEnvironment>('environment');

export class ApplicationEnvironment {
  get environment(): Environment {
    return environment;
  }
}
