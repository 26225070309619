<app-console-header
  (workSelect)="setWorkId($event)"
  (companySelect)="setCompanyId($event)"
></app-console-header>

@if (this.classificationVersion === 'v1') {
  <div class="parent classification-and-template">
    <app-console-image-classification-uploader
      [companyId]="companyId"
      [workId]="workId"
      (fileSelectMulti)="setFiles($event)"
      [multiple]="true"
    ></app-console-image-classification-uploader>
    <app-console-template-list
      [workId]="workId"
      [companyId]="companyId"
      [selectedTemplate]="selectedTemplate.id!"
      [readonly]="true"
    ></app-console-template-list>
  </div>
} @else {
  <div class="tag is-danger">分類Version2では「作成」のテンプレート追加時に、テストできます。</div>
}
<div class="parent workflow-and-output">
  <div style="display: flex; flex-direction: row; align-items: center; width: 100%">
    <div class="title" style="width: auto" translate>結果</div>
    <div style="flex-grow: 1"></div>
    <button (click)="saveCsvAll()" class="save" style="margin-left: 12px">
      <p translate>CSVダウンロード</p>
    </button>
  </div>
  @if (loading) {
    <app-inner-progress></app-inner-progress>
  }
  @if (loading) {
    <p>{{ loadingStat }}</p>
  }
  @for (data of datas; track data) {
    <div class="sample_parent">
      <div class="sample_image">
        <img [src]="data.img" />
      </div>
      <div class="sample_table">
        @if (data.stat == 'OK') {
          <app-spreadsheet [data]="data.csv" [readonly]="true"></app-spreadsheet>
        }
        @if (data.stat == 'ERROR') {
          <p translate>読み取り失敗</p>
        }
        @if (data.stat == 'TEMPLATE-NOTFOUND') {
          <p translate>テンプレートが見つかりませんでした</p>
        }
      </div>
    </div>
  }
</div>
