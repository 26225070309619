<div class="columns">
  <div class="column">
    <p class="subtitle is-5" *ngIf="selectedLatestMaster == null">
      {{ selectedMaster.name }}
    </p>
    <p class="subtitle is-5" *ngIf="selectedLatestMaster != null">
      {{ selectedLatestMaster.name }}
    </p>
    <div class="table-container">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>マスタ名</th>
            <th>更新</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!--STAGED-->
          <ng-container *ngIf="stagedHistories != null && isConsole">
            <tr class="staged-master" *ngFor="let item of stagedHistories">
              <td style="position: relative">
                <div style="min-width: 200px">{{ item.name }}</div>
                <div class="staged-master-tip">未反映</div>
              </td>
              <td class="no-opacity">
                <div class="has-text-grey-light" style="white-space: nowrap">
                  <span
                    tooltip="{{ item.createdBy | safeEmail }} が更新しました"
                  >
                    {{ item.createdBy | safeEmail }}
                  </span>
                </div>
                <div class="">
                  <span
                    class="has-text-grey-light"
                    tooltip="{{ item.createdAt | dateLocalString : true }}"
                    tooltipPlacement="bottom"
                    >{{ item.createdAt | dateLocalString }}</span
                  >
                </div>
              </td>
              <td>
                <ng-container> </ng-container>
              </td>
              <td class="no-opacity">
                <ng-container>
                  <button class="button" (click)="removeStagedMaster(item)">
                    取り消し
                  </button>
                </ng-container>
              </td>
            </tr>
          </ng-container>

          <tr *ngFor="let item of histories">
            <td>
              <div style="min-width: 200px">{{ item.name }}</div>
            </td>
            <td>
              <div class="has-text-grey-light" style="white-space: nowrap">
                <span tooltip="{{ item.createdBy | safeEmail }} が更新しました">
                  {{ item.createdBy | safeEmail }}
                </span>
              </div>
              <div class="">
                <span
                  class="has-text-grey-light"
                  tooltip="{{ item.createdAt | dateLocalString : true }}"
                  tooltipPlacement="bottom"
                  >{{ item.createdAt | dateLocalString }}</span
                >
              </div>
            </td>
            <td>
              <ng-container *ngIf="item.id !== latestMaster.id; else noRevert">
                <div class="is-inline-flex">
                  <button (click)="revert.emit(item)" class="button">
                    復元
                  </button>
                </div>
              </ng-container>
              <ng-template #noRevert>
                <p class="has-text-centered">現在のマスタ</p>
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="item.csv.filePath; else noFile">
                <button
                  download-master-csv
                  [filePath]="item.csv.filePath"
                  class="button"
                >
                  ダウンロード
                </button>
              </ng-container>
              <ng-template #noFile>
                <button disabled class="button is-small">ダウンロード</button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
