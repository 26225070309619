import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { WorkflowMasterDropdownComponent } from '../workflow-master-dropdown/workflow-master-dropdown.component';

import { TooltipDirective } from '../../../../../tooltip.directive';

import { GroupMasters } from '../master-map-select.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { MapWorkflow } from '../../../../../polar/entity/Workflow';
import { MasterDocumentData, MasterGroupDocumentData } from '../../../../../polar/entity/master';
import { Option } from '../workflow-master-dropdown/option';

@Component({
  selector: 'app-workflow-master-list',
  templateUrl: './workflow-master-list.component.html',
  styleUrls: ['./workflow-master-list.component.scss'],
  standalone: true,
  imports: [TooltipDirective, WorkflowMasterDropdownComponent],
})
export class WorkflowMasterListComponent {
  _companyMasters!: GroupMasters[];
  @Input() set companyMasters(val: GroupMasters[]) {
    this._companyMasters = val;
    this.createOptions();
  }
  @Input() options!: Option[];

  _selectedMaster?: MapWorkflow['master'];
  @Input() set selectedMaster(val: MapWorkflow['master']) {
    this._selectedMaster = val;
    this.createOptions();
  }

  get selectedMaster() {
    return this._selectedMaster;
  }

  @Output() readonly selectedMasterChange = new EventEmitter<MasterDocumentData>();
  @Output() readonly newMasterClicked = new EventEmitter();
  @Output() readonly editMasterClicked: EventEmitter<MasterDocumentData> = new EventEmitter();
  @Output() readonly deleteMasterClicked: EventEmitter<MasterDocumentData> = new EventEmitter();
  @Output() readonly useLatestMasterClicked: EventEmitter<MasterDocumentData> = new EventEmitter();

  constructor(private polar: PolarFirebaseService) {}

  createOptions() {
    let options: Option[] = [];
    for (const group of this._companyMasters) {
      if (group.group.type == 'withoutGroup') {
        for (const master of group.masters) {
          options.push({
            type: 'master',
            value: master,
            isLatest: this.isLatestMasterItem(master),
          } as Option);
        }
      } else {
        options.push({
          type: 'group',
          value: group.group,
          masters: group.masters,
          children: group.masters.map((master) => {
            return {
              type: 'master',
              value: master,
              isLatest: this.isLatestMasterItem(master),
            } as Option;
          }),
        } as Option);
      }
    }
    this.options = options;
  }

  private isLatestMasterItem(master: MasterDocumentData) {
    if (!this.selectedMaster) return 'not-selected';
    if (this.selectedMaster.id !== master.id) return 'not-selected';

    // TODO: something??
    //return this.selectedMaster.historyId === master.latestHistoryId ? 'latest' : 'not-latest';
    return 'latest';
  }

  dropdownChange($event: Option | undefined) {
    if ($event?.type == 'master') {
      this.selectedMasterChange.emit($event.value as MasterDocumentData);
    }
  }

  async downloadMaster(option: Option) {
    const master: MasterDocumentData = option.value as MasterDocumentData;
    const latestMaster = await this.polar.getLatestMaster(
      master.companyId,
      master.masterGroupId,
      master.id!,
      true,
    );
    const path = await this.polar.getCsvMasterFileUrl(latestMaster.csv.filePath!);
    // use a to download the file
    let a = document.createElement('a');
    a.href = path;
    a.download = 'master.csv';
    // simulate a click
    a.click();
    a.remove();
  }
}
