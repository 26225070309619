import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PolarFirebaseService } from '../../polar/polar-firebase.service';
import { CommonUiProviderService } from '../../common/common-ui-provider.service';
import { MAX_GROUP_NAME_LENGTH, validateInputLength } from '../utils';
import { CommonModule } from '@angular/common';
import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { LabelAndTextInputComponent } from '../../common/label-and-text-input/label-and-text-input.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-add-master-group-modal',
  standalone: true,
  imports: [CommonModule, InnerProgressComponent, LabelAndTextInputComponent, TranslateModule],
  templateUrl: './add-master-group-modal.component.html',
  styleUrls: ['./add-master-group-modal.component.scss'],
})
export class AddMasterGroupModalComponent {
  private readonly polar = inject(PolarFirebaseService);
  private readonly commonUi = inject(CommonUiProviderService);

  @Input() companyId!: string;

  @Output() saved: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  loading: boolean = false;
  currentGroupName: string = '';

  async save() {
    if (!(await this.validate())) {
      return;
    }

    try {
      this.loading = true;

      await this.polar.createMasterGroup(this.companyId, this.currentGroupName);
    } catch (e) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '処理中にエラーが発生しました。',
        buttons: ['OK'],
      });

      return;
    } finally {
      this.loading = false;
    }

    this.saved.emit();
  }

  private async validate() {
    if (!validateInputLength(this.currentGroupName, 1, MAX_GROUP_NAME_LENGTH)) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: `グループ名は 1 から ${MAX_GROUP_NAME_LENGTH} 文字以内で入力してください`,
        buttons: ['OK'],
      });

      return false;
    }

    return true;
  }
}
