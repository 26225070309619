import { Component, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() showSignOut: boolean = false;

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
  ) {}

  goMain() {
    this.router.navigate(['/main']);
  }

  async signOut() {
    await this.auth.signOut();
  }
}
