import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { CommonUiProviderService } from '../common/common-ui-provider.service';
import { UserWorkDownloadHistoryInfo } from '../polar/entity/CompanyInfo';
import { PolarFirebaseService } from '../polar/polar-firebase.service';

@Component({
  selector: 'app-modal-list-download-history',
  templateUrl: './modal-list-download-history.component.html',
  styleUrls: ['./modal-list-download-history.component.scss'],
})
export class ModalListDownloadHistoryComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() companyId: string = '';
  @Input() workId: string = '';
  downloadHistories: UserWorkDownloadHistoryInfo[] = [];

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
  ) {}

  async ngOnInit() {
    let obsv = await this.polar.getDownloadHistories(this.companyId, this.workId);
    obsv.subscribe((histories) => {
      this.downloadHistories = histories;
      // sort by createdAt
      this.downloadHistories.sort((a, b) => {
        return (b.createdAt as Timestamp).toMillis() - (a.createdAt as Timestamp).toMillis();
      });
    });
  }

  closeEvent() {
    this.close.emit();
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    return (timestamp as Timestamp).toDate().toLocaleString();
  }

  loading: boolean = false;

  async makeItAvailable(history: UserWorkDownloadHistoryInfo) {
    let result = await this.commonUi.showConfirm({
      title: '確認',
      body: 'このデータを再ダウンロード可能にしますか？',
      buttons: ['OK', 'キャンセル'],
    });
    if (result != 'OK') return;
    this.loading = true;

    let moved = false;

    for (let i = 0; i < history.downloadedIds!.length; i++) {
      let id = history.downloadedIds![i];

      try {
        let imageData = await this.polar.getUserDataImage(
          this.companyId,
          this.workId,
          'archived',
          id,
        );

        moved = true;

        // TODO: make this transactional
        imageData.status = 'confirmed';
        await this.polar.addUserDataImage(this.companyId, this.workId, imageData);
        await this.polar.deleteUserDataImage(this.companyId, this.workId, {
          id: id,
          status: 'archived',
        });
      } catch {}
    }

    if (!moved) {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '既に復元が実行された履歴です',
        buttons: ['OK'],
      });
    } else {
      await this.commonUi.showConfirm({
        title: '完了',
        body: '復元しました',
        buttons: ['OK'],
      });
      this.close.emit();
    }

    this.loading = false;
  }
}
