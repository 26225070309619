@if (!useFieldLayout) {
  <p class="drop-title">{{ label }}</p>
}
@if (!useFieldLayout) {
  <div class="dropdown" [ngClass]="getClassName()">
    <div class="dropdown-trigger" [ngClass]="notice ? 'deprecated' : null">
      <button
        class="button dropdown-button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        (click)="isActive = !isActive"
        [disabled]="disableEdit"
      >
        <span
          [style.maxWidth]="maxWidth"
          [style.overflow]="maxWidth != undefined ? 'hidden' : undefined"
          [style.textOverflow]="maxWidth != undefined ? 'ellipsis' : undefined"
          >{{ getName(selectedItem) }}</span
        >
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
      [style.left]="useRight ? 'auto' : null"
      [style.right]="useRight ? '0' : null"
    >
      <div class="dropdown-content" [ngClass]="scrollable ? 'content-scrollable' : undefined">
        @for (item of items; track item) {
          <a
            class="dropdown-item"
            [ngClass]="item.id == selectedItem ? 'is-active' : null"
            (click)="selectItem(item.id!)"
          >
            {{ getName(item.id!) }}
          </a>
        }
        @if (showNew) {
          <hr class="dropdown-divider" />
        }
        @if (showNew) {
          <a class="dropdown-item">新規追加</a>
        }
      </div>
    </div>
  </div>
}

@if (useFieldLayout) {
  <div class="field is-horizontal" style="margin-top: 8px; width: 100%">
    <div class="field-label is-normal">
      <label class="label">{{ label }}</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="dropdown" [ngClass]="getClassName()">
          <div class="dropdown-trigger" [ngClass]="notice ? 'deprecated' : null">
            <button
              class="button dropdown-button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              (click)="isActive = !isActive"
              [disabled]="disableEdit"
            >
              <span>{{ getName(selectedItem) }}</span>
              <span class="icon is-small">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div
            class="dropdown-menu"
            id="dropdown-menu"
            role="menu"
            [style.left]="useRight ? 'auto' : null"
            [style.right]="useRight ? '0' : null"
          >
            <div class="dropdown-content" [ngClass]="scrollable ? 'content-scrollable' : undefined">
              @for (item of items; track item) {
                <a
                  class="dropdown-item"
                  [ngClass]="item.id == selectedItem ? 'is-active' : null"
                  (click)="selectItem(item.id!)"
                >
                  {{ getName(item.id!) }}
                </a>
              }
              @if (showNew) {
                <hr class="dropdown-divider" />
              }
              @if (showNew) {
                <a class="dropdown-item">新規追加</a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
