import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterSettingsMainComponent } from '../master-settings/master-settings-main.component';
import { MASTER_UPDATING_SERVICE } from '../master-settings/master-updating.service';
import { MasterUpdatingUserService } from './master-updating-user.service';

@Component({
  selector: 'app-main-master-settings-modal',
  standalone: true,
  imports: [CommonModule, MasterSettingsMainComponent],
  templateUrl: './main-master-settings-modal.component.html',
  styleUrls: ['./main-master-settings-modal.component.scss'],
  providers: [{ provide: MASTER_UPDATING_SERVICE, useClass: MasterUpdatingUserService }],
})
export class MainMasterSettingsModalComponent {
  @Input() companyId!: string;
  @Input() companyName?: string = '';
  @Output() close = new EventEmitter();
}
