import { Injectable, inject } from '@angular/core';

import {
  MasterUpdatingService,
  UpdatingMasterCsv,
} from '../master-settings/master-updating.service';
import { PolarApiService } from '../polar/polar-api.service';
import { PolarFirebaseService } from '../polar/polar-firebase.service';

import {
  MasterDocumentData,
  MasterGroupDocumentData,
  MasterHistoryDocumentData,
} from '../polar/entity/master';

@Injectable()
export class MasterUpdatingUserService implements MasterUpdatingService {
  private readonly polar = inject(PolarFirebaseService);
  private readonly clova = inject(PolarApiService);

  async getCurrent() {
    console.log('current from user');
  }

  // TODO: 重複している
  async getMaster(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Promise<[MasterGroupDocumentData, MasterDocumentData] | undefined> {
    const masters = await this.polar.getMastersByIds(companyId, masterGroupId, [masterId]);
    const groups = await this.polar.getMasterGroupsByIds(companyId, [masterGroupId]);

    if (masters.length == 0 || groups.length == 0) {
      return undefined;
    }

    return [groups[0], masters[0]];
  }

  async updateMaster(
    companyId: string,
    masterGroupId: string,
    master: MasterHistoryDocumentData,
    csv: UpdatingMasterCsv,
  ) {
    if (csv) {
      const uploadedPath = await this.polar.uploadCompanyCsvMasterFile(
        companyId,
        'master.csv',
        masterGroupId,
        csv.csvFile,
      );

      master.csv = {
        filePath: uploadedPath,
        header: csv.csvHeader,
      };
    }

    return this.polar.updateMaster(companyId, masterGroupId, master);
  }

  confirmMessageWhenUpdate(): string {
    return 'マスタを更新すると、その時点でデータ化処理に反映されます。\n本当に実行しますか？';
  }

  confirmMessageWhenRevert(): string {
    return 'マスタを元に戻すと、その時点でデータ化処理に反映されます。\n本当に実行しますか？';
  }

  async applyToProduction(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    stagedId: string | undefined,
  ): Promise<void> {
    if (stagedId != undefined) {
      await this.clova.createVectorsForCsvMappingToSatisfyRequirement(
        companyId,
        masterGroupId,
        masterId,
        stagedId,
      );
      await this.polar.moveStagedMasterToProductionWithHistoryId(
        companyId,
        masterGroupId,
        masterId,
        stagedId,
      );
    }
    const latestMaster = await this.polar.getLatestMaster(
      companyId,
      masterGroupId,
      masterId,
      false,
    );
    await this.clova.createVectorsForCsvMappingToSatisfyRequirement(
      companyId,
      masterGroupId,
      masterId,
      latestMaster.id!,
    );
  }

  async revertMaster(
    companyId: string,
    master: MasterDocumentData,
    history: MasterHistoryDocumentData,
  ): Promise<void> {
    const revertTarget = await this.polar.revertMaster(companyId, master, history);
    try {
      await this.applyToProduction(companyId, master.masterGroupId, master.id!, revertTarget);
    } catch (e) {
      await this.polar.deleteMasterHistory(
        companyId,
        master.masterGroupId,
        master.id!,
        revertTarget,
      );
      throw e;
    }
  }

  async deleteMasterHistory(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    stagedId: string,
  ): Promise<void> {
    await this.polar.deleteMasterHistory(companyId, masterGroupId, masterId, stagedId);
  }
}
