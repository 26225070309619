@if (showConfirm) {
  <app-confirm-dialog
    (close)="confirmClosed($event)"
    [body]="confirmBody"
    [additonalLinkTitle]="confirmAdditionalLinkTitle"
    [additonalLinkAction]="confirmAdditionalLinkAction"
    [title]="confirmTitle"
    [buttons]="confirmButtons"
  ></app-confirm-dialog>
}
