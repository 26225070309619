import { Injectable } from '@angular/core';
// import AngularFireAuth from compat
import { AngularFireAuth } from '@angular/fire/compat/auth';
// firestore
import {
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  serverTimestamp,
} from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';

import { PolarFireswitchService } from './bridge/polar-fireswitch.service';

import {
  CompanyAllowedUser,
  CompanyInfo,
  UserImageInfo,
  UserImageInnerDataInfo,
  UserUploadFileReference,
  UserWorkCostInfo,
  UserWorkDownloadHistoryInfo,
  UserWorkInfo,
  WorkInfo,
} from './entity/CompanyInfo';
import {
  ClovaDomainUrlAndSecret,
  EmptyPrivateConfiguration,
  PrivateConfiguration,
} from './entity/PrivateConfiguration';
import { PromptConfiguration } from './entity/PromptConfiguration';
import { TemplateInfo } from './entity/TemplateInfo';
import {
  MasterDocumentData,
  MasterGroupDocumentData,
  MasterHistoryDocumentData,
  blankMasterDocumentData,
  blankMasterGroupDocumentData,
  createHistoryFromMasterData,
  withoutGroupName,
} from './entity/master';

import { FieldValue, Timestamp } from '@firebase/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolarFirebaseService {
  constructor(
    private auth: AngularFireAuth,
    private firestore: Firestore,
    private storage: Storage,
    private switcher: PolarFireswitchService,
  ) {}

  async initUser() {
    let user = await this.auth.currentUser;

    if (user == null) throw new Error('user is not signed in');
    // create user collection with companies if not exists
    let col = this.switcher.collection(this.firestore, 'users');
    let userDoc = this.switcher.doc(col, user.uid);
    let userDocData = (await this.switcher.getDoc(userDoc)).data();
    if (userDocData == null) {
      await this.switcher.setDoc(userDoc, {
        companies: [],
      });
    } else {
      const groupId = userDocData['authorized_group_id'] as string;
      if (groupId != undefined && groupId.length > 0) {
        // group is is set. use firebridge for storage
        this.switcher.useBridgeOnStorage = true;
        if (await this.isAdmin()) {
          this.switcher.useBridgeOnStorage = false;
        }
        console.log('use firebridge on storage');
      }
    }
  }

  async getUserProtectionType(): Promise<string> {
    let user = await this.auth.currentUser;

    if (user == null) return '';
    let col = this.switcher.collection(this.firestore, 'users');
    let userDoc = this.switcher.doc(col, user.uid);
    let userDocData = (await this.switcher.getDoc(userDoc)).data();

    if (
      userDocData != null &&
      userDocData['protection'] != undefined &&
      userDocData['protection'] != ''
    ) {
      return userDocData['protection'];
    }

    return '';
  }

  async getUserCompany(): Promise<string | undefined> {
    let user = await this.auth.currentUser;

    if (user == null) throw new Error('user is not signed in');
    // create user collection with companies if not exists
    let col = this.switcher.collection(this.firestore, 'users');
    let userDoc = this.switcher.doc(col, user.uid);
    let userDocData = (await this.switcher.getDoc(userDoc)).data();
    if (userDocData == null) {
      return undefined;
    } else {
      return userDocData['company'];
    }
  }

  async isAdmin() {
    let user = await this.auth.currentUser;
    if (user == null) throw new Error('user is not signed in');

    try {
      let col = this.switcher.collection(this.firestore, 'admins');
      let userDoc = this.switcher.doc(col, user.uid);
      let userDocData = (await this.switcher.getDoc(userDoc)).data();
      return userDocData != undefined;
    } catch (e) {
      return false;
    }
  }

  async getConfigurations(): Promise<PrivateConfiguration> {
    let col = this.switcher.collection(this.firestore, 'settings');
    let privateDoc = this.switcher.doc(col, 'private');
    let privateDocData = (await this.switcher.getDoc(privateDoc)).data();
    return (privateDocData as PrivateConfiguration) ?? EmptyPrivateConfiguration;
  }

  // setConfiguration
  async setConfiguration(val: PrivateConfiguration) {
    let col = this.switcher.collection(this.firestore, 'settings');
    let privateDoc = this.switcher.doc(col, 'private');
    await this.switcher.setDoc(privateDoc, val);
  }

  async getConfigurationsClovaDomains(): Promise<Observable<ClovaDomainUrlAndSecret[]>> {
    let col = await this.switcher.collection(this.firestore, 'settings/private/clova_domains');
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<
      ClovaDomainUrlAndSecret[]
    >;
  }

  async deleteConfigurationsClovaDomain(val: ClovaDomainUrlAndSecret) {
    let col = await this.switcher.collection(this.firestore, 'settings/private/clova_domains');
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }

  async setConfigurationsClovaDomain(val: ClovaDomainUrlAndSecret) {
    let col = await this.switcher.collection(this.firestore, 'settings/private/clova_domains');
    if (val.id == undefined || val.id == '' || val.id == null) {
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
    } else {
      let docRef = this.switcher.doc(col, val.id);
      await this.switcher.setDoc(docRef, val);
    }
  }

  async getPromptConfigurations(): Promise<Observable<PromptConfiguration[]>> {
    let col = await this.switcher.collection(this.firestore, 'settings/private/prompt');
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<
      PromptConfiguration[]
    >;
  }

  async setPromptConfiguration(val: PromptConfiguration) {
    let col = await this.switcher.collection(this.firestore, 'settings/private/prompt');
    if (val.id == undefined || val.id == '' || val.id == null) {
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
    } else {
      let docRef = this.switcher.doc(col, val.id);
      await this.switcher.setDoc(docRef, val);
    }
  }

  async deletePromptConfiguration(val: PromptConfiguration) {
    let col = await this.switcher.collection(this.firestore, 'settings/private/prompt');
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }

  async getCompany(id: string): Promise<CompanyInfo> {
    let col = await this.switcher.collection(this.firestore, 'companies');
    let docRef = this.switcher.doc(col, id);
    let docData = (await this.switcher.getDoc(docRef)).data({}) as CompanyInfo;
    docData.id = id;
    return docData as CompanyInfo;
  }

  async getCompanies(): Promise<Observable<CompanyInfo[]>> {
    let col = await this.switcher.collection(this.firestore, 'companies');
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<CompanyInfo[]>;
  }

  async getCompaniesArray(): Promise<CompanyInfo[]> {
    let col = await this.switcher.collection(this.firestore, 'companies');
    let docs = await this.switcher.getDocs(col);
    let ret: CompanyInfo[] = [];
    docs.forEach((doc) => {
      let data = doc.data() as CompanyInfo;
      data.id = doc.id;
      ret.push(data);
    });
    return ret;
  }

  async setCompany(val: CompanyInfo) {
    let col = await this.switcher.collection(this.firestore, 'companies');
    if (val.id == undefined || val.id == '' || val.id == null) {
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
    } else {
      let docRef = this.switcher.doc(col, val.id);
      await this.switcher.setDoc(docRef, val);
    }
  }

  async deleteCompany(val: CompanyInfo) {
    let col = await this.switcher.collection(this.firestore, 'companies');
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }

  async getAllowedUsersInCompany(companyId: string): Promise<Observable<CompanyAllowedUser[]>> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/allowed');
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<CompanyAllowedUser[]>;
  }

  async getRestrictIPs(companyId: string): Promise<string[]> {
    const col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/ips');
    const docRef = this.switcher.doc(col, 'all');
    const ipsObject = ((await this.switcher.getDoc(docRef)).data() as { ips: string[] }) ?? [];
    return ipsObject.ips;
  }

  async updateRestrictIPList(companyId: string, val: string[]): Promise<void> {
    const col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/ips');
    const docRef = this.switcher.doc(col, 'all');
    await this.switcher.setDoc(docRef, { ips: val });
  }

  async addAllowedUserInCompany(companyId: string, val: CompanyAllowedUser): Promise<void> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/allowed');
    let docRef = this.switcher.doc(col, val.id!);
    await this.switcher.setDoc(docRef, val);

    // update user primary company
    col = this.switcher.collection(this.firestore, 'users');
    let userDoc = this.switcher.doc(col, val.id!);

    // TODO: we may want to change this to be transactional in the future
    // but we dont have the usage of users collection other than this right now
    if ((await this.switcher.getDoc(userDoc)).exists()) {
      await this.switcher.setDoc(
        userDoc,
        {
          company: companyId,
        },
        { merge: true },
      );
    } else {
      await this.switcher.setDoc(userDoc, {
        company: companyId,
      });
    }
  }

  async checkUserExists(userId: string): Promise<boolean> {
    let col = this.switcher.collection(this.firestore, 'users');
    let userDoc = this.switcher.doc(col, userId);
    return (await this.switcher.getDoc(userDoc)).exists();
  }

  async addAllowedIpRestrictionUserInCompany(
    companyId: string,
    val: CompanyAllowedUser,
  ): Promise<void> {
    const userCollection = this.switcher.collection(this.firestore, 'users');
    const userDocuments = this.switcher.doc(userCollection, val.id);
    await this.switcher.setDoc(
      userDocuments,
      {
        company: companyId,
        protection: 'ip',
      },
      { merge: true },
    );
    const companyUserCollection = await this.switcher.collection(
      this.firestore,
      'companies/' + companyId + '/allowed',
    );
    const uidProxy = val.id + '_proxy';
    const companyUserDocumentRef = this.switcher.doc(companyUserCollection, uidProxy);
    await this.switcher.setDoc(companyUserDocumentRef, val);
  }

  async deleteAllowedIpRestrictionUserInCompany(
    companyId: string,
    val: CompanyAllowedUser,
  ): Promise<void> {
    const userCollection = this.switcher.collection(this.firestore, 'users');
    const userDocuments = this.switcher.doc(userCollection, val.id?.replace('_proxy', ''));
    await this.switcher.setDoc(
      userDocuments,
      {
        company: companyId,
        protection: '',
      },
      { merge: true },
    );
    const companyUserCollection = await this.switcher.collection(
      this.firestore,
      'companies/' + companyId + '/allowed',
    );
    const companyUserDocumentRef = this.switcher.doc(companyUserCollection, val.id);
    await this.switcher.deleteDoc(companyUserDocumentRef);

    val.id = val.id?.replace('_proxy', '');
    const disableIpCompanyUserDocumentRef = this.switcher.doc(companyUserCollection, val.id);
    await this.switcher.setDoc(disableIpCompanyUserDocumentRef, val);
  }

  async deleteAllowedUserInCompany(companyId: string, val: CompanyAllowedUser): Promise<void> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/allowed');
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);

    // update user primary company as empty
    col = this.switcher.collection(this.firestore, 'users');
    let userDoc = this.switcher.doc(col, val.id!);
    await this.switcher.setDoc(
      userDoc,
      {
        company: '',
      },
      { merge: true },
    );
  }

  async getWork(companyId: string, id: string): Promise<WorkInfo> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/works');
    let docRef = this.switcher.doc(col, id);
    let docData = (await this.switcher.getDoc(docRef)).data({}) as WorkInfo;
    docData.id = id;
    return docData as WorkInfo;
  }

  async getWorksInCompany(companyId: string): Promise<Observable<WorkInfo[]>> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/works');
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<WorkInfo[]>;
  }

  async getWorksInCompanyArray(companyId: string): Promise<WorkInfo[]> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/works');
    let docs = await this.switcher.getDocs(col);
    let ret: WorkInfo[] = [];
    docs.forEach((doc) => {
      let data = doc.data() as WorkInfo;
      data.id = doc.id;
      ret.push(data);
    });
    return ret;
  }

  async setWorkInCompany(companyId: string, val: WorkInfo): Promise<string> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/works');
    if (val.createdAt == null) {
      val.createdAt = serverTimestamp();
    }
    val.updatedAt = serverTimestamp();
    if (val.id == undefined || val.id == '' || val.id == null) {
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
      return docRef.id;
      /*
      await this.addTemplate(companyId, docRef.id, {
        id: 'default',
        createdAt: new Date(),
      });*/
    } else {
      let docRef = this.switcher.doc(col, val.id);
      await this.switcher.setDoc(docRef, val);
      return val.id;
    }
  }

  async deleteWorkInCompany(companyId: string, val: WorkInfo): Promise<void> {
    let col = await this.switcher.collection(this.firestore, 'companies/' + companyId + '/works');
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }

  async getTemplates(companyId: string, workId: string): Promise<Observable<TemplateInfo[]>> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies/' + companyId + '/works/' + workId + '/templates',
    );
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<TemplateInfo[]>;
  }

  async getTemplatesAsArray(companyId: string, workId: string): Promise<TemplateInfo[]> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies/' + companyId + '/works/' + workId + '/templates',
    );
    let docs = await this.switcher.getDocs(col);
    let ret: TemplateInfo[] = [];
    docs.forEach((doc) => {
      let data = doc.data() as TemplateInfo;
      data.id = doc.id;
      ret.push(data);
    });
    return ret;
  }

  async addTemplate(companyId: string, workId: string, val: TemplateInfo): Promise<{ id: string }> {
    const col = await this.switcher.collection(
      this.firestore,
      'companies/' + companyId + '/works/' + workId + '/templates',
    );
    if (val.id == undefined || val.id == '' || val.id == null) {
      const docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
      return { id: docRef.id };
    } else {
      const docRef = this.switcher.doc(col, val.id!);
      await this.switcher.setDoc(docRef, val, { merge: true });
      return { id: docRef.id };
    }
  }

  async deleteTemplate(companyId: string, workId: string, val: TemplateInfo): Promise<void> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies/' + companyId + '/works/' + workId + '/templates',
    );
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }

  // generate UUID
  private generateUUID(): string {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  async uploadTemplateFile(companyId: string, workId: string, file: File): Promise<string> {
    let path = companyId + '/' + workId + '/' + 'template-files' + '/' + this.generateUUID();
    let storageRef = this.switcher.ref(this.storage, path);
    await this.switcher.uploadBytes(storageRef, file);
    return path;
  }

  async uploadCsvMasterFile(
    companyId: string,
    workId: string,
    fileName: string,
    file: File,
  ): Promise<string> {
    let path =
      companyId +
      '/' +
      workId +
      '/' +
      'csv-master-files' +
      '/' +
      this.generateUUID() +
      '/' +
      fileName;
    let storageRef = this.switcher.ref(this.storage, path);
    await this.switcher.uploadBytes(storageRef, file);
    return path;
  }

  async getCsvMasterFileUrl(path: string): Promise<string> {
    let storageRef = this.switcher.ref(this.storage, path);
    return await this.switcher.getDownloadURL(storageRef);
  }

  async getTemplateFile(companyId: string, workId: string, path: string): Promise<string> {
    let storageRef = this.switcher.ref(this.storage, path);
    return await this.switcher.getDownloadURL(storageRef);
  }

  async getUserWork(companyId: string, workId: string) {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works',
    );
    let docRef = this.switcher.doc(col, workId);
    let docData = (await this.switcher.getDoc(docRef)).data({}) as UserWorkInfo;
    docData.id = workId;
    return docData;
  }

  async userWorkExists(companyId: string, workId: string) {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works',
    );
    let docRef = this.switcher.doc(col, workId);
    return (await this.switcher.getDoc(docRef)).exists();
  }

  async setUserWork(companyId: string, workId: string, val: UserWorkInfo) {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works',
    );
    let docRef = this.switcher.doc(col, workId);

    // check existence and set createdAt
    let existingDoc = this.switcher.getDoc(docRef);
    if (!(await existingDoc).exists()) {
      val.createdAt = serverTimestamp();
    }

    await this.switcher.setDoc(docRef, val, { merge: true });
    // overwrite fields
    if (val.reflection != undefined && val.reflection != null) {
      await this.switcher.setDoc(docRef, val, { mergeFields: ['reflection'] } as any);
    }
  }

  async initUserDataImages(companyId: string, workId: string) {
    try {
      let col = await this.switcher.collection(this.firestore, 'companies_userdata');
      let docRef = this.switcher.doc(col, companyId);
      await this.switcher.setDoc(docRef, {}, { merge: true });
    } catch {
      console.log(
        'insufficient permission to create companies_userdata but ignores since its not mandatory',
      );
    }

    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works',
    );
    let docRef = this.switcher.doc(col, workId);
    await this.switcher.setDoc(docRef, {}, { merge: true });
  }

  async getUserDataImages(
    companyId: string,
    workId: string,
    status: 'uploaded' | 'converted' | 'failed' | 'succeeded' | 'confirmed' | 'archived',
  ): Promise<Observable<UserImageInfo[]>> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/' + status,
    );
    // sort by createdAt and imageIndexFrom
    let q = this.switcher.query(col, this.switcher.orderBy('createdAt'));
    return this.switcher.collectionData(q, { idField: 'id' }) as Observable<UserImageInfo[]>;
  }

  async getUserDataImage(
    companyId: string,
    workId: string,
    status: 'uploaded' | 'converted' | 'failed' | 'succeeded' | 'confirmed' | 'archived',
    id: string,
  ): Promise<UserImageInfo> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/' + status,
    );
    let docRef = this.switcher.doc(col, id);
    let docData = (await this.switcher.getDoc(docRef)).data({}) as UserImageInfo;
    docData.id = id;
    return docData;
  }

  prepareUploadUserFile(
    companyId: string,
    workId: string,
    userImageId: string,
  ): UserUploadFileReference {
    const id = this.generateUUID();
    const type = 'user-files';
    const path = companyId + '/' + workId + '/' + type + '/' + id;
    return { companyId, workId, id, type, path, relatedId: userImageId };
  }

  prepareUploadArchivedFile(
    companyId: string,
    workId: string,
    historyId: string,
  ): UserUploadFileReference {
    const id = this.generateUUID();
    const type = 'archived-files';
    const path = companyId + '/' + workId + '/' + type + '/' + id;
    return { companyId, workId, id, type, path, relatedId: historyId };
  }

  async uploadUserFilePrepared(val: UserUploadFileReference, file: File): Promise<string> {
    let storageRef = this.switcher.ref(this.storage, val.path);
    await this.switcher.uploadBytes(storageRef, file);
    return val.path;
  }

  async getUserFileUrl(companyId: string, workId: string, path: string): Promise<string> {
    let storageRef = this.switcher.ref(this.storage, path);
    return await this.switcher.getDownloadURL(storageRef);
  }

  async createUploadFileReferencePrepared(prepared: UserUploadFileReference): Promise<void> {
    const col = await this.switcher.collection(
      this.firestore,
      'companies_upload_ref/' + prepared.companyId + '/' + prepared.type,
    );

    // create document by image ID
    let docRef = this.switcher.doc(col, prepared.id);
    prepared.createdAt = serverTimestamp();
    await this.switcher.setDoc(docRef, prepared);
  }

  async addUserDataImage(companyId: string, workId: string, val: UserImageInfo): Promise<void> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/' + val.status,
    );
    if (val.id == undefined || val.id == '' || val.id == null) {
      let docRef = this.switcher.doc(col);
      await this.switcher.setDoc(docRef, val);
    } else {
      let docRef = this.switcher.doc(col, val.id!);
      await this.switcher.setDoc(docRef, val, { merge: true });
    }
  }

  async prepareAddUserDataImage(
    companyId: string,
    workId: string,
    status: string,
  ): Promise<DocumentReference<DocumentData>> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/' + status,
    );

    let docRef = this.switcher.doc(col);
    return docRef;
  }

  async setUserDataImagePrepared(
    docRef: DocumentReference<DocumentData>,
    val: UserImageInfo,
  ): Promise<void> {
    await this.switcher.setDoc(docRef, val);
  }

  async deleteUserDataImage(companyId: string, workId: string, val: UserImageInfo): Promise<void> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/' + val.status,
    );
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.deleteDoc(docRef);
  }

  async addUserImageInnerData(
    companyId: string,
    workId: string,
    val: UserImageInnerDataInfo,
  ): Promise<void> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/inner_data',
    );
    let docRef = this.switcher.doc(col, val.id);
    await this.switcher.setDoc(docRef, val, { merge: true });
  }

  async getUserImageInnerData(
    companyId: string,
    workId: string,
    id: string,
  ): Promise<UserImageInnerDataInfo> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/inner_data',
    );
    let docRef = this.switcher.doc(col, id);
    let docData = (await this.switcher.getDoc(docRef)).data({}) as UserImageInnerDataInfo;
    docData.id = id;
    return docData;
  }

  sliceByNumber = (array: string[], number: number) => {
    const length = Math.ceil(array.length / number);
    return new Array(length)
      .fill(undefined)
      .map((_, i) => array.slice(i * number, (i + 1) * number));
  };

  async getUserImageInnerDataListByIds(
    companyId: string,
    workId: string,
    ids: string[],
  ): Promise<UserImageInnerDataInfo[]> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/inner_data',
    );

    // where query only supports 10 ids
    let batches = this.sliceByNumber(ids, 10);

    let ret: UserImageInnerDataInfo[] = [];

    for (let i = 0; i < batches.length; i++) {
      let batch = batches[i];
      let docs = await this.switcher.getDocs(
        this.switcher.query(col, this.switcher.where(this.switcher.documentId(), 'in', batch)),
      );
      docs.forEach((doc) => {
        let docData = doc.data() as UserImageInnerDataInfo;
        docData.id = doc.id;
        ret.push(docData);
      });
    }
    return ret;
  }

  async addDownloadHistory(
    companyId: string,
    workId: string,
    val: UserWorkDownloadHistoryInfo,
  ): Promise<string> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/download_history',
    );
    let docRef = this.switcher.doc(col);
    await this.switcher.setDoc(docRef, val);
    let data = await (await this.switcher.getDoc(docRef)).data();
    return docRef.id;
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    const _date = (timestamp as Timestamp).toDate();
    const _year = _date.getFullYear();
    const _month = String(_date.getMonth() + 1).padStart(2, '0');
    const _day = String(_date.getDate()).padStart(2, '0');
    return `${_year}${_month}${_day}`;
  }

  async getDownloadHistories(
    companyId: string,
    workId: string,
  ): Promise<Observable<UserWorkDownloadHistoryInfo[]>> {
    let col = await this.switcher.collection(
      this.firestore,
      'companies_userdata/' + companyId + '/works/' + workId + '/download_history',
    );
    return this.switcher.collectionData(col, { idField: 'id' }) as Observable<
      UserWorkDownloadHistoryInfo[]
    >;
  }

  async calcCost(
    companyId: string,
    workId: string,
    startTimestamp: Timestamp,
    endTimeestamp: Timestamp,
  ) {
    // get documents from startTimestamp to endTimeestamp
    let col = await this.switcher.collection(
      this.firestore,
      'companies_cost/' + companyId + '/works/' + workId + '/succeeded',
    );
    let docs = await this.switcher.getDocs(
      this.switcher.query(
        col,
        this.switcher.where('createdAt', '>=', startTimestamp),
        this.switcher.where('createdAt', '<', endTimeestamp),
      ),
    );
    let cost = 0;
    docs.forEach((doc) => {
      let data = doc.data() as UserWorkCostInfo;
      if (data != null && data.costs != null && data.costs['cells'] != null)
        cost += data.costs['cells'];
    });
    return cost;
  }

  async isUserInDemoCollection(userId: string): Promise<boolean> {
    let col = await this.switcher.collection(this.firestore, 'demo_users');
    let docRef = this.switcher.doc(col, userId);
    let docData = await this.switcher.getDoc(docRef);
    return docData.exists();
  }

  // #######################################################

  private mastersRef = (companyId: string, masterGroupId: string) =>
    this.switcher.collection(
      this.firestore,
      `companies/${companyId}/master_groups/${masterGroupId}/masters`,
    );

  private masterGroupsRef = (companyId: string) =>
    this.switcher.collection(this.firestore, `companies/${companyId}/master_groups`);

  private masterHistoriesRef = (companyId: string, masterGroupId: string, masterId: string) =>
    this.switcher.collection(
      this.firestore,
      `companies/${companyId}/master_groups/${masterGroupId}/masters/${masterId}/master_histories`,
    );

  private masterHistoriesStagedRef = (companyId: string, masterGroupId: string, masterId: string) =>
    this.switcher.collection(
      this.firestore,
      `companies/${companyId}/master_groups/${masterGroupId}/masters/${masterId}/master_histories_staged`,
    );

  private masterTrainingRequirementsRef = (
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ) =>
    this.switcher.collection(
      this.firestore,
      `companies/${companyId}/master_groups/${masterGroupId}/masters/${masterId}/master_training_requirements`,
    );

  // private masterHistoriesRefWithPaginate = (companyId: string, masterGroupId: string, masterId: string, currentPage: QuerySnapshot<DocumentSnapshot>) =>
  //   this.switcher.query(
  //     this.switcher.collection(
  //       this.firestore, `companies/${companyId}/master_groups/${masterGroupId}/masters/${masterId}/master_histories`
  //     ),
  //     this.switcher.orderBy("createdAt"),
  //     this.switcher.limit(10),
  //     this.switcher.startAfter(currentPage.docs[currentPage.docs.length - 1])
  //   )

  getMastersByIdWithListening(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Observable<MasterDocumentData[]> {
    return this.switcher.collectionData(
      this.switcher.query(
        this.mastersRef(companyId, masterGroupId),
        this.switcher.where(this.switcher.documentId(), '==', masterId),
      ),
      { idField: 'id' },
    ) as Observable<MasterDocumentData[]>;
  }

  async getMasterGroups(companyId: string): Promise<MasterGroupDocumentData[]> {
    // TODO make sure that using converters is better than this
    // 特定のフィールドだけ取得するには admin-sdk が必要なので、ここでは全て取得する
    return (await this.switcher.getDocs(this.masterGroupsRef(companyId))).docs.map((doc) => {
      // TODO: converter を使えるように改修する
      return { id: doc.id, ...doc.data() };
    }) as MasterGroupDocumentData[];
  }

  getMasterGroupsWithListening(companyId: string): Observable<MasterGroupDocumentData[]> {
    return this.switcher.collectionData(this.masterGroupsRef(companyId), {
      idField: 'id',
    }) as Observable<MasterGroupDocumentData[]>;
  }

  getMastersInGroupWithListening(
    companyId: string,
    masterGroupId: string,
  ): Observable<MasterDocumentData[]> {
    return this.switcher.collectionData(this.mastersRef(companyId, masterGroupId), {
      idField: 'id',
    }) as Observable<MasterDocumentData[]>;
  }

  async getMastersInGroup(companyId: string, masterGroupId: string): Promise<MasterDocumentData[]> {
    return this.switcher.getDocs(this.mastersRef(companyId, masterGroupId)).then(
      (docs) =>
        docs.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        }) as MasterDocumentData[],
    );
  }

  async getMastersByIds(
    companyId: string,
    masterGroupId: string,
    masterId: string[],
  ): Promise<MasterDocumentData[]> {
    return (
      await this.switcher.getDocs(
        this.switcher.query(
          this.mastersRef(companyId, masterGroupId),
          this.switcher.where(this.switcher.documentId(), 'in', masterId),
        ),
      )
    ).docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    }) as MasterDocumentData[];
  }

  async getMasterGroupsByIds(
    companyId: string,
    masterGroupId: string[],
  ): Promise<MasterGroupDocumentData[]> {
    return (
      await this.switcher.getDocs(
        this.switcher.query(
          this.masterGroupsRef(companyId),
          this.switcher.where(this.switcher.documentId(), 'in', masterGroupId),
        ),
      )
    ).docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    }) as MasterGroupDocumentData[];
  }

  getMasterHistoriesWithListening(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Observable<MasterHistoryDocumentData[]> {
    return this.switcher.collectionData(
      this.masterHistoriesRef(companyId, masterGroupId, masterId),
      { idField: 'id' },
    ) as Observable<MasterHistoryDocumentData[]>;
  }

  getMasterHistoriesStagedWithListening(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Observable<MasterHistoryDocumentData[]> {
    return this.switcher.collectionData(
      this.masterHistoriesStagedRef(companyId, masterGroupId, masterId),
      { idField: 'id' },
    ) as Observable<MasterHistoryDocumentData[]>;
  }

  async getMasterHistoriesStaged(
    companyId: string,
    masterGroupId: string,
    masterId: string,
  ): Promise<MasterHistoryDocumentData[]> {
    return (
      await this.switcher.getDocs(this.masterHistoriesStagedRef(companyId, masterGroupId, masterId))
    ).docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    }) as MasterHistoryDocumentData[];
  }

  async getMasterHistoriesByIds(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    historyIds: string[],
  ): Promise<MasterHistoryDocumentData[]> {
    return (
      await this.switcher.getDocs(
        this.switcher.query(
          this.masterHistoriesRef(companyId, masterGroupId, masterId),
          this.switcher.where(this.switcher.documentId(), 'in', historyIds),
        ),
      )
    ).docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    }) as MasterHistoryDocumentData[];
  }

  private async getValidUser() {
    const user = await this.auth.currentUser;

    if (user == null || user.email == null) {
      throw new Error('user is null');
    }

    return user;
  }

  private async getMasterCreatorInfo(): Promise<MasterDocumentData['createdBy']> {
    const { uid, email } = await this.getValidUser();
    const isAdmin = await this.isAdmin();

    return {
      uid,
      emailSnapshot: isAdmin ? null : email,
      isAdminSnapshot: isAdmin,
    };
  }

  async createMasterGroupAsWithoutGroup(companyId: string): Promise<MasterGroupDocumentData> {
    return this._createMasterGroup(companyId, withoutGroupName, 'withoutGroup');
  }

  async createMasterGroup(companyId: string, groupName: string): Promise<MasterGroupDocumentData> {
    return this._createMasterGroup(companyId, groupName, 'group');
  }

  private async _createMasterGroup(
    companyId: string,
    groupName: string,
    type: MasterGroupDocumentData['type'],
  ): Promise<MasterGroupDocumentData> {
    const { uid, email } = await this.getValidUser();

    const newGroupRef = this.switcher.doc(this.masterGroupsRef(companyId));
    const group: MasterGroupDocumentData = {
      ...blankMasterGroupDocumentData,
      type: type,
      name: groupName,
      createdBy: uid,
    };

    await this.switcher.setDoc(newGroupRef, {
      ...group,
      createdAt: serverTimestamp(),
    });

    return { ...group, id: newGroupRef.id };
  }

  async updateMaster(
    companyId: string,
    masterGroupId: string,
    masterDocData: MasterHistoryDocumentData,
  ): Promise<string> {
    const currentMasterDocRef = this.switcher.doc(
      this.mastersRef(companyId, masterGroupId),
      masterDocData.masterId,
    );

    const currentMasterDocSnapshot = await this.switcher.getDoc(currentMasterDocRef);

    if (!currentMasterDocSnapshot.exists()) {
      throw new Error('master not found');
    }

    const creatorInfo = await this.getMasterCreatorInfo();

    const currentMasterDocData = {
      id: currentMasterDocRef.id,
      ...currentMasterDocSnapshot.data(),
    } as MasterDocumentData;

    const updatingMaster: MasterDocumentData = {
      ...currentMasterDocData,
      name: masterDocData.name,
      csv: masterDocData.csv,
      createdBy: creatorInfo,
    };

    // writeBatch
    {
      // NOTE: 更新する master を履歴に保存する. 本番反映しないのでStagedに置く
      const newHistoryDocRef = this.switcher.doc(
        this.masterHistoriesStagedRef(companyId, masterGroupId, currentMasterDocRef.id),
      );

      // never update parent master. its just for storing purpose since we always reference history data
      /*
      await this.switcher.setDoc(
        currentMasterDocRef,
        {
          ...updatingMaster,
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );*/

      // NOTE: 履歴と時刻を合わせるために再取得する (batch で処理すれば同じ時間になるので不要)
      /*
      const updatedMasterDocData = (
        await this.switcher.getDoc(currentMasterDocRef)
      ).data() as MasterDocumentData;
      */

      let newHistory = createHistoryFromMasterData(updatingMaster, currentMasterDocData.id!);

      newHistory.createdAt = serverTimestamp();

      if (newHistory.reason == undefined) {
        newHistory.reason = '';
      }

      await this.switcher.setDoc(newHistoryDocRef, newHistory);

      return newHistoryDocRef.id;
    }
  }

  async getLatestMaster(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    staged: boolean,
  ): Promise<MasterHistoryDocumentData> {
    const snapshots = (
      await this.switcher.getDocs(
        this.switcher.query(
          this.masterHistoriesRef(companyId, masterGroupId, masterId),
          this.switcher.orderBy('createdAt'),
        ),
      )
    ).docs;

    if (snapshots.length < 1) {
      // this is impossible
      throw new Error('master history not found');
    }

    let latestSnapshot = {
      id: snapshots[snapshots.length - 1].id,
      ...snapshots[snapshots.length - 1].data(),
    } as MasterHistoryDocumentData;

    if (staged) {
      const stagedSnapshots = (
        await this.switcher.getDocs(
          this.switcher.query(
            this.masterHistoriesStagedRef(companyId, masterGroupId, masterId),
            this.switcher.orderBy('createdAt'),
          ),
        )
      ).docs;

      if (stagedSnapshots.length > 0) {
        let latestStagedSnapshot = {
          id: stagedSnapshots[stagedSnapshots.length - 1].id,
          ...stagedSnapshots[stagedSnapshots.length - 1].data(),
        } as MasterHistoryDocumentData;

        if (
          (latestStagedSnapshot.createdAt as Timestamp).toMillis() >
          (latestSnapshot.createdAt as Timestamp).toMillis()
        ) {
          latestSnapshot = latestStagedSnapshot;
        }
      }
    }

    return latestSnapshot;
  }

  // stagedマスタの本番反映
  async moveStagedMastersToProduction(companyId: string, masterGroupId: string, masterId: string) {
    const stagedMasters = await this.switcher.getDocs(
      this.switcher.query(
        this.masterHistoriesStagedRef(companyId, masterGroupId, masterId),
        this.switcher.orderBy('createdAt'),
      ),
    );

    if (stagedMasters.docs.length < 1) {
      // nothing to do
      return;
    }

    stagedMasters.docs.forEach(async (doc) => {
      const ref = this.switcher.doc(
        this.masterHistoriesRef(companyId, masterGroupId, masterId),
        doc.id,
      );

      // NOTE: 本番反映
      await this.switcher.setDoc(ref, doc.data());

      // NOTE: 本番反映したのでStagedから削除する
      await this.switcher.deleteDoc(doc.ref);
    });
  }

  async moveStagedMasterToProductionWithHistoryId(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    historyId: string,
  ) {
    const ref = this.switcher.doc(
      this.masterHistoriesStagedRef(companyId, masterGroupId, masterId),
      historyId,
    );

    const doc = await this.switcher.getDoc(ref);

    if (!doc.exists()) {
      // nothing to do
      return;
    }

    // NOTE: 本番反映
    await this.switcher.setDoc(
      this.switcher.doc(this.masterHistoriesRef(companyId, masterGroupId, masterId), historyId),
      doc.data(),
    );

    // NOTE: 本番反映したのでStagedから削除する
    await this.switcher.deleteDoc(ref);
  }

  // stagedマスタの本番反映: 会社内全て
  async moveStagedMastersToProductionInCompany(companyId: string) {
    const masterGroups = await this.switcher.getDocs(
      this.switcher.query(this.masterGroupsRef(companyId)),
    );

    if (masterGroups.docs.length < 1) {
      // nothing to do
      return;
    }

    masterGroups.docs.forEach(async (doc) => {
      const masterGroupId = doc.id;

      const masters = await this.switcher.getDocs(
        this.switcher.query(this.mastersRef(companyId, masterGroupId)),
      );

      if (masters.docs.length < 1) {
        // nothing to do
        return;
      }

      masters.docs.forEach(async (doc) => {
        const masterId = doc.id;

        await this.moveStagedMastersToProduction(companyId, masterGroupId, masterId);
      });
    });
  }

  async createMasterWithoutGroup(
    companyId: string,
    masterDocData: MasterDocumentData,
  ): Promise<void> {
    const withoutGroupType: MasterGroupDocumentData['type'] = 'withoutGroup';

    // NOTE: 所属グループ無しの場合
    const withoutGroup = await this.switcher.getDocs(
      this.switcher.query(
        this.masterGroupsRef(companyId),
        this.switcher.where('type', '==', withoutGroupType),
      ),
    );

    let groupId = '';
    if (withoutGroup.docs.length < 1) {
      const group = await this.createMasterGroupAsWithoutGroup(companyId);

      groupId = group.id!;
    } else {
      groupId = withoutGroup.docs[0].id;
    }

    return this.createMasterWithGroupId(companyId, masterDocData, groupId);
  }

  async createMasterWithGroupId(
    companyId: string,
    masterDocData: MasterDocumentData,
    masterGroupId: string,
  ): Promise<void> {
    // writeBatch
    {
      const master: MasterDocumentData = {
        ...blankMasterDocumentData,
        ...masterDocData,
        masterGroupId,
        createdBy: await this.getMasterCreatorInfo(),
        companyId,
      };

      const mastersRef = this.mastersRef(companyId, masterGroupId);
      const newMasterRef = this.switcher.doc(mastersRef);

      const newHistoryDocRef = this.switcher.doc(
        this.masterHistoriesRef(companyId, masterGroupId, newMasterRef.id),
      );

      await this.switcher.setDoc(newMasterRef, {
        ...master,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      // NOTE: 履歴と時刻を合わせるために再取得する (batch で処理すれば同じ時間になるので不要)
      const newMasterDocData = (
        await this.switcher.getDoc(newMasterRef)
      ).data() as MasterDocumentData;

      const newHistory = createHistoryFromMasterData(newMasterDocData, newMasterRef.id!);

      await this.switcher.setDoc(newHistoryDocRef, newHistory);
    }
  }

  async deleteCompanyMappingMaster(companyId: string, val: MasterDocumentData): Promise<void> {
    // TODO delete histories collection with Cloud Function
    // await this.switcher.deleteDoc(this.switcher.doc(this.companyMappingMastersRef(companyId), val.id));
    throw new Error('not implemented');
  }

  async deleteMasterHistory(
    companyId: string,
    masterGroupId: string,
    masterId: string,
    historicalId: string,
  ) {
    await this.switcher.deleteDoc(
      this.switcher.doc(this.masterHistoriesRef(companyId, masterGroupId, masterId), historicalId),
    );
    await this.switcher.deleteDoc(
      this.switcher.doc(
        this.masterHistoriesStagedRef(companyId, masterGroupId, masterId),
        historicalId,
      ),
    );
  }

  async revertMaster(
    companyId: string,
    masterDocData: MasterDocumentData,
    history: MasterHistoryDocumentData,
  ): Promise<string> {
    const masterGroupId = masterDocData.masterGroupId!;
    const masterId = masterDocData.id!;
    const masterDocRef = this.switcher.doc(this.mastersRef(companyId, masterGroupId), masterId);

    const historyCollectionRef = this.masterHistoriesRef(companyId, masterGroupId, masterId);
    const revertingHistorySnapshot = await this.switcher.getDoc(
      this.switcher.doc(historyCollectionRef, history.id),
    );

    if (!revertingHistorySnapshot.exists()) {
      throw new Error('revertingHistory is not found');
    }

    const revertingHistory = revertingHistorySnapshot.data() as MasterHistoryDocumentData;
    const currentMasterData = (
      await this.switcher.getDoc(masterDocRef)
    ).data() as MasterHistoryDocumentData;

    // NOTE: revert 対象のデータで修正することと同じ
    const newMaster: MasterHistoryDocumentData = {
      ...currentMasterData,
      name: revertingHistory.name,
      csv: revertingHistory.csv,
      createdBy: await this.getMasterCreatorInfo(),
      masterGroupId: masterGroupId,
      masterId: masterId,
      reason: 'revert',
    };

    return await this.updateMaster(companyId, masterGroupId, newMaster);
  }

  async uploadCompanyCsvMasterFile(
    companyId: string,
    fileName: string,
    groupId: string,
    file: File,
  ): Promise<string> {
    const path = `${companyId}/csv-master-files/${groupId}_${this.generateUUID()}/${fileName}`;

    await this.switcher.uploadBytes(this.switcher.ref(this.storage, path), file);

    return path;
  }
}
