<div class="back" [ngClass]="hiding ? 'hiding' : null" (click)="closeModal()"></div>
<div class="base content-scroll" [ngClass]="hiding ? 'hiding' : null">
  <header>
    <h2>
      <i class="fa-light fa-sliders-up"></i>チームの設定
      @if (isNew) {
        <i style="font-size: 12px">新規</i>
      }
    </h2>
    <p>チームの設定を行ってください</p>
  </header>
  <div class="block">
    <h3>基本情報</h3>
    @if (!productionMasterGroupsLoading) {
      <app-label-and-text-input
        [(value)]="team.name"
        label="名前"
        [useFieldLayout]="true"
        [disableEdit]="team.id == 'admin' || team.id == 'all'"
      ></app-label-and-text-input>
      <app-label-and-combo-box
        label="権限"
        [(selectedItem)]="team.authority"
        [items]="teamTypes"
        [useFieldLayout]="true"
        [scrollable]="true"
        [disableEdit]="team.id == 'all'"
      ></app-label-and-combo-box>
      @if (!isNew) {
        <app-label-and-button
          [smallButton]="true"
          label="作成日"
          [buttonText]="getDate(team.createdAt)"
          [nonInteractible]="true"
        ></app-label-and-button>
      }
      @if (!isNew) {
        <app-label-and-button
          [smallButton]="true"
          label="更新日"
          [buttonText]="getDate(team.updatedAt)"
          [nonInteractible]="true"
        ></app-label-and-button>
      }
    }
    @if (productionMasterGroupsLoading) {
      <app-inner-progress></app-inner-progress>
    }
  </div>
  <div class="block-container">
    <div class="block">
      <app-label-and-combo-box
        label="会社"
        [items]="companies"
        [(selectedItem)]="currentCompanyId"
        [scrollable]="true"
      ></app-label-and-combo-box>
    </div>
    <div class="block">
      <h3>仕事のアクセス権限</h3>
      @if (!productionWorksLoading) {
        <app-label-and-check-box
          label="全て許可"
          [useFieldLayout]="true"
          [(checked)]="allowAllWorks"
        ></app-label-and-check-box>
      }
      @if (productionWorksLoading) {
        <app-inner-progress></app-inner-progress>
      }
      @if (!allowAllWorks && !productionWorksLoading && currentCompanyId != '') {
        <div class="rows">
          @if (productionWorksDict[currentCompanyId].length == 0) {
            <div>仕事なし</div>
          }
          @for (work of productionWorksDict[currentCompanyId]; track work) {
            <div class="row">
              <div class="row-label">{{ work.name }}</div>
              <div class="row-ope">
                <app-label-and-check-box
                  label="操作"
                  [(checked)]="
                    team.accessible_companies[currentCompanyId].accessible_works[work.id!]
                      .interactable
                  "
                ></app-label-and-check-box>
              </div>
              <div class="row-border"></div>
            </div>
          }
        </div>
      }
    </div>
    <div class="block">
      <h3>マスタのアクセス権限</h3>
      @if (!productionMasterGroupsLoading) {
        <app-label-and-check-box
          label="全て許可"
          [useFieldLayout]="true"
          [(checked)]="allowAllMasterGroups"
        ></app-label-and-check-box>
      }
      @if (productionMasterGroupsLoading) {
        <app-inner-progress></app-inner-progress>
      }
      @if (!allowAllMasterGroups && !productionMasterGroupsLoading && currentCompanyId != '') {
        <div class="rows">
          @if (productionMasterGroupsDict[currentCompanyId].length == 0) {
            <div>マスタなし</div>
          }
          @for (masterGroup of productionMasterGroupsDict[currentCompanyId]; track masterGroup) {
            <div class="row">
              <div class="row-label">{{ masterGroup.name }}</div>
              <div class="row-ope">
                <app-label-and-check-box
                  label="閲覧"
                  [(checked)]="
                    team.accessible_companies[currentCompanyId].accessible_master_groups[
                      masterGroup.id!
                    ].readable
                  "
                  [disabled]="
                    team.accessible_companies[currentCompanyId].accessible_master_groups[
                      masterGroup.id!
                    ].writable
                  "
                ></app-label-and-check-box>
                <app-label-and-check-box
                  label="編集"
                  [checked]="
                    team.accessible_companies[currentCompanyId].accessible_master_groups[
                      masterGroup.id!
                    ].writable
                  "
                  (checkedChange)="
                    updateMasterWritableFlag(currentCompanyId, masterGroup.id!, $event)
                  "
                ></app-label-and-check-box>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
  @if (!isNew && team.id != 'all' && team.id != 'admin' && !productionMasterGroupsLoading) {
    <div class="block">
      <h3>操作</h3>
      <div class="delete-team" (click)="deleteTeam()">このチームを削除する</div>
    </div>
  }
  <div class="line"></div>
  <div class="buttons">
    <button class="button close" (click)="closeModal()" style="width: 92px">キャンセル</button>
    <button class="button done" style="width: 112px" (click)="save()">保存</button>
  </div>
</div>

@if (loadingFull) {
  <div class="loading">
    <p>保存しています...</p>
    <app-inner-progress style="filter: brightness(1.5)"></app-inner-progress>
  </div>
}
