<div class="base">
  <div class="background" (click)="close.emit()"></div>

  <div class="content">
    <div class="header">
      <div class="close" (click)="close.emit()">
        <i class="fa-solid fa-times"></i>
      </div>
      <div class="title">新規グループ作成</div>
    </div>

    <div class="body mt-4" style="height: 100%">
      <section>
        <div class="mb-2">
          <app-label-and-text-input
            [label]="'グループ名' | translate"
            [placeholder]="'グループ名' | translate"
            [(value)]="currentGroupName"
            [grow]="4"
          ></app-label-and-text-input>
        </div>
        <button class="button" (click)="save()">保存</button>
      </section>
    </div>
  </div>
</div>

@if (loading) {
  <div class="loading">
    <app-inner-progress></app-inner-progress>
  </div>
}
