<div class="content users-content" *ngIf="!loading && !parentLoading">
  <div class="controls">
    <div class="search-bar-container">
      <div class="search-bar-icon"><i class="fa-solid fa-magnifying-glass"></i></div>
      <input class="search-bar" type="text" placeholder="Search" [(ngModel)]="searchText" />
    </div>
    <div class="export-icon control" (click)="exportCsv()">
      <i class="fa-regular fa-arrow-down-to-line"></i>
    </div>
    <div class="refresh-icon control" (click)="update(true)">
      <i class="fa-regular fa-rotate-right"></i>
    </div>
    <div class="add-icon" *ngIf="!addingUser" (click)="startAddingUser()">
      <i class="fa-solid fa-plus"></i>ユーザー追加
    </div>
    <div class="add-icon" *ngIf="addingUser" (click)="startAddingUser()" (click)="stopAddingUser()">
      キャンセル
    </div>
  </div>
  <div class="users">
    <div class="user-row header">
      <div class="user-icon"></div>
      <div class="user-name">名前</div>
      <div class="user-email">メール</div>
      <div class="user-team">チーム</div>
      <div class="user-edit">編集</div>
    </div>
    <div class="user-row data new" *ngIf="addingUser">
      <div class="user-icon" style="font-size: 14px">新規</div>
      <div class="user-name new">
        <input
          class="edit-user-data"
          type="text"
          placeholder="名前を入力"
          [(ngModel)]="addingUserName"
        />
      </div>
      <div class="user-email new">
        <input
          class="edit-user-data"
          type="text"
          placeholder="メール"
          [(ngModel)]="addingUserEmail"
        />
        <div class="new-email-text">
          <input
            type="checkbox"
            name="new-email"
            id="new-email"
            [(ngModel)]="addingUserSendEmail"
          />
          <label for="new-email">パスワード設定メールを送信</label>
        </div>
      </div>
      <div class="user-team new">未設定</div>
      <div class="user-edit new">
        <button class="create-button" (click)="addUser()">
          <i class="fa-solid fa-paper-plane"></i>
          作成
        </button>
      </div>
    </div>
    <div class="users-list-loader" *ngIf="usersListInitialLoading">
      <app-inner-progress *ngIf="usersListInitialLoading"></app-inner-progress>
    </div>
    <div
      class="user-row data"
      *ngFor="let user of currentPageUsers; trackBy: userTrackBy"
      [ngClass]="
        isUserInSelectedTeam(user) || editingUserId == user.id ? undefined : 'in-different-team'
      "
    >
      <ng-container *ngIf="user.id != editingUserId">
        <div
          class="user-icon data"
          style="cursor: pointer"
          (click)="toggleUserSelected(user)"
          [ngClass]="isUserSelected(user) ? 'checked' : ''"
        >
          <i class="fa-regular fa-square-check checked-icon"></i>
          <i class="fa-regular fa-square unchecked-icon"></i>
        </div>
        <div class="user-name data">
          {{ user.name }}
        </div>
        <div class="user-email data">
          {{ user.email
          }}<i *ngIf="user.id == selfUserId" class="fa-solid fa-user user-self-icon"></i>
        </div>
        <div
          class="user-team data"
          [tooltip]="teamNameQuoted + 'に変更'"
          [tooltipDynamic]="teamNameQuoted + 'に変更'"
          [style.pointerEvents]="getUserTeamId(user) == teamId || teamId == 'all' ? 'none' : 'all'"
          (click)="updateUserTeam(user, teamId)"
        >
          <ng-container *ngIf="!userTeamUpdatingDict[user.id!]">
            {{ getUserTeamName(user) }}
            <i
              class="fa-solid fa-rotate"
              [style.display]="getUserTeamId(user) == teamId || teamId == 'all' ? 'none' : 'block'"
            ></i>
          </ng-container>
          <app-inner-progress *ngIf="userTeamUpdatingDict[user.id!]"></app-inner-progress>
        </div>
        <div class="user-edit data" (click)="editUser(user)">
          <i class="fa-solid fa-pen-to-square"></i>
        </div>
      </ng-container>

      <ng-container *ngIf="user.id == editingUserId">
        <div
          class="user-icon data"
          style="cursor: pointer"
          (click)="toggleUserSelected(user)"
          [ngClass]="isUserSelected(user) ? 'checked' : ''"
        >
          <i class="fa-regular fa-square-check checked-icon"></i>
          <i class="fa-regular fa-square unchecked-icon"></i>
        </div>
        <div class="user-name new">
          <input
            class="edit-user-data"
            type="text"
            placeholder="名前を入力"
            [(ngModel)]="editingName"
          />
        </div>
        <div class="user-email data">{{ user.email }}</div>
        <div class="user-team data edit">
          <app-label-and-combo-box
            label=""
            [(selectedItem)]="editingTeamId!"
            [items]="editingTeamList"
            maxWidth="90px"
            [scrollable]="true"
          ></app-label-and-combo-box>
        </div>
        <div class="user-edit new">
          <button class="create-button" (click)="saveEditingUser()">保存</button>
        </div>
      </ng-container>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 12px;
        margin-bottom: 6px;
      "
    >
      <div
        class="pager"
        (click)="prevPage()"
        [ngClass]="hasPrevPage ? 'pager-enabled' : 'pager-disabled'"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </div>
      <div class="pager-text">{{ currentPage }}/{{ pages }}</div>
      <div
        class="pager"
        (click)="nextPage()"
        [ngClass]="hasNextPage ? 'pager-enabled' : 'pager-disabled'"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
    <div
      class="user-operation-button"
      [tooltip]="'選択ユーザーのチームを' + teamNameQuoted + 'に設定する'"
      [tooltipDynamic]="'選択ユーザーのチームを' + teamNameQuoted + 'に設定する'"
      tooltipPlacement="bottom"
      [ngClass]="countSelectedUsers() > 0 && teamId != 'all' ? 'has-selected-user' : ''"
      (click)="countSelectedUsers() > 0 && teamId != 'all' ? updateUserTeamSelected(teamId) : null"
    >
      <i class="fa-solid fa-rotate"></i>
    </div>
    <div
      class="user-operation-button delete-operation"
      tooltip="選択ユーザーのアカウントを削除する"
      tooltipPlacement="bottom"
      [ngClass]="countSelectedUsers() > 0 ? 'has-selected-user' : ''"
      (click)="countSelectedUsers() > 0 ? deleteUserSelected() : null"
    >
      <i class="fa-solid fa-user-minus"></i>
    </div>
  </div>
</div>

<div class="loading" *ngIf="fullLoading">
  <app-inner-progress></app-inner-progress>
</div>
