import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { CompanyInfo } from 'src/app/polar/entity/CompanyInfo';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

@Component({
  selector: 'app-console-create-company',
  templateUrl: './console-create-company.component.html',
  styleUrls: ['./console-create-company.component.scss'],
})
export class ConsoleCreateCompanyComponent implements OnInit {
  saving: boolean = false;
  savingStat: 'loading' | 'completed' | 'failed' = 'loading';
  companies: CompanyInfo[] = [];
  companiesObservable$?: Observable<CompanyInfo[]>;
  createCompanyForm: FormGroup;

  showEditCompany: boolean = false;
  editCompanyInfo: CompanyInfo = {};

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private createCompanyFormBuilder: FormBuilder,
  ) {
    this.createCompanyForm = this.createCompanyFormBuilder.group({
      companyName: ['', [Validators.required]],
      memo: [''],
    });
  }

  async ngOnInit() {
    // get companies from polar
    this.companiesObservable$ = await this.polar.getCompanies();
    this.companiesObservable$.subscribe((d) => {
      this.companies = d;
    });
  }

  async createCompany() {
    if (this.createCompanyForm.valid) {
      this.saving = true;
      this.savingStat = 'loading';
      try {
        const companyInfoData: CompanyInfo = {
          name: this.createCompanyForm.value['companyName'],
          memo: this.createCompanyForm.value['memo'],
        };
        await this.polar.setCompany(companyInfoData);
        this.savingStat = 'completed';
      } catch (e) {
        this.savingStat = 'failed';
      }
    }
    this.createCompanyForm.reset();
  }

  async deleteCompany(company: CompanyInfo) {
    const deleteComfarmModal = await this.commonUi.showConfirm({
      title: '確認',
      body: `会社名「${company.name}」を削除しますか？`,
      buttons: ['Yes', 'No'],
    });
    if (deleteComfarmModal != 'Yes') return;
    await this.polar.deleteCompany(company);
  }

  async editCompany(company: CompanyInfo) {
    this.editCompanyInfo = company;
    this.showEditCompany = true;
  }
}
