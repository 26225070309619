import * as iconv from 'iconv-lite';
import { Component, OnInit } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { NavigationService } from 'src/app/navigation.service';
import {
  CompanyInfo,
  UploadedFileNameOutputSetting,
  UserImageInfo,
  UserWorkDownloadHistoryInfo,
  UserWorkInfo,
  WorkInfo,
} from 'src/app/polar/entity/CompanyInfo';
import { CsvLineSplitter } from 'src/app/polar/entity/Workflow';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import * as XLSX from 'xlsx';
import { WorkBook } from 'xlsx/types';
import { SortUserImageInfos, timeElapsedPerFile } from '../work-utils';
import { FirebaseAnalyticsService } from 'src/app/firebase-analytics/firebase-analytics.service';
import { UploadedEventInfo } from 'src/app/polar/entity/Updateinfo';
import { TemplateInfo } from 'src/app/polar/entity/TemplateInfo';
import { PolarFirebaseAuthService } from 'src/app/polar/polar-firebase-auth.service';

enum ExportType {
  XLSX = 'xlsx',
  CSV = 'csv',
  CSV_SJIS = 'csv_sjis',
  CSV_SJIS_CRLF = 'csv_sjis_crlf',
}

const UploadedFileNameOutputSettingDisabled: UploadedFileNameOutputSetting = {
  outputType: 'disabled',
  columnNameOfFileName: '',
  fileIndexColumnEnabled: false,
  columnNameOfFileIndex: '',
  fileIndexStartingNumber: 0,
  isMultiFileDownloadEnabled: false,
};

@Component({
  selector: 'app-work-dashboard',
  templateUrl: './work-dashboard.component.html',
  styleUrls: ['./work-dashboard.component.scss'],
})
export class WorkDashboardComponent implements OnInit {
  uploaderShowing: boolean = false;
  listShowing: boolean = false;

  companyId: string = '';
  workId: string = '';
  companyInfo?: CompanyInfo;
  workInfo?: WorkInfo;
  templeteListInfo?: TemplateInfo[];

  loading: boolean = true;

  uploaded: UserImageInfo[] = [];
  converted: UserImageInfo[] = [];
  failed: UserImageInfo[] = [];
  succeeded: UserImageInfo[] = [];
  confirmed: UserImageInfo[] = [];

  uploadedObservable$: Observable<UserImageInfo[]> | undefined;
  convertedObservable$: Observable<UserImageInfo[]> | undefined;
  failedObservable$: Observable<UserImageInfo[]> | undefined;
  succeededObservable$: Observable<UserImageInfo[]> | undefined;
  confirmedObservable$: Observable<UserImageInfo[]> | undefined;

  listingImages: UserImageInfo[] = [];
  listingImagesUpdatable: UserImageInfo[] = [];
  listingImagesFailTargetProperty: string = '';

  showWorkSettingsModal: boolean = false;
  uploadedEventInfo?: UploadedEventInfo;
  uploaded_event_file_count?: number;

  constructor(
    private router: Router,
    private commonUi: CommonUiProviderService,
    private route: ActivatedRoute,
    private polar: PolarFirebaseService,
    private polarAuthGroup: PolarFirebaseAuthService,
    private nav: NavigationService,
    private firebaseAnalytics: FirebaseAnalyticsService,
  ) {
    this.companyId = this.route.snapshot.paramMap.get('company_id')!;
    this.workId = this.route.snapshot.paramMap.get('work_id')!;
  }

  async ngOnInit() {
    this.companyInfo = await this.polar.getCompany(this.companyId);
    this.workInfo = await this.polar.getWork(this.companyId, this.workId);
    (await this.polar.getTemplates(this.companyId, this.workInfo.parentWorkId || '')).subscribe(
      async (f) => {
        this.templeteListInfo = f;
      },
    );

    await this.polar.initUserDataImages(this.companyId, this.workId);

    this.uploadedObservable$ = await this.polar.getUserDataImages(
      this.companyId,
      this.workId,
      'uploaded',
    );
    this.uploadedObservable$.subscribe((f) => {
      this.uploaded = f;
      if (!this.uploadedEventInfo) {
        this.uploadedEventInfo = {
          work_id: this.workId,
          status: 'uploaded',
          uploadedStartTime: new Date(),
        };
        return;
      }

      if (
        this.uploadedEventInfo &&
        f.length === 0 &&
        this.uploaded_event_file_count !== undefined
      ) {
        this.firebaseAnalytics.trackEvent({
          name: 'hide-uploaded-icon',
          category: 'time-track',
          label: 'hide-uploaded-icon',
          value: 1,
          interfaction: true,
          customValues: {
            timeElapsed: timeElapsedPerFile(
              this.uploadedEventInfo.uploadedStartTime,
              this.uploaded_event_file_count,
            ),
          },
        });
      }
    });

    this.convertedObservable$ = await this.polar.getUserDataImages(
      this.companyId,
      this.workId,
      'converted',
    );
    this.convertedObservable$.subscribe((f) => {
      this.converted = f;
      // statusが→convertedになった時に、uploadedEventInfoのstatusをconvertedに変更する
      if (
        this.uploadedEventInfo?.status === 'uploaded' &&
        this.uploadedEventInfo.work_id === this.workId &&
        f.length > 0 &&
        this.uploaded_event_file_count !== undefined
      ) {
        this.uploadedEventInfo.status = 'converted';
        this.uploadedEventInfo.convertedStartTime = new Date();
        return;
      }
      // コンバートが完了した時にfailedかsuccessにするまでにかかった時間を計測する
      if (
        this.converted.length === 0 &&
        this.uploaded.length === 0 &&
        this.uploadedEventInfo &&
        this.uploadedEventInfo.status === 'converted' &&
        this.uploaded_event_file_count !== undefined &&
        this.uploadedEventInfo.convertedStartTime !== undefined
      ) {
        this.firebaseAnalytics.trackEvent({
          name: 'hide-converted-icon',
          category: 'time-track',
          label: 'hide-converted-icon',
          value: 1,
          interfaction: true,
          customValues: {
            timeElapsed: timeElapsedPerFile(
              this.uploadedEventInfo.convertedStartTime,
              this.uploaded_event_file_count,
            ),
          },
        });
        this.uploadedEventInfo = undefined;
        this.uploaded_event_file_count = undefined;
      }
    });

    this.failedObservable$ = await this.polar.getUserDataImages(
      this.companyId,
      this.workId,
      'failed',
    );
    this.failedObservable$.subscribe((f) => {
      this.failed = f;
    });

    this.succeededObservable$ = await this.polar.getUserDataImages(
      this.companyId,
      this.workId,
      'succeeded',
    );
    this.succeededObservable$.subscribe((f) => {
      this.succeeded = f;
    });

    this.confirmedObservable$ = await this.polar.getUserDataImages(
      this.companyId,
      this.workId,
      'confirmed',
    );
    this.confirmedObservable$.subscribe((f) => {
      this.confirmed = f;
    });

    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  showUploader(e: MouseEvent) {
    if ((e.target as HTMLElement).classList.contains('button')) {
      return;
    }
    this.firebaseAnalytics.trackEvent({
      name: 'show-uploader',
      category: 'button-click',
      label: 'show-uploader',
      value: 1,
      interfaction: true,
    });
    this.uploaderShowing = true;
  }

  hideUploader() {
    this.uploaderShowing = false;
  }

  handleFileCount(count: number) {
    this.uploaded_event_file_count = count;
  }

  showSettings() {
    this.showWorkSettingsModal = true;
  }

  hideSettings() {
    this.showWorkSettingsModal = false;
  }

  async back() {
    this.nav.back('main');
  }

  goCheck(e: MouseEvent, status: string) {
    if ((e.target as HTMLElement).classList.contains('button')) {
      return;
    }
    this.router.navigate(['/work/' + this.companyId + '/' + this.workId + '/check/' + status]);

    const name =
      status === 'succeeded' ? 'show-confirm-succeeded-component' : 'show-confirm-failed-component';
    // Tracking the event for click confirm button
    const fileCount = status === 'succeeded' ? this.succeeded.length : this.failed.length;
    this.firebaseAnalytics.trackEvent({
      name: name,
      category: 'button-click',
      label: name,
      value: 1,
      interfaction: true,
      customValues: {
        fileCount: fileCount,
      },
    });
  }

  downloading: boolean = false;
  downloadProgress: string = '';

  async download(e: MouseEvent) {
    if ((e.target as HTMLElement).classList.contains('button')) {
      return;
    }

    let downloadTarget = this.confirmed.filter((f) => true);

    downloadTarget = SortUserImageInfos(downloadTarget);

    // downloadTargetはファイル単位じゃなくてページ単位。アップロードしたファイル単位でのダウンロード数を取得
    const uniqueFileCount = new Set(downloadTarget.map((f) => f.rawFilePath)).size;
    const downloadTargetLength = downloadTarget.length;

    const result = await this.commonUi.showConfirm({
      title: downloadTargetLength + '件のファイルをダウンロードしますか？',
      body: 'ダウンロードは１回のみ可能です',
      buttons: ['OK', 'キャンセル'],
    });

    if (result != 'OK') {
      return;
    }

    this.downloading = true;
    this.downloadProgress = '0%';

    let csv = '';

    let downloadHistory: UserWorkDownloadHistoryInfo = {
      createdAt: serverTimestamp(),
      downloadedIds: [],
    };

    const userWork = await this.polar.getUserWork(this.companyId, this.workId);
    console.log(userWork.exportType);
    // Load UploadedFileNameOutputSetting, but if it fails or is invalid, set UploadedFileNameOutputSettingDisabled.
    const uploadedFileNameOutputSetting =
      this.loadUploadedFileNameOutputSettingIfValid(userWork) ??
      UploadedFileNameOutputSettingDisabled;

    const isMultiFileDownload = uploadedFileNameOutputSetting.isMultiFileDownloadEnabled;

    // initialize idx for incrementalNumber
    let idx = 0;
    if (userWork.incrementalType == 'leading' || userWork.incrementalType == 'trailing') {
      if (userWork.incrementalStartingNumber) {
        idx = parseInt(userWork.incrementalStartingNumber);
        if (isNaN(idx)) {
          idx = 0;
        }
      }
    }

    for (let i = 0; i < downloadTargetLength; i++) {
      let downloadTargetObject = downloadTarget[i];
      let innerData = await this.polar.getUserImageInnerData(
        this.companyId,
        this.workId,
        downloadTargetObject.id!,
      );

      if (csv == '') {
        // initialize header
        csv += innerData.header!;

        // it is necessary to apply uploadedFileNameOutputSetting before incrementalType
        csv = this.applyUploadedFileNameOutputSettingToCsvHeader(
          csv,
          uploadedFileNameOutputSetting,
        );

        if (userWork.incrementalType == 'trailing') {
          csv += ',' + userWork.incrementalColumnName;
        } else if (userWork.incrementalType == 'leading') {
          csv = userWork.incrementalColumnName + ',' + csv;
        }
      }

      if (innerData.actualBody != '' && innerData.actualBody != undefined) {
        let splitted = CsvLineSplitter(innerData.actualBody);

        for (let j = 0; j < splitted.length; j++) {
          let line = splitted[j];
          if (line == '') {
            continue;
          }
          if (line.replace(/,/g, '') == '') {
            continue;
          }
          //NOTE: if export type csv sjis, convert 'CRLF' type
          userWork.exportType === ExportType.CSV_SJIS_CRLF ? (csv += '\r\n') : (csv += '\n');

          // it is necessary to apply uploadedFileNameOutputSetting before incrementalType
          line = this.applyUploadedFileNameOutputSettingToCsvEachRow(
            line,
            uploadedFileNameOutputSetting,
            downloadTargetObject,
          );

          if (userWork.incrementalType == 'trailing') {
            line += ',' + idx.toString();
          } else if (userWork.incrementalType == 'leading') {
            line = idx.toString() + ',' + line;
          }
          idx += 1;

          csv += line;
        }
      }

      this.downloadProgress = Math.floor(((i * 2 + 1) / downloadTargetLength) * 50) + '%';
      downloadHistory.downloadedIds!.push(downloadTargetObject.id!);

      // move confirmed datas to archived
      let prevStat = downloadTargetObject.status;
      downloadTargetObject.status = 'archived';

      // TODO: make this transactional
      await this.polar.addUserDataImage(this.companyId, this.workId, downloadTargetObject);
      await this.polar.deleteUserDataImage(this.companyId, this.workId, {
        id: downloadTargetObject.id,
        status: prevStat,
      });
      this.downloadProgress = Math.floor(((i * 2 + 2) / downloadTargetLength) * 50) + '%';

      if (isMultiFileDownload || (!isMultiFileDownload && i + 1 == downloadTargetLength)) {
        const downloadHistoryId = await this.polar.addDownloadHistory(this.companyId, this.workId, downloadHistory);

        const imageIndexFrom = downloadTargetObject.imageIndexFrom ?? 0;
        const outPutFileName = innerData?.outputFileName;

        const fileExtension = userWork.exportType === ExportType.XLSX ? '.xlsx' : '.csv';

        // NOTE: Decide file name process
        const date = new Date().toLocaleString('ja-JP').replace(/ /g, '-');
        let fileName = date + fileExtension;

        if (isMultiFileDownload) {
          if (imageIndexFrom == 0) {
            fileName = date + fileExtension;
          } else {
            fileName = date + '_' + imageIndexFrom + fileExtension;
          }
        }

        if (outPutFileName && outPutFileName.length > 0) {
          fileName = outPutFileName + fileExtension;
          if (isMultiFileDownload) {
            if (imageIndexFrom == 0) {
              fileName = outPutFileName + fileExtension;
            } else {
              fileName = outPutFileName + '_' + imageIndexFrom + fileExtension;
            }
          }
        }
        let file;
        //NOTE: check if user requires excel format
        if (userWork.exportType === ExportType.XLSX) {
          const wb = this.createWorkbook(csv);
          file = new File([wb], fileName, {
            type: 'application/octet-stream',
          });
        } else if (
          userWork.exportType === ExportType.CSV_SJIS ||
          userWork.exportType === ExportType.CSV_SJIS_CRLF
        ) {
          const sjisEncord = iconv.encode(csv, 'Shift_JIS');
          const fileType = { type: 'text/csv' };
          const blob = new Blob([sjisEncord], fileType);
          file = new File([blob], fileName, fileType);
        } else {
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const fileType = { type: 'text/csv' };
          const blob = new Blob([csv], fileType);
          file = new File([bom, blob], fileName, fileType);
        }

        if (file) {
          const prepared = await this.polar.prepareUploadArchivedFile(this.companyId, this.workId, downloadHistoryId);
          await this.polar.createUploadFileReferencePrepared(prepared);
          downloadHistory.dumpPath = await this.polar.uploadUserFilePrepared(prepared, file);

          let a = document.createElement('a');
          a.href = URL.createObjectURL(file);
          a.download = fileName;
          a.click();
          a.remove();
        }

        csv = '';
      }
    }
    // Tracking the event for click download button
    this.firebaseAnalytics.trackEvent({
      name: 'download',
      category: 'button-click',
      label: 'download-data',
      value: 1,
      interfaction: true,
      customValues: {
        fileCount: uniqueFileCount,
      },
    });
    this.downloading = false;
    this.downloadProgress = '0%';
  }

  createWorkbook(csv: string): ArrayBuffer {
    let rows: (string | undefined)[][] = [];
    let lines = CsvLineSplitter(csv);

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];
      if (line == '' || line.replace(/,/g, '').replace(/"/g, '') == '') {
        continue;
      }
      let cells = [];
      let inQuote = false;
      let currentCell = '';
      for (let j = 0; j < line.length; j++) {
        let char = line[j];
        if (char == '"') {
          inQuote = !inQuote;
        } else if (char == ',' && !inQuote) {
          if (currentCell == '') cells.push(undefined);
          else cells.push(currentCell);
          currentCell = '';
        } else {
          currentCell += char;
        }
      }
      if (currentCell == '') cells.push(undefined);
      else cells.push(currentCell);
      rows.push(cells);
    }

    let workBook: WorkBook = {
      SheetNames: ['Sheet1'],
      Sheets: {
        Sheet1: XLSX.utils.aoa_to_sheet(rows),
      },
      bookType: 'xlsx',
    };
    const wbout = XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    var buf = new ArrayBuffer(wbout.length);
    var view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt(i) & 0xff;
    }

    return buf;
  }

  subscription?: Subscription;

  showListUploaded() {
    this.listingImages = this.uploaded;
    this.listingImagesUpdatable = this.uploaded;
    this.subscription = this.uploadedObservable$?.subscribe((f) => {
      this.listingImagesUpdatable = f;
    });
    this.listingImagesFailTargetProperty = 'isFileInvalid';
    this.listShowing = true;

    this.firebaseAnalytics.trackEvent({
      name: 'uploaded-list-modal',
      category: 'modal-track',
      label: 'show-uploaded-file-list-modal',
      value: 1,
      interfaction: true,
    });
  }

  showListConverted() {
    this.listingImages = this.converted;
    this.listingImagesUpdatable = this.converted;
    this.subscription = this.convertedObservable$?.subscribe((f) => {
      this.listingImagesUpdatable = f;
    });
    this.listingImagesFailTargetProperty = 'isProcessingFailed';
    this.listShowing = true;

    this.firebaseAnalytics.trackEvent({
      name: 'converting-list-modal',
      category: 'modal-track',
      label: 'show-converting-file-list-modal',
      value: 1,
      interfaction: true,
    });
  }

  showListFailed() {
    this.listingImages = this.failed;
    this.listingImagesUpdatable = this.failed;
    this.subscription = this.failedObservable$?.subscribe((f) => {
      this.listingImagesUpdatable = f;
    });
    this.listingImagesFailTargetProperty = '';
    this.listShowing = true;

    this.firebaseAnalytics.trackEvent({
      name: 'failed-list-modal',
      category: 'modal-track',
      label: 'show-failed-file-list-modal',
      value: 1,
      interfaction: true,
    });
  }

  showListSucceeded() {
    this.listingImages = this.succeeded;
    this.listingImagesUpdatable = this.succeeded;
    this.subscription = this.succeededObservable$?.subscribe((f) => {
      this.listingImagesUpdatable = f;
    });
    this.listingImagesFailTargetProperty = '';
    this.listShowing = true;

    this.firebaseAnalytics.trackEvent({
      name: 'succeeded-list-modal',
      category: 'modal-track',
      label: 'show-succeeded-file-list-modal',
      value: 1,
      interfaction: true,
    });
  }

  showListConfirmed() {
    this.listingImages = this.confirmed;
    this.listingImagesUpdatable = this.confirmed;
    this.subscription = this.confirmedObservable$?.subscribe((f) => {
      this.listingImagesUpdatable = f;
    });
    this.listingImagesFailTargetProperty = '';
    this.listShowing = true;

    this.firebaseAnalytics.trackEvent({
      name: 'confirmed-list-modal',
      category: 'modal-track',
      label: 'show-confirmed-file-list-modal',
      value: 1,
      interfaction: true,
    });
  }

  downloadHistoryShowing: boolean = false;

  showDownloadHistory() {
    this.downloadHistoryShowing = true;
  }

  hideDownloadHistory() {
    this.downloadHistoryShowing = false;
  }

  hideList() {
    this.listShowing = false;
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  private loadUploadedFileNameOutputSettingIfValid(
    userWork: UserWorkInfo,
  ): UploadedFileNameOutputSetting | undefined {
    if (
      userWork.uploadedFileNameOutputSetting == null ||
      userWork.uploadedFileNameOutputSetting.outputType == null
    ) {
      return undefined;
    }

    const {
      outputType,
      columnNameOfFileName,
      fileIndexColumnEnabled,
      columnNameOfFileIndex,
      fileIndexStartingNumber,
    } = userWork.uploadedFileNameOutputSetting;

    if (outputType == 'disabled') {
      return userWork.uploadedFileNameOutputSetting;
    }

    // if outputType is not 'disabled', columnNameOfFileName must be set
    // if fileIndexColumnEnabled is true, columnNameOfFileIndex must be set
    if (columnNameOfFileName == null || (fileIndexColumnEnabled && columnNameOfFileIndex == null)) {
      return undefined;
    }

    return userWork.uploadedFileNameOutputSetting;
  }

  private applyUploadedFileNameOutputSettingToCsvHeader(
    csv: string,
    uploadedFileNameOutputSetting: UploadedFileNameOutputSetting,
  ): string {
    const { outputType, columnNameOfFileName, fileIndexColumnEnabled, columnNameOfFileIndex } =
      uploadedFileNameOutputSetting;

    if (outputType == 'trailing') {
      csv += ',' + columnNameOfFileName;

      if (fileIndexColumnEnabled) {
        csv += ',' + columnNameOfFileIndex;
      }
    } else if (outputType == 'leading') {
      if (fileIndexColumnEnabled) {
        csv = columnNameOfFileIndex + ',' + csv;
      }

      csv = columnNameOfFileName + ',' + csv;
    }

    return csv;
  }

  private applyUploadedFileNameOutputSettingToCsvEachRow(
    line: string,
    uploadedFileNameOutputSetting: UploadedFileNameOutputSetting,
    obj: UserImageInfo,
  ): string {
    const { outputType, fileIndexColumnEnabled, fileIndexStartingNumber } =
      uploadedFileNameOutputSetting;

    const imageIndexFrom = obj.imageIndexFrom ?? 0;

    if (outputType == 'trailing') {
      line += ',' + obj.name;

      if (fileIndexColumnEnabled) {
        line += ',' + (fileIndexStartingNumber + imageIndexFrom).toString();
      }
    } else if (outputType == 'leading') {
      if (fileIndexColumnEnabled) {
        line = (fileIndexStartingNumber + imageIndexFrom).toString() + ',' + line;
      }

      line = obj.name + ',' + line;
    }

    return line;
  }
}
