<div class="base" (click)="baseClick($event)">
  @if (status == 'exists') {
    <div class="container">
      <p translate>テンプレートが見つかりました</p>
      <img [src]="imageUrl" />
      <button (click)="close.emit()" translate>閉じる</button>
      <button (click)="create.emit()" translate>新規追加</button>
      <div>{{ csv }}</div>
    </div>
  }
  @if (status == 'loading' || status == 'rotating' || status == 'matching') {
    <div class="container">
      @if (status == 'loading') {
        <p translate>認識中です...</p>
      }
      @if (status == 'matching') {
        <p translate>テンプレートを探しています...</p>
      }
      @if (status == 'rotating') {
        <p translate>回転しています...</p>
      }
      <app-inner-progress status="loading"></app-inner-progress>
    </div>
  }

  @if (status == 'select-rect') {
    <div class="container">
      <div class="field is-horizontal" style="margin-top: 8px; width: 160px">
        <div class="field-label is-normal">
          <label class="label" translate>テキスト表示</label>
        </div>
        <div class="field-body" style="flex-grow: 0.1">
          <div class="field">
            <p class="control" style="margin: 4px">
              <label class="checkbox">
                <input
                  style="transform: scale(1.3) translate(-2px, 2px)"
                  type="checkbox"
                  [(ngModel)]="showText"
                />
              </label>
            </p>
          </div>
        </div>
      </div>
      <p translate>３つ以上のマーカーを選択してください</p>
      <div style="position: relative">
        <img #IMAGE [src]="imageUrl" />
        @for (rect of rects; track rect) {
          <div
            class="rect"
            [style.left.px]="rect.x"
            [style.top.px]="rect.y"
            [style.width.px]="rect.width"
            [style.height.px]="rect.height"
            [style.borderColor]="selectedRects.includes(rect) ? 'blue' : null"
            (click)="rectClick(rect)"
          ></div>
        }
        @if (showText) {
          <div>
            @for (rect of rects; track rect) {
              <div
                class="rect-field"
                [style.left.px]="rect.x"
                [style.top.px]="rect.y"
                [style.width.px]="rect.width"
                (click)="preview(rect)"
              >
                {{ rect.title }}
              </div>
            }
          </div>
        }
      </div>
      <button (click)="done()" translate>決定</button>
    </div>
  }
</div>
