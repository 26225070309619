import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonUiProviderService } from '../common/common-ui-provider.service';
import { PolarFirebaseService } from '../polar/polar-firebase.service';
import {
  UPLOADED_FILE_NAME_OUTPUT_TYPES,
  UploadedFileNameOutputSetting,
  UserWorkInfo,
} from '../polar/entity/CompanyInfo';

const UploadedFileNameOutputSettingDefault: UploadedFileNameOutputSetting = {
  outputType: 'disabled',
  columnNameOfFileName: 'ファイル名',
  fileIndexColumnEnabled: false,
  columnNameOfFileIndex: 'ファイル枚目',
  fileIndexStartingNumber: 0,
  isMultiFileDownloadEnabled: false,
};

@Component({
  selector: 'app-main-work-settings-modal',
  templateUrl: './main-work-settings-modal.component.html',
  styleUrls: ['./main-work-settings-modal.component.scss'],
})
export class MainWorkSettingsModalComponent implements OnInit {
  @Input() companyId: string = '';
  @Input() workId: string = '';

  @Output() close: EventEmitter<void> = new EventEmitter();
  loading: boolean = true;

  fileTypes: any[] = [
    { id: 'csv', name: 'CSV' },
    { id: 'csv_sjis', name: 'CSV(SJIS-LF)' },
    { id: 'csv_sjis_crlf', name: 'CSV(SJIS-CRLF)' },
    { id: 'xlsx', name: 'XLSX' },
  ];
  fileType: string = 'csv';

  incrementalTypes: any[] = [
    { id: 'disabled', name: '無効' },
    { id: 'leading', name: '有効 (先頭)' },
    { id: 'trailing', name: '有効 (末尾)' },
  ];
  incrementalType: 'disabled' | 'leading' | 'trailing' = 'disabled';

  incrementalColumnName: string = '連番';
  incrementalStartingNumber: string = '0';

  uploadedFileNameOutputTypes: { id: string; name: string }[] = [
    { id: 'disabled', name: '無効' },
    { id: 'leading', name: '有効 (先頭)' },
    { id: 'trailing', name: '有効 (末尾)' },
  ];

  uploadedFileNameOutputSetting: UploadedFileNameOutputSetting =
    UploadedFileNameOutputSettingDefault;

  showConfidence: boolean = true;

  constructor(
    private commonUi: CommonUiProviderService,
    private polar: PolarFirebaseService,
  ) {}

  closeEvent() {
    this.close.emit();
  }

  async ngOnInit() {
    this.loading = true;
    const userWork = await this.polar.getUserWork(this.companyId, this.workId);
    if (userWork.id == undefined || userWork.id == null || userWork.id == '') {
      alert('Error');
      this.close.emit();
      return;
    }

    if (userWork.exportType != null && userWork.exportType != undefined) {
      this.fileType = userWork.exportType;
    } else {
      this.fileType = 'csv';
    }

    if (userWork.incrementalType != null && userWork.incrementalType != undefined) {
      this.incrementalType = userWork.incrementalType;
    } else {
      this.incrementalType = 'disabled';
    }

    if (userWork.incrementalColumnName != null && userWork.incrementalColumnName != undefined) {
      this.incrementalColumnName = userWork.incrementalColumnName;
    } else {
      this.incrementalColumnName = '連番';
    }

    if (userWork.incrementalStartingNumber != null) {
      this.incrementalStartingNumber = userWork.incrementalStartingNumber.toString();
    }

    if (userWork.disableConfidence != null) {
      this.showConfidence = !userWork.disableConfidence;
    }

    this.initUploadedFileNameOutputSetting(userWork);

    this.loading = false;
  }

  private initUploadedFileNameOutputSetting(userWork: UserWorkInfo) {
    if (userWork.uploadedFileNameOutputSetting != null) {
      this.uploadedFileNameOutputSetting = {
        ...this.uploadedFileNameOutputSetting,
        ...userWork.uploadedFileNameOutputSetting,
      };
    }
  }

  async fileTypeChange(e: string) {
    if (this.fileType != e) {
      this.loading = true;
      try {
        this.fileType = e;
        console.log('changed: ' + e);

        const userWork = await this.polar.getUserWork(this.companyId, this.workId);
        if (userWork.id == undefined || userWork.id == null || userWork.id == '') {
          alert('Error');
          this.close.emit();
          return;
        }
        userWork.exportType = e;
        await this.polar.setUserWork(this.companyId, this.workId, userWork);
      } catch {}
      this.loading = false;
    }
  }

  async incrementalTypeChange(e: string) {
    if (this.incrementalType != e) {
      if (e == 'leading' || e == 'disabled' || e == 'trailing') {
        this.incrementalType = e;
        console.log('changed: ' + e);
        await this.updateIncrementalSettings();
      }
    }
  }

  async changeIncrementalColumnName() {
    let columnName = window.prompt('項目名を入力', this.incrementalColumnName);
    if (columnName != null) await this.incrementalColumnNameChange(columnName);
  }

  async incrementalColumnNameChange(e: string) {
    if (this.incrementalColumnName != e) {
      this.incrementalColumnName = e;
      console.log('changed: ' + e);
      await this.updateIncrementalSettings();
    }
  }

  async changeIncrementalStartingNumber() {
    let startingNumber = window.prompt('開始番号を入力', this.incrementalStartingNumber);
    if (startingNumber != null) await this.incrementalStartingNumberChange(startingNumber);
  }

  async incrementalStartingNumberChange(e: string) {
    if (!e.match(/^[0-9]+$/)) {
      alert('半角数字のみ入力してください。');
      return;
    }
    if (this.incrementalStartingNumber != e) {
      this.incrementalStartingNumber = e;
      console.log('starting number changed: ' + e);
      await this.updateIncrementalSettings();
    }
  }

  async updateIncrementalSettings() {
    this.loading = true;
    try {
      const userWork = await this.polar.getUserWork(this.companyId, this.workId);
      if (userWork.id == undefined || userWork.id == null || userWork.id == '') {
        alert('Error');
        this.close.emit();
        return;
      }
      userWork.incrementalColumnName = this.incrementalColumnName;
      userWork.incrementalType = this.incrementalType;
      userWork.incrementalStartingNumber = this.incrementalStartingNumber;
      await this.polar.setUserWork(this.companyId, this.workId, userWork);
    } catch {}
    this.loading = false;
  }

  async updateShowConfidence() {
    this.loading = true;
    try {
      const userWork = await this.polar.getUserWork(this.companyId, this.workId);
      if (userWork.id == undefined || userWork.id == null || userWork.id == '') {
        alert('Error');
        this.close.emit();
        return;
      }
      userWork.disableConfidence = !this.showConfidence;
      await this.polar.setUserWork(this.companyId, this.workId, userWork);
    } catch {}
    this.loading = false;
  }

  async toggleShowConfidence() {
    this.showConfidence = !this.showConfidence;
    await this.updateShowConfidence();
  }

  async uploadedFileNameOutputTypeChange($event: string) {
    if (
      this.uploadedFileNameOutputSetting.outputType != $event &&
      UPLOADED_FILE_NAME_OUTPUT_TYPES.includes($event)
    ) {
      this.uploadedFileNameOutputSetting.outputType = $event;

      await this.updateUploadedFileNameOutputSettings();
    }
  }

  async changeUploadedFileNameColumnName() {
    const columnName = window.prompt(
      '項目名を入力',
      this.uploadedFileNameOutputSetting.columnNameOfFileName,
    );

    if (columnName != null && columnName?.trim() !== '') {
      await this.uploadedFileNameColumnNameChange(columnName);
    }
  }

  async toggleUploadedFileIndexColumnEnabled() {
    this.uploadedFileNameOutputSetting.fileIndexColumnEnabled =
      !this.uploadedFileNameOutputSetting.fileIndexColumnEnabled;

    await this.updateUploadedFileNameOutputSettings();
  }

  async toggleMultifileDownloadEnabled() {
    this.uploadedFileNameOutputSetting.isMultiFileDownloadEnabled =
      !this.uploadedFileNameOutputSetting.isMultiFileDownloadEnabled;

    await this.updateUploadedFileNameOutputSettings();
  }

  async changeUploadedFileIndexColumnName() {
    const columnName = window.prompt(
      '連番項目名を入力',
      this.uploadedFileNameOutputSetting.columnNameOfFileIndex,
    );

    if (columnName != null && columnName?.trim() !== '') {
      await this.uploadedFileIndexColumnNameChange(columnName);
    }
  }

  async changeUploadedFileIndexStartingNumber() {
    const startingNumber = window.prompt(
      '開始番号を入力',
      this.uploadedFileNameOutputSetting.fileIndexStartingNumber.toString(),
    );

    if (startingNumber != null) {
      await this.uploadedFileIndexStartingNumberChange(startingNumber);
    }
  }

  private async uploadedFileNameColumnNameChange(columnName: string) {
    if (this.uploadedFileNameOutputSetting.columnNameOfFileName != columnName) {
      this.uploadedFileNameOutputSetting.columnNameOfFileName = columnName;

      await this.updateUploadedFileNameOutputSettings();
    }
  }

  private async uploadedFileIndexColumnNameChange(columnName: string) {
    if (this.uploadedFileNameOutputSetting.columnNameOfFileIndex != columnName) {
      this.uploadedFileNameOutputSetting.columnNameOfFileIndex = columnName;

      await this.updateUploadedFileNameOutputSettings();
    }
  }

  private async uploadedFileIndexStartingNumberChange(startingNumber: string) {
    if (!startingNumber.match(/^[0-9]+$/)) {
      alert('半角数字のみ入力してください。');

      return;
    }

    const _startingNumber = parseInt(startingNumber);

    if (this.uploadedFileNameOutputSetting.fileIndexStartingNumber != _startingNumber) {
      this.uploadedFileNameOutputSetting.fileIndexStartingNumber = _startingNumber;

      await this.updateUploadedFileNameOutputSettings();
    }
  }

  private async updateUploadedFileNameOutputSettings() {
    this.loading = true;

    try {
      const userWork = await this.polar.getUserWork(this.companyId, this.workId);

      if (userWork.id == undefined || userWork.id == '') {
        alert('Error');

        this.close.emit();
        return;
      }

      userWork.uploadedFileNameOutputSetting = this.uploadedFileNameOutputSetting;

      await this.polar.setUserWork(this.companyId, this.workId, userWork);
    } catch {}

    this.loading = false;
  }

  uploadedFileNameOutputTypeEnabled(): boolean {
    return (
      this.uploadedFileNameOutputSetting.outputType != null &&
      this.uploadedFileNameOutputSetting.outputType != 'disabled'
    );
  }
}
