<app-console-header
  label="Company - 作成"
  [combos]="false"
></app-console-header>
<div class="parent">
  <article class="message is-link">
    <div class="message-header">
      <p>会社を作成する</p>
    </div>
    <div class="message-body">
      <form name="createCompany" [formGroup]="createCompanyForm" (submit)="createCompany()">
        <div class="field">
          <label class="label">会社名 (必須)</label>
          <input
            name="companyName"
            class="input"
            type="text"
            placeholder="会社名を入力してください"
            formControlName="companyName"
          />
          <p
            class="help createCompanyFormError"
            *ngIf="createCompanyForm.get('companyName')?.touched && createCompanyForm.get('companyName')?.hasError('required')">
            会社名は必須です。
          </p>
        </div>

        <div class="field">
          <label class="label">メモ</label>
          <div class="control">
            <textarea
              name="memo"
              class="textarea"
              type="text"
              placeholder="メモを入力してください"
              formControlName="memo"></textarea>
          </div>
        </div>

        <div class="field is-grouped is-grouped-right">
          <div class="control">
            <button class="px-5 button is-link" type="submit" [disabled]="createCompanyForm.invalid">作成
            </button>
          </div>
          <app-inner-progress
            *ngIf="saving"
            [status]="savingStat"
          ></app-inner-progress>
        </div>
      </form>
    </div>
  </article>

  <div class="title py-4">登録されてる会社一覧</div>
  <table class="table is-fullwidth">
    <thead>
    <th>会社名</th>
    <th>メモ</th>
    <th></th>
    <th></th>
    </thead>

    <tbody>
    <tr *ngFor="let company of  companies">
      <th>{{company.name}}</th>
      <th>{{company.memo}}</th>
      <th>
        <button
          class="button px-2 js-modal-trigger"
          (click)="editCompany(company)"
        >
          編集
        </button>
      </th>
      <th>
        <button class="button px-2" (click)="deleteCompany(company)">
          削除
        </button>
      </th>
    </tr>
    </tbody>
  </table>
</div>

<app-console-edit-company
  *ngIf="showEditCompany"
  [selectCompanyInfo]="editCompanyInfo"
  (closed)="showEditCompany = false"
></app-console-edit-company>
