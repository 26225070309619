<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">{{ label }}</label>
  </div>
  <div class="field-body" [style.flexGrow]="grow">
    <div class="field">
      <div class="control">
        @if (ref == undefined) {
          <div style="margin-top: 4px; font-weight: 100; font-size: 14px" translate>未選択</div>
        }
        @if (ref != undefined) {
          <div
            style="
              margin-top: 4px;
              font-weight: 100;
              text-decoration: underline;
              cursor: pointer;
              font-size: 14px;
            "
            (click)="downloadFile()"
            translate
          >
            選択済 (クリックでダウンロード)
          </div>
        }
        <button class="button" (click)="selectFile()" translate>ファイル選択</button>
      </div>
    </div>
  </div>
</div>

<input #INPUT type="file" accept="text/csv" (change)="filePicked($event)" />

@if (loading) {
  <div class="loading">
    <p translate>ファイルをアップロードしています...</p>
    <app-inner-progress></app-inner-progress>
  </div>
}
