import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { MasterHistoryComponent } from './master-history.component';

import { CommonUiProviderService } from '../../common/common-ui-provider.service';
import { PolarFirebaseService } from '../../polar/polar-firebase.service';
import { MASTER_UPDATING_SERVICE } from '../master-updating.service';

import { MasterDocumentData, MasterHistoryDocumentData } from '../../polar/entity/master';

import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-master-history-container',
  standalone: true,
  imports: [CommonModule, MasterHistoryComponent, InnerProgressComponent],
  templateUrl: './master-history-container.component.html',
  styleUrls: ['./master-history-container.component.scss'],
})
export class MasterHistoryContainerComponent {
  private readonly polar = inject(PolarFirebaseService);
  private readonly commonUi = inject(CommonUiProviderService);
  private readonly masterUpdatingService = inject(MASTER_UPDATING_SERVICE);

  @Input() currentCompanyId!: string;
  @Input() selectedGroupId!: string;
  @Input() selectedMasterId!: string;
  @Input() isConsole: boolean = false;

  @Output() revertCompleted = new EventEmitter();
  @Output() close = new EventEmitter();

  loading: boolean = false;
  histories$?: Observable<MasterHistoryDocumentData[]>;
  stagedHistories$?: Observable<MasterHistoryDocumentData[]>;
  master$?: Observable<MasterDocumentData[]>;
  latestMaster?: MasterHistoryDocumentData;

  async ngOnInit() {
    const orderByDate = (a: MasterHistoryDocumentData, b: MasterHistoryDocumentData) => {
      if (a.createdAt == null) return 1;
      if (b.createdAt == null) return 1;
      return (b.createdAt as Timestamp).toMillis() - (a.createdAt as Timestamp).toMillis();
    };

    this.master$ = this.polar.getMastersByIdWithListening(
      this.currentCompanyId,
      this.selectedGroupId,
      this.selectedMasterId,
    );
    this.histories$ = this.polar
      .getMasterHistoriesWithListening(
        this.currentCompanyId,
        this.selectedGroupId,
        this.selectedMasterId,
      )
      .pipe(map((histories) => histories.sort(orderByDate)));

    this.stagedHistories$ = this.polar
      .getMasterHistoriesStagedWithListening(
        this.currentCompanyId,
        this.selectedGroupId,
        this.selectedMasterId,
      )
      .pipe(map((histories) => histories.sort(orderByDate)));

    this.latestMaster = await this.polar.getLatestMaster(
      this.currentCompanyId,
      this.selectedGroupId,
      this.selectedMasterId,
      false,
    );
  }

  async revert(master: MasterDocumentData, history: MasterHistoryDocumentData) {
    const res = await this.commonUi.showConfirm({
      title: '確認',
      body: this.masterUpdatingService.confirmMessageWhenRevert(),
      buttons: ['OK', 'キャンセル'],
    });

    if (res != 'OK') {
      return;
    }

    try {
      this.loading = true;

      await this.masterUpdatingService.revertMaster(this.currentCompanyId, master, history);
    } catch (e) {
      console.log(e);
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '処理中にエラーが発生しました。',
        buttons: ['OK'],
      });

      return;
    } finally {
      this.loading = false;
    }

    this.revertCompleted.emit();
  }
}
