@if (isBusters) {
  <app-header [showSignOut]="signedIn ?? true"></app-header>
}
@if (signedIn === true) {
  <div class="app-container">
    <router-outlet></router-outlet>
  </div>
}

@if (signedIn === false) {
  <app-login></app-login>
}

@if (signedIn === undefined) {
  <app-overlay-loading></app-overlay-loading>
}

<app-common-ui-provider></app-common-ui-provider>
