<div
  class="base"
  [style.marginLeft.px]="offsetX"
  [style.marginTop.px]="offsetY"
>
  <div class="container">
    <div style="position: relative">
      <img #IMAGE [src]="imageUrl2" />
    </div>
  </div>
</div>
