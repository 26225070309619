<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">{{ label }}</label>
  </div>
  <div class="field-body" [style.flexGrow]="grow">
    <div class="field">
      <div class="control">
        <button
          class="button"
          [ngClass]="smallButton ? 'small-button' : ''"
          (click)="buttonClick.emit()"
          [style.border]="nonInteractible ? 'none' : undefined"
          [style.pointerEvents]="nonInteractible ? 'none' : undefined"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
