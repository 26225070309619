import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { Encoding, detect, convert } from 'encoding-japanese';
import { CommonUiProviderService } from '../common-ui-provider.service';

@Component({
  selector: 'app-label-and-prompt',
  templateUrl: './label-and-prompt.component.html',
  styleUrls: ['./label-and-prompt.component.scss'],
})
export class LabelAndPromptComponent {
  @ViewChild('INPUT')
  el?: ElementRef;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiline: boolean = false;
  @Input() grow: number = 4.2;
  @Input() companyId: string = '';
  @Input() workId: string = '';

  _prompt: string = '';
  @Input()
  set prompt(val: string) {
    this._prompt = val;
  }

  get prompt(): string {
    return this._prompt;
  }

  @Output()
  promptChange = new EventEmitter<string>();

  changePrompt(val: string): void {
    this.prompt = val;
    this.promptChange.emit(val);
  }

  _script: string = '';
  @Input()
  set script(val: string) {
    this._script = val;
  }

  get script(): string {
    return this._script;
  }

  @Output()
  scriptChange = new EventEmitter<string>();

  changeScript(val: string): void {
    this.script = val;
    this.scriptChange.emit(val);
  }

  loading: boolean = false;

  constructor(
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
  ) {}

  updatePrompt() {}
}
