<div class="back" [ngClass]="hiding ? 'hiding' : null" (click)="closeUploadWindow()"></div>
<div class="base" [ngClass]="hiding ? 'hiding' : null">
  <header>
    <h2><i class="fa-regular fa-cloud"></i>ファイルをアップロード</h2>
    <p>PDFまたは画像をアップロードしてください</p>
  </header>
  <div
    class="upload-area"
    [ngClass]="dragging ? 'dragging' : ''"
    (dragover)="dragover($event)"
    (drop)="drop($event)"
    (dragleave)="dragleave()"
  >
    <i *ngIf="!dragging" class="fa-light fa-file-import"></i>
    <p *ngIf="!dragging">ドラッグ&ドロップでファイルをアップロードする</p>
    <p *ngIf="!dragging">または</p>
    <button *ngIf="!dragging" class="button" (click)="pickFile()">
      ファイルを選択
    </button>
    <i *ngIf="dragging" class="fa-light fa-hand"></i>
    <p *ngIf="dragging">ドロップ</p>
  </div>
  <div class="uploaded-files-parent">
    <div class="uploaded-files">
      <div class="uploaded-file" *ngFor="let file of files">
        <div class="delete-file">
          <button class="delete is-medium" (click)="remove(file)"></button>
        </div>
        <div class="icon" *ngIf="file.name.endsWith('.tiff') || file.name.endsWith('.tif'); else isPdf">
          <img
            src="assets/tiff-file-icon.svg"
            alt="tiff-file"
            class="tiff-file-icon"
          />
        </div>
        <ng-template #isPdf>
          <div class="icon" *ngIf="file.name.endsWith('.pdf'); else elseBlock">
            <i class="fa-regular fa-file-pdf"></i>
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <img
            *ngIf="urls[file.name + file.size]"
            [src]="urls[file.name + file.size]"
            alt=""/>
        </ng-template>
      </div>
    </div>
    <div class="uploaded-file-blurry"></div>
  </div>
  <div class="line"></div>
  <div class="buttons">
    <button class="button close" (click)="closeUploadWindow()" style="width: 92px">
      キャンセル
    </button>
    <button class="button done" (click)="uploadWorks()" style="width: 112px">
      アップロード
    </button>
  </div>
</div>

<input
  #INPUT
  class="file-input"
  type="file"
  accept="text/csv,image/*,application/pdf"
  (change)="filePicked()"
  multiple
/>

<div class="loading" *ngIf="loadingFull">
  <p *ngIf="uploadStatus == 'loading'">
    ファイルをアップロードしています... {{ percent }}
  </p>
  <app-inner-progress
    style="filter: brightness(1.5)"
    [status]="uploadStatus"
  ></app-inner-progress>
</div>
