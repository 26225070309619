import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { Encoding, detect, convert } from 'encoding-japanese';
import { CommonUiProviderService } from '../common-ui-provider.service';

@Component({
  selector: 'app-label-and-button',
  templateUrl: './label-and-button.component.html',
  styleUrls: ['./label-and-button.component.scss'],
})
export class LabelAndButtonComponent {
  @ViewChild('INPUT')
  el?: ElementRef;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() multiline: boolean = false;
  @Input() grow: number = 4.2;
  @Input() buttonText: string = '';
  @Input() smallButton: boolean = false;
  @Input() nonInteractible: boolean = false;

  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}
}
