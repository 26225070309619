import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Option } from './option';
import { TooltipDirective } from '../../../../../tooltip.directive';
import { MasterSelectStateService } from '../master-select-state.service';

@Component({
  selector: 'app-workflow-master-dropdown',
  standalone: true,
  imports: [CommonModule, TooltipDirective],
  templateUrl: './workflow-master-dropdown.component.html',
  styleUrls: ['./workflow-master-dropdown.component.scss'],
})
export class WorkflowMasterDropdownComponent {
  private readonly masterSelectStateService = inject(MasterSelectStateService);
  readonly state$ = this.masterSelectStateService.state$;

  isActive = false;

  @Input() newItemTemplateRef?: TemplateRef<any>;
  @Input() itemTemplateRef?: TemplateRef<any>;

  _options!: Option[];
  @Input() set options(value: Option[]) {
    this._options = value;

    // NOTE: set value の後に set options が呼ばれることがある
    this.selectByValue(this._value);
  }

  private _value?: string;
  @Input() set value(value: string | undefined) {
    this._value = value;

    this.selectByValue(value);
  }

  @Output() valueChange = new EventEmitter<Option | undefined>();

  ngOnInit() {
    if (this._value) {
      const option = this.findOptionsRecursive(this._value);
      this._setValue(option, false);
    }
  }

  selectByValue(value: string | undefined) {
    if (value && this._options && this._options.length > 0) {
      const option = this.findOptionsRecursive(value);
      this._setValue(option, false);
    } else {
      this._setValue(undefined, false);
    }
  }

  hasChildren(option: Option) {
    return option.children && option.children?.length > 0;
  }

  selectOption(option: Option | undefined) {
    this._setValue(option, true);
  }

  toggleActive() {
    this.isActive = !this.isActive;
  }

  private findOptionsRecursive(masterId: string) {
    if (!this._options) return undefined;

    return this._options
      .flatMap((o) => o.children ?? [])
      .concat(this._options)
      .find((f) => f.value.id === masterId);
  }

  private _setValue(option: Option | undefined, emit: boolean) {
    if (emit) this.valueChange.emit(option);
  }
}
