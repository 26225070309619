<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">{{ label }}</label>
  </div>
  <div class="field-body" [style.flex-grow]="grow">
    <div class="field">
      <div class="control">
        <button class="button" (click)="selectFile()" translate>ファイル選択</button>
        @if (preview) {
          <code>
            {{ preview }}
          </code>
        }
        @if (currentCsvData) {
          <div
            style="
              margin-top: 4px;
              font-weight: 100;
              text-decoration: underline;
              cursor: pointer;
              font-size: 14px;
            "
            (click)="downloadNewFile()"
            translate
          >
            {{ currentCsvData.originalFileName }} 選択済 (クリックでダウンロード)
          </div>
        } @else {
          <div style="margin-top: 4px; font-weight: 100; font-size: 14px" translate>未選択</div>
        }
      </div>
    </div>
  </div>
</div>

<input #INPUT type="file" accept="text/csv" (change)="fileSelected(INPUT.files)" />
