<div class="parent">
  <img *ngIf="image != null" id="img" #IMG [src]="image" />
  <div
    style="
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <app-inner-progress *ngIf="image == null"></app-inner-progress>
  </div>
  <div class="overlay-container">
    <div class="overlay" [style.width.%]="overlayWidthPer" [style.height.%]="overlayHeightPer">
      <div *ngFor="let rect of overlayRects"
        [style.top.%]="rect.y * 100"
        [style.left.%]="rect.x * 100"
        [style.width.%]="rect.w * 100"
        [style.height.%]="rect.h * 100"
        class="overlay-rect">
      </div>
    </div>
  </div>
</div>
