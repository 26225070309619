<div class="floating-container">
  <div
    class="floating-item"
    tooltip="マニュアルを見る"
    tooltipPlacement="bottom"
    (click)="openHelp()"
  >
    <i class="fa-regular fa-question"></i>
  </div>
</div>
