<div class="base">
  <div class="background" (click)="close.emit()"></div>

  <div class="content">
    <div class="header">
      <div class="close" (click)="close.emit()">
        <i class="fa-solid fa-times"></i>
      </div>
      <div class="title">変更履歴</div>
    </div>

    <ng-container *ngIf="master$ | async as masters">
      <ng-container *ngIf="histories$ | async as histories">
        <ng-container *ngIf="stagedHistories$ | async as stagedHistories">
          <div class="body" *ngIf="masters[0] as master">
            <app-master-history
              [selectedMaster]="master"
              [selectedLatestMaster]="latestMaster"
              [histories]="histories"
              [stagedHistories]="stagedHistories"
              [isConsole]="isConsole"
              [currentCompanyId]="currentCompanyId"
              (revert)="revert(master, $event)"
              (close)="close.emit()"
            ></app-master-history>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <app-inner-progress></app-inner-progress>
</div>
