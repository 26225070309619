import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-workflow-editor-control-header',
  templateUrl: './workflow-editor-control-header.component.html',
  styleUrls: ['./workflow-editor-control-header.component.scss'],
  standalone: true,
})
export class WorkflowEditorControlHeaderComponent {
  @Output()
  insertWorkflow: EventEmitter<void> = new EventEmitter<void>();
  @Input() itemIndex: number = 0;
  @Input() itemLength: number = 0;
}
