<div class="parent">
  @if (image != null) {
    <img id="img" #IMG [src]="image" />
  }
  <div
    style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center"
  >
    @if (image == null) {
      <app-inner-progress></app-inner-progress>
    }
  </div>
  @if (drawRectangle) {
    <div
      class="rectangle-parent"
      (mousedown)="drawRectangleMoseDown($event)"
      (mousemove)="drawRectangleMouseMove($event)"
      (mouseup)="drawRectangleMouseUp($event)"
    >
      <div
        class="rectangle"
        [style.top.px]="startLocationY"
        [style.left.px]="startLocationX"
        [style.width.px]="endLocationX - startLocationX"
        [style.height.px]="endLocationY - startLocationY"
      ></div>
    </div>
  }
  <div class="overlay-container">
    <div class="overlay" [style.width.%]="overlayWidthPer" [style.height.%]="overlayHeightPer">
      @for (rect of overlayRects; track rect) {
        <div
          [style.top.%]="rect.y * 100"
          [style.left.%]="rect.x * 100"
          [style.width.%]="rect.w * 100"
          [style.height.%]="rect.h * 100"
          class="overlay-rect"
        ></div>
      }
    </div>
  </div>
</div>
