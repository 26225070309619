  <ng-container *ngIf="canCreateAndDeleteItem">
    <ul class="menu-list">
      <li>
        <button
          class="button"
          style="margin-bottom: 12px; width: 150px; font-size: 12px"
          (click)="newGroupClicked.emit()"
          translate
        >
          新規グループ作成
        </button>
      </li>
      <li>
        <button
          class="button"
          style="width: 150px; font-size: 12px"
          (click)="newMasterClicked.emit()"
          translate
        >
          新規マスタ作成
        </button>
      </li>
    </ul>
  </ng-container>
  <p class="menu-label">グループ一覧</p>
  <app-inner-progress *ngIf="loading"></app-inner-progress>
  <ul class="menu-list">
    <ng-container *ngFor="let group of masterAndGroups">
      <ng-container *ngIf="selectedGroup?.id === group.id; else notSelectedGroup">
        <li>
          <div class="message is-link">
            <a class="message-body" (click)="groupSelected.emit(group)">{{group.name}}</a>
          </div>
        </li>
      </ng-container>
      <ng-template #notSelectedGroup>
        <li><a (click)="groupSelected.emit(group)">{{group.name}}</a></li>
      </ng-template>
    </ng-container>
  </ul>
