import { WorkInfo } from 'src/app/polar/entity/CompanyInfo';

export function SortWorks(wks: WorkInfo[]): WorkInfo[] {
  wks.sort((a, b) => {
    // by name but if name is ends with number, sort by number
    try {
      let aName = a.name!;
      let bName = b.name!;
      let aNum = aName.match(/\d+$/);
      let bNum = bName.match(/\d+$/);
      if (
        aNum != null &&
        bNum != null &&
        aName.replace(aNum[0], '') == bName.replace(bNum[0], '')
      ) {
        let aNumInt = parseInt(aNum[0]);
        let bNumInt = parseInt(bNum[0]);
        if (aNumInt < bNumInt) {
          return -1;
        } else if (aNumInt > bNumInt) {
          return 1;
        } else {
          return 0;
        }
      }
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    } catch {
      return 0;
    }
  });

  return wks;
}
