<ng-container *ngIf="state$ | async as state">
  <div
    class="dropdown"
    [ngClass]="{ 'is-active': isActive }"
    (click)="toggleActive()"
  >
    <div class="dropdown-trigger">
      <button
        class="button dropdown-button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span class="wrap-name">{{ state.selectedMasterFullName }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
        <!--
        <ng-container *ngIf="state.selectedMasterIsLatest !== undefined && !state.selectedMasterIsLatest">
        <span tooltip="最新のマスタが利用されていません" class="icon is-small">
          <i class="fas fa-link-slash" aria-hidden="true"></i>
        </span>
        </ng-container>
      --></button>
    </div>
    <div
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
      style="width: 200px"
    >
      <div class="dropdown-content">
        <ng-container *ngFor="let option of _options">
          <a
            *ngIf="option.type === 'master'"
            class="dropdown-item"
            (click)="selectOption(option)"
          >
            <ng-container
              *ngTemplateOutlet="
                itemTemplateRef ?? defaultItemTemplate;
                context: { $implicit: option }
              "
            ></ng-container>
          </a>

          <ng-container *ngIf="option.type === 'group'">
            <div class="nested dropdown dropdown-item">
              <div class="dropdown-trigger">
                <div aria-haspopup="true" aria-controls="dropdown-menu">
                  <div class="is-flex is-justify-content-space-between">
                    <span class="wrap-name">{{ option.value.name }}</span>
                    <div>
                      <span class="icon is-small">
                        <i class="fas fa-caret-right" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="dropdown-menu"
                id="dropdown-menu-{{ option.value.id }}"
                role="menu"
                style="width: 200px"
              >
                <div class="dropdown-content">
                  <p class="has-text-grey-light is-size-7 m-1 wrap-name">
                    {{ option.value.name }}
                  </p>
                  <ng-container *ngIf="hasChildren(option); else noChildren">
                    <ng-container *ngFor="let option of option.children">
                      <a
                        *ngIf="option.type === 'master'"
                        class="dropdown-item"
                        (click)="selectOption(option)"
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            itemTemplateRef ?? defaultItemTemplate;
                            context: { $implicit: option }
                          "
                        ></ng-container>
                      </a>
                    </ng-container>
                  </ng-container>
                  <ng-template #noChildren>
                    <p class="dropdown-item">マスタがありません</p>
                  </ng-template>

                  <ng-container *ngIf="newItemTemplateRef">
                    <ng-container
                      *ngTemplateOutlet="
                        newItemTemplateRef;
                        context: { $implicit: option }
                      "
                    ></ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="newItemTemplateRef">
          <ng-container *ngTemplateOutlet="newItemTemplateRef"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #defaultItemTemplate let-option>
    <div class="is-flex is-justify-content-space-between">
      <span>{{ option.value.name }}</span>
    </div>
  </ng-template>
</ng-container>
