import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
})
export class ConfirmDialogComponent {
  @Input() title?: string = '確認';
  @Input() body?: string = 'メッセージ';
  @Input() additonalLinkTitle?: string = undefined;
  @Input() additonalLinkAction?: () => void = undefined;
  @Input() buttons: string[] = ['Yes', 'No'];
  @Output() close: EventEmitter<string | undefined> = new EventEmitter();

  requestClose() {
    this.close.emit(undefined);
  }

  requestInput(button: string) {
    this.close.emit(button);
  }
}
