<app-console-left-menu
  [current]="current"
  (itemSelected)="leftMenuSelected($event)"
></app-console-left-menu>
<div class="main">
  <app-console-create-template
    *ngIf="current == 'template-create'"
  ></app-console-create-template>
  <app-console-try-template
    *ngIf="current == 'template-test'"
  ></app-console-try-template>
  <app-console-settings
    *ngIf="current == 'config-config'"
  ></app-console-settings>
  <app-console-prompt-settings
    *ngIf="current == 'config-prompt'"
  ></app-console-prompt-settings>
  <app-console-master-settings
    *ngIf="current == 'config-master'"
  ></app-console-master-settings>
  <app-console-create-company
    *ngIf="current == 'company-create'"
  ></app-console-create-company>
  <app-console-work-settings
    *ngIf="current == 'work-settings'"
  ></app-console-work-settings>
  <app-console-auth-groups
    *ngIf="current == 'auth-settings'"
  ></app-console-auth-groups>
</div>
