<ng-template #newMaster let-option>
  <hr class="dropdown-divider" />
  <a class="dropdown-item has-text-link" (click)="newMasterClicked.emit(option?.value)"
    >新規マスタ作成</a
  >
</ng-template>

<ng-template #itemMaster let-option>
  <div class="is-flex is-justify-content-space-between">
    <span class="wrap-name">{{ option.value.name }}</span>
    <div>
      @if (option.isLatest === 'not-latest') {
        <span class="icon">
          <a tooltip="最新のマスタを使う" class="has-text-success"
            ><i
              class="fas fa-link"
              (click)="useLatestMasterClicked.emit(option.value); $event.stopPropagation()"
            ></i
          ></a>
        </span>
      }
      <span class="icon">
        <a tooltip="CSVをダウンロードする" (click)="downloadMaster(option)"
          ><i class="fas fa-circle-down"></i
        ></a>
      </span>
      <span class="icon">
        <a
          tooltip="マスタを修正する"
          (click)="editMasterClicked.emit(option.value); $event.stopPropagation()"
          ><i class="fas fa-pen-to-square"></i
        ></a>
      </span>
    </div>
  </div>
</ng-template>

<app-workflow-master-dropdown
  [value]="selectedMaster?.id"
  (valueChange)="dropdownChange($event)"
  [options]="options"
  [newItemTemplateRef]="newMaster"
  [itemTemplateRef]="itemMaster"
></app-workflow-master-dropdown>
