<div class="base">
  <div class="background" (click)="requestClose()"></div>
  <div class="confirm-container">
    <div class="header" *ngIf="title != undefined">{{ title }}</div>
    <div class="body" *ngIf="body != undefined">{{ body }}</div>
    <div
      class="additionalLink"
      *ngIf="
        additonalLinkTitle != undefined && additonalLinkAction != undefined
      "
    >
      <button class="additional-button" (click)="additonalLinkAction!()">
        {{ additonalLinkTitle }}
      </button>
    </div>
    <div class="footer">
      <button
        class="button"
        *ngFor="let button of buttons"
        (click)="requestInput(button)"
      >
        {{ button }}
      </button>
    </div>
  </div>
</div>
