<div class="field is-horizontal">
  <div class="field-label is-normal">
    <div class="notice" *ngIf="showNotice" [tooltip]="noticeMessage">
      <i class="fa-regular fa-triangle-exclamation"></i>
    </div>
    <label class="label" [style.width.px]="useFieldLayout ? 78 : null">{{ label }}</label>
  </div>
  <div class="field-body" [style.flexGrow]="grow">
    <div class="field">
      <p class="control">
        <input
          class="input is-small"
          type="text"
          [placeholder]="placeholder"
          *ngIf="!multiline"
          [(ngModel)]="ref"
          [ngClass]="disableEdit ? 'disable-edit' : ''"
          [disabled]="disableEdit"
        />
        <textarea
          class="textarea"
          [placeholder]="placeholder"
          rows="5"
          *ngIf="multiline"
          [(ngModel)]="ref"
          [ngClass]="disableEdit ? 'disable-edit' : ''"
          [disabled]="disableEdit"
        ></textarea>
      </p>
    </div>
  </div>
</div>
