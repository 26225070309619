<div class="templates">
  <div class="title" translate>テンプレート</div>
  <div class="block transparent scrollbar" appHorizontalScrollEnabled>
    <div
      class="template"
      *ngFor="let item of templates; let idx = index"
      [ngClass]="
        selectedTemplate == item.id && !readonly ? 'active-template' : null
      "
      (click)="templateSelect.emit(item)"
      (mouseenter)="mouseOver(item)"
      (mousemove)="mouseMove(item, $event)"
      (mouseleave)="mouseLeave()"
      (contextmenu)="contextMenu($event, item)"
    >
      <i class="fa-solid fa-infinity" *ngIf="item.id == 'default'"></i>
      <div
        class="img-parent"
        *ngIf="item.id != 'default' && imageUrls[item.id!] != null"
      >
        <img
          *ngIf="item.id != 'default' && imageUrls[item.id!] != null"
          [src]="imageUrls[item.id!]"
        />
      </div>
      <app-inner-progress
        *ngIf="item.id != 'default' && imageUrls[item.id!] == null"
        status="loading"
      ></app-inner-progress>
      <div class="idx-display">
        {{ idx + 1 }}
      </div>
      <div class="delete-template" *ngIf="!readonly">
        <button
          class="delete is-medium"
          (click)="deleteTemplate(item)"
        ></button>
      </div>
    </div>
  </div>
</div>

<app-console-zoom-image
  *ngIf="mouseOverTemplate != undefined"
  [imageUrl2]="imageUrls[mouseOverTemplate.id!]"
  [offsetX]="templateOffsetX"
  [offsetY]="templateOffsetY"
></app-console-zoom-image>
