import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { GroupMasters, MasterMapSelectService } from './master-map-select.service';
import { MasterDocumentData, MasterGroupDocumentData } from '../../../../polar/entity/master';
import { MapWorkflow } from '../../../../polar/entity/Workflow';
import { MasterSelectStateService } from './master-select-state.service';

@Component({
  selector: 'app-workflow-map-select',
  templateUrl: './workflow-map-select.component.html',
  styleUrls: ['./workflow-map-select.component.scss'],
  providers: [MasterSelectStateService],
})
export class WorkflowMapSelectComponent {
  private readonly masterMapSelectService = inject(MasterMapSelectService);

  private readonly masterSelectStateService = inject(MasterSelectStateService);
  readonly state$ = this.masterSelectStateService.state$;

  @Input() grow: number = 4.2;

  @Input() set companyMasters(val: GroupMasters[]) {
    this.masterSelectStateService.groupMasters = val;
  }

  @Input() set selectedMaster(val: MapWorkflow['master'] | undefined) {
    this.masterSelectStateService.selectMaster(val);
  }
  @Output() readonly selectedMasterChange = new EventEmitter<MapWorkflow['master']>();

  selectedMasterChanged($event: MasterDocumentData) {
    if (!$event) return;

    const master: MapWorkflow['master'] = {
      id: $event.id!,
      groupId: $event.masterGroupId!,
    };
    this.masterSelectStateService.selectMaster(master);

    this.selectedMasterChange.emit(master);
  }

  openMasterCreatingModal(group?: MasterGroupDocumentData) {
    this.masterMapSelectService.openMasterCreatingModal(group?.id);
  }

  openMasterEditingModal(master: MasterDocumentData) {
    this.masterMapSelectService.openMasterEditingModal(master);
  }

  deleteMaster($event: MasterDocumentData) {
    throw new Error('Method not implemented.');
  }
}
