import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import AngularFireAuth from compat
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { OverlayLoadingComponent } from '../../modal/overlay-loading/overlay-loading.component';

import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-console-left-menu',
  templateUrl: './console-left-menu.component.html',
  styleUrls: ['./console-left-menu.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule, OverlayLoadingComponent],
})
export class ConsoleLeftMenuComponent implements OnInit {
  // template-create, template-testing
  // config-create, config-prompt
  @Input() current: string = '';
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();
  loggingOut: boolean = false;
  isDemo: boolean = false;

  constructor(
    private auth: AngularFireAuth,
    private polar: PolarFirebaseService,
    private commonUi: CommonUiProviderService,
    private translator: TranslateService,
  ) {}

  itemClick(item: string) {
    if (this.isDemo && item != 'template-create' && item != 'template-test') {
      this.commonUi.showConfirm({
        title: this.translator.instant('エラー'),
        body: this.translator.instant('デモユーザーはこの画面にアクセスすることはできません'),
        buttons: ['OK'],
      });
      return;
    }
    this.itemSelected.emit(item);
  }

  async ngOnInit() {
    if (await this.polar.isUserInDemoCollection((await this.auth.currentUser)!.uid)) {
      this.isDemo = true;
    }
  }

  logout() {
    this.loggingOut = true;
    setTimeout(() => {
      this.auth.signOut();
      setTimeout(() => {
        this.loggingOut = false;
      }, 500);
    }, 500);
  }
}
