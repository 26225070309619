<div class="base">
  <div class="background" (click)="close.emit()"></div>

  <div class="content" style="overflow: hidden">
    <div class="header">
      <div class="close" (click)="close.emit()">
        <i class="fa-solid fa-times"></i>
      </div>
      <div class="title">新規マスタ作成</div>
    </div>

    <div class="body mt-4" style="height: 100%">
      <section>
        <div style="margin-bottom: 20px">
          <app-master-group-breadcrumbs
            [groupName]="currentGroupName"
            [masterName]="'新規作成'"
          >
          </app-master-group-breadcrumbs>
        </div>
        <app-label-and-text-input
          [label]="'マスタ名' | translate"
          [placeholder]="'マスタ名' | translate"
          [(value)]="currentMaster.name"
          style="width: 100%"
        ></app-label-and-text-input>
        <div class="mt-2 mb-2">
          <app-csv-input
            [label]="'CSV'"
            (csvUploaded)="csvUploaded($event)"
            placeholder=""
            [multiline]="true"
            [companyId]="companyId"
          >
          </app-csv-input>
          <ng-container *ngIf="csvErrorMessages">
            <div class="message is-danger">
              <div class="message-body csv-error csv-error-button">
                <div (click)="openModal = true">CSV のエラーの詳細をみる</div>
              </div>
            </div>
            <app-csv-error-details
              [openModal]="openModal"
              [csvErrorMessages]="csvErrorMessages"
              (closeModal)="openModal = false"
            >
            </app-csv-error-details>
          </ng-container>
        </div>
        <button class="button" (click)="save()">保存</button>
      </section>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <app-inner-progress></app-inner-progress>
</div>
