<ng-container *ngIf="state$ | async as state">
  <ng-container *ngIf="state.currentCompanyId">
    <div class="mt-4">
      <div class="columns">
        <div class="column is-one-quarter grow-background">
          <app-master-group-list
            [masterAndGroups]="(state.groups$ | async) || []"
            [selectedGroup]="state.selectedGroup"
            [canCreateAndDeleteItem]="canCreateAndDeleteItem(state)"
            [loading]="state.updatingGroups"
            (newMasterClicked)="openMasterCreatingModal(null)"
            (newGroupClicked)="openGroupCreatingModal()"
            (groupSelected)="selectGroup($event)"
          >
          </app-master-group-list>
        </div>
        <div class="column is-two-quarter">
          <ng-container *ngIf="state.selectedGroup">
            <app-master-group-detail [selectedGroup]="state.selectedGroup">
              <app-master-grouped-master-list
                [masters]="state.mastersInSelectedGroup"
                [latestMastersDict]="state.latestMasterDict"
                [accessibleMasterGroups]="state.accessibleMasterGroups"
                [loading]="state.updating"
                [canCreateAndDeleteItem]="canCreateAndDeleteItem(state)"
                (newMasterClicked)="
                  openMasterCreatingModal(state.selectedGroup)
                "
                (editMasterClicked)="openMasterEditingModal($event)"
                (deleteMasterClicked)="
                  deleteMaster(state.currentCompanyId, $event)
                "
                (openHistoryClicked)="openHistory($event)"
              >
              </app-master-grouped-master-list>
            </app-master-group-detail>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- modals -->
    <ng-container *ngIf="state.showingHistoriesMasterId">
      <app-master-history-container
        *ngIf="state.showHistories"
        [selectedGroupId]="state.selectedGroup?.id!"
        [selectedMasterId]="state.showingHistoriesMasterId"
        [currentCompanyId]="state.currentCompanyId"
        (close)="closeHistory()"
        (revertCompleted)="revertCompleted()"
        [isConsole]="useAsAdmin"
      ></app-master-history-container>
    </ng-container>

    <ng-container *ngIf="state.currentEditingMasterId">
      <app-edit-master-modal
        *ngIf="state.showMasterEditingModal"
        (close)="closeMasterEditingModal()"
        [companyId]="state.currentCompanyId"
        [groupId]="state.selectedGroup?.id!"
        [masterId]="state.currentEditingMasterId"
        (saved)="masterListUpdated()"
      ></app-edit-master-modal>
    </ng-container>

    <ng-container *ngIf="canCreateAndDeleteItem(state)">
      <app-add-master-modal
        *ngIf="state.showMasterCreatingModal"
        (close)="closeMasterCreatingModal()"
        [companyId]="state.currentCompanyId"
        [groupId]="state.newMasterBelongingGroupId ?? null"
        (saved)="masterListUpdated()"
      >
      </app-add-master-modal>

      <app-add-master-group-modal
        *ngIf="state.showGroupCreatingModal"
        (close)="closeGroupCreatingModal()"
        [companyId]="state.currentCompanyId"
        (saved)="groupListUpdated()"
      >
      </app-add-master-group-modal>
    </ng-container>
    <!-- /modals -->
  </ng-container>
</ng-container>
