import { Component, EventEmitter, Input, Output } from '@angular/core';

import { InnerProgressComponent } from 'src/app/common/inner-progress/inner-progress.component';

import { MasterGroupDocumentData } from '../../polar/entity/master';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-master-group-list',
  standalone: true,
  imports: [TranslateModule, InnerProgressComponent],
  templateUrl: './master-group-list.component.html',
  styleUrls: ['./master-group-list.component.scss'],
})
export class MasterGroupListComponent {
  @Input() masterAndGroups!: MasterGroupDocumentData[];
  @Input() selectedGroup?: MasterGroupDocumentData;
  @Input() canCreateAndDeleteItem!: boolean;
  @Input() loading: boolean = false;

  @Output() newMasterClicked = new EventEmitter();
  @Output() newGroupClicked = new EventEmitter();
  @Output() groupSelected = new EventEmitter();
}
