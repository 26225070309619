<app-console-header
  (workSelect)="setWorkId($event)"
  (companySelect)="setCompanyId($event)"
></app-console-header>

<div class="parent classification-and-template">
  <app-console-image-classification-uploader
    (fileSelectMulti)="setFiles($event)"
    [multiple]="true"
  ></app-console-image-classification-uploader>
  <app-console-template-list
    [workId]="workId"
    [companyId]="companyId"
    [selectedTemplate]="selectedTemplate.id!"
    [readonly]="true"
  ></app-console-template-list>
</div>
<div class="parent workflow-and-output">
  <div
    style="display: flex; flex-direction: row; align-items: center; width: 100%"
  >
    <div class="title" style="width: auto" translate>結果</div>
    <div style="flex-grow: 1"></div>
    <button (click)="saveCsvAll()" class="save" style="margin-left: 12px">
      <p translate>CSVダウンロード</p>
    </button>
  </div>
  <app-inner-progress *ngIf="loading"></app-inner-progress>
  <p *ngIf="loading">{{ loadingStat }}</p>
  <div class="sample_parent" *ngFor="let data of datas">
    <div class="sample_image">
      <img [src]="data.img" />
    </div>
    <div class="sample_table">
      <app-spreadsheet
        *ngIf="data.stat == 'OK'"
        [data]="data.csv"
        [readonly]="true"
      ></app-spreadsheet>
      <p *ngIf="data.stat == 'ERROR'" translate>読み取り失敗</p>
      <p *ngIf="data.stat == 'TEMPLATE-NOTFOUND'" translate>
        テンプレートが見つかりませんでした
      </p>
    </div>
  </div>
</div>
