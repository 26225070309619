import {
  Timestamp,
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentReference,
  FieldValue,
} from '@angular/fire/firestore';

type MasterDocumentData = {
  id?: string;
  name: string;
  // NOTE: このドキュメントはユーザーに権限があるため、管理者の email は保存しない
  createdBy: {
    uid: string;
    emailSnapshot: string | null;
    isAdminSnapshot: boolean;
  };
  createdAt: Timestamp | FieldValue;
  updatedAt: Timestamp | FieldValue;
  // use this as where key to query with collectionGroup
  companyId: string;
  masterGroupId: string;
  csv: {
    header: string;
    filePath: string;
  };
};

const blankMasterDocumentData = {
  name: '',
  createdBy: {
    uid: '',
    emailSnapshot: null,
    isAdminSnapshot: true,
  },
  createdAt: null,
  updatedAt: null,
  companyId: '',
  masterGroupId: '',
  csv: {
    header: '',
    filePath: '',
  },
};

type MasterGroupDocumentData = {
  id?: string;
  type: 'group' | 'withoutGroup';
  name: string;
  createdBy: string;
  createdAt: Timestamp | null;
};

const withoutGroupName = '所属グループ無し';

const blankMasterGroupDocumentData = {
  type: 'group',
  name: '',
  createdBy: '',
  createdAt: null,
};

type MasterHistoryDocumentData = Omit<
  MasterDocumentData,
  'latestHistoryId' | 'updatedAt' | 'companyId' | 'selfRef'
> & {
  masterId: string;
  reason: string;
};

const blankMasterHistoryDocumentData = {
  name: '',
  masterId: '',
  masterGroupId: null,
  createdBy: {
    uid: '',
    emailSnapshot: null,
    isAdminSnapshot: true,
  },
  createdAt: null,
  csv: {
    header: '',
    filePath: '',
  },
};

const createHistoryFromMasterData = (
  master: MasterDocumentData,
  id: string,
): MasterHistoryDocumentData => {
  const newHistory: MasterHistoryDocumentData = {
    ...blankMasterHistoryDocumentData,
    name: master.name,
    masterGroupId: master.masterGroupId,
    createdAt: master.updatedAt, // NOTE: 履歴の作成日はマスタの前回更新日 (ここでは current が更新前の現在、つまり前回）
    createdBy: master.createdBy,
    csv: master.csv,
    masterId: id,
    reason: '',
  };

  return newHistory;
};

type WithId<T> = T & { id: string };
type Master = WithId<MasterDocumentData>;
type MasterGroup = WithId<MasterGroupDocumentData>;
type MasterHistory = WithId<MasterHistoryDocumentData>;

export {
  Master,
  MasterGroup,
  MasterHistory,
  MasterDocumentData,
  blankMasterDocumentData,
  MasterGroupDocumentData,
  blankMasterGroupDocumentData,
  MasterHistoryDocumentData,
  blankMasterHistoryDocumentData,
  createHistoryFromMasterData,
  WithId,
  withoutGroupName,
};
