import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { SpreadsheetComponent } from '../../common/spreadsheet/spreadsheet.component';
import { ConsoleHeaderComponent } from '../console-header/console-header.component';
import { ConsoleImageClassificationUploaderComponent } from '../console-image-classification-uploader/console-image-classification-uploader.component';
import { ConsoleTemplateListComponent } from '../console-template-list/console-template-list.component';

import { PolarApiService } from 'src/app/polar/polar-api.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { TemplateInfo } from 'src/app/polar/entity/TemplateInfo';
import { SpreadsheetData, WorkflowProcessor } from 'src/app/polar/entity/Workflow';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface Result {
  img: SafeResourceUrl;
  csv: SpreadsheetData;
  stat: string;
}

@Component({
  selector: 'app-console-try-template',
  templateUrl: './console-try-template.component.html',
  styleUrls: ['./console-try-template.component.scss'],
  standalone: true,
  imports: [
    ConsoleHeaderComponent,
    ConsoleImageClassificationUploaderComponent,
    ConsoleTemplateListComponent,
    TranslateModule,
    InnerProgressComponent,
    SpreadsheetComponent,
  ],
})
export class ConsoleTryTemplateComponent {
  companyId: string = '';
  workId: string = '';
  classificationVersion: string = '';
  selectedTemplate: TemplateInfo = {};

  datas: Result[] = [];

  constructor(
    private clova: PolarApiService,
    private polar: PolarFirebaseService,
    private sanitizer: DomSanitizer,
    private translator: TranslateService,
  ) {}

  setCompanyId(val: string) {
    this.companyId = val;
  }

  setWorkId(val: string) {
    this.workId = val;
    this.onCompanyIdOrWorkIdChange();
  }

  async onCompanyIdOrWorkIdChange() {
    const workInfo = await this.polar.getWork(this.companyId, this.workId);
    if (workInfo.classificationVersion) {
      this.classificationVersion = workInfo.classificationVersion.toString();
    } else {
      this.classificationVersion = 'v1';
    }
  }

  url?: SafeResourceUrl;

  loading: boolean = false;
  loadingStat: string = '';

  size = 0;
  cnt = 0;

  async setFiles(files: File[]) {
    this.size = files.length;
    this.cnt = 0;
    for (let i = 0; i < files.length; i++) {
      this.cnt += 1;
      await this.setFile(files[i]);
    }
  }

  download(filename: string, text: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, text], { type: 'text/csv' });
    var element = document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(blob));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  async saveCsvAll() {
    let csvs = this.datas.map((f) => f.csv.csv);
    let mergedCsv = '';

    // merge csvs into mergedCsv
    csvs.forEach((csv) => {
      // get first line
      let idx = csv.indexOf('\n');

      if (idx <= 0) return;
      let firstLine = csv.substring(0, idx);

      if (mergedCsv == '') {
        mergedCsv += firstLine + '\n';
      }

      let remainingLines = csv.substring(idx + 1);

      mergedCsv += remainingLines;

      if (remainingLines.length > 0 && remainingLines[remainingLines.length - 1] != '\n') {
        mergedCsv += '\n';
      }
    });

    this.download('example_csv_all.csv', mergedCsv);
  }

  async setFile(file: File) {
    this.loading = true;
    this.loadingStat =
      this.translator.instant('ファイルの形式を認識しています... (') +
      this.cnt +
      '/' +
      this.size +
      ')';

    this.loadingStat =
      this.translator.instant('画像を回転しています... (') + this.cnt + '/' + this.size + ')';
    let fileRotated = await this.clova.rotateImageAuto(file);
    file = new File([fileRotated], 'filename');

    this.loadingStat =
      this.translator.instant('テンプレートを探しています... (') + this.cnt + '/' + this.size + ')';
    let matched = await this.clova.getMatchedTemplate(this.companyId, this.workId, file, false);

    let data = {
      stat: 'OK',
      csv: {
        csv: '',
        loadableDump: null,
      },
      img: '',
    } as Result;

    if (matched['id'] != '' && matched['similarity'] > 0.6) {
      // matched
      try {
        this.loadingStat =
          this.translator.instant('認識を実行しています... (') + this.cnt + '/' + this.size + ')';
        let wroteResponse = await this.clova.executeReadWrite(
          this.companyId,
          this.workId,
          matched.id,
          false,
          file,
        );

        let wrote = wroteResponse['wrote'];
        let confidences = wroteResponse['confidences'];

        let csv = '';

        for (let i = 0; i < wrote.length; i++) {
          let datas = [];
          for (let j = 0; j < wrote[i].length; j++) {
            // replace " to ""
            wrote[i][j] = wrote[i][j].replace(/"/g, '""');
            datas.push('"' + wrote[i][j] + '"');
          }
          csv += datas.join(',') + '\n';
        }

        data.csv = {
          csv: csv,
          loadableDump: null,
        };
        data.stat = 'OK';
      } catch {
        data.stat = 'ERROR';
      }
    } else {
      data.stat = 'TEMPLATE-NOTFOUND';
    }

    this.loadingStat =
      this.translator.instant('ファイルを読み込んでいます... (') + this.cnt + '/' + this.size + ')';
    var reader = new FileReader();

    reader.onload = (event: any) => {
      let url = event.target.result;
      data.img = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.datas.splice(0, 0, data);
    };

    reader.readAsDataURL(file);
    this.loading = false;
  }
}
