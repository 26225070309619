<div class="classification">
  <div class="title" translate>
    分類
    <span class="tag is-success ml-2 has-text-weight-bold"
      >version-{{ this.classificationVersion }}</span
    >
  </div>
  <div class="block">
    @if (this.classificationVersion != 'v2-processing') {
      <div class="upload-box" (click)="pickFile()">
        <i class="fa-regular fa-plus"></i>
        <p translate>ファイル選択</p>
      </div>
    } @else {
      <div class="upload-box">
        <p class="small">切り替え中はアップロードできません</p>
      </div>
    }
  </div>
</div>
<input
  #INPUT
  type="file"
  [multiple]="multiple"
  accept="image/*,application/pdf"
  (change)="filePicked($event)"
/>

@if (loading) {
  <div class="loading">
    <p translate>ファイルを変換しています...</p>
    <app-inner-progress></app-inner-progress>
  </div>
}
