import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { EditWorkNameComponent } from './edit-work/edit-work-name/edit-work-name.component';
import { EditWorkInputComponent } from './edit-work/edit-work-input/edit-work-input.component';
import { EditWorkFormatComponent } from './edit-work/edit-work-format/edit-work-format.component';
import { EditWorkMapsComponent } from './edit-work/edit-work-maps/edit-work-maps.component';
import { EditWorkCompleteComponent } from './edit-work/edit-work-complete/edit-work-complete.component';
import { WorkDashboardComponent } from './work/work-dashboard/work-dashboard.component';
import { WorkCheckComponent } from './work/work-check/work-check.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './common/header/header.component';
import { ConsoleComponent } from './console/console.component';
import { ConsoleLeftMenuComponent } from './console/console-left-menu/console-left-menu.component';
import { ConsoleCreateTemplateComponent } from './console/console-create-template/console-create-template.component';
import { WorkflowEditorComponent } from './console/workflow/workflow-editor/workflow-editor.component';
import { WorkflowEditorItemComponent } from './console/workflow/workflow-editor-item/workflow-editor-item.component';
import { WorkflowOutputComponent } from './console/workflow/workflow-output/workflow-output.component';
import { ConsoleHeaderComponent } from './console/console-header/console-header.component';
import { HorizontalScrollEnabledDirective } from './horizontal-scroll-enabled.directive';
import { ConsoleTemplateListComponent } from './console/console-template-list/console-template-list.component';
import { ConsoleImageClassificationUploaderComponent } from './console/console-image-classification-uploader/console-image-classification-uploader.component';
import { LabelAndTextInputComponent } from './common/label-and-text-input/label-and-text-input.component';
import { LabelAndComboBoxComponent } from './common/label-and-combo-box/label-and-combo-box.component';
import { SpreadsheetComponent } from './common/spreadsheet/spreadsheet.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage, getStorage, connectStorageEmulator } from '@angular/fire/storage';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { OverlayLoadingComponent } from './modal/overlay-loading/overlay-loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsoleSettingsComponent } from './console/console-settings/console-settings.component';
import { InnerProgressComponent } from './common/inner-progress/inner-progress.component';
import { ConsolePromptSettingsComponent } from './console/console-prompt-settings/console-prompt-settings.component';
import { ConsoleCreateCompanyComponent } from './console/console-create-company/console-create-company.component';
import { ConsoleEditCompanyComponent } from './console/console-create-company/console-edit-company/console-edit-company.component';
import { ConsoleBoundingBoxSelectorComponent } from './console/console-create-template/console-bounding-box-selector/console-bounding-box-selector.component';
import { WorkflowSelectModalComponent } from './console/workflow/workflow-editor/workflow-select-modal/workflow-select-modal.component';
import { ConsoleZoomImageComponent } from './console/console-zoom-image/console-zoom-image.component';
import { ConsoleTryTemplateComponent } from './console/console-try-template/console-try-template.component';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { CommonUiProviderComponent } from './common/common-ui-provider/common-ui-provider.component';
import { LabelAndFileInputComponent } from './common/label-and-file-input/label-and-file-input.component';
import { WorkListComponent } from './work/work-list/work-list.component';
import { WorkUploadFileComponent } from './work/work-upload-file/work-upload-file.component';
import { SpreadsheetV2Component } from './common/spreadsheet-v2/spreadsheet-v2.component';
import { ImageViewerComponent } from './common/image-viewer/image-viewer.component';
import { LargeCheckboxComponent } from './common/large-checkbox/large-checkbox.component';
import { TooltipDirective } from './tooltip.directive';
import { ModalListFilesComponent } from './modal-list-files/modal-list-files.component';
import { ModalListDownloadHistoryComponent } from './modal-list-download-history/modal-list-download-history.component';
import { LabelAndPromptComponent } from './common/label-and-prompt/label-and-prompt.component';
import { LabelAndButtonComponent } from './common/label-and-button/label-and-button.component';
import { ConsoleTestImagesListComponent } from './console/console-test-images-list/console-test-images-list.component';
import { ConsoleWorkSettingsComponent } from './console/console-work-settings/console-work-settings.component';
import { MainAccountModalComponent } from './main-account-modal/main-account-modal.component';
import { MainBillingModalComponent } from './main-billing-modal/main-billing-modal.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MainWorkSettingsModalComponent } from './main-work-settings-modal/main-work-settings-modal.component';
import { WorkRightBottomFloatingComponent } from './work/work-right-bottom-floating/work-right-bottom-floating.component';
import { ApplicationEnvironment, ENVIRONMENT } from './applicationEnvironment';
import { ConsoleMasterSettingsComponent } from './console/console-master-settings/console-master-settings.component';
import { MasterGroupListComponent } from './master-settings/master-group-list/master-group-list.component';
import { AddMasterGroupModalComponent } from './master-settings/add-master-group-modal/add-master-group-modal.component';
import { MasterGroupDetailComponent } from './master-settings/master-group-detail/master-group-detail.component';
import { MasterGroupedMasterListComponent } from './master-settings/master-grouped-master-list/master-grouped-master-list.component';
import { AddMasterModalComponent } from './master-settings/add-master-modal/add-master-modal.component';
import { MasterHistoryContainerComponent } from './master-settings/master-history/master-history-container.component';
import { MasterHistoryComponent } from './master-settings/master-history/master-history.component';
import { DownloadMasterCsvDirective } from './master-settings/download-master-csv-directive';
import { EditMasterModalComponent } from './master-settings/edit-master-modal/edit-master-modal.component';
import { MainMasterSettingsModalComponent } from './main-master-settings-modal/main-master-settings-modal.component';
import { WorkflowMapSelectComponent } from './console/workflow/workflow-editor/workflow-map-select/workflow-map-select.component';
import { WorkflowMasterListComponent } from './console/workflow/workflow-editor/workflow-map-select/workflow-master-select/workflow-master-list.component';
import { WorkflowMasterDropdownComponent } from './console/workflow/workflow-editor/workflow-map-select/workflow-master-dropdown/workflow-master-dropdown.component';
import { CsvInputComponent } from './master-settings/csv-input/csv-input.component';
import { MasterGroupBreadcrumbsComponent } from './master-settings/master-group-breadcrumbs.component';
import { SafeEmailPipe } from './master-settings/safe-email.pipe';
import { DateLocalString } from './master-settings/date-local-string.pipe';
import { WorkflowEditorControlHeaderComponent } from './console/workflow/workflow-editor/workflow-editor-control-header/workflow-editor-control-header.component';
import { ConsoleAuthGroupsComponent } from './console/console-auth-groups/console-auth-groups.component';
import { AuthGroupSettingsComponent } from './auth-group-settings/auth-group-settings.component';
import { AuthGroupSettingsAuthorityModalComponent } from './auth-group-settings/auth-group-settings-authority-modal/auth-group-settings-authority-modal.component';
import { LabelAndCheckBoxComponent } from './common/label-and-check-box/label-and-check-box.component';
import { AuthGroupSettingsUsersListComponent } from './auth-group-settings/auth-group-settings-users-list/auth-group-settings-users-list.component';
import { WorkListAuthGroupEnabledComponent } from './work/work-list-auth-group-enabled/work-list-auth-group-enabled.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    EditWorkNameComponent,
    EditWorkInputComponent,
    EditWorkFormatComponent,
    EditWorkMapsComponent,
    EditWorkCompleteComponent,
    WorkDashboardComponent,
    WorkCheckComponent,
    LoginComponent,
    HeaderComponent,
    ConsoleComponent,
    ConsoleLeftMenuComponent,
    ConsoleCreateTemplateComponent,
    WorkflowEditorComponent,
    WorkflowEditorItemComponent,
    WorkflowOutputComponent,
    HorizontalScrollEnabledDirective,
    ConsoleTemplateListComponent,
    ConsoleImageClassificationUploaderComponent,
    SpreadsheetComponent,
    OverlayLoadingComponent,
    ConsoleSettingsComponent,
    ConsolePromptSettingsComponent,
    ConsoleCreateCompanyComponent,
    ConsoleEditCompanyComponent,
    ConsoleBoundingBoxSelectorComponent,
    WorkflowSelectModalComponent,
    ConsoleZoomImageComponent,
    ConsoleTryTemplateComponent,
    ConfirmDialogComponent,
    CommonUiProviderComponent,
    LabelAndFileInputComponent,
    WorkListComponent,
    WorkUploadFileComponent,
    SpreadsheetV2Component,
    ImageViewerComponent,
    LargeCheckboxComponent,
    ModalListFilesComponent,
    ModalListDownloadHistoryComponent,
    LabelAndPromptComponent,
    LabelAndButtonComponent,
    ConsoleTestImagesListComponent,
    ConsoleWorkSettingsComponent,
    MainAccountModalComponent,
    MainBillingModalComponent,
    MainWorkSettingsModalComponent,
    WorkRightBottomFloatingComponent,
    WorkflowMapSelectComponent,
    WorkflowMasterListComponent,
    WorkflowEditorControlHeaderComponent,
    ConsoleAuthGroupsComponent,
    AuthGroupSettingsComponent,
    AuthGroupSettingsAuthorityModalComponent,
    LabelAndCheckBoxComponent,
    AuthGroupSettingsUsersListComponent,
    WorkListAuthGroupEnabledComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    TooltipDirective,
    ReactiveFormsModule,
    InnerProgressComponent,
    ConsoleHeaderComponent,
    LabelAndTextInputComponent,
    LabelAndComboBoxComponent,
    EditMasterModalComponent,
    ConsoleMasterSettingsComponent,
    MainMasterSettingsModalComponent,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    NgxGoogleAnalyticsModule.forRoot(environment.gtag),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      const auth = getAuth();

      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099');
      }

      return auth;
    }),
    provideFirestore(() => {
      const store = getFirestore();

      if (environment.useEmulators) {
        connectFirestoreEmulator(store, 'localhost', 8086);
      }

      return store;
    }),
    provideFunctions(() => {
      const functions = getFunctions();

      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }

      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();

      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }

      return storage;
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MasterGroupListComponent,
    MasterGroupDetailComponent,
    MasterGroupedMasterListComponent,
    DownloadMasterCsvDirective,
    WorkflowMasterDropdownComponent,
    CsvInputComponent,
    MasterGroupBreadcrumbsComponent,
    DateLocalString,
    SafeEmailPipe,
    MasterHistoryComponent,
    MasterHistoryContainerComponent,
    AddMasterModalComponent,
    AddMasterGroupModalComponent,
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: ENVIRONMENT, useClass: ApplicationEnvironment },
    ScreenTrackingService,
    UserTrackingService,
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent],
  exports: [TooltipDirective],
})
export class AppModule {}
