import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonUiProviderService } from '../common/common-ui-provider.service';
import { MasterDocumentData, MasterGroupDocumentData } from '../polar/entity/master';
import { canCreateAndDeleteItem, MasterSettingsService } from './master-settings.service';
import { MASTER_UPDATING_SERVICE } from './master-updating.service';
import { CommonModule } from '@angular/common';
import { MasterGroupListComponent } from './master-group-list/master-group-list.component';
import { MasterGroupDetailComponent } from './master-group-detail/master-group-detail.component';
import { MasterGroupedMasterListComponent } from './master-grouped-master-list/master-grouped-master-list.component';
import { MasterHistoryContainerComponent } from './master-history/master-history-container.component';
import { EditMasterModalComponent } from './edit-master-modal/edit-master-modal.component';
import { AddMasterModalComponent } from './add-master-modal/add-master-modal.component';
import { AddMasterGroupModalComponent } from './add-master-group-modal/add-master-group-modal.component';
import { InnerProgressComponent } from '../common/inner-progress/inner-progress.component';

@Component({
  selector: 'app-master-settings-main',
  standalone: true,
  imports: [
    CommonModule,
    MasterGroupListComponent,
    MasterGroupDetailComponent,
    MasterGroupedMasterListComponent,
    MasterHistoryContainerComponent,
    EditMasterModalComponent,
    AddMasterModalComponent,
    AddMasterGroupModalComponent,
    InnerProgressComponent,
  ],
  templateUrl: './master-settings-main.component.html',
  providers: [MasterSettingsService],
})
export class MasterSettingsMainComponent implements OnInit, OnDestroy {
  private readonly settingService = inject(MasterSettingsService);
  private readonly masterUpdatingService = inject(MASTER_UPDATING_SERVICE);
  private readonly commonUi = inject(CommonUiProviderService);
  private readonly translator = inject(TranslateService);

  readonly state$ = this.settingService.state$;

  @Input() useAsAdmin!: boolean;

  @Input() set companyId(val: string) {
    this.settingService.currentCompanyId = val;
    this.settingService.changeGroup(undefined);
  }

  async ngOnInit() {
    return this.settingService.initialize(this.useAsAdmin);
  }

  ngOnDestroy() {
    this.settingService.finalize();
  }

  async masterListUpdated() {
    await this.commonUi.showConfirm({
      title: this.translator.instant('マスタを更新しました'),
      body: this.translator.instant('マスタを更新しました'),
      buttons: ['OK'],
    });

    this.settingService.closeMasterCreatingModal();
    this.settingService.closeMasterEditingModal();
  }

  async groupListUpdated() {
    await this.commonUi.showConfirm({
      title: this.translator.instant('グループを作成しました'),
      body: this.translator.instant('グループを作成しました'),
      buttons: ['OK'],
    });

    this.settingService.changeGroup(undefined);
    this.settingService.closeGroupCreatingModal();
  }

  openMasterCreatingModal($event: MasterGroupDocumentData | null) {
    this.settingService.openMasterCreatingModal($event?.id ?? null); // TODO null に意味を持たせているので読みにくい
  }

  closeMasterCreatingModal() {
    this.settingService.closeMasterCreatingModal();
  }

  openMasterEditingModal($event: MasterDocumentData) {
    // TODO: id! を回避
    this.settingService.openMasterEditingModal($event.id!);
  }

  closeMasterEditingModal() {
    this.settingService.closeMasterEditingModal();
  }

  async deleteMaster(companyId: string, $event: MasterDocumentData) {
    const result = await this.commonUi.showConfirm({
      title: '確認',
      body: '削除してもよろしいですか？',
      buttons: ['OK', 'キャンセル'],
    });

    if (result != 'OK') return;

    await this.settingService.deleteMaster(companyId, $event);
  }

  openHistory($event: MasterDocumentData) {
    this.settingService.openHistory($event);
  }

  closeHistory() {
    this.settingService.closeHistory();
  }

  async revertCompleted() {
    await this.commonUi.showConfirm({
      title: this.translator.instant('マスタを履歴から戻しました'),
      body: this.translator.instant('マスタを履歴から戻しました'),
      buttons: ['OK'],
    });

    this.closeHistory();
  }

  selectGroup($event: MasterGroupDocumentData) {
    this.settingService.changeGroup($event);
  }

  openGroupCreatingModal() {
    this.settingService.openGroupCreatingModal();
  }

  closeGroupCreatingModal() {
    this.settingService.closeGroupCreatingModal();
  }

  protected readonly canCreateAndDeleteItem = canCreateAndDeleteItem;
}
