<div class="base">
  <div class="background" (click)="closeEvent()"></div>
  <div class="content">
    <div class="header">
      <div class="title">セル数 - {{ yearMonth }}</div>
      <div class="close" (click)="closeEvent()">
        <i class="fa-solid fa-times"></i>
      </div>
    </div>
    <div class="body">
      <table>
        <thead>
          <tr>
            <th scope="col">仕事</th>
            <th scope="col">セル数</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of table">
            <th scope="row">{{ item[0] }}</th>
            <td>{{ item[1] }}</td>
          </tr>
          <tr>
            <th scope="row">合計</th>
            <td style="font-weight: bold">{{ total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <app-inner-progress></app-inner-progress>
</div>
