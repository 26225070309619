import { Injectable, inject } from '@angular/core';

import { PolarFirebaseService } from '../../../../polar/polar-firebase.service';

import { MapWorkflow } from '../../../../polar/entity/Workflow';
import { MasterDocumentData, MasterGroupDocumentData } from '../../../../polar/entity/master';

import { BehaviorSubject, Subscription } from 'rxjs';

export type GroupMasters = {
  group: MasterGroupDocumentData;
  masters: MasterDocumentData[];
};

interface MasterMapSelectState {
  currentCompanyId?: string;

  groupFetched: boolean;
  groupMastersList: GroupMasters[];
  selectedMaster?: MapWorkflow['master'];
  selectedMasterIsLatest?: boolean;

  showMasterCreatingModal: boolean;
  newMasterBelongingGroupId?: string | null;
  showMasterEditingModal: boolean;
  currentEditingMasterId?: string;
  currentEditingMasterGroupId?: string | null;
}

const initialState: MasterMapSelectState = {
  currentCompanyId: undefined,

  groupFetched: false,
  groupMastersList: [],
  selectedMaster: undefined,

  showMasterCreatingModal: false,
  newMasterBelongingGroupId: undefined,
  showMasterEditingModal: false,
};

@Injectable()
export class MasterMapSelectService {
  private readonly polar = inject(PolarFirebaseService);

  private readonly store = new BehaviorSubject<MasterMapSelectState>(initialState);
  private readonly _currentCompanyId = new BehaviorSubject<string | undefined>(undefined);
  private currentCompanyIdSubscription?: Subscription;

  get state$() {
    return this.store.asObservable();
  }

  get currentCompanyId() {
    return this._currentCompanyId.value;
  }

  set currentCompanyId(val: string | undefined) {
    this._currentCompanyId.next(val);
  }

  async initialize() {
    if (!(await this.polar.isAdmin())) {
      throw new Error('has no permission');
    }

    this.currentCompanyIdSubscription = this._currentCompanyId.subscribe(async (val) => {
      if (val == undefined) {
        this.clearGroupMastersList();

        return;
      }

      this.store.next({
        ...this.store.value,
        currentCompanyId: val,
      });

      await this.fetchGroups(val);
    });
  }

  finalize() {
    this.currentCompanyIdSubscription?.unsubscribe();
  }

  clearGroupMastersList() {
    this.store.next({ ...this.store.value, groupMastersList: [] });
  }

  async fetchGroups(companyId: string) {
    // NOTE: 所属無しを先頭に持ってくる
    const orderByAlphabetically = (a: MasterGroupDocumentData, b: MasterGroupDocumentData) => {
      if (a.type == 'withoutGroup') return -1;

      return a.name.localeCompare(b.name);
    };

    const orderByAlphabeticallyMaster = (a: MasterDocumentData, b: MasterDocumentData) => {
      return a.name.localeCompare(b.name);
    };

    // NOTE: N+1 になっているので、コレクショングループの利用を検討する
    //       その場合は masters のドキュメントに絞り込み用の companyId を用意する
    const groups = await this.polar.getMasterGroups(companyId);
    const groupMastersList: GroupMasters[] = [];
    for await (const group of groups.sort(orderByAlphabetically)) {
      groupMastersList.push({
        group,
        masters: (await this.polar.getMastersInGroup(companyId, group.id!)).sort(
          orderByAlphabeticallyMaster,
        ),
      });
    }

    this.store.next({ ...this.store.value, groupMastersList: groupMastersList });
  }

  openMasterCreatingModal(belongingGroupId?: string | null) {
    this.store.next({
      ...this.store.value,
      showMasterCreatingModal: true,
      newMasterBelongingGroupId: belongingGroupId,
    });
  }

  closeMasterCreatingModal() {
    this.store.next({
      ...this.store.value,
      showMasterCreatingModal: false,
      newMasterBelongingGroupId: undefined,
    });
  }

  openMasterEditingModal(editingMaster: MasterDocumentData) {
    this.store.next({
      ...this.store.value,
      showMasterEditingModal: true,
      currentEditingMasterId: editingMaster.id,
      currentEditingMasterGroupId: editingMaster.masterGroupId,
    });
  }

  closeMasterEditingModal() {
    this.store.next({
      ...this.store.value,
      showMasterEditingModal: false,
      currentEditingMasterId: undefined,
      currentEditingMasterGroupId: undefined,
    });
  }
}
