import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { PolarFireswitchService } from './bridge/polar-fireswitch.service';
import { PolarFirebaseAuthService } from './polar-firebase-auth.service';
import { ENVIRONMENT } from '../applicationEnvironment';

@Injectable({
  providedIn: 'root',
})
export class PolarFirebaseUserAuthService {
  pathSetUserAuthorizedTeam: string = '';
  pathAddUser: string = '';
  pathDeactivateUser: string = '';
  applicationEnvironment = inject(ENVIRONMENT);

  constructor(
    private auth: AngularFireAuth,
    private firestore: Firestore,
    private storage: Storage,
    private switcher: PolarFireswitchService,
    private authGroupService: PolarFirebaseAuthService,
  ) {
    let host = this.applicationEnvironment.environment.polarApiUrl;

    this.pathSetUserAuthorizedTeam = host + '/set_user_authorized_team';
    this.pathAddUser = host + '/add_authorized_user';
    this.pathDeactivateUser = host + '/deactivate_authorized_user';
  }

  async deactivateUser(userId: string, authGroupId: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const user = await this.auth.currentUser;
      const token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('authorized_group_id', authGroupId);
      formData.append('user_id', userId);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathDeactivateUser, param)
        .then(async (res) => {
          if (res.status === 500) {
            try {
              reject(false);
            } catch {
              reject();
            }
          } else {
            resolve((await res.json())['success']);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  async addUser(
    email: string,
    authGroupId: string,
  ): Promise<{ success: boolean; uid: string; success_to_join_group: boolean }> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('authorized_group_id', authGroupId);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathAddUser, param)
        .then(async (res) => {
          if (res.status === 500) {
            try {
              reject(false);
            } catch {
              reject();
            }
          } else {
            resolve(await res.json());
          }
        })
        .catch(() => {
          reject();
        });
    });
  }

  async setUserTeam(userId: string, authGroupId: string, teamId: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let user = await this.auth.currentUser;
      let token = await user?.getIdToken()!;
      const formData = new FormData();
      formData.append('authorized_group_id', authGroupId);
      formData.append('team_id', teamId);
      formData.append('user_id', userId);

      const param = {
        method: 'POST',
        body: formData,
        headers: { Authorization: token },
      };

      fetch(this.pathSetUserAuthorizedTeam, param)
        .then(async (res) => {
          if (res.status === 500) {
            try {
              reject(false);
            } catch {
              reject();
            }
          } else {
            resolve((await res.json())['success']);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }
}
