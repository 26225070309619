<script
  src="../console-create-company.component.spec.ts"
  xmlns="http://www.w3.org/1999/html"
></script>
<script src="../console-create-company.component.ts"></script>
<div class="base" (click)="close($event)">
  <div class="container">
    <article class="message is-link">
      <div class="message-header">
        <p>会社名：「{{ editCompanyForm.get('companyName')?.value }}」を編集する</p>
      </div>
      <div class="message-body">
        <form name="editCompany" [formGroup]="editCompanyForm" (submit)="updateCompanyInfo()">
          <div class="field">
            <label class="label">会社名</label>
            <div class="control">
              <input
                name="companyName"
                class="input"
                type="text"
                placeholder="会社名を入力してください"
                formControlName="companyName"
              />
              @if (
                editCompanyForm.get('companyName')?.touched &&
                editCompanyForm.get('companyName')?.hasError('required')
              ) {
                <p class="help formError">会社名は必須です。</p>
              }
            </div>
          </div>

          <div class="field">
            <label class="label">メモ</label>
            <div class="control">
              <textarea
                class="textarea"
                type="text"
                placeholder="メモを入力してください"
                formControlName="memo"
              ></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">認証グループID</label>
            <div class="control">
              <input
                name="authGroupId"
                class="input"
                type="text"
                placeholder="認証グループID設定"
                formControlName="authGroupId"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">
              マスタ編集可能
              <input type="checkbox" formControlName="masterEditable" />
            </label>
          </div>

          <div class="field">
            <label class="label">
              データ保持ポリシーの有効
              <input type="checkbox" formControlName="enableDataRetentionPolicy" />
            </label>
          </div>

          @if (isDataRetentionPolicyEnabled()) {
            <div class="field">
              <label class="label">データ保持期間 (日数)</label>
              <div class="control">
                <input
                  name="dataRetentionPeriodInDays"
                  class="input"
                  type="text"
                  placeholder="日数"
                  formControlName="dataRetentionPeriodInDays"
                />
                @if (
                  editCompanyForm
                    .get('dataRetentionPeriodInDays')
                    ?.hasError('invalidDataRetentionPeriodInDays')
                ) {
                  <p class="help formError">データ保持期間が無効です</p>
                }
              </div>
            </div>
          }

          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="editCompanyForm.invalid">
                保存
              </button>
            </div>
            @if (savingCompany) {
              <app-inner-progress [status]="savingStatCompany"></app-inner-progress>
            }
          </div>
        </form>
      </div>
    </article>

    <article class="pt-8 message is-link">
      <div class="message-header">
        <p>ユーザーを追加する</p>
      </div>
      <div class="message-body">
        <form name="addUser" [formGroup]="addUserForm" (submit)="addUser()">
          <div class="field">
            <label class="label">ユーザーID</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="firebaseのユーザーIDを入力"
                formControlName="userId"
              />
              @if (
                addUserForm.get('userId')?.touched &&
                addUserForm.get('userId')?.hasError('required')
              ) {
                <p class="help formError">会社名は必須です。</p>
              }
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="addUserForm.invalid">
                追加
              </button>
            </div>
            @if (savingUser) {
              <app-inner-progress [status]="savingStatUser"></app-inner-progress>
            }
          </div>
        </form>
        <div class="title py-4">登録されてるユーザー一覧</div>
        <table class="table is-fullwidth">
          <thead>
            <th>利用ユーザー</th>
            <th></th>
            <th></th>
          </thead>
          <tbody>
            @for (allowedUser of allowedUsers; track allowedUser) {
              <tr>
                <th>
                  @if (allowedUser.id?.includes('_proxy')) {
                    <span class="tag is-warning">IP制限モード</span>
                  } @else {
                    <span class="tag is-light">通常モード</span>
                  }
                  {{ allowedUser.id }}
                </th>
                <th>
                  @if (allowedUser.id?.includes('_proxy')) {
                    <button class="button px-2" (click)="disableIpRestriction(allowedUser)">
                      IP制限を解除する
                    </button>
                  } @else {
                    <button class="button px-2" (click)="enableIpRestriction(allowedUser)">
                      IP制限モードに変更
                    </button>
                  }
                </th>
                <th>
                  <button class="button px-2" (click)="deleteUser(allowedUser)">削除</button>
                </th>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </article>
    @if (isExistRestrictedIP) {
      <article class="pt-8 message is-link">
        <div class="message-header">
          <p>IP制限リスト</p>
        </div>
        <div class="message-body">
          <form
            name="editRestrictIP"
            [formGroup]="editRestrictIPForm"
            (submit)="updateRestrictIP()"
          >
            <div class="field">
              <label class="label">IP</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="制限IPを入力"
                  formControlName="restrictIP"
                />
                @if (editRestrictIPForm.get('restrictIP')?.touched) {
                  <p class="help formError">
                    @if (editRestrictIPForm.get('restrictIP')?.hasError('required')) {
                      <span> IPは必須です。 </span>
                    }
                    @if (
                      !editRestrictIPForm.get('restrictIP')?.hasError('required') &&
                      editRestrictIPForm.get('restrictIP')?.hasError('invalidIpAddress')
                    ) {
                      <span> IPは有効な形式ではありません。 </span>
                    }
                    @if (
                      currentIPEditIndex === -1 &&
                      editRestrictIPForm.get('restrictIP')?.hasError('isDoubled')
                    ) {
                      <span> 既に登録されています。 </span>
                    }
                  </p>
                }
              </div>
            </div>
            <div class="field is-grouped is-grouped-right">
              <div class="control">
                <button
                  class="button is-link"
                  type="submit"
                  [disabled]="editRestrictIPForm.invalid"
                >
                  {{ currentIPEditIndex === -1 ? '追加' : '保管' }}
                </button>
              </div>
              @if (savingRestrictIP) {
                <app-inner-progress [status]="savingStatRestrictIP"></app-inner-progress>
              }
            </div>
          </form>
          <div class="title py-4">登録されてるIP</div>
          <table class="table is-fullwidth">
            <thead>
              <th>IP List</th>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              @for (restrictIP of restrictIPs; track restrictIP; let index = $index) {
                <tr [ngClass]="{ 'ip-edit-bg': index === currentIPEditIndex }">
                  <th>
                    {{ restrictIP }}
                  </th>
                  <th class="button-container">
                    <button class="button px-7" (click)="editRestrictIP(index)">編集</button>
                  </th>
                  <th class="button-container">
                    <button class="button px-7" (click)="deleteRestrictIP(index)">削除</button>
                  </th>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </article>
    }
    <article class="pt-8 message is-link">
      <div class="message-header">
        <p>仕事を追加する</p>
      </div>
      <div class="message-body">
        <form class="addWork" [formGroup]="addWorkForm" (submit)="addWork()">
          <div class="field" style="flex-grow: 1; margin-right: 12px">
            <label class="label">仕事名</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="仕事名を入力"
                formControlName="workName"
              />
              @if (
                addWorkForm.get('workName')?.touched &&
                addWorkForm.get('workName')?.hasError('required')
              ) {
                <p class="help formError">仕事名は必須です。</p>
              }
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-link" type="submit" [disabled]="addWorkForm.invalid">
                追加
              </button>
            </div>
            @if (savingWork) {
              <app-inner-progress [status]="savingStatWork"></app-inner-progress>
            }
          </div>
        </form>

        <div class="title py-4">登録されてる仕事一覧</div>
        <table class="table is-fullwidth">
          <thead>
            <th>仕事名</th>
            <th></th>
          </thead>
          <tbody>
            @for (work of works; track work) {
              <tr>
                <th>{{ work.name }}</th>
                <th>
                  <button class="button" (click)="deleteWork(work)">削除</button>
                </th>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </article>
  </div>
</div>
