import { Injectable } from '@angular/core';
import {
  Firestore,
  collectionData,
  collection,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  where,
  getDocs,
  query,
  orderBy,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  WithFieldValue,
  Query,
  FieldPath,
  WhereFilterOp,
  QueryFieldFilterConstraint,
  QueryConstraint,
  QuerySnapshot,
  QueryOrderByConstraint,
  documentId,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PolarFirebridgeService } from './polar-firebridge.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Storage, StorageReference, getDownloadURL, ref, uploadBytes } from '@angular/fire/storage';
import { PolarFirebridgeSocketService } from './polar-firebridge-socket.service';

@Injectable({
  providedIn: 'root',
})
export class PolarFireswitchService {
  useBridge: boolean = false;
  useBridgeOnStorage: boolean = false;
  constructor(
    private bridge: PolarFirebridgeService,
    private auth: AngularFireAuth,
    private socket: PolarFirebridgeSocketService,
  ) {}

  async checkIpStatus(): Promise<boolean> {
    return await this.bridge.checkIpStatus();
  }

  documentId(): FieldPath {
    if (!this.useBridge) {
      return documentId();
    } else {
      return this.bridge.documentId() as any;
    }
  }

  async try(firestore: Firestore) {}

  collection(firestore: Firestore, path: string): CollectionReference<DocumentData> {
    if (!this.useBridge) {
      return collection(firestore, path);
    } else {
      return this.bridge.collection(firestore, path) as any;
    }
  }

  doc(col: CollectionReference<DocumentData>, path?: string): DocumentReference<DocumentData> {
    if (!this.useBridge) {
      if (path == undefined) {
        return doc(col);
      } else {
        return doc(col, path);
      }
    } else {
      return this.bridge.doc(col as any, path) as any;
    }
  }

  getDoc<T>(reference: DocumentReference<T>): Promise<DocumentSnapshot<T>> {
    if (!this.useBridge) {
      return getDoc(reference);
    } else {
      return this.bridge.getDoc(reference as any) as any;
    }
  }

  setDoc<T>(
    reference: DocumentReference<T>,
    data: WithFieldValue<T>,
    options?: { merge: boolean },
  ): Promise<void> {
    if (!this.useBridge) {
      if (options == undefined) {
        return setDoc(reference, data);
      } else {
        return setDoc(reference, data, options);
      }
    } else {
      return this.bridge.setDoc(reference as any, data, options) as any;
    }
  }

  deleteDoc(reference: DocumentReference<unknown>): Promise<void> {
    if (!this.useBridge) {
      return deleteDoc(reference);
    } else {
      return this.bridge.deleteDoc(reference as any) as any;
    }
  }

  collectionData<T = DocumentData>(
    query: Query<T>,
    options?: {
      idField?: string;
    },
  ): Observable<T[]> {
    if (!this.useBridge) {
      return collectionData(query, options);
    } else {
      return this.bridge.collectionData(query as any, options as any) as any;
    }
  }

  getDocs<T>(query: Query<T>): Promise<QuerySnapshot<T>> {
    if (!this.useBridge) {
      return getDocs(query);
    } else {
      return this.bridge.getDocs(query as any) as any;
    }
  }

  where(
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    value: unknown,
  ): QueryFieldFilterConstraint {
    if (!this.useBridge) {
      return where(fieldPath, opStr, value);
    } else {
      return this.bridge.where(fieldPath as any, opStr, value) as any;
    }
  }

  orderBy(fieldPath: string | FieldPath): QueryOrderByConstraint {
    if (!this.useBridge) {
      return orderBy(fieldPath);
    } else {
      return this.bridge.orderBy(fieldPath as any) as any;
    }
  }

  query<T>(q: Query<T>, ...queryConstraints: QueryConstraint[]): Query<T> {
    if (!this.useBridge) {
      return query<T>(q, ...queryConstraints);
    } else {
      return this.bridge.query(q as any, ...(queryConstraints as any)) as any;
    }
  }

  // storage functions
  ref(storage: Storage, path: string): StorageReference {
    if (!this.useBridge && !this.useBridgeOnStorage) {
      return ref(storage, path);
    } else {
      return this.bridge.ref(storage, path) as any;
    }
  }

  async uploadBytes(ref: StorageReference, file: File) {
    if (!this.useBridge && !this.useBridgeOnStorage) {
      await uploadBytes(ref, file);
    } else {
      await this.bridge.uploadBytes(ref as any, file);
    }
  }

  async getDownloadURL(ref: StorageReference): Promise<string> {
    if (!this.useBridge && !this.useBridgeOnStorage) {
      return await getDownloadURL(ref);
    } else {
      return await this.bridge.getDownloadURL(ref as any);
    }
  }
}
