import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ClovaDomainUrlAndSecret,
  PrivateConfiguration,
} from 'src/app/polar/entity/PrivateConfiguration';
import { PromptConfiguration } from 'src/app/polar/entity/PromptConfiguration';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

@Component({
  selector: 'app-console-prompt-settings',
  templateUrl: './console-prompt-settings.component.html',
  styleUrls: ['./console-prompt-settings.component.scss'],
})
export class ConsolePromptSettingsComponent implements OnInit {
  promptConfigurationsObservable$?: Observable<PromptConfiguration[]>;
  promptConfigurations: PromptConfiguration[] = [];
  deletes: PromptConfiguration[] = [];

  loading: boolean = false;

  saving: boolean = false;
  savingStat: 'loading' | 'completed' | 'failed' = 'loading';

  constructor(private polar: PolarFirebaseService) {}

  async ngOnInit() {
    this.loading = true;
    this.promptConfigurationsObservable$ = await this.polar.getPromptConfigurations();

    // get clova domains once from observable object to list
    this.promptConfigurationsObservable$.subscribe((d) => {
      this.deletes = [];
      this.promptConfigurations = d;
      this.loading = false;
    });
  }

  addDomain() {
    this.promptConfigurations.push({});
  }

  removeDomain(idx: number) {
    this.deletes.push(this.promptConfigurations[idx]);
    this.promptConfigurations.splice(idx, 1);
  }

  async save() {
    this.saving = true;
    this.savingStat = 'loading';
    try {
      await this.deletes.forEach(async (f) => {
        if (f.id != null && f.id != '' && f.id != undefined) {
          await this.polar.deletePromptConfiguration(f);
        }
      });
      await this.promptConfigurations.forEach(async (f) => {
        await this.polar.setPromptConfiguration(f);
      });
      this.deletes = [];
      this.savingStat = 'completed';
    } catch {
      this.savingStat = 'failed';
    } finally {
    }
  }
}
