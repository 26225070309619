<div class="base">
  <header>
    <h1 class="company-name" *ngIf="authGroupName != undefined">
      {{ authGroupName }} {{ '様' | translate }}
    </h1>

    <h1 class="company-name" *ngIf="authGroupName == undefined">所属している会社がありません</h1>
    <div style="flex-grow: 1"></div>
    <div>
      <ng-container *ngIf="enableAuthGroupSettings && !loading">
        <div
          class="user-ope auth-button"
          tooltip="権限設定"
          tooltipPlacement="bottom"
          (click)="goAuthGroupSettings()"
        >
          <i class="fa-regular fa-users" style="color: white"></i>
        </div>
      </ng-container>
    </div>
    <div
      class="user-ope"
      tooltip="ユーザーについて"
      tooltipPlacement="bottom"
      (click)="showAccountModalWithAuthGroup()"
    >
      <i class="fa-regular fa-user-shield"></i>
    </div>
  </header>
  <div class="gradient-line"></div>

  <app-inner-progress *ngIf="loading"></app-inner-progress>

  <ng-container *ngFor="let company of accessibleCompanies">
    <header class="pb-4" *ngIf="company.companyName != undefined && company.works != undefined">
      <h1 style="font-size: 20px; font-weight: bold; color: #333" translate>
        {{ company.companyName }}
      </h1>
      <div style="flex-grow: 1"></div>
      <div *ngIf="company.masterEditable">
        <ng-container *ngIf="company.masterEditable">
          <div
            class="user-ope smaller"
            tooltip="マスタ設定"
            tooltipPlacement="bottom"
            (click)="showMasterSettingsModalWithCompany(company)"
          >
            <i class="fa-regular fa-table-list"></i>
          </div>
        </ng-container>
      </div>
      <div
        class="user-ope smaller"
        tooltip="会社について"
        tooltipPlacement="bottom"
        (click)="showAccountModalWithCompany(company)"
        *ngIf="enableCompanyCostReference"
      >
        <i class="fa-regular fa-ellipsis"></i>
      </div>
    </header>
    <p
      *ngIf="
        company.companyName != undefined && company.works != undefined && company.works.length == 0
      "
    >
      まだ仕事がありません
    </p>
    <p *ngIf="company.companyName == undefined" style="padding: 24px">表示するデータがありません</p>

    <div class="rows-parent">
      <div class="row" *ngFor="let work of company.works">
        <div class="row-content" (click)="open(work)" tooltip="作業画面に移動">
          <div class="row-title">
            <div class="icon">
              <i class="fa-regular fa-folder-open"></i>
              <div #NOTIF class="notif" *ngIf="false">1</div>
            </div>
            <div class="row-title-text">{{ work.name }}</div>
          </div>
          <div class="gap-row"></div>
          <div class="row-center">
            <p>{{ '作成日:' | translate }} {{ getDate(work.createdAt) }}</p>
            <p>{{ '更新日:' | translate }} {{ getDate(work.updatedAt) }}</p>
          </div>
          <div
            class="row-stat"
            style="pointer-events: all"
            [tooltip]="work.status != 'ready' ? '学習中...' : '利用可能'"
            tooltipPlacement="right"
          >
            <app-inner-progress
              class="training"
              *ngIf="work.status != 'ready'"
            ></app-inner-progress>
            <app-inner-progress
              *ngIf="work.status == 'ready'"
              class="completed"
              status="completed"
            ></app-inner-progress>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="has-text-centered is-size-7 mt-6" style="opacity: 0.7">
    受発注バスターズ {{ version }} - by Batton Inc.
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7">
    {{ userId }}
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7; user-select: all">
    {{ userEmail }}
  </div>
</div>

<app-work-right-bottom-floating></app-work-right-bottom-floating>

<app-main-account-modal
  [companyName]="selectedAccessibleCompany?.companyName"
  [companyId]="selectedAccessibleCompany?.companyId"
  [works]="selectedAccessibleCompany?.works"
  [showAccountBlock]="showAccountSettingsOnAccountModal"
  [showCompanyBlock]="showCompanyInfoOnAccountModal"
  *ngIf="showAccountModal"
  (close)="showAccountModal = false"
  [modalTitle]="accountModalTitle"
></app-main-account-modal>

<ng-container *ngIf="selectedAccessibleCompany?.companyId">
  <app-main-master-settings-modal
    [companyName]="selectedAccessibleCompany?.companyName"
    [companyId]="selectedAccessibleCompany!.companyId"
    *ngIf="showMasterSettingsModal"
    (close)="showMasterSettingsModal = false"
  >
  </app-main-master-settings-modal>
</ng-container>
