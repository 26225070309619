<div class="base">
  <header>
    <h1 class="company-name" *ngIf="companyName != undefined && works != undefined">
      {{ companyName }} {{ '様' | translate }}
    </h1>

    <h1 class="company-name" *ngIf="companyName == undefined">所属している会社がありません</h1>
    <div style="flex-grow: 1"></div>
    <div>
      <ng-container *ngIf="enableAuthGroupSettings && works != undefined">
        <div
          class="user-ope auth-button"
          tooltip="権限設定"
          tooltipPlacement="bottom"
          (click)="goAuthGroupSettings()"
        >
          <i class="fa-regular fa-users"></i>
        </div>
      </ng-container>
    </div>
    <div *ngIf="masterEditable && works != undefined">
      <ng-container *ngIf="companyId">
        <div
          class="user-ope"
          tooltip="マスタ設定"
          tooltipPlacement="bottom"
          (click)="showMasterSettingsModal = true"
        >
          <i class="fa-regular fa-table-list"></i>
        </div>
      </ng-container>
    </div>
    <div
      class="user-ope"
      tooltip="メニュー"
      tooltipPlacement="bottom"
      (click)="showAccountModal = true"
    >
      <i class="fa-regular fa-ellipsis"></i>
    </div>
  </header>
  <div class="gradient-line"></div>
  <header class="pb-4" *ngIf="companyName != undefined && works != undefined">
    <h1 style="font-size: 20px; font-weight: bold; color: #333" translate>仕事を選びましょう</h1>
  </header>

  <app-inner-progress *ngIf="works == undefined"></app-inner-progress>

  <p *ngIf="companyName != undefined && works != undefined && works.length == 0">まだありません</p>
  <p *ngIf="companyName == undefined" style="padding: 24px">表示するデータがありません</p>

  <div class="rows-parent">
    <div class="row" *ngFor="let work of works">
      <div class="row-content" (click)="open(work)" tooltip="作業画面に移動">
        <div class="row-title">
          <div class="icon">
            <i class="fa-regular fa-folder-open"></i>
            <div #NOTIF class="notif" *ngIf="false">1</div>
          </div>
          <div class="row-title-text">{{ work.name }}</div>
        </div>
        <div class="gap-row"></div>
        <div class="row-center">
          <p>{{ '作成日:' | translate }} {{ getDate(work.createdAt) }}</p>
          <p>{{ '更新日:' | translate }} {{ getDate(work.updatedAt) }}</p>
        </div>
        <div
          class="row-stat"
          style="pointer-events: all"
          [tooltip]="work.status != 'ready' ? '学習中...' : '利用可能'"
          tooltipPlacement="right"
        >
          <app-inner-progress class="training" *ngIf="work.status != 'ready'"></app-inner-progress>
          <app-inner-progress
            *ngIf="work.status == 'ready'"
            class="completed"
            status="completed"
          ></app-inner-progress>
        </div>
      </div>
    </div>
  </div>

  <div class="has-text-centered is-size-7 mt-6" style="opacity: 0.7">
    受発注バスターズ {{ version }} - by Batton Inc.
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7">
    {{ userId }}
  </div>
  <div class="has-text-centered is-size-7" style="opacity: 0.7; user-select: all">
    {{ userEmail }}
  </div>
</div>

<app-work-right-bottom-floating></app-work-right-bottom-floating>

<app-main-account-modal
  [companyId]="companyId"
  [works]="works"
  *ngIf="showAccountModal"
  (close)="showAccountModal = false"
></app-main-account-modal>

<ng-container *ngIf="companyId">
  <app-main-master-settings-modal
    [companyId]="companyId"
    *ngIf="showMasterSettingsModal"
    (close)="showMasterSettingsModal = false"
  >
  </app-main-master-settings-modal>
</ng-container>
