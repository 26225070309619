<div class="output tile-margin" style="overflow: auto; width: calc(100% - 128px)">
  <div style="display: flex; flex-direction: row; align-items: center">
    <div class="title" translate>テストデータ選択</div>
  </div>
  <div class="block test-images">
    @for (item of selectableImages; track item) {
      <div
        style="position: relative; min-width: 86px"
        (mouseenter)="mouseOver(item)"
        (mousemove)="mouseMove(item, $event)"
        (mouseleave)="mouseLeave()"
      >
        <img
          class="overable"
          style="height: 85px"
          [src]="item.url"
          [style.borderColor]="selectedTestImage == item.path ? 'var(--console-color)' : null"
          (click)="selectImage.emit(item)"
          (contextmenu)="contextMenu($event, item)"
          [style.cursor]="selectedTestImage == item.path ? 'zoom-in' : 'pointer'"
        />
        <div class="delete-template">
          @if (item.path != selectedTemplate?.referenceImage) {
            <button class="delete is-medium" (click)="deleteSelectedImage.emit(item.path)"></button>
          }
        </div>
      </div>
    }
    @if (loadingTemplate != null) {
      <app-inner-progress [status]="loadingTemplate" style="margin-left: 12px"></app-inner-progress>
    }
    <button
      class="button"
      style="margin-left: 12px; width: 54px; font-size: 12px"
      (click)="pickFile.emit()"
      translate
    >
      +追加
    </button>
  </div>
</div>

@if (mouseOverPath != null) {
  <app-console-zoom-image
    [imageUrl2]="mouseOverPath!"
    [offsetX]="templateOffsetX"
    [offsetY]="templateOffsetY"
  ></app-console-zoom-image>
}
