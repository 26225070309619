import { UserImageInfo } from '../polar/entity/CompanyInfo';
import { Timestamp } from '@angular/fire/firestore';

export function SortUserImageInfos(f: UserImageInfo[]) {
  // 1. group by rawFilePath
  let grouped = f.reduce((group: { [index: string]: UserImageInfo[] }, item) => {
    let key = item.rawFilePath!;
    if (!group[key]) {
      group[key] = [];
    }
    group[key].push(item);
    return group;
  }, {});
  // for each group
  for (let key in grouped) {
    let items = grouped[key];
    // sort by imageIndexFrom
    items.sort((a, b) => {
      return (a.imageIndexFrom ?? 0) - (b.imageIndexFrom ?? 0);
    });
  }
  let groupedArray = Object.keys(grouped).map((key) => {
    return grouped[key];
  });
  // sort by first createdAt
  groupedArray.sort((a, b) => {
    let aCreatedAt = 0;
    if (a[0].createdAt != null) {
      aCreatedAt = (a[0].createdAt! as Timestamp).toMillis();
    }
    let bCreatedAt = 0;
    if (b[0].createdAt != null) {
      bCreatedAt = (b[0].createdAt! as Timestamp).toMillis();
    }
    return aCreatedAt - bCreatedAt;
  });
  // flatten
  return groupedArray.reduce((a, b) => a.concat(b), []);
}

export function timeElapsedPerFile(startTime: Date, fileCount: number): number {
  const currentTime = new Date();
  return Math.floor((currentTime.getTime() - startTime.getTime()) / 1000) / fileCount;
}
