import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';

import { InnerProgressComponent } from '../../common/inner-progress/inner-progress.component';
import { MainAccountModalComponent } from '../../main-account-modal/main-account-modal.component';
import { MainMasterSettingsModalComponent } from '../../main-master-settings-modal/main-master-settings-modal.component';
import { WorkRightBottomFloatingComponent } from '../work-right-bottom-floating/work-right-bottom-floating.component';

import { TooltipDirective } from '../../tooltip.directive';

import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import {
  AccessibleRule,
  PolarFirebaseAuthService,
} from 'src/app/polar/polar-firebase-auth.service';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';

import { WorkInfo } from 'src/app/polar/entity/CompanyInfo';

import { SortWorks } from '../work-list/WorkListUtils';
import { environment } from 'src/environments/environment';

import { TranslateModule } from '@ngx-translate/core';
import tippy from 'tippy.js';

export interface AccessibleCompany {
  companyName: string;
  companyId: string;
  masterEditable: boolean;
  works: WorkInfo[];
}

@Component({
  selector: 'app-work-list-auth-group-enabled',
  templateUrl: './work-list-auth-group-enabled.component.html',
  styleUrls: ['./work-list-auth-group-enabled.component.scss'],
  standalone: true,
  imports: [
    TooltipDirective,
    InnerProgressComponent,
    TranslateModule,
    WorkRightBottomFloatingComponent,
    MainAccountModalComponent,
    MainMasterSettingsModalComponent,
  ],
})
export class WorkListAuthGroupEnabledComponent {
  userEmail?: string | null = '...';
  userId?: string = '...';
  authGroupName: string = '';
  showAccountModal: boolean = false;
  showAccountSettingsOnAccountModal: boolean = false;
  showCompanyInfoOnAccountModal: boolean = false;
  showMasterSettingsModal: boolean = false;
  accessibleWorks: AccessibleRule[] | undefined = undefined;
  accessibleMasterGroups: AccessibleRule[] | undefined = undefined;
  enableAuthGroupSettings: boolean = false;
  enableCompanyCostReference: boolean = false;
  accountModalTitle: string = 'メニュー';

  accessibleCompanies: AccessibleCompany[] = [];

  selectedAccessibleCompany?: AccessibleCompany;

  loading: boolean = false;

  get version(): string {
    return environment.version;
  }

  constructor(
    private commonUi: CommonUiProviderService,
    private router: Router,
    private auth: AngularFireAuth,
    private polar: PolarFirebaseService,
    private polarAuthGroup: PolarFirebaseAuthService,
  ) {}

  async ngOnInit() {
    this.loading = true;
    let userData = await this.auth.currentUser!;
    this.userEmail = userData?.email;
    this.userId = userData?.uid;

    const authGroupId = await this.polarAuthGroup.getAuthGroupId(this.userId!);
    const authGroup = await this.polarAuthGroup.get(authGroupId);
    this.authGroupName = authGroup.name!;

    if (await this.polarAuthGroup.isAuthGroupEnabled()) {
      this.accessibleWorks = await this.polarAuthGroup.getAccessibleWorks();
      this.accessibleMasterGroups = await this.polarAuthGroup.getAccessibleMasterGroups();

      if (await this.polarAuthGroup.isUserInUnlimitedAuthorityTeam(this.userId!)) {
        this.enableAuthGroupSettings = true;
        this.enableCompanyCostReference = true;
      }
    } else {
      throw new Error('AuthGroup is not enabled');
    }

    const companyIds = new Set(this.accessibleWorks.map((a) => a.companyId));
    const companyIdsFromMasterGroups = new Set(this.accessibleMasterGroups.map((a) => a.companyId));
    companyIdsFromMasterGroups.forEach((c) => companyIds.add(c));

    const accessibleCompanies: AccessibleCompany[] = [];

    for (let companyId of companyIds) {
      let accessibleCompany: AccessibleCompany = {
        companyId: companyId,
        companyName: '',
        masterEditable: false,
        works: [],
      };

      let company = await this.polar.getCompany(companyId);
      accessibleCompany.companyName = company.name!;
      accessibleCompany.masterEditable = company.masterEditable ?? false;

      if (accessibleCompany.masterEditable) {
        if (
          this.accessibleMasterGroups == undefined ||
          this.accessibleMasterGroups.length == 0 ||
          this.accessibleMasterGroups.findIndex((a) => a.companyId == companyId) < 0
        ) {
          // if no master group is accessible, then masterEditable is false
          accessibleCompany.masterEditable = false;
        } else {
          accessibleCompany.masterEditable = true;
        }
      }

      const works = await this.polar.getWorksInCompanyArray(accessibleCompany.companyId);

      let wks = works.filter((f) => f.isProduction);
      wks = SortWorks(wks);

      wks = wks.filter((w) => {
        return (
          this.accessibleWorks!.findIndex(
            (a) => a.companyId == companyId && (a.id == w.id || a.id == '*') && a.interactable,
          ) >= 0
        );
      });

      accessibleCompany.works = wks;

      if (wks.length > 0 || accessibleCompany.masterEditable) {
        accessibleCompanies.push(accessibleCompany);
      }
    }

    this.accessibleCompanies = accessibleCompanies;
    this.loading = false;
  }

  getDate(timestamp: Timestamp | FieldValue | undefined) {
    return (timestamp as Timestamp).toDate().toLocaleString('ja-JP');
  }

  async goAuthGroupSettings() {
    const user = await this.auth.currentUser;
    const userId = user!.uid;
    const authGroupId = await this.polarAuthGroup.getAuthGroupId(userId);
    this.router.navigate(['/auth-group/' + authGroupId]);
  }

  open(work: WorkInfo) {
    const companyId = this.accessibleCompanies.find((c) => c.works.find((w) => w.id == work.id))
      ?.companyId;
    if (work.status == 'ready') {
      this.router.navigate(['/work/' + companyId! + '/' + work.id!]);
    } else {
      this.commonUi.showConfirm({
        title: '学習中です',
        body: '学習中のため、この仕事はまだ利用できません。\nもうしばらくお待ちください',
        buttons: ['OK'],
      });
    }
  }

  showMasterSettingsModalWithCompany(company: AccessibleCompany) {
    this.selectedAccessibleCompany = company;
    this.showMasterSettingsModal = true;
  }

  showAccountModalWithCompany(company: AccessibleCompany) {
    this.accountModalTitle = '会社について';
    this.selectedAccessibleCompany = company;
    this.showCompanyInfoOnAccountModal = true;
    this.showAccountSettingsOnAccountModal = false;
    this.showAccountModal = true;
  }

  showAccountModalWithAuthGroup() {
    this.accountModalTitle = 'ユーザーについて';
    this.showAccountSettingsOnAccountModal = true;
    this.showCompanyInfoOnAccountModal = false;
    this.showAccountModal = true;
  }
}
