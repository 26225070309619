import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PolarApiService } from 'src/app/polar/polar-api.service';
import { TemplateInfo } from 'src/app/polar/entity/TemplateInfo';
import {
  CsvLineRowSplitter,
  CsvLineSplitter,
  dumpPromptScriptLastGenerated,
  LogicalWorkflow,
  MapWorkflow,
  SpreadsheetData,
  WorkflowProcessor,
} from 'src/app/polar/entity/Workflow';
import { PolarFirebaseService } from 'src/app/polar/polar-firebase.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonUiProviderService } from 'src/app/common/common-ui-provider.service';
import { serverTimestamp } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { MasterMapSelectService } from '../workflow/workflow-editor/workflow-map-select/master-map-select.service';
import { MASTER_UPDATING_SERVICE } from '../../master-settings/master-updating.service';
import { MasterUpdatingAdminService } from '../console-master-settings/master-updating-admin.service';

@Component({
  selector: 'app-console-create-template',
  templateUrl: './console-create-template.component.html',
  styleUrls: ['./console-create-template.component.scss'],
  providers: [
    MasterMapSelectService,
    { provide: MASTER_UPDATING_SERVICE, useClass: MasterUpdatingAdminService },
  ],
})
export class ConsoleCreateTemplateComponent implements OnInit, OnDestroy {
  private readonly masterMapSelectService = inject(MasterMapSelectService);

  companyId: string = '';
  workId: string = '';
  selectedTemplate?: TemplateInfo;
  selectedFile: File | null = null;

  fileEditing: boolean = false;
  fileEditingData: any;
  fileEditingFile?: File;
  fileEditingStatus: 'rotating' | 'matching' | 'loading' | 'select-rect' | 'exists' = 'loading';
  fileEditingCsv: string = '';

  editingProcessor: WorkflowProcessor = {
    read: {},
    write: {},
    fileName: '',
  };

  editingSpreadsheet: SpreadsheetData = { csv: '', loadableDump: null };
  editingSpreadsheetIsActivated: boolean = false;

  outputCsv: SpreadsheetData = { csv: '', loadableDump: null };

  @Input() saving: boolean = false;
  @Input() savingStat: 'loading' | 'completed' | 'failed' = 'loading';

  get workUrl(): string {
    return 'work/' + this.companyId + '/' + this.workId;
  }

  constructor(
    private clova: PolarApiService,
    private polar: PolarFirebaseService,
    private sanitizer: DomSanitizer,
    private commonUi: CommonUiProviderService,
    private translator: TranslateService,
  ) {
    setInterval(async () => {
      await this.updateOutput();
    }, 3000);
  }

  async ngOnInit() {
    return this.masterMapSelectService.initialize();
  }

  ngOnDestroy() {
    this.masterMapSelectService.finalize();
  }

  setCompanyId(val: string) {
    this.companyId = val;

    this.masterMapSelectService.currentCompanyId = val;
  }

  setWorkId(val: string) {
    this.workId = val;
  }

  selectedTestImage: string = '';
  selectableImages: { url: string; path: string }[] = [];

  loadingTemplate: undefined | 'loading' | 'failed' = undefined;
  expectOutPutFileName: string = '';

  async setSelectedTemplate(val?: TemplateInfo) {
    if (this.selectedTemplate == val) {
      // zoom
      return;
    }
    this.loadingTemplate = 'loading';
    this.lastErrWorkflowId = '';
    this.cellHasWhiteSpace = '';

    try {
      this.currentEstimatedCost = undefined;
      this.selectableImages = [];
      this.requiredColumn = '';
      this.outputCsv = {
        csv: '',
        loadableDump: null,
      };
      this.editingProcessor = {
        read: {},
        write: {},
        fileName: '',
      };
      this.editingSpreadsheet = this.editingProcessor.write.spreadsheet!;
      this.frozenJson = '';
      this.frozenData = {};

      if (val == undefined) {
        this.selectedTemplate = undefined;
        this.loadingTemplate = undefined;
        return;
      }

      this.selectedTemplate = val;
      if (val.workflowProcessor != null && (val.workflowProcessor as any) != '') {
        this.editingProcessor = JSON.parse(JSON.stringify(val.workflowProcessor));
      } else {
        this.editingProcessor = {
          read: {},
          write: {},
          fileName: '',
        };
      }
      this.editingSpreadsheet = this.editingProcessor.write.spreadsheet!;
      this.expectOutPutFileName = this.selectedTemplate.expectOutPutFileName || '';

      if (this.editingProcessor.write.export_cache_json_str != null) {
        this.frozenJson = this.editingProcessor.write.export_cache_json_str;
        this.frozenData = JSON.parse(this.frozenJson);
        this.updateOutput();
      } else {
        this.frozenJson = '';
        this.frozenData = {};
      }
      if (this.editingProcessor.write.export_confidence_cache_json_str != null) {
        this.frozenConfidenceDict = this.editingProcessor.write.export_confidence_cache_json_str;
        this.frozenConfidenceDictData = JSON.parse(this.frozenConfidenceDict);
        this.updateOutput();
      } else {
        this.frozenJson = '';
        this.frozenData = {};
      }
      if (this.editingProcessor.write.export_rects_cache_json_str != null) {
        this.frozenRectsDict = this.editingProcessor.write.export_rects_cache_json_str;
        this.frozenRectsDictData = JSON.parse(this.frozenRectsDict);
        this.updateOutput();
      }
      if (this.editingProcessor?.write?.required_column != null) {
        this.requiredColumn = this.editingProcessor.write.required_column;
      }

      this.selectedTestImage = '';
      if (val.referenceImage != undefined && val.referenceImage != '') {
        this.selectedTestImage = val.referenceImage!;
        let url = await this.polar.getTemplateFile(
          this.companyId,
          this.workId,
          val.referenceImage!,
        );
        if (this.selectedTemplate == val) {
          this.selectableImages.push({
            url: url,
            path: val.referenceImage!,
          });
        }
      }

      if (val.exampleImages != undefined && val.exampleImages != null) {
        for (let i = 0; i < val.exampleImages?.length; i++) {
          if (this.selectedTemplate != val) {
            break;
          }
          let url = await this.polar.getTemplateFile(
            this.companyId,
            this.workId,
            val.exampleImages![i],
          );
          if (this.selectedTemplate != val) {
            break;
          }
          this.selectableImages.push({
            url: url,
            path: val.exampleImages![i],
          });
        }
      }

      this.loadingTemplate = undefined;
    } catch {
      this.loadingTemplate = 'failed';
    }
  }

  async selectImage(item: { url: string; path: string }) {
    this.selectedTestImage = item.path;
  }

  @ViewChild('INPUT')
  el?: ElementRef;

  pickFile() {
    let elm = this.el!.nativeElement as HTMLInputElement;
    elm.click();
  }

  loading: boolean = false;

  async filePicked(e: any) {
    this.loading = true;
    let elm = this.el!.nativeElement as HTMLInputElement;
    let files = elm.files;

    let list: File[] = [];
    for (let i = 0; i < files!.length; i++) {
      if (files![i].type.toLowerCase() == 'application/pdf') {
        // pdf. should be converted
        let image = await this.clova.pdfToImage(files![i]);
        let newFile = new File([image], 'filename', { type: 'image/png' });
        list.push(newFile);
      } else {
        // image. as it is
        list.push(files![i]);
      }
    }

    for (let i = 0; i < list.length; i++) {
      let rotated = await this.clova.rotateImageAuto(list[i]);
      let rotatedFile = new File([rotated], 'filename', { type: 'image/png' });
      let path = await this.polar.uploadTemplateFile(this.companyId, this.workId, rotatedFile);
      this.selectedTemplate?.exampleImages?.push(path);
      let url = await this.polar.getTemplateFile(this.companyId, this.workId, path);
      this.selectableImages.push({
        url: url,
        path: path,
      });
    }

    elm.value = '';
    this.loading = false;
  }

  async deleteSelectedImage(path: string) {
    this.selectableImages = this.selectableImages.filter((item) => {
      return item.path != path;
    });
    this.selectedTemplate!.exampleImages = this.selectedTemplate!.exampleImages!.filter((item) => {
      return item != path;
    });
  }

  url?: SafeResourceUrl;

  async setFile(file: File) {
    this.editingTemplateRects = false;
    this.fileEditingSelectedRects = [];
    this.fileEditingStatus = 'rotating';
    this.fileEditing = true;
    this.fileEditingFile = file;
    let fileRotated = await this.clova.rotateImageAuto(this.fileEditingFile!);
    this.fileEditingStatus = 'matching';
    this.fileEditingFile = new File([fileRotated], 'filename');

    let matched = await this.clova.getMatchedTemplate(
      this.companyId,
      this.workId,
      this.fileEditingFile,
      false,
    );
    this.fileEditingStatus = 'loading';
    if (matched['id'] != '' && matched['similarity'] > 0.6) {
      // matched

      try {
        let wroteResponse = await this.clova.executeReadWrite(
          this.companyId,
          this.workId,
          matched.id,
          false,
          this.fileEditingFile,
        );

        let wrote = wroteResponse['wrote'];
        let confidences = wroteResponse['confidences'];

        let csv = '';

        for (let i = 0; i < wrote.length; i++) {
          let datas = [];
          for (let j = 0; j < wrote[i].length; j++) {
            // replace " to ""
            wrote[i][j] = wrote[i][j].replace(/"/g, '""');
            datas.push('"' + wrote[i][j] + '"');
          }
          csv += datas.join(',') + '\n';
        }

        this.fileEditingCsv = csv;
      } catch {
        this.fileEditingCsv = '';
      }
      this.fileEditingStatus = 'exists';
    } else {
      let result = await this.clova.callGeneral(this.fileEditingFile, false);
      this.fileEditingData = result;
      this.fileEditingStatus = 'select-rect';
    }
  }

  async checkMatch(url: string) {
    this.loading = true;
    let resp = await fetch(url);
    let blob = await resp.blob();
    // rotated
    let file = new File([blob], 'image.png');

    let matched = await this.clova.getMatchedTemplate(this.companyId, this.workId, file, false);
    this.loading = false;

    if (matched['id'] == '') {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'マッチしませんでした',
        buttons: ['OK'],
      });
    } else if (matched['id'] == this.selectedTemplate?.id) {
      await this.commonUi.showConfirm({
        title: '成功',
        body: 'マッチしました!\n正確さ: ' + matched['similarity'] * 100 + '%',
        buttons: ['OK'],
      });
    } else {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: '別の帳票にマッチしました\n' + matched['id'],
        buttons: ['OK'],
      });
    }
  }

  fileEditingSelectedRects: any[] = [];
  editingTemplateRects: boolean = false;

  async editExistingTemplateRects(item: TemplateInfo) {
    if (this.selectedTemplate?.id != item.id) return;
    let currentId = this.selectedTemplate?.id;

    this.editingTemplateRects = true;
    this.fileEditing = true;
    this.fileEditingStatus = 'loading';
    this.fileEditingSelectedRects = [];
    let filePath = await this.polar.getTemplateFile(
      this.companyId,
      this.workId,
      item.referenceImage!,
    );

    if (currentId != this.selectedTemplate?.id) return;

    let resp = await fetch(filePath);
    let blob = await resp.blob();
    // rotated
    let file = new File([blob], 'image.png');

    this.fileEditingFile = file;

    if (currentId != this.selectedTemplate?.id) return;

    let matched = await this.clova.getMatchedTemplate(
      this.companyId,
      this.workId,
      this.fileEditingFile,
      false,
    );

    if (currentId != this.selectedTemplate?.id) return;

    this.fileEditingSelectedRects = matched.combination;
    this.fileEditingStatus = 'loading';

    let result = await this.clova.callGeneral(this.fileEditingFile, false);

    if (currentId != this.selectedTemplate?.id) return;

    if (matched['id'] != '' && matched['similarity'] > 0.6) {
      if (matched['id'] != currentId) {
        await this.commonUi.showConfirm({
          title: 'エラー',
          body: '別の帳票にマッチしました。初期化して表示します',
          buttons: ['OK'],
        });
      }
    } else {
      await this.commonUi.showConfirm({
        title: 'エラー',
        body: 'マッチしませんでした。初期化して表示します',
        buttons: ['OK'],
      });
    }

    this.fileEditingData = result;
    this.fileEditingStatus = 'select-rect';
  }

  async rectsSelected(rectangles: any) {
    if (this.editingTemplateRects) {
      await this.polar.addTemplate(this.companyId, this.workId, {
        id: this.selectedTemplate!.id,
        referenceFields: rectangles,
      });
    } else {
      let path = await this.polar.uploadTemplateFile(
        this.companyId,
        this.workId,
        this.fileEditingFile!,
      );
      this.polar.addTemplate(this.companyId, this.workId, {
        referenceFields: rectangles,
        referenceImage: path,
        exampleImages: [],
        createdAt: new Date(),
      });
    }

    this.fileEditing = false;
    this.selectedFile = null;
    this.fileEditingFile = undefined;
    this.fileEditingData = null;
  }

  async rectCreate() {
    this.fileEditingStatus = 'loading';
    let result = await this.clova.callGeneral(this.fileEditingFile!, false);
    this.fileEditingData = result;
    this.fileEditingStatus = 'select-rect';
  }

  lastTargetCsv: string = '';
  lastRequiredColumn: string = '';
  cellHasWhiteSpace: string = '';

  writing: boolean = false;

  getExcelNameFromXY(x: number, y: number) {
    let name = '';
    while (x >= 0) {
      name = String.fromCharCode((x % 26) + 65) + name;
      x = Math.floor(x / 26) - 1;
    }
    return name + (y + 1);
  }

  checkCsvHasWhiteSpace(csv: string) {
    const lines = CsvLineSplitter(csv);
    const cells = CsvLineRowSplitter(lines);

    let foundCell = "";

    for (let i = 0; i < cells.length; i++) {
      for (let j = 0; j < cells[i].length; j++) {
        if (cells[i][j].trim() != cells[i][j] && cells[i][j].trim() == '') {
          foundCell = this.getExcelNameFromXY(j, i);
          break;
        }
      }
    }

    return foundCell;
  }

  async updateOutput() {
    if (this.editingSpreadsheetIsActivated) return;
    if (this.editingSpreadsheet == null) return;
    let targetCsv = this.editingSpreadsheet.csv;
    if (this.frozenJson == '') return;
    if (this.selectedTemplate == undefined) return;

    if (targetCsv == this.lastTargetCsv && this.lastRequiredColumn == this.requiredColumn) {
      return;
    }

    let selectedTemplate = this.selectedTemplate;

    this.writing = true;

    try {
      const whitespaceCell = this.checkCsvHasWhiteSpace(targetCsv);
      this.cellHasWhiteSpace = whitespaceCell;

      let wroteResponse = await this.clova.executeWrite(
        this.companyId,
        this.workId,
        this.selectedTemplate.id!,
        this.frozenJson,
        targetCsv,
        this.requiredColumn,
        this.frozenConfidenceDict,
        this.frozenRectsDict,
      );

      let wrote = wroteResponse['wrote'];
      let confidences = wroteResponse['confidences'];

      if (this.selectedTemplate != selectedTemplate) {
        this.writing = false;
        console.log('canceled');
        return;
      }

      this.lastTargetCsv = targetCsv;
      this.lastRequiredColumn = this.requiredColumn;

      let csv = '';
      let confidenceCsv = '';

      for (let i = 0; i < wrote.length; i++) {
        let datas = [];
        let datas_confidence = [];
        for (let j = 0; j < wrote[i].length; j++) {
          // replace " to ""
          wrote[i][j] = wrote[i][j].replace(/"/g, '""');
          datas.push('"' + wrote[i][j] + '"');
          datas_confidence.push(confidences[i][j]);
        }
        csv += datas.join(',') + '\n';
        confidenceCsv += datas_confidence.join(',') + '\n';
      }

      this.outputCsv = {
        csv: csv,
        confidenceCsv: confidenceCsv,
        loadableDump: null,
      };
      console.log('wrote');
    } catch {
    } finally {
      this.writing = false;
    }
  }

  async spreadsheetChanged(data: SpreadsheetData) {
    this.editingSpreadsheet = data;
  }

  frozenJson: string = '';
  frozenConfidenceDict: string = '';
  frozenRectsDict: string = '';
  frozenData: { [index: string]: string } = {};
  frozenConfidenceDictData: { [index: string]: string } = {};
  frozenRectsDictData: { [index: string]: string } = {};

  requiredColumn: string = '';

  vectoring: boolean = false;

  async validateAndPreprocess(processor: WorkflowProcessor): Promise<boolean> {
    // validation and other process
    if (processor.read != null && processor.read['*'] != null) {
      let workflowCount = processor.read['*'].length;
      for (let i = 0; i < workflowCount; i++) {
        let workflow = processor.read['*'][i];
        if (workflow.provider == 'map') {
          let map = workflow as MapWorkflow;

          if (map.strategy == 'ai' && map.master != undefined) {
            // put csv_column_from to master's training requirements
            const masterGroupId = map.master!.groupId;
            const masterId = map.master!.id;

            try {
              if (
                map.csv_column_from != undefined &&
                map.csv_column_from != null &&
                map.csv_column_from != '' &&
                masterGroupId != undefined &&
                masterGroupId != null &&
                masterGroupId != '' &&
                masterId != undefined &&
                masterId != null &&
                masterId != ''
              ) {
                // checking csv headers
                const masterInitialDatas = await this.polar.getMastersByIds(
                  this.companyId,
                  masterGroupId,
                  [masterId],
                );

                if (masterInitialDatas.length == 0) {
                  await this.commonUi.showConfirm({
                    title: 'エラー',
                    body: 'AIで使用されているマスターが見つかりません',
                    buttons: ['OK'],
                  });
                  return false;
                }
                if (masterInitialDatas[0].csv.header.indexOf(map.csv_column_from) == -1) {
                  await this.commonUi.showConfirm({
                    title: 'エラー',
                    body: 'AIで使用されているCSVのfromカラムが見つかりません',
                    buttons: ['OK'],
                  });
                  return false;
                }

                // add "csv column from" to the requirements
                await this.clova.addMasterTrainingRequirement(
                  this.companyId,
                  masterGroupId,
                  masterId,
                  map.csv_column_from,
                );

                // get latest master
                const masterData = await this.polar.getLatestMaster(
                  this.companyId,
                  masterGroupId,
                  masterId,
                  true,
                );

                const needVectorize = await this.clova.checkIfVectorizingRequiredForTheMaster(
                  this.companyId,
                  masterGroupId,
                  masterId,
                  masterData.id!,
                );

                if (needVectorize) {
                  // vectorize
                  this.vectoring = true;

                  // create vectors for using it temporary in the console work
                  await this.clova.createVectorsForCsvMappingToSatisfyRequirement(
                    this.companyId,
                    masterGroupId,
                    masterId,
                    masterData.id!,
                  );
                }
              }
            } catch {
              await this.commonUi.showConfirm({
                title: 'CSVの学習リクエストに失敗しました',
                buttons: ['OK'],
              });
              return false;
            } finally {
              this.vectoring = false;
            }
          }

          if (
            map.strategy == 'ai' &&
            (map.vectors_file_name == null ||
              map.vectors_csv_column_name_last_generated != map.csv_column_from)
          ) {
            // generate vectors
            console.log('generate vectors');
            this.vectoring = true;

            try {
              if (map.master !== undefined) {
                // nothing to do here
              } else {
                map.vectors_engine = 'text-embedding-ada-002';

                const fileName = await this.clova.createVectorsForCsvMapping(
                  map.csv_file_name,
                  map.csv_column_from,
                  map.vectors_engine,
                );

                // set
                map.vectors_file_name = fileName;
                map.vectors_csv_column_name_last_generated = map.csv_column_from;
              }
            } catch {
              await this.commonUi.showConfirm({
                title: 'CSVの学習に失敗しました',
                buttons: ['OK'],
              });
              return false;
            } finally {
              this.vectoring = false;
            }
          }
        } else if (workflow.provider == 'logical') {
          let logical = workflow as LogicalWorkflow;

          if (
            logical.promptScriptLastGenerated == null ||
            logical.promptScriptLastGenerated != dumpPromptScriptLastGenerated(logical)
          ) {
            // generate script
            console.log('generate script');
            this.vectoring = true;
            try {
              let script = await this.clova.createScriptFromPrompt(
                logical.prompt,
                logical.unifyNumbers,
                logical.unifySymbols,
                logical.unifyAlphas,
                logical.engine,
              );

              // set
              logical.script = script;
              logical.promptScriptLastGenerated = dumpPromptScriptLastGenerated(logical);
              this.vectoring = false;
            } catch {
              this.vectoring = false;
              this.commonUi.showConfirm({
                title: 'logicalの生成に失敗しました',
                buttons: ['OK'],
              });
              return false;
            }
            this.vectoring = false;
          }
        }
      }
    }
    return true;
  }

  async saveWorkflow(processor: WorkflowProcessor) {
    if (this.selectedTemplate == undefined) return;
    if (this.savingStat == 'loading' && this.saving) {
      this.commonUi.showConfirm({
        title: this.translator.instant('確認'),
        body: this.translator.instant('保存中です。保存が完了するまでお待ちください。'),
        buttons: ['OK'],
      });
      return;
    }

    let selectedTemplate = this.selectedTemplate;

    this.savingStat = 'loading';
    this.saving = true;

    if (this.editingSpreadsheet == null) {
      this.editingSpreadsheet = { csv: '', loadableDump: null };
    }

    if (!(await this.validateAndPreprocess(processor))) {
      this.savingStat = 'failed';
      return;
    }

    processor.write = {
      spreadsheet: this.editingSpreadsheet,
    };
    processor.write.required_column = this.requiredColumn;
    this.editingProcessor = processor;

    if (this.selectedTemplate != selectedTemplate) {
      console.log('failed');
      this.saving = false;
      return;
    }

    await this.polar.addTemplate(this.companyId, this.workId, {
      id: this.selectedTemplate.id,
      workflowProcessor: processor,
      exampleImages: this.selectedTemplate.exampleImages,
      expectOutPutFileName: this.expectOutPutFileName,
    });

    let header = '';
    try {
      let csv = processor.write.spreadsheet!.csv;
      // get header from csv
      header = csv.split('\n')[0];
    } catch {}

    await this.polar.setUserWork(this.companyId, this.workId, {
      updatedAt: serverTimestamp(),
      defaultRow: '',
      //requiredColumn: processor.write.required_column,
      //header: header,
    });

    this.lastTargetCsv = '';

    this.savingStat = 'completed';
    this.saving = false;
  }

  currentEstimatedCost?: number = undefined;
  currentEstimatedCostDetail?: string = undefined;

  async refreshWorkflow(processor: WorkflowProcessor) {
    this.lastErrWorkflowId = '';
    if (this.selectedTemplate == undefined) return;
    if (this.savingStat == 'loading' && this.saving) {
      this.commonUi.showConfirm({
        title: this.translator.instant('確認'),
        body: this.translator.instant('保存中です。保存が完了するまでお待ちください。'),
        buttons: ['OK'],
      });
      return;
    }

    let selectedTemplate = this.selectedTemplate;
    let companyId = this.companyId;
    let workId = this.workId;
    let selectedTestImage = this.selectedTestImage;
    let requiredColumn = this.requiredColumn;

    if (this.editingSpreadsheet == null) {
      this.editingSpreadsheet = { csv: '', loadableDump: null };
    }

    let editingSpreadsheet = this.editingSpreadsheet;

    this.savingStat = 'loading';
    this.saving = true;

    // this will block user operation
    if (!(await this.validateAndPreprocess(processor))) {
      this.savingStat = 'failed';
      return;
    }

    processor.write = {
      spreadsheet: editingSpreadsheet,
    };
    processor.write.required_column = requiredColumn;
    this.editingProcessor = processor;

    if (selectedTemplate.exampleImages == undefined) {
      selectedTemplate.exampleImages = [];
    }

    await this.polar.addTemplate(companyId, workId, {
      id: selectedTemplate.id,
      workflowProcessor: processor,
      exampleImages: selectedTemplate.exampleImages,
    });

    if (this.selectedTemplate != selectedTemplate) {
      console.log('canceled');
      this.saving = false;
      this.savingStat = 'loading';
      return;
    }

    try {
      let result = await this.clova.executeRead(
        companyId,
        workId,
        selectedTemplate.id!,
        false,
        undefined,
        selectedTestImage,
        true,
      );

      if (this.selectedTemplate != selectedTemplate) {
        console.log('canceled');
        this.saving = false;
        this.savingStat = 'loading';
        return;
      }

      try {
        let actualCosts = result['actual_costs'];
        let inYen = 0;
        let generalPrice = actualCosts['general-calls'] * 0.2308;
        let generalTablePrice = actualCosts['general-table-calls'] * 2.3081;
        let rectItemsBasedCalls = 1;
        if (actualCosts['items-rect'] != undefined && actualCosts['items-rect'] != null) {
          rectItemsBasedCalls = Math.floor(actualCosts['items-rect'] / 50) + 1;
        }
        let rectPrice = actualCosts['clova-rect-calls'] * 9.2323 * rectItemsBasedCalls;
        let gptPrice = (actualCosts['natural-tokens'] / 1000.0) * 0.02 * 130;
        let refinePrice = (actualCosts['items-refine'] ? actualCosts['items-refine'] : 0) * 0.4;
        inYen = generalPrice + generalTablePrice + rectPrice + gptPrice + refinePrice;
        this.currentEstimatedCost = inYen;
        this.currentEstimatedCostDetail = '';
        this.currentEstimatedCostDetail +=
          this.translator.instant('# セル') + '\n' + actualCosts['items'] + '\n\n';
        this.currentEstimatedCostDetail += this.translator.instant('# 原価') + '\n';
        this.currentEstimatedCostDetail +=
          'General: ' + generalPrice + '円 (' + actualCosts['general-calls'] + '回)' + '\n';
        this.currentEstimatedCostDetail +=
          'General Table: ' +
          generalTablePrice +
          '円 (' +
          actualCosts['general-table-calls'] +
          '回)' +
          '\n';
        this.currentEstimatedCostDetail +=
          'Rect: ' + rectPrice + '円 (' + actualCosts['clova-rect-calls'] + '回)' + '\n';
        this.currentEstimatedCostDetail +=
          'Natural: ' +
          gptPrice +
          '円 (' +
          actualCosts['natural-tokens'] +
          this.translator.instant('トークン)') +
          '\n';
        this.currentEstimatedCostDetail +=
          'Refine: ' +
          refinePrice +
          '円 (' +
          (actualCosts['items-refine'] ? actualCosts['items-refine'] : 0) +
          this.translator.instant('回)') +
          '\n';
        this.currentEstimatedCostDetail += '==========\n';
        if (this.translator.currentLang == 'ja') {
          this.currentEstimatedCostDetail +=
            this.translator.instant('合計: ') + inYen + '円' + '\n';
        } else if (this.translator.currentLang == 'en') {
          this.currentEstimatedCostDetail +=
            this.translator.instant('合計: ') + '$' + inYen / 130 + '\n';
          this.currentEstimatedCost = inYen / 130;
        }
      } catch {}

      let confidence_dict = result['export_confidence_dict'];
      let rects_dict = result['export_rects_dict'];
      result = result['export_dict'];

      processor.write.export_cache_json_str = JSON.stringify(result);
      processor.write.export_confidence_cache_json_str = JSON.stringify(confidence_dict);
      processor.write.export_rects_cache_json_str = JSON.stringify(rects_dict);
      await this.polar.addTemplate(companyId, workId, {
        id: selectedTemplate.id,
        workflowProcessor: processor,
      });

      if (this.selectedTemplate != selectedTemplate) {
        console.log('canceled');
        this.saving = false;
        this.savingStat = 'loading';
        return;
      }

      this.frozenJson = JSON.stringify(result);
      this.frozenConfidenceDict = JSON.stringify(confidence_dict);
      this.frozenRectsDict = JSON.stringify(rects_dict);
      this.frozenData = result;
      this.frozenConfidenceDictData = confidence_dict;
      this.frozenRectsDictData = rects_dict;

      this.lastTargetCsv = '';

      await this.updateOutput();

      this.savingStat = 'completed';
      this.saving = false;
    } catch (e: any) {
      if (e != null && e.at != null && e.at != '') {
        this.lastErrWorkflowId = e.at;
      }
      this.savingStat = 'failed';
      this.saving = true;
    }
  }

  lastErrWorkflowId: string = '';

  download(filename: string, text: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, text], { type: 'text/csv' });
    var element = document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(blob));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  saveCsv() {
    this.download('example.csv', this.outputCsv.csv);
  }
}
