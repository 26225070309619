<app-console-header
  (workSelect)="setWorkId($event)"
  (companySelect)="setCompanyId($event)"
  [targetUrl]="workUrl"
></app-console-header>

@if (workId.length == 0) {
  <div class="notification is-danger is-light mx-3" style="width: 600px">
    仕事が存在していません。会社作成より仕事を追加してください。
  </div>
}

@if (workId != '') {
  <div class="parent classification-and-template">
    <app-console-image-classification-uploader
      (fileSelect)="setFile($event)"
      [workId]="workId"
      [companyId]="companyId"
    ></app-console-image-classification-uploader>
    <app-console-template-list
      [workId]="workId"
      [companyId]="companyId"
      [selectedTemplate]="selectedTemplate == undefined ? '' : selectedTemplate.id"
      (templateSelect)="setSelectedTemplate($event)"
      (templateResetRects)="editExistingTemplateRects($event)"
    ></app-console-template-list>
  </div>
}
@if (workId != '' && selectedTemplate == null) {
  <p class="simple_text" translate>テンプレートを作成してください</p>
}
@if (selectedTemplate != null && selectedTemplate.id != undefined && selectedTemplate.id != '') {
  <div class="parent workflow-and-output">
    <div class="workflow">
      <app-label-and-text-input
        class="templete-filename-input"
        [(value)]="expectOutPutFileName"
        [label]="'出力ファイル名'"
      >
      </app-label-and-text-input>
      <app-workflow-editor
        [proc]="editingProcessor"
        (readSave)="saveWorkflow($event)"
        (readRefresh)="refreshWorkflow($event)"
        [saving]="saving"
        [savingStat]="savingStat"
        [keyValues]="frozenData"
        [companyId]="companyId"
        [workId]="workId"
        [lastErrId]="lastErrWorkflowId"
        [estimatedCost]="currentEstimatedCost"
        [estimatedCostDetail]="currentEstimatedCostDetail"
      ></app-workflow-editor>
    </div>
    <div class="output tile-margin">
      <div style="display: flex; flex-direction: row; align-items: center">
        <div class="title" style="width: 46px" translate>出力</div>
        @if (cellHasWhiteSpace != '') {
          <p class="empty-alert">*{{ cellHasWhiteSpace }}に空白が含まれています</p>
        }
        <div style="flex-grow: 1"></div>
        <app-label-and-text-input
          style="flex-grow: 1; max-width: 300px; margin-bottom: 4px"
          [label]="'必須カラム' | translate"
          [grow]="1"
          [(value)]="requiredColumn"
        ></app-label-and-text-input>
      </div>
      <div class="block">
        <app-spreadsheet
          (changed)="spreadsheetChanged($event)"
          [data]="editingProcessor.write.spreadsheet"
          (activateStatusChanged)="editingSpreadsheetIsActivated = $event"
        ></app-spreadsheet>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center">
        <div class="title" translate>結果サンプル</div>
        @if (writing) {
          <app-inner-progress></app-inner-progress>
        }
        <div style="flex-grow: 1"></div>
        <button (click)="saveCsv()" class="save">
          <p>CSV</p>
        </button>
      </div>
      <div class="block" style="position: relative">
        <app-spreadsheet [readonly]="true" [data]="outputCsv"></app-spreadsheet>
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            background: gray;
            width: 100%;
            height: 100%;
            opacity: 0.05;
          "
        ></div>
      </div>
    </div>
  </div>
}
@if (selectedTemplate != null && selectedTemplate.id != undefined && selectedTemplate.id != '') {
  <div class="parent workflow-and-output" style="padding: 4px">
    <app-console-test-images-list
      [selectableImages]="selectableImages"
      [selectedTestImage]="selectedTestImage"
      [selectedTemplate]="selectedTemplate"
      [loadingTemplate]="loadingTemplate"
      (selectImage)="selectImage($event)"
      (deleteSelectedImage)="deleteSelectedImage($event)"
      (pickFile)="pickFile()"
      (checkMatch)="checkMatch($event)"
    ></app-console-test-images-list>
  </div>
}

<input
  #INPUT
  class="file-input"
  type="file"
  accept="text/csv"
  accept="image/*,application/pdf"
  (change)="filePicked($event)"
/>

@if (loading) {
  <div class="loading">
    <p translate>ファイルをアップロードしています...</p>
    <app-inner-progress></app-inner-progress>
  </div>
}

@if (vectoring) {
  <div class="loading">
    <p translate>学習しています...</p>
    <app-inner-progress></app-inner-progress>
  </div>
}

@if (classfiicationVersion === 'v2') {
  <div>
    <app-console-classification-version-second-selector
      [company_id]="companyId"
      [work_id]="workId"
      [isActiveModal]="this.isActivateModalV2"
      [fileEditingFile]="this.fileEditingFile"
      [v2CandidatesData]="this.v2CandidatesData"
      [status]="fileEditingStatus"
    ></app-console-classification-version-second-selector>
  </div>
} @else {
  @if (fileEditing) {
    <app-console-bounding-box-selector
      [rawData]="fileEditingData"
      [image]="fileEditingFile"
      [status]="fileEditingStatus"
      [csv]="fileEditingCsv"
      (selectRectsComplete)="rectsSelected($event)"
      (close)="fileEditing = false"
      (create)="rectCreate()"
      [preSelectedRects]="fileEditingSelectedRects"
    ></app-console-bounding-box-selector>
  }
}
