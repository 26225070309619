<div class="half-modal bottom-sheet slide-in-up pl-1 pr-1" [ngClass]="openModal ? 'modal-enter' : 'modal-leave'">
  <div class="message is-danger css-error-content">
    <div class="message-header">
      <span>CSV のエラーの詳細</span>
      <button class="delete" (click)="closeModal.emit()"></button>
    </div>
    <div class="message-body csv-error">
      <dl>
        <ng-container *ngFor="let message of csvErrorMessages">
          <dt>{{ message.message }}</dt>
          <dd>{{ message.row }}</dd>
        </ng-container>
      </dl>
    </div>
  </div>
</div>
